import {dom as DOM_UTILS} from 'core-utils';
import {template as TPL_RENDERER} from 'core-utils';
import {aveCanvasTemplate as AVE_TPL} from './ave-canvas-template';
const __ = {},
	exports = {
		'__': __
	};
window.i18n=window.i18n||{};
__.optionMappings = {
	'Trunk': window.i18n['rendering-layer.option-trunk'],
	'Doors': window.i18n['rendering-layer.option-doors'],
	'MMI': window.i18n['rendering-layer.option-mmi'],
	'RTTVoid': window.i18n['rendering-layer.option-env-grey'],
	'WhiteVoid': window.i18n['rendering-layer.option-env-white'],
	'Tunnel_Env': window.i18n['rendering-layer.option-Tunnel_Env'],
	'Iceland_Void': window.i18n['rendering-layer.option-Iceland_Void'],
	'SoftTop': window.i18n['rendering-layer.option-SoftTop']
};

__.oDefaults = {
	'sClassHidden': 'nm-hidden',
	'sClassActive': 'nm-active',
	'sClassSelectorClosed': 'nm-icon-expand-white',
	'sClassSelectorOpen': 'nm-icon-collapse-white',

	'sSelectorOptionsSelectorWrapper': '.nm-ave-animation-selector-wrapper',
	'sSelectorOptionsSelector': '.nm-ave-animation-selector',
	'sSelectorActive3dContainer': '.nm-md-ave-container',
	'sSelectorStreamWrapper': '#streamWrapper'
};

/**
 * render the animation controls
 * @param {Array.<Object>} animationArr - array containing the available animations
 * @returns {string} - html string of the animation controls
 */
exports.renderAnimationControls = function(animationArr) {

	var animationControls = '';
	var optionKeys = Object.keys(__.optionMappings);
	var i, l;

	for (i=0, l=optionKeys.length; i<l; i++) {
		if (animationArr.indexOf(optionKeys[i]) > -1) {
			animationControls = animationControls +
				__.renderAnimationOption(optionKeys[i], __.optionMappings[optionKeys[i]]);
		}
	}
	return animationControls;
};

/**
 * render enironment controls
 * @param {Array<Object>} environmentsArr - array containing the evalable environments
 * @returns {string} - html string of the environment controls
 */
exports.renderEnvironmentControls = function(environmentsArr) {

	var environmentControls = '';
	var optionKeys = Object.keys(__.optionMappings);
	var i, l;

	for (i=0, l=optionKeys.length; i<l; i++) {
		if (environmentsArr.indexOf(optionKeys[i]) > -1) {
			environmentControls = environmentControls +
				__.renderEnvironmentOption(optionKeys[i], __.optionMappings[optionKeys[i]]);
		}
	}
	return environmentControls;
};

/**
 * enables the options button
 * @returns {void}
 */
exports.enableOptions = function() {
	DOM_UTILS.getElement(__.oDefaults.sSelectorOptionsSelector).classList.remove(__.oDefaults.sClassHidden);
	DOM_UTILS.getEventDelegate('body').off('click', __.oDefaults.sSelectorOptionsSelectorWrapper, __.handlerAnimationSelectorClick);
	DOM_UTILS.getEventDelegate('body').on('click', __.oDefaults.sSelectorOptionsSelectorWrapper, __.handlerAnimationSelectorClick);

};

/**
 * render an animation option
 * @param {string} animationKey - the animation key of the animation to render
 * @param {string} animationName - the animation name of the animation to render
 * @returns {string} - html string of one single animation option
 */
__.renderAnimationOption = function(animationKey, animationName) {
	return '<li class="nm-avectrl-animation animation-option nm-el-lbl nm-el-lbl-01 nm-at-lbl-b" data-animation="' +
			animationKey + '">' + animationName + '</li>';
};

/**
 * render an enironment option
 * @param {string} envKey - key of the environment to render
 * @param {string} envName - name of the environement to render
 * @returns {string} - html string of one single environment option
 */
__.renderEnvironmentOption = function(envKey, envName) {
	return '<li class="nm-avectrl-environment animation-option nm-el-lbl nm-el-lbl-01 nm-at-lbl-b" data-environment="' +
			envKey + '">' + envName + '</li>';
};

/**
 * click handler for option button
 * @param {Object} e - the click event
 * @returns {void}
 */
__.handlerAnimationSelectorClick = function(e) {
	var optionsSelector = DOM_UTILS.getElement(__.oDefaults.sSelectorOptionsSelector);
	var optionsSelectorWrapper = DOM_UTILS.getElement(__.oDefaults.sSelectorOptionsSelectorWrapper);
	e.preventDefault();

	if (optionsSelector.classList.contains(__.oDefaults.sClassSelectorClosed)) {
		optionsSelector.classList.remove(__.oDefaults.sClassSelectorClosed);
		optionsSelector.classList.add(__.oDefaults.sClassSelectorOpen);
		optionsSelectorWrapper.classList.add('active');
	}
	else {
		optionsSelector.classList.remove(__.oDefaults.sClassSelectorOpen);
		optionsSelector.classList.add(__.oDefaults.sClassSelectorClosed);
		optionsSelectorWrapper.classList.remove('active');
	}
};

/**
 * stop scroll wheel
 * @param {Object} e - the mousewheel event
 * @returns {void}
 */
__.stopWheel = function(e) {
	var event;

	if (!e) { /* IE7, IE8, Chrome, Safari */
		event = window.event;
	}
	else {
		event = e;
	}

	if (!!event && event.preventDefault) { /* Chrome, Safari, Firefox */
		event.preventDefault();
	}
	if (!!event) {
		event.returnValue = false; /* IE7, IE8 */
	}
};

/**
 * catch scroll wheel
 * @returns {void}
 */
__.catchScrollWheel = function() {
	document.addEventListener('mousewheel', __.stopWheel);
	document.addEventListener('DOMMouseScroll', __.stopWheel);
};

/**
 * release scroll wheel
 * @returns {void}
 */
__.releaseScrollWheel = function() {
	document.removeEventListener('mousewheel', __.stopWheel);
	document.removeEventListener('DOMMouseScroll', __.stopWheel);
};

/**
 * insert AVE canvas before selectore element
 * @param {string} canvasId - the id of the container to add AVE to
 * @returns {void}
 */
exports.activateAveCanvas = function(canvasId) {
	console.log('ave-dom: activate ave canvas');
	var canvas = document.getElementById(canvasId);

	if (DOM_UTILS.isElement(canvas)) {
		var renderer = document.createElement('div');
		renderer.innerHTML = TPL_RENDERER.render(AVE_TPL, {});
		canvas.insertBefore(renderer.firstChild, canvas.firstChild);

		DOM_UTILS.getEventDelegate('body').on('mouseover', '#'+canvasId, __.catchScrollWheel);
		DOM_UTILS.getEventDelegate('body').on('mouseout', '#'+canvasId, __.releaseScrollWheel);
	}

};

exports.resizeStreamWrapper = function() {
	console.log('resizeStream');
	var streamWrapperObj = DOM_UTILS.getElement(__.oDefaults.sSelectorStreamWrapper);
	if (DOM_UTILS.isElement(streamWrapperObj)) {
		streamWrapperObj.style.width = '100%';
		streamWrapperObj.style.height = '100%';
		streamWrapperObj.style.position = 'absolute';
	}
};

/**
 * move AVE canvas to another element
 * @param {string} originId - the id of the original container
 * @param {string} targetId - the id of the target container
 * @returns {void}
 */
exports.moveAveCanvas = function(originId, targetId) {
	var originCanvas = document.getElementById(originId);
	var target = document.getElementById(targetId);
	var selectorOptionsWrapper = DOM_UTILS.getElement(__.oDefaults.sSelectorOptionsSelectorWrapper, originCanvas);
	console.log('ave-dom: move ave canvas');

	if (DOM_UTILS.isElement(originCanvas) && DOM_UTILS.isElement(selectorOptionsWrapper) && DOM_UTILS.isElement(target)) {
		originCanvas.classList.remove(__.oDefaults.sClassActive);
		target.insertBefore(selectorOptionsWrapper, target.firstChild);
		DOM_UTILS.getEventDelegate('body').on('mouseover', '#'+targetId, __.catchScrollWheel);
		DOM_UTILS.getEventDelegate('body').on('mouseout', '#'+targetId, __.releaseScrollWheel);
	}
	// exports.resizeStreamWrapper();
};

/**
 * remove AVE from canvas
 * @param {string} canvasId - the id of the container including the AVE
 * @returns {void}
 */
exports.deactivateAveCanvas = function(canvasId) {
	console.info('ave-dom: deactivate ave canvas');
	var canvas = document.getElementById(canvasId);
	if (DOM_UTILS.isElement(canvas)) {
		while (canvas.hasChildNodes()) {
			canvas.removeChild(canvas.firstChild);
		}
		DOM_UTILS.getEventDelegate('body').off('mouseover', '#'+canvasId, __.catchScrollWheel);
		DOM_UTILS.getEventDelegate('body').off('mouseout', '#'+canvasId, __.releaseScrollWheel);
	}
};

export {exports as aveDom};
