/**
 * isPageTypeNemo
 * @returns {boolean} returns true if pageType is nemo / false if pageType does not exist or is != nemo
 */
const isPageTypeNemo = () => {
	let nemoType = false;

	const content = document.querySelector('.nm-content');

	if (content) {
		const dataType = content.getAttribute('data-type');

		if (dataType && dataType === 'nemo') {
			nemoType = true;
		}
	}

	return nemoType;
};

/**
 * isModelStartPage
 * @returns {boolean} returns true if current page is a modelstartpage / false if not
 */
const isModelStartPage = () => {
	let isTemplateHome = false;

	const content = document.querySelector('.nm-content');

	if (content) {
		const dataTemplate = content.getAttribute('data-template');

		if (dataTemplate && dataTemplate === 'home') {
			isTemplateHome = true;
		}
	}

	return isTemplateHome;
};

/**
 * get product info for DPU item
 * @param {Object} dpuItem - the dpu item
 * @return {Object} the product info
 */
const extractProductInfo = (dpuItem) => {
	const productID = dpuItem.id ? {productID: dpuItem.id} : {};
	const productMbvId = dpuItem.mbvId
		? {productMbvId: dpuItem.mbvId}
		: {};
	const productName = dpuItem.name ? {productName: dpuItem.name} : {};
	const mileage = dpuItem.mileage ? {mileage: dpuItem.mileage} : {};

	return Object.assign({}, productID, productMbvId, productName, mileage);
};

/**
 * get incentives info for DPU item
 * @param {Object} dpuItems - the dpu item
 * @return {Object} the incentive info
 */
const extractIncentiveInfo = (dpuItems) => {

	let incentives = dpuItems.map(incentive => {
		return {
			id : incentive.actionId,
			value : incentive.value,
			targetGroup : incentive.customerGroupClassification,
			discountSelected : incentive.status === "11010"
		};
	});

	return incentives;
};

/**
 * get the Family Hierarchy
 * @param {Object} dpuItem - the dpu Item
 * @param {Object} families - dpu families
 * @returns {array} the hierarchy
 */
const getFamilyHierarchy = (dpuItem, families) => {
	const hasFamilyHierarchy = famHierarchy =>
		typeof famHierarchy !== 'undefined'
		&& famHierarchy.length > 0;

	return families
		&& families[dpuItem.family]
		&& hasFamilyHierarchy(dpuItem.family.split('.'))
		&& dpuItem.family.split('.')
		|| [];
};

/**
 * get the Product Type
 * @param {Object} dpuItem - the dpu Item
 * @param {Object} families - dpu families
 * @returns {string} the product
 */
const getProductType = (dpuItem, families) => {
	const hasFamilyType = family =>
		family.type &&
		typeof family.type !== 'undefined';

	return families &&
		families[dpuItem.family] &&
		hasFamilyType(families[dpuItem.family]) &&
		families[dpuItem.family].type || '';
};

/**
 * get the product category
 * @param {Object} dpuItem - the dpu item
 * @param {Object} families - dpu families
 * @return {Object} the product category
 */
const extractProductCategory = (dpuItem, families) => {
	const [primaryCategory=''] = getFamilyHierarchy(dpuItem, families);
	const [, subCategory1=''] = getFamilyHierarchy(dpuItem, families);
	const productType = getProductType(dpuItem, families);

	return {
		primaryCategory,
		subCategory1,
		productType
	};
};

/**
 * get price info for DPU item
 * @param {Object} dpuItem - the dpu item
 * @return {Object} the product info
 */
const getPrice = (dpuItem) => {
	const basePrice = dpuItem.price ? {price: dpuItem.price} : {};
	// These are required on the documentation but not delivered at the
	// moment by the DPU
	// const taxRate = dpuItem.taxRate ? {taxRate: dpuItem.taxRate}:{};
	// const priceWithTax = dpuItem.price && dpuItem.taxRate ?
	// {priceWithTax: dpuItem.price*(1+(dpuItem.price/100))}:{};

	return Object.assign(
		{},
		basePrice
		// taxRate,
		// priceWithTax
	);
};

/**
 * get attributes info for DPU item
 * @param {Object} dpuItem - the dpu item
 * @return {Object} the product info
 */
const getAttributes = () => {
	// We ignore this section until the product documentation is completed
	// let definedAttributesOnDocumentation = {
	// 	bodyType: '',
	// 	fuelType: '',
	// 	gearType: '',
	// 	driveType: '',
	// 	engine: '',
	// 	transmission: '',
	// 	ecom: '',
	// 	modelLine: '',
	// 	model: '',
	// 	modelYear: '',
	// 	modelShortCode: '',
	// 	dealerID: '',
	// 	rentalLocation: '',
	// 	rentalPeriod: '',
	// 	rentalDuration: ''
	// };

	return {};
};

/**
 * gets a product item in the needed object structure
 * @param {Object} dpuItem - the DPU item to create tracking object for
 * @param {Object} incentives_ - dpu incentives for the engines
 * @param {Object} families - dpu families
 * @returns {Object} tracking object in the correct form
 */
const getProductItem = (dpuItem, incentives_, families) => {
	const productInfo =
		Object.keys(extractProductInfo(dpuItem)).length !== 0
			? {productInfo: extractProductInfo(dpuItem)}
			: {};

	let incentives = [];

	if (incentives_ && dpuItem.family === 'synthetic.motor' && Object.keys(extractIncentiveInfo(incentives_)).length !== 0) {
		incentives = {incentives: extractIncentiveInfo(incentives_)};
	}

	const category =
		Object.keys(extractProductCategory(dpuItem, families)).length !== 0
			? {category: extractProductCategory(dpuItem, families)}
			: {};
	const price =
		getPrice(dpuItem) !== {}
			? {price: getPrice(dpuItem)}
			: {};
	const attributes =
		Object.keys(getAttributes(dpuItem)).length !== 0
			? {attributes: getAttributes(dpuItem)}
			: {};
	return Object.assign({}, productInfo, incentives, category, price, attributes);
};

function useConfigurationTrackingEvent(type) {
	const validStartTypes = [
		'audicode-input',
		'teaser-audicode',
		'audicode.visualizer',
		'audicode-search',
		'teaser-prstring',
		'user-configuration',
		'configuration-reset'
	];

	return validStartTypes.indexOf(type) > -1;
}

export {
	isModelStartPage,
	isPageTypeNemo,
	getProductItem,
	useConfigurationTrackingEvent
};
