const template = `<div class="nm-su-result-audicode-wrap">
	<div class="nm-su-result-audicode">
		<h2 class="nm-el-hdl nm-el-hdl-05 nm-at-hdl-b">{{=window.i18n['search-layer.audicode.error']}}: </h2>
		<div class="nm-error">
			<p class="nm-error-layer-message nm-el-pg nm-el-pg-03 nm-at-pg-indicator">{{=it.message}}</p>
		</div>
		{{?it.pdflink}}
			<a class="audi-link-s audi-link-s--arrow-front" href="{{=it.pdflink}}" target="_blank">
				<span class="audi-link-s__text">{{=it.pdflabel}}</span>
				<svg class="audi-link-s__icon">
					<use xlink:href="{{=SETUPS.get('coreci.url.assets')+"/icons/svg/print-small.svg#nm-icon-print-small"}}"></use>
				</svg>
			</a>
		{{?}}
	</div>
</div>`;
export {template as searchAudicodeErrorTemplate};
