import {USERDATA_STORE_ID} from '../stores/userdata-store';
import {stateRegistry} from 'microkernel';
import carstoreApi from './carstore-api';

export default class CarstoreSaveAudicode extends HTMLElement {
	constructor() {
		super();
		this.updateVisibility = this.updateVisibility.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
	}

	connectedCallback() {
		stateRegistry.subscribeToStore(USERDATA_STORE_ID, this.updateVisibility);

		this.button = this.querySelector('button');
		this.button.addEventListener('click', this.handleSaveClick);
	}

	disconnectedCallback() {
		stateRegistry.unsubscribeFromStore(USERDATA_STORE_ID, this.updateVisibility);
	}

	updateVisibility(state) {
		const audicode = state.currentAudicode;
		this.setAttribute('data-audicode', audicode);

		if (audicode && state.audicodes !== null && !state.audicodes.includes(audicode)) {
			this.classList.remove('nm-hidden');
		}
		else {
			this.classList.add('nm-hidden');
		}
	}

	handleSaveClick() {
		const audicode = this.getAttribute('data-audicode');

		if (audicode) {
			carstoreApi.saveAudicode(audicode);
		}
	}
}

if (window.customElements.get('audi-carstore-save-audicode') === undefined) {
	window.customElements.define('audi-carstore-save-audicode', CarstoreSaveAudicode);
}
