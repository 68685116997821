import {formGroup as FormGroup} from './group';
import {dpuApi as CONFIGURATOR_API} from '../dpu/api';
import {dpuController as DPU_CONTROLLER} from '../dpu/controller';
import {dynamicDot} from 'core-application';
import {appEvents as EVENTS} from 'core-application';
import {doT} from 'core-vendor';

const _forms = {};
// mapped templates
let _templateMap = {},
	// doT template functions; lazily loaded and cached;
	_templateFuncs = {},
	// All custom doT templates; loaded on initialize;
	_customTemplateIds = {},
	// urls for different api entry points; loaded on initialize;
	_apiBaseUrl,
	// Initial form datasets. Added via exports.addInitData;
	_initData = {};
// Internal module methods
const __ = {},
	// Publicly usable module methods
	exports = {
		__: __
	};
/**
 * Initializes a form
 */
__.initFormAsync = function(formContainer) {
	return new Promise((resolve, reject) => {
		const id = formContainer.attr('id');
		const url = __.getUrl('start', id);
		if (_initData[id] !== undefined) {
			_forms[id] = new __.Form(_initData[id], _customTemplateIds);
			_forms[id].initializeFormAsync().then(() => {
				_forms[id].el.appendTo(formContainer);
				resolve(_forms[id]);
			});
		}
		else {
			fetch(url, {credentials: 'include'})
				.then(response => {
					if (response.ok) {
						return response.json();
					}
					else {
						throw new Error(response.statusText);
					}
				})
				.then(response => {
					const data = exports.responseToObj(response);
					if (data === false) {
						__.displayStandardError(id);
						resolve(id);
					}
					else if (!!data.form) {
						_forms[id] = new __.Form(data, _customTemplateIds);
						_forms[id].initializeFormAsync().then(() => {
							_forms[id].el.appendTo(formContainer);
							resolve(_forms[id]);
							// update subsession in configurator
							if (!!(data.header && data.header.subsession)) {
								DPU_CONTROLLER.setSubsessionID(data.header.subsession);
							}
						});
					}
					else {
						__.displayStandardError(id);
						resolve(id);
					}
				})
				.catch((error) => {
					__.displayStandardError(id);
					reject(error);
				});
		}
	});
};
__.bindEnablers = function() {
	jQuery('body .nm-form input[data-enables]').on('change', function(e) {
		var related, targetEl, status, i, el, disabledVal;
		targetEl = jQuery(e.target);
		related = targetEl.attr('data-enables').split(';');
		// Sanitize domIDs and save as jQuery obj.
		for (i = related.length; i > -1; i--) {
			related[i] = __.sanitizeId(related[i]);
		}
		// set active status
		status = targetEl.attr('data-active') || 'off';
		if (status === 'off') {
			status = 'on';
		}
		else {
			status = 'off';
		}
		targetEl.attr('data-active', status);
		for (i = related.length; i > -1; i--) {
			if (!!related[i]) {
				el = jQuery('#nm-id-form-field-' + related[i] + ' input');
				disabledVal = status === 'off' ? 'disabled' : false;
				el.attr('disabled', disabledVal);
			}
		}
	});
};
/**
 * Return an object containing the ids of all elements with the given
 * selector.
 * This is used to make lookups of custom templates faster.
 */
__.getCustomTemplateIds = function(selector) {
	var ids = {};
	if (dynamicDot.requiresDynamicDot()) {
		ids["nm-id-form-or_nls-vvega-nls-page-vvega-nls-foreword-tpl"] = true;
		ids["nm-id-form-tdrdeal-vvega-enquiry-dealer-page-form-tpl"] = true;
		ids["nm-id-form-or_nls-vvega-nls-page-vvega-nls-foreword-tpl"] = true;
		ids["nm-id-form-tdrdealerreq-vvega-probe-request-page-Item-VehicleConfigurationInterest-VehicleCarlineInterest-tpl"] = true;
	}
	else {
		jQuery(selector).each(function() {
			ids[jQuery(this).attr('id')] = true;
		});
	}

	return ids;
};
__.blockForm = function(form) {
	var formEl = jQuery('#' + form.id),
		shaderEl = formEl.find('.nm-form-shader');
	shaderEl.height(formEl.height() + 'px').show();
};
/**
 * Replaces form container contents.
 * Triggers events accordingly.
 */
__.handleFormAsync = function(data, prevForm) {
	return new Promise(resolve => {
		var prevFormData = {};
		let initialized = true;
		data = exports.responseToObj(data);
		// scroll to top
		// Display error if data cannot be parsed
		if (data === false) {
			__.displayStandardError(prevForm.id);
		}
		// Replace form contents.
		else if (!!data.form) {
			// remove event bindings
			jQuery('#' + prevForm.id).off('blur, click, change');
			jQuery('#' + prevForm.id + ' button[data-button-action]').off('click');
			initialized = false;
			_forms[prevForm.id] = new __.Form(data, _customTemplateIds);
			_forms[prevForm.id].initializeFormAsync().then(() => {
				jQuery('#' + data.form.id)
					.html('')
					.append(_forms[prevForm.id].el);
				__.scrollToTop(prevForm.id);
				resolve(_forms[prevForm.id]);
			});
		}
		else if (!!data.success) {
			prevFormData.formHeadline = prevForm.data.form.name;
			prevFormData.message = data.message;
			jQuery('#' + prevForm.id).html(prevForm.getSuccessString(prevFormData));
			// mark form as finished in DOM
			jQuery('#' + prevForm.id).attr('data-form-is-finished', 1);
			// trigger form finished event to ba handled by the controller
			__.eventBus.emit(EVENTS.FORM_FINISHED, {id: prevForm.id});
			// update configuration if configuration is present (rate changed)
			if (!!data.configuration) {
				// adopt configuration price/rate update
				DPU_CONTROLLER.handleExternalConfiguration(data, {
					startType: null
				});
			}
			delete _forms[prevForm.id];
		}
		else {
			__.displayStandardError(prevForm.id);
			delete _forms[prevForm.id];
		}

		if (initialized) {
			__.scrollToTop(prevForm.id);
			resolve(prevForm.id);
		}
	});
};

__.scrollToTop = function(prevFormId) {
	if (jQuery('#' + prevFormId).closest('.nm-layer-wrapper').length) {
		jQuery('.nm-layer-wrapper').scrollTop(0);
	}
	else {
		jQuery('body').scrollTop(0);
	}
}
__.displayStandardError = function(formId) {
	var formEl = jQuery('#' + formId),
		shaderEl = formEl.find('.nm-form-shader'),
		errorEl = formEl.find('.nm-form-standard-error');
	shaderEl.hide();
	formEl.html(errorEl.html());
};
__.getUrl = function(action, id) {
	var prstring = CONFIGURATOR_API.getPrString(),
		context = CONFIGURATOR_API.getContext(),
		subsession = CONFIGURATOR_API.getSubsessionID(),
		configuratorPage = !!CONFIGURATOR_API.getCarline(),
		args = [];
	// try to add context
	if (context) {
		args.push('context=' + context);
	}
	// try to add the subsession
	if (subsession) {
		args.push('subsession=' + subsession);
	}
	// try to add the prstring
	// NEMOBA-5216 - do not send ids for all forms
	if (id === 'tdrdealerreq' && configuratorPage && prstring) {
		args.push('ids=' + encodeURIComponent(prstring));
	}
	else if (id !== 'tdrdealerreq' && prstring) {
		args.push('ids=' + encodeURIComponent(prstring));
	}
	// add action and id
	args.push('action=' + encodeURIComponent(action));
	args.push('id=' + encodeURIComponent(id));
	return _apiBaseUrl + '?' + args.join('&');
};
__.parseData = function(formJson) {
	var formObj;
	try {
		formObj = JSON.parse(formJson);
	}
	catch (err) {
		return false;
	}
	return formObj;
};
__.getTemplateFuncAsync = function() {
	let tplId;
	// This func is added to the prototype of all __.FormX instances.
	// 'this' is assumed to be the instance. To prevent errors, if
	// templateKey is not defined a function returning an empty string
	// is returned.
	let key = this.templateKey || null,
		tpl,
		fallbackFunc = function() {
			return '';
		};
	if (key === null) {
		return Promise.resolve(fallbackFunc);
	}
	tplId = __.getTemplateId(this, key);
	if (_templateFuncs[tplId] === undefined) {
		if (dynamicDot.requiresDynamicDot()) {
			return new Promise((resolve, reject) => {
				dynamicDot.getTemplateFromBackend(tplId).then(content => {
					_templateFuncs[tplId] = doT.template(content);
					resolve(_templateFuncs[tplId]);
				})
					.catch(error => {
						console.error('Error reading dot-template with id: ' + tplId, error);
						reject(error);
					});
			});
		}
		else {
			tpl = document.querySelector('#' + tplId);
			if (tpl && tpl.innerHTML) {
				_templateFuncs[tplId] = doT.template(tpl.innerHTML.trim());
			}
			else {
				return Promise.resolve(fallbackFunc);
			}
		}
	}

	return Promise.resolve(_templateFuncs[tplId]);
};
/**
 * Sanitize Id to get a DOM-usable id string
 */
__.sanitizeId = function(id) {
	if (id !== undefined) {
		return id.replace(/[^a-z0-9_-]/gi, '');
	}
	return '';
};
/**
 * Chose the correct template id in this order of specificity:
 * 1. <form.id>-<obj.id> found in _templateMap
 * 2. <form.id>-<form.pageId> found in _templateMap
 * 3. <form.id>-<key> found in _templateMap
 * 4. <form.id>-<form.pageId>-<obj.id>
 * 5. <form.id>-<obj.id>
 * 6. <form.id>-<form.pageId>-<key>
 * 7. <form.id>-<key>
 * 7. <key>
 */
__.getTemplateId = function(obj, key) {
	var pre = 'nm-id-form-',
		post = '-tpl',
		form = obj.form || obj,
		customTemplateIds = form.customTemplateIds || {},
		objDOMId,
		idStr;
	key = key.toLowerCase();
	if (obj.id !== undefined) {
		objDOMId = __.sanitizeId(obj.id);
		// try very very very high specificity (mapped)
		idStr = _templateMap[form.id + '-' + objDOMId];
		if (idStr !== undefined) {
			return idStr;
		}
		// try very high specificity
		idStr = pre + form.id + '-' + form.pageId + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
		// try high specificity
		idStr = pre + form.id + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
	}
	// try mapped page
	if (obj.formConstructorName === 'Form') {
		idStr = _templateMap[form.id + '-' + form.pageId];
		if (idStr !== undefined) {
			return idStr;
		}
	}
	// try mapped with <formid>-<key> (field type)
	idStr = pre + form.id + '-' + key + post;
	if (_templateMap[form.id + '-' + key] !== undefined) {
		return idStr;
	}
	// try normal specificity
	idStr = pre + form.id + '-' + form.pageId + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// try low specificity
	idStr = pre + form.id + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// return id string with very low specificity (default)
	idStr = pre + key + post;
	return idStr;
};
/**
 * Form
 */
__.Form = function Form(data, customTemplateIds) {
	this.formConstructorName = 'Form';
	this.data = data;
	this.id = !!data.form ? data.form.id : undefined;
	this.domId = __.sanitizeId(this.id);
	// an object containing all available custom template ids
	this.customTemplateIds = customTemplateIds || {};
	this.templateKey = 'form';
	this.groups = [];
	this.fields = [];
	this.pageId = this.getPageId();
	this.processSteps = this.getProcessSteps();
	this.processStepName = this.getStepHeadline();


	return this;
};

__.Form.prototype.initializeFormAsync = function() {
	return new Promise((resolve, reject) => {
		const promisesIntialize = [];
		promisesIntialize.push(this.processGroupsAsync());
		promisesIntialize.push(this.getTemplateFuncAsync());
		promisesIntialize.push(this.getSuccessTemplateFuncAsync());

		Promise.all(promisesIntialize).then(results => {
			const [groups, templateFunction, successTemplate] = results;
			this.getString = templateFunction;
			this.getSuccessString = successTemplate;
			this.load();
			resolve();
		})
			.catch(error => {
				console.error('Error initializing form', error);
				reject(error);
			});
	});
}

__.Form.prototype.getPageId = function() {
	var i;
	if (!!(this.data && this.data.pages)) {
		for (i = this.data.pages.length - 1; i > -1; i--) {
			if (this.data.pages[i].active === true) {
				return this.data.pages[i].id;
			}
		}
	}
	return null;
};

__.Form.prototype.getStepHeadline = function() {
	var i;
	if (!!(this.data && this.data.pages)) {
		for (i = this.data.pages.length - 1; i > -1; i--) {
			if (this.data.pages[i].active === true) {
				return this.data.pages[i].name;
			}
		}
	}
	return null;
};
__.Form.prototype.getProcessSteps = function() {
	var i;
	if (!!(this.data && this.data.pages)) {
		for (i = this.data.pages.length - 1; i > -1; i--) {
			if (this.data.pages[i].active === true) {
				return {
					step: i + 1,
					maxSteps: this.data.pages.length
				};
			}
		}
	}
	return null;
};

__.Form.prototype.getSuccessTemplateFuncAsync = function() {
	var tpl,
		tplId = 'nm-id-form-success-tpl',
		fallbackFunc = function() {
			return '';
		};
	if (_templateFuncs[tplId] === undefined) {
		if (dynamicDot.requiresDynamicDot()) {
			return new Promise((resolve, reject) => {
				dynamicDot.getTemplateFromBackend(tplId).then(content => {
					_templateFuncs[tplId] = doT.template(content);
					resolve(_templateFuncs[tplId]);
				})
					.catch(error => {
						console.error('Error reading dot-template with id: ' + tplId, error);
						reject(error);
					});
			});
		}
		else {
			tpl = document.querySelector('#' + tplId);
			if (tpl && tpl.innerHTML) {
				_templateFuncs[tplId] = doT.template(tpl.innerHTML.trim());
			}
			else {
				return Promise.resolve(fallbackFunc);
			}
		}
	}

	return Promise.resolve(_templateFuncs[tplId]);
};

__.Form.prototype.getTemplateFuncAsync = __.getTemplateFuncAsync;
__.Form.prototype.processGroupsAsync = function() {
	var i, l, group;
	return new Promise(resolve => {
		if (!!(this.data.form && this.data.form.groups)) {
			const promises = [];
			for (i = 0, l = this.data.form.groups.length; i < l; i++) {
				group = new FormGroup(this.data.form.groups[i], this);
				promises.push(group.initializeGroupAsync());
				this.groups.push(group);
			}

			Promise.allSettled(promises).then(() => {
				resolve(this.groups);
			});
		}
		else {
			resolve(this.groups);
		}
	});
};

__.Form.prototype.load = function() {
	var formContainerEl, isFirstView, data;
	data = {
		formHeadline: this.data.form.name,
		pageDisclaimers: this.data.form.disclaimers,
		pageHeadline: this.data.form.headline,
		pageDescription: this.data.form.description,
		pageError: this.data.form.error,
		groups: this.groups,
		pages: this.data.pages,
		id: this.data.form.id,
		actions: this.data.form.actions
	};
	// If this is the first view of the form, mark it as such:
	formContainerEl = jQuery('#' + this.data.form.id);
	isFirstView = formContainerEl.attr('data-form-is-first-view');
	if (isFirstView === undefined) {
		formContainerEl.attr('data-form-is-first-view', 1);
	}
	else {
		formContainerEl.attr('data-form-is-first-view', 0);
	}
	if (typeof this.data.form.footnote !== 'undefined') {
		data.footnote = this.data.form.footnote;
	}
	if (this.processSteps) {
		formContainerEl.attr('data-form-process-step', this.processSteps.step);
		formContainerEl.attr('data-form-process-max', this.processSteps.maxSteps);
	}
	if (!!this.processStepName) {
		formContainerEl.attr('data-form-process-name', this.processStepName);
	}
	this.el = jQuery(this.getString(data));
	let that = this;
	// button bindings with delay (hotfix to trick other bindings)
	window.setTimeout(function() {
		that.bindActions();
		that.bindActionLinks();
		that.preventSubmit();
		__.eventBus.emit(EVENTS.FORM_LOADED, {id: that.id, form: that});
	}, 100);
};
__.Form.prototype.preventSubmit = function() {
	jQuery('#' + this.id + ' form').on('submit', function(e) {
		e.preventDefault();
		return false;
	});
};
__.Form.prototype.bindActions = function() {
	var form = this;
	jQuery('#' + this.id + ' button[data-button-action]').on('click', function(e) {
		var btn, action, formId;
		e.preventDefault();
		btn = jQuery(e.target);
		action = btn.attr('data-button-action');
		formId = btn.attr('data-form-id');
		if (action === 'cancel') {
			btn.addClass('nm-button-cancel');
		}
		if (formId === form.id && !!action) {
			__.blockForm(form);
			form.serializeAndSend(action, form.id);
		}
	});
};
__.Form.prototype.bindActionLinks = function() {
	var form = this;
	jQuery('#' + this.id + ' .nm-form-action-link').on('click', '.nm-form-action-link', function(e) {
		e.preventDefault();
		form.serializeAndSend(jQuery(this).attr('data-form-action'), form.id);
	});
};
__.Form.prototype.disableButtons = function() {
	var i, l, action;
	if (!!(this.data && this.data.form && this.data.form.actions)) {
		for (i = 0, l = this.data.form.actions.length; i < l; i++) {
			action = this.data.form.actions[i];
			if (action.disable_on_change === true) {
				jQuery('button[data-button-action="' + action.action + '"]').hide();
			}
		}
	}
};
__.Form.prototype.changed = function() {
	this.disableButtons();
};
__.Form.prototype.autoSubmit = function(action_) {
	var action = action_ || 'submit';
	__.blockForm(this);
	this.serializeAndSend(action, this.id);
};
__.Form.prototype.serializeAndSend = function(action, id) {
	var formElement = jQuery('#' + id + ' form'),
		url = __.getUrl(action, id),
		payloadArray = formElement.serializeArray(),
		payloadSize = payloadArray.length,
		newPayload = [],
		form = this,
		datetime = [],
		tmp,
		i;
	for (i = 0; i < payloadSize; i += 1) {
		if (payloadArray[i].name.indexOf('DateTime-') !== -1) {
			tmp = payloadArray[i].name.slice(9, payloadArray[i].name.length - 2);
			if (typeof datetime[tmp] === 'undefined') {
				datetime[tmp] = [];
			}
			datetime[tmp].push(payloadArray[i].value);
		}
		else {
			newPayload.push(payloadArray[i]);
		}
	}
	for (var value in datetime) {
		// tmp = datetime[value].split('.');
		if (
			datetime.hasOwnProperty(value) &&
			datetime[tmp][0] !== '0' &&
			datetime[tmp][1] !== '0' &&
			datetime[tmp][2] !== '0'
		) {
			tmp = datetime[tmp].reverse().join('-');
			newPayload.push({
				name: value,
				value: tmp
			});
		}
	}

	const formParams = newPayload.reduce((paramString, nameValuePair, index) => {
		const separator = index > 0 ? '&' : '';
		return paramString + separator + nameValuePair.name + '=' + nameValuePair.value;
	}, '');

	const options = {
		method: 'POST',
		headers: {
			'Content-type': 'application/x-www-form-urlencoded'
		},
		body: formParams,
		credentials: 'include'
	};
	return fetch(url, options)
		.then(response => {
			if (response.ok) {
				return response.json();
			}
			else {
				throw new Error(response.statusText);
			}
		})
		.then(data => __.handleFormAsync(data, form))
		.catch(() => {
			__.displayStandardError(form)
		});
};
/**
 * Parses JSON string if necessary.
 */
exports.responseToObj = function(response) {
	if (!response) {
		return false;
	}
	else if (typeof response === 'object') {
		return response;
	}
	else {
		try {
			return JSON.parse(response);
		}
		catch (err) {
			return false;
		}
	}
};
/**
 * Scans html for .nm-form elements on layer.loaded.
 */
exports.layerLoadHandler = function(layerEl) {
	var layer;
	layer = jQuery(layerEl);
	layer.find('.nm-form').each(function() {
		__.initFormAsync(jQuery(this));
	});
};
exports.processNavHandler = function(e) {
	var link = jQuery(e.target),
		action,
		id;
	e.preventDefault();
	if (link.attr('data-selectable') === 'true') {
		id = link.parents('.nm-form').attr('id');
		exports.getForm(id).serializeAndSend(link.attr('data-action'), id);
	}
};
/**
 * Adds init data for a form id.
 */
exports.addInitData = function(formId, data) {
	_initData[formId] = data;
};
/**
 * Return Form instance by id.
 */
exports.getForm = function(id) {
	return _forms[id];
};
/**
 * Sets API URL and adds event bindings.
 */
__.initialize = function() {
	var initialLayerContent;
	// Store the dpu url once.
	_apiBaseUrl = SETUPS.get('nemo.url.formapi');
	// _store the templateMap
	_templateMap = SETUPS.get('nemo.form.templatemap');
	// Lookup all custom template ids.
	_customTemplateIds = __.getCustomTemplateIds('.nm-custom-form-tpl');
	// Listen to the layer.loaded event.
	__.eventBus.on(EVENTS.LAYER_LOADED, function(data) {
		if (!!data && !!data.element) {
			exports.layerLoadHandler(data.element);
		}
	});

__.eventBus.on(EVENTS.FORM_LOADED, function() {
	__.addHelpTextToPrivateLeasing();
});
	// Bind process nav clicks
	jQuery('body .nm-process-navigation a').on('click', exports.processNavHandler);
	// Bind checkbox enablers.
	__.bindEnablers();
	// check for initail Form wo layer
	initialLayerContent = jQuery('.nm-layer-wrapper');
	if (initialLayerContent.length) {
		exports.layerLoadHandler(initialLayerContent);
	}
};

__.addHelpTextToPrivateLeasing = function() {
	const allScopes = SETUPS.get('nemo.env.scopes');
	const expectedScope = "SHOW_PRIVATE_LEASING_HELP_TEXT";
	const isScopeSet = allScopes.indexOf(expectedScope);

	if(isScopeSet === -1){
		return;
	}

	const configuration = CONFIGURATOR_API.getConfiguration();
	const country = SETUPS.get('nemo.locale.country');
	const inputForPrivateLeasing = document.querySelector('div.nm-form#fdproxy form div.nm-input-wrap div.nm-input-label-wrapper input[value="PL"]');
	const isCarElectric = configuration['wltp-status']?.fuelTypes[0] === "ELECTRICAL";

	if(country === "DE" && inputForPrivateLeasing && isCarElectric){
		const textToBeAdded = window.i18n['nemo.ui.form.online.leasing.help.text'];
		const newSpan = document.createElement('span');

		newSpan.textContent = textToBeAdded;
		inputForPrivateLeasing.parentNode.appendChild(newSpan);
	}
}

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.initialize();
};
export {exports as formCore};
