import {dpuApi as API} from './dpu/api';
import {appEvents as EVENTS} from 'core-application';
import {template as TPL_RENDERER} from 'core-utils';
import {recommendationsListTemplate as TEMPLATE_LIST} from './recommendations-list-template';

const __ = {},
	// Public API
	exports = {
		__: __
	};

__.oDefaults={

	// the query selector to insert rendered html
	sSelectorTemplateClass :'.nm-recommendation',

	// the query selector to
	sSelectorTabClass:'.nm-j-link-recommendations'
};
// get the template function from doTJS
__.tplFunc = null;

// doT tpl func for no recommendations
__.tplFuncNoRecs = function() { return ''; };


// private helper


/*
	 * Find elementId in DOM or return empty string.
	 */
__.getTplString = function (elementSelector) {
	var element = $(elementSelector);
	if (element.length > 0) {
		return element.html();
	}
	return '';
};


__.showTab = function _show () {
	jQuery('body').addClass('nm-has-recommendations');
};

__.hideTab = function _hide () {
	jQuery('body').removeClass('nm-has-recommendations');
};

__.tabAvailable = function _available () {
	return (jQuery(__.oDefaults.sSelectorTabClass).length > 0);
};


__.containerAvailable = function _available () {
	return (jQuery(__.oDefaults.sSelectorTemplateClass).length > 0);
};


__.initialize = function () {
	__.addEvents();
};


__.dispose = function _dispose () {
	__.disposeEvents();
};


__.parse = function (recommendations) {

	var items = API.getItems()||{}, list;

	var r = {recommendations: []};

	// create a clone to avoid altering passed reference
	var clone = JSON.parse(JSON.stringify(recommendations));

	var mbvItemsMap = {};

	jQuery.each(items, function (index, e) {
		mbvItemsMap[e.mbvId] = e;
	});

	list = [];

	jQuery.each(clone, function (index, rec) {

		if (rec === null) { return false; } // tmp fix

		rec.recommendation = jQuery.map(rec.recommendation, function (k) {

			var result = null;
			var isShortMbvId = !!mbvItemsMap[k.id];

			if (isShortMbvId) {
				result = mbvItemsMap[k.id];
			}
			else {
				result = API.getItem(k.id);
			}

			// console.log('Recommendations::parse(rec) -- short: ', isShortMbvId, result);

			return result;
		});

		rec.chosen = jQuery.map(rec.chosen, function (k) {

			var result = null;
			var isShortMbvId = !!mbvItemsMap[k.id];

			if (isShortMbvId) {
				result = mbvItemsMap[k.id];
			}
			else {
				result = items[k.id];
			}

			// console.log('Recommendations::parse(chosen) -- short: ', isShortMbvId, result);

			return result;
		});

		list.push(rec);
	});

	// merge arrays
	jQuery.each(list, function (index, e) {
		Array.prototype.push.call(r.recommendations, e);
	});

	r.recommendations = list;
	r.carlineName = API.getCarlineName();
	return r;
};


__.render = function () {
	var recommnds = API.getRecommendations();

	var result = __.parse(recommnds);
	var htmlString=TPL_RENDERER.render(TEMPLATE_LIST, result);
	htmlString=htmlString.replace('{{=it.carlineName}}', result.carlineName.toUpperCase());
	jQuery(__.oDefaults.sSelectorTemplateClass).html(htmlString);
};


__.renderNoRecommendations = function () {
	var data=API.getConfiguration();
	var htmlString=TPL_RENDERER.render(TEMPLATE_LIST, data);
	htmlString=htmlString.replace('{{=it.carlineName}}', (API.getCarline()||'').toUpperCase());
	jQuery(__.oDefaults.sSelectorTemplateClass).html(htmlString);
	jQuery(__.oDefaults.sSelectorTemplateClass).html(__.tplFuncNoRecs(data));
};


__.addEvents = function () {

	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigUpdate);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.LAYER_CLOSED, __.handleLayerClosed);
};


__.disposeEvents = function () {

	__.eventBus.off(EVENTS.CONFIG_UPDATE, __.handleConfigUpdate);
	__.eventBus.off(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.off(EVENTS.PAGE_LOADED, __.handleLayerLoaded);
	__.eventBus.off(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.off(EVENTS.LAYER_CLOSED, __.handleLayerClosed);
};


__.recommendationsAvailable = function () {
	return API.isConfigurable()&& API.getRecommendations().length;
};


__.updateView = function () {

	if (__.containerAvailable()) {
		// noop - do nothing to left the recommendation intact in layer
		return;
	}
	else {
		if (__.recommendationsAvailable()) {
			__.showTab();
			__.render();
		}
		else {
			__.hideTab();
		}
	}
};


// event handler

__.handleConfigUpdate = function () {
	__.updateView();
};


__.handlePageLoaded = function () {
	__.updateView();
};


__.handleLayerClosed = function () {
	if (!__.recommendationsAvailable()) {
		__.hideTab();
	}
};


__.handleLayerLoaded = function () {
	/*
		 * There are no recommendations, but the recommendations layer
		 * is opened, so show the tab and render the appropriate tpl.
		 */
	if (!__.recommendationsAvailable() && __.containerAvailable()) {
		__.showTab();
		__.renderNoRecommendations();
	}
	/*
		 * Container is present, recommendations available. Render rec view.
		 */
	else if (__.recommendationsAvailable() && __.containerAvailable()) {
		__.showTab();
		__.render();
	}
	/*
		 * Container is present, recommendations available. Render rec view.
		 */
	else if (__.recommendationsAvailable() && __.tabAvailable()) {
		__.showTab();
	}
};


exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();

};

export {exports as recommendations};

