import {iph as IPH} from 'core-global';
import {patternMatching as PATTERN} from 'core-global';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
var __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * // To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};
// reference to the global eventEmitter
__.eventBus = null;

__.register = function () {
	PATTERN.register('partnerid', __.getPartnerId);
};

__.getPartnerId = function() {
	var data;
	return new Promise(function(resolve, reject) {
		data = IPH.inIPHMode(); //  returns iphdealerBNR
		if (data) {
			resolve(data);
		}
		else {
			reject(new TypeError('load pattern -partnerid- failed'));
		}
	});
};

exports.initialize = function() {
	__.register();
};

export {exports as patternPartnerId};
