const template = `
	<div class="nm-layer-toolbar">
		<div class="nm-renderingcounter">
			&nbsp;<span class="nm-renderingcounter-current">01</span>|
			<span class="nm-renderingcounter-total">--</span>&nbsp;
		</div>
		<div class="nm-layer__close-button nm-j-layer-close-button">
			<svg class="nm-layer__close-button-icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel-newci-large.svg#audi-icon-cancel-newci-large"}}></use>
			</svg>
		</div>
	</div>
	<div class="nm-layer-inner nm-renderinglayer-wrapper">
		<div class="nm-renderinglayer {{? it.open }} nm-cabrio-open{{ } }}" style="{{?it.native_width!=undefined}} width: {{=it.native_width}}px{{?}}">
			<input type="radio" id="nm-renderinglayer-exteriortoggle" name="nm-renderinglayer-toggle-radio" value="exterior" class="nm-renderinglayer-toggle-radio" {{? it.interiorActive }}{{?? it.open }}{{??}}checked{{?}} >
			<input type="radio" id="nm-renderinglayer-exterioropentoggle" name="nm-renderinglayer-toggle-radio" value="exterioropen" class="nm-renderinglayer-toggle-radio" {{? it.interiorActive }}{{?? it.open }}checked{{??}}{{?}} >
			<input type="radio" id="nm-renderinglayer-interiortoggle" name="nm-renderinglayer-toggle-radio" value="interior" class="nm-renderinglayer-toggle-radio" {{? it.interiorActive }}checked{{?? it.open }}{{??}}{{?}} >
			<!--
			<input type="checkbox" id="nm-renderinglayer-opentoggle" name="nm-rl-toggle-open" class="nm-rl-toggle-open" {{? it.open}}checked{{?}} >
		-->
			<div class="nm-renderinglayer-stagewrapper {{? it.openavailable}} openavailable {{ } }}">
				<ul class="nm-renderinglayer-stagebrowse">
					<li class="nm-backarrow"><a href="" class="nm-b-icon nm-icon-back-arrow-l"></a></li>
					<li class="nm-nextarrow"><a href="" class="nm-b-icon nm-icon-next-arrow-l"></a></li>
				</ul>
				<a href="" class="nm-renderinglayer-open nm-el-btn nm-el-btn-01">{{? it.open}}{{=window.i18n['rendering-layer.cabrio-close']}}{{??}}{{=window.i18n['rendering-layer.cabrio-open']}}{{?}}</a>

	 			<div class="nm-renderinglayer-stage">
	 				<ul class="nm-renderinglayer-stageimages">{{~ it.exterior :value:i }}<li class="nm-renderinglayer-imagewrapper{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-view="{{=value.name}}"><div class="nm-renderinglayer-image nm-j-configurator-renderimage nm-rendersource-{{=value.name}}" data-renderimage-type="fullscreen" data-renderimage-view="{{=value.name}}" style="background-image: url({{=value.image}});"></div></li>{{~}}{{~ it.exterior_open :value:i }}<li class="nm-renderinglayer-imagewrapper{{? it.currentRenderingView == value.name }} nm-active{{?}}"><div class="nm-renderinglayer-image nm-j-configurator-renderimage nm-rendersource-{{=value.name}}" data-renderimage-type="fullscreen" data-renderimage-view="{{=value.name}}" style="background-image: url({{=value.image}});"></div></li>{{~}}{{~ it.interior :value:i }}<li class="nm-renderinglayer-imagewrapper{{? it.currentRenderingView == value.name }} nm-active{{?}}"><div class="nm-renderinglayer-image nm-j-configurator-renderimage nm-rendersource-{{=value.name}}" data-renderimage-type="fullscreen" data-renderimage-view="{{=value.name}}" style="background-image: url({{=value.image}});"></div></li>{{~}}</ul>
	 			</div>
			</div>
			<div class="nm-renderinglayer-thumbnails clearfix">
				<ul class="nm-renderinglayer-thumbs">
{{~ it.exterior :value:i }}
	{{? i == 0}}
					<li class="nm-renderinglayer-item nm-rl-toggle-exterior">
						<div class="nm-rl-placeholder nm-js-exterior {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}">
							<label for="nm-renderinglayer-exteriortoggle" class="nm-renderinglayer-expander"></label>
						</div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"><a class="nm-b-icon nm-icon-plus"></a></div>
	{{??}}
					<li class="nm-renderinglayer-item nm-rl-item-exterior">
						<div class="nm-rl-placeholder nm-js-exterior {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}"></div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"></div>
	{{?}}
					</li>
{{~}}
{{~ it.exterior_open :value:i }}
	{{? i == 0}}
					<li class="nm-renderinglayer-item nm-rl-toggle-exterior-open">
						<div class="nm-rl-placeholder nm-js-exterior-open {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}">
							<label for="nm-renderinglayer-exterioropentoggle" class="nm-renderinglayer-expander"></label>
						</div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"><a class="nm-b-icon nm-icon-plus"></a></div>
	{{??}}
					<li class="nm-renderinglayer-item nm-rl-item-exterior-open">
						<div class="nm-rl-placeholder nm-js-exterior-open {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}"></div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"></div>
	{{?}}
					</li>
{{~}}
{{~ it.interior :value:i }}
	{{? i == 0}}
					<li class="nm-renderinglayer-item nm-js-toggle nm-rl-toggle-interior">
						<div class="nm-rl-placeholder nm-js-interior {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}">
							<label for="nm-renderinglayer-interiortoggle" class="nm-renderinglayer-expander"></label>
						</div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"><a class="nm-b-icon nm-icon-plus"></a></div>
	{{??}}
					<li class="nm-renderinglayer-item nm-js-item nm-rl-item-interior">
						<div class="nm-rl-placeholder nm-js-interior {{=value.name}}{{? it.currentRenderingView == value.name }} nm-active{{?}}" data-key="{{=value.name}}"></div>
						<div class="nm-renderinglayer-thumb nm-j-configurator-renderimage {{=value.name}}" data-renderimage-type="{{=value.type}}" data-renderimage-view="{{=value.name}}" style="background-image:url({{=value.thumbnail}})"></div>
	{{?}}
					</li>
{{~}}
				</ul>
			</div>
		</div>
	</div>`;
export {template as renderingLayerTemplate};
