import {stateRegistry} from 'microkernel';
import {CONFIGURATION_STORE_ID} from './stores/configuration-store';
export default class EngineSelection extends HTMLElement {

	static get selectors() {
		return {
			radioFilter: '.nm-module-engine-selection-filter-radio',
			selectFilter: '.nm-module-engine-selection-filter-select',
			engineEntry: '.nm-module-engine-selection-engine',
			engineSubList: '.nm-module-engine-selection-engine-list',
			summaryLink: '.nm-module-engine-selection-summary-link',
			trimlineDetailLinks: '.nm-module-engine-selection-trimline-layerlink-icon',
			enginesVisibleCount: '.nm-j-enginesVisible',
			noResults: '.nm-module-engine-selection-no-results',
			resetButton: '.nm-module-engine-selection-no-results-reset',
			notHidden: ':not(.nm-hidden)',
			hidden: 'nm-hidden'
		};
	}

	get isInitialFiltersReset() {
		return this.dataset.resetFilters === 'true';
	}

	get isConfigurationObserver() {
		return this.dataset.resetFilters === 'false';
	}

	connectedCallback() {
		this.initElements();
		this.addEvents();

		this.initTrimlineFilter = this.initTrimlineFilter.bind(this);
		if (this.isConfigurationObserver) {
			stateRegistry.subscribeToStore(CONFIGURATION_STORE_ID, this.initTrimlineFilter);
		}
	}

	disconnectedCallback() {
		this.removeEvents();
		if (this.isConfigurationObserver) {
			stateRegistry.unsubscribeFromStore(CONFIGURATION_STORE_ID, this.initTrimlineFilter);
		}
	}
	performClickTracking(event) {
		event.preventDefault();
		const payload = this.getTrackingData(event);
		const summaryPageEvent = new CustomEvent('CUSTOM_TRACKING_EVENT', payload);
		document.dispatchEvent(summaryPageEvent);
	}
	addEvents() {
		this.resetButton.addEventListener('click', () => this.resetFilters());

		if (this.summaryLinks.length > 0) {
			[...this.summaryLinks].forEach(link => {
				link.addEventListener('click', (event) => this.performClickTracking(event));
			});
		}

		[...this.radioFilters].forEach(filter => {
			filter.addEventListener('change', () => this.updateRadioFilterSelection(filter));
		});
		[...this.selectFilters].forEach(filter => {
			filter.addEventListener('change', () => this.updateFilters());
		});
	}


	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ -event Data
	* @returns {object} trackingObject
	*/
	getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('nm-module-engine-selection-summary-link')) {
			return {};
		}
		console.log(eventData_);
		const payload = {
			detail: {
				eventInfo: {
					eventAction: "internal_link",
					eventName: "engine selection - click on 'go to summary'"
				},
				attributes: {
					componentName: "engine-selection",
					label: eventData_.currentTarget.innerText,
					currentURL: window.location.href,
					targetURL: eventData_.currentTarget.href,
					elementName: "text link",
					pos: ""
				}
			}
		};
		return payload;
	}


	removeEvents() {
		this.resetButton.removeEventListener('click', () => this.resetFilters());
		[...this.summaryLinks].forEach(link => {
			link.removeEventListener('click', (event) => this.performClickTracking(event));
		});
		[...this.radioFilters].forEach(filter => {
			filter.removeEventListener('change', () => this.updateRadioFilterSelection(filter));
		});
		[...this.selectFilters].forEach(filter => {
			filter.removeEventListener('change', () => this.updateFilters());
		});
	}

	initElements() {
		this.engineEntries = this.querySelectorAll(EngineSelection.selectors.engineEntry);
		this.selectFilters = this.querySelectorAll(EngineSelection.selectors.selectFilter);
		this.radioFilters = this.querySelectorAll(EngineSelection.selectors.radioFilter);
		this.summaryLinks = this.querySelectorAll(EngineSelection.selectors.summaryLink);
		this.visibleCounter = this.querySelector(EngineSelection.selectors.enginesVisibleCount);
		this.noResultsBox = this.querySelector(EngineSelection.selectors.noResults);
		this.resetButton = this.querySelector(EngineSelection.selectors.resetButton);


		if (this.isInitialFiltersReset) {
			this.resetFilters();
		}
	}

	resetFilters() {
		[...this.selectFilters].forEach(filter => (filter.value = ''));
		[...this.radioFilters].forEach(filter => (filter.checked = false));
		this.updateFilters();
	}

	initTrimlineFilter(state) {
		const selectedTrimline = state.selectedTrimline;
		if (!!selectedTrimline) {
			[...this.selectFilters].forEach(filter => {
				filter.value = selectedTrimline;
			});
			this.updateFilters();
		}
	}

	updateRadioFilterSelection(filter) {
		const filterName = filter.getAttribute('name');
		[...this.querySelectorAll(`${EngineSelection.selectors.radioFilter}[name="${filterName}"]`)]
			.filter(radio => radio !== filter)
			.forEach(radio => (radio.checked = false));
		this.updateFilters();
	}

	updateFilters() {
		const activeFilters = this.getRadioFiltersValues().concat(this.getSelectFiltersValues());
		this.updateTrimlineDetailLink(activeFilters);
		this.filterEngineList(activeFilters);
	}

	updateTrimlineDetailLink(activeFilters) {
		const trimlineFilter = activeFilters.filter(filter => filter.name === 'trimline');

		[...this.querySelectorAll(EngineSelection.selectors.trimlineDetailLinks)].forEach(detailLink =>
			detailLink.classList.add(EngineSelection.selectors.hidden)
		);
		if (trimlineFilter.length > 0) {
			const detailsLink = this.querySelector(
				`${EngineSelection.selectors.trimlineDetailLinks}[data-trimline="${trimlineFilter[0].value}"]`
			);
			if (detailsLink) {
				detailsLink.classList.remove(EngineSelection.selectors.hidden);
			}
		}
	}

	getRadioFiltersValues() {
		return [...this.radioFilters]
			.filter(currentFilter => currentFilter.checked === true)
			.map(currentFilter => this.getFilter(currentFilter));
	}

	getSelectFiltersValues() {
		return [...this.selectFilters]
			.filter(currentFilter => currentFilter.value !== '')
			.map(currentFilter => this.getFilter(currentFilter));
	}

	getFilter(currentFilter) {
		return {
			name: currentFilter.getAttribute('name'),
			value: currentFilter.value
		};
	}

	filterEngineList(filters) {
		const engineFilterSelector = filters.reduce((selector, filter) => {
			return `${selector}[data-filter-${filter.name}~="${filter.value}"]`;
		}, EngineSelection.selectors.engineEntry);

		[...this.engineEntries].forEach(entry => entry.classList.add(EngineSelection.selectors.hidden));
		[...this.querySelectorAll(engineFilterSelector)].forEach(entry =>
			entry.classList.remove(EngineSelection.selectors.hidden)
		);

		this.hideEmptyContainer();
		this.updateVisibleEngineCounter();
	}

	hideEmptyContainer() {
		[...this.querySelectorAll(EngineSelection.selectors.engineSubList)].forEach(subList => {
			const shownEngines = subList.querySelectorAll(
				EngineSelection.selectors.engineEntry + EngineSelection.selectors.notHidden
			);
			if (shownEngines.length === 0) {
				subList.classList.add(EngineSelection.selectors.hidden);
			}
			else {
				subList.classList.remove(EngineSelection.selectors.hidden);
			}
		});
	}

	updateVisibleEngineCounter() {
		const allShownEnginesCount = this.querySelectorAll(
			EngineSelection.selectors.engineEntry + EngineSelection.selectors.notHidden
		).length;
		this.visibleCounter.innerHTML = allShownEnginesCount;
		if (allShownEnginesCount !== 0) {
			this.noResultsBox.classList.add(EngineSelection.selectors.hidden);
		}
		else {
			this.noResultsBox.classList.remove(EngineSelection.selectors.hidden);
		}
	}
}
if (!window.customElements.get('audi-configurator-engine-selection')) {
	window.customElements.define('audi-configurator-engine-selection', EngineSelection);
}
