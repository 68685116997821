/**
 * @description module to store and load user´s configuration in the Browser´s storage
 * during the user´s configuration process his configuration will be saved locally (in his Browser Storage) for later use.
 * If the user returns to a carline in which has configured a car before his previous configuration will be loaded from his Browser storage
 */

import {dpuApi as CONFIGURATOR_API} from './api';
import {appEvents as EVENTS} from 'core-application';
var __ = {},
	// Public API
	exports = {
		__: __
	};
// use Browser´s Session storage
__.STORAGE = window.sessionStorage || null;
__.entryPrefix = '__nemo__';

/*
 * write current configuration into storage on configuration.update Event
 */
__.handleConfigurationUpdates = function() {
	var entry = {
		prString: CONFIGURATOR_API.getPrString(),
		carlineID: CONFIGURATOR_API.getCarline()
	};
	// if(CONFIGURATOR_API.isConfigurationStarted){
	var promise = __.writeEntry(entry);
	promise.then(
		function(data) {
			console.log('user´s configuration storageID: ' + data.stored);
		},
		function(err) {
			console.warn('Error storing user´s configuration: ' + err.message);
		}
	);
	// }
};

/**
 * write entry into storage
 * @param {Object} entry_ entry to save
 * @returns {Promise} stored item
 */
__.writeEntry = function(entry_) {
	let entry = entry_;
	return new Promise(function(resolve, reject) {
		var uuid;
		uuid = __.uuid();
		if (!entry) {
			reject(new TypeError("writeEntry: missing param 'entry_'"));
		}
		entry = JSON.stringify(entry);
		if (!__.STORAGE) {
			reject(new TypeError('sessionStorage is not available'));
		}
		__.STORAGE.setItem(uuid, entry);
		resolve({
			stored: uuid
		});
	});
};

/*
 * generate name for the storage entry
 */
__.uuid = function() {
	return __.entryPrefix + CONFIGURATOR_API.getVersion();
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigurationUpdates);
};

/*
 * read stored entry from Browser´s storage
 */
__.readEntryFromStorage = function() {
	return new Promise(function(resolve, reject) {
		var entry;
		if (!__.STORAGE) {
			reject(new TypeError('Browserstorage is not available'));
		}
		try {
			entry = __.STORAGE.getItem(__.uuid());
			entry = JSON.parse(entry);
			resolve(entry);
		}
		catch (err) {
			reject(new TypeError('Error reading user´s configuration: ' + err.message));
		}
	});
};

// initialize module on APP_READY
__.initialize = function() {
	if (CONFIGURATOR_API.isInPreviewMode() || !CONFIGURATOR_API.isConfigurable()) {
		console.log('user-configurations.js -- no initialization because of ' + CONFIGURATOR_API.isInPreviewMode() ? 'preview-mode' : 'missing DPU');
		return;
	}
	__.addEvents();
};
/**
 * get user configurations prString for the current carline
 * @returns {string|null} prstring
 */
exports.getPreviousUserConfigurationPrString = function() {
	var promise = __.readEntryFromStorage();
	return promise.then(
		function(entry) {
			if (entry && !!entry.carlineID && !!entry.prString && entry.carlineID === CONFIGURATOR_API.getCarline() && entry.prString !== CONFIGURATOR_API.getPrString()) {
				return Promise.resolve(entry.prString);
			}
			else {
				return Promise.reject(new TypeError('no valid user configuration available'));
			}
		},
		function() {
			return Promise.reject(new TypeError('error reading user configuration from storage'));
		}
	);
};

__.debugEntries = function() {
	// getStoredCarlines
	var entry,
		entries = [],
		pattern = new RegExp(__.entryPrefix);
	/* jshint forin: false */
	for (entry in __.STORAGE) {
		if (pattern.test(entry)) {
			entries.push(entry);
		}
	}
	return entries;
};
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();
};
export {exports as userConfigurations};
