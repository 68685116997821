import {doT} from 'core-vendor';
import { dynamicDot } from 'core-application';
import {formItem as FormItem} from './item';

const
	// mapped templates
	_templateMap = {},

	// doT template functions; lazily loaded and cached;
	_templateFuncs = {},

	// Internal module methods
	__ = {};

// TODO: #form-group-001 - refactor to helper or 'service'
__.getTemplateFuncAsync = function() {
	// This func is added to the prototype of all __.FormX instances.
	// 'this' is assumed to be the instance. To prevent errors, if
	// templateKey is not defined a function returning an empty string
	// is returned.
	let key = this.templateKey || null,
		tpl,
		fallbackFunc = function() {
			return '';
		};
	if (key === null) {
		return Promise.resolve(fallbackFunc);
	}

	let tplId = __.getTemplateId(this, key);
	if (_templateFuncs[tplId] === undefined) {
		if (dynamicDot.requiresDynamicDot()) {
			return new Promise((resolve, reject) => {
				dynamicDot.getTemplateFromBackend(tplId).then(content => {
					_templateFuncs[tplId] = doT.template(content);
					resolve(_templateFuncs[tplId]);
				})
					.catch(error => {
						console.error('Error reading dot-template with id: ' + tplId, error);
						resolve(fallbackFunc);
					});
			});
		}
		else {
			tpl = document.querySelector('#' + tplId);
			if (tpl && tpl.innerHTML) {
				_templateFuncs[tplId] = doT.template(tpl.innerHTML.trim());
			} else {
				return Promise.resolve(fallbackFunc);
			}
		}
	}

	return Promise.resolve(_templateFuncs[tplId]);
};

/**
 * Sanitize Id to get a DOM-usable id string
 */
__.sanitizeId = function(id) {
	if (id !== undefined) {
		return id.replace(/[^a-z0-9_-]/gi, '');
	}
	return '';
};

/**
 * TODO: #form-group-002 - refactor to helper or 'service'
 *
 * Chose the correct template id in this order of specificity:
 * 1. <form.id>-<obj.id> found in _templateMap
 * 2. <form.id>-<form.pageId> found in _templateMap
 * 3. <form.id>-<key> found in _templateMap
 * 4. <form.id>-<form.pageId>-<obj.id>
 * 5. <form.id>-<obj.id>
 * 6. <form.id>-<form.pageId>-<key>
 * 7. <form.id>-<key>
 * 7. <key>
 */
__.getTemplateId = function(obj, key) {
	var pre = 'nm-id-form-',
		post = '-tpl',
		form = obj.form || obj,
		customTemplateIds = form.customTemplateIds || {},
		pageId = form.pageId,
		objDOMId,
		idStr;
	key = key.toLowerCase();
	if (obj.id !== undefined) {
		objDOMId = __.sanitizeId(obj.id);
		// try very very very high specificity (mapped)
		idStr = _templateMap[form.id + '-' + objDOMId];
		if (idStr !== undefined) {
			return idStr;
		}
		// try very high specificity
		idStr = pre + form.id + '-' + form.pageId + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
		// try high specificity
		idStr = pre + form.id + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
	}
	// try mapped page
	if (obj.formConstructorName === 'Form') {
		idStr = _templateMap[form.id + '-' + form.pageId];
		if (idStr !== undefined) {
			return idStr;
		}
	}
	// try mapped with <formid>-<key> (field type)
	idStr = pre + form.id + '-' + key + post;
	if (_templateMap[form.id + '-' + key] !== undefined) {
		return idStr;
	}
	// try normal specificity
	idStr = pre + form.id + '-' + form.pageId + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// try low specificity
	idStr = pre + form.id + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// return id string with very low specificity (default)
	idStr = pre + key + post;
	return idStr;
};

/**
 * FormGroup
 */
__.FormGroup = function FormGroup(data, form) {
	var key;
	// TODO: refactor this into a helper method (?)
	for (key in data) {
		if (data.hasOwnProperty(key)) {
			this[key] = data[key];
		}
	}
	this.formConstructorName = 'FormGroup';
	this.form = form;
	this.domId = __.sanitizeId(this.id);
	this.templateKey = 'group';
	this.dataItems = data.items;
	this.items = [];
	this.namedItems = {};
	
	return this;
};

__.FormGroup.prototype.initializeGroupAsync = function() {
	return new Promise(resolve=> {
		this.processItemsAsync().then(items=> {
			this.getTemplateFuncAsync().then(templateFunction=> {
				this.getString = templateFunction;
				resolve(this.formGroup);
			})
		})
	});
}

__.FormGroup.prototype.getTemplateFuncAsync = __.getTemplateFuncAsync;

__.FormGroup.prototype.processItemsAsync = function() {
	return new Promise(resolve => {
		var i, l, item;
		if (!!this.dataItems) {
			let promises = [];
			for (i = 0, l = this.dataItems.length; i < l; i++) {
				item = new FormItem(this.dataItems[i], this.form);
				promises.push(item.initializeTemplatesAndFieldsAsync());
				this.items.push(item);
				this.namedItems[item.id] = item;
			}

			Promise.allSettled(promises).then(() => {
				resolve(this.items);
			});
		}
		else {
			resolve(this.items);
		}
	});
};

__.FormGroup.prototype.toString = function toString() {
	return this.getString(this);
};
const api = __.FormGroup;
export {api as formGroup};
