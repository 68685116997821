import SearchProvider from '../search-provider/search-provider';

import {state} from 'core-global';
import {dpuController} from '../../docroot/cms4i-nemo/js/configurator-bundle';

class SetFlagsSearch extends SearchProvider {

	performSearch(searchQuery) {
		if (!!searchQuery) {
			this.setApplicationFlags(searchQuery);
			this.setDpuFlags(searchQuery);
		}
	}

	setApplicationFlags(searchQuery) {
		if (searchQuery.search(/^([a-z0-9]+)=((true)|(false))$/) !== -1) {
			const modeChange = (/^([a-z0-9]+)=((true)|(false))$/).exec(searchQuery);
			state.triggerModeChange(modeChange[1], modeChange[2]);

		}
	}

	setDpuFlags(searchQuery) {
		if (searchQuery.search(/dpu_extra=(.*)/) !== -1) {
			const dpuExtra = (/^dpu_extra=(.*)/).exec(searchQuery);
			dpuController.setDpuExtra(dpuExtra[1]);
		}
	}
}

const setFlagsSearch = new SetFlagsSearch();
export {setFlagsSearch};
