import {doT} from 'core-vendor';
import { dynamicDot } from 'core-application';

// mapped templates
const _templateMap = {};

// doT template functions; lazily loaded and cached;
const _templateFuncs = {};

// Internal module methods
const __ = {};

__.getTemplateFuncAsync = function() {
	var tplId;
	// This func is added to the prototype of all __.FormX instances.
	// 'this' is assumed to be the instance. To prevent errors, if
	// templateKey is not defined a function returning an empty string
	// is returned.
	var key = this.templateKey || null,
		tpl,
		fallbackFunc = function() {
			return '';
		};
	if (key === null) {
		return Promise.resolve(fallbackFunc);
	}
	tplId = __.getTemplateId(this, key);
	if (_templateFuncs[tplId] === undefined) {
		if(dynamicDot.requiresDynamicDot()) {
			return new Promise((resolve, reject) => {
				dynamicDot.getTemplateFromBackend(tplId).then(content => {
					_templateFuncs[tplId] = doT.template(content);
					resolve(_templateFuncs[tplId]);
				})
				.catch(error=> {
					console.warn('Error reading dot-template with id: ' + tplId, error);
					resolve(fallbackFunc);
				});
			});
		}
		else {
			tpl = document.querySelector('#' + tplId);
			if (tpl && tpl.innerHTML) {
				_templateFuncs[tplId] = doT.template(tpl.innerHTML.trim());
			}
			else {
				return Promise.resolve(fallbackFunc);
			}
		}
	}
	return Promise.resolve(_templateFuncs[tplId]);
};

/**
 * Sanitize Id to get a DOM-usable id string
 */
__.sanitizeId = function(id) {
	if (id !== undefined) {
		return id.replace(/[^a-z0-9_-]/gi, '');
	}
	return '';
};

/**
 * Chose the correct template id in this order of specificity:
 * 1. <form.id>-<obj.id> found in _templateMap
 * 2. <form.id>-<form.pageId> found in _templateMap
 * 3. <form.id>-<key> found in _templateMap
 * 4. <form.id>-<form.pageId>-<obj.id>
 * 5. <form.id>-<obj.id>
 * 6. <form.id>-<form.pageId>-<key>
 * 7. <form.id>-<key>
 * 7. <key>
 */
__.getTemplateId = function(obj, key) {
	var pre = 'nm-id-form-',
		post = '-tpl',
		form = obj.form || obj,
		customTemplateIds = form.customTemplateIds || {},
		objDOMId,
		idStr;
	key = key.toLowerCase();
	if (obj.id !== undefined) {
		objDOMId = __.sanitizeId(obj.id);
		// try very very very high specificity (mapped)
		idStr = _templateMap[form.id + '-' + objDOMId];
		if (idStr !== undefined) {
			return idStr;
		}
		// try very high specificity
		idStr = pre + form.id + '-' + form.pageId + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
		// try high specificity
		idStr = pre + form.id + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
	}
	// try mapped page
	if (obj.formConstructorName === 'Form') {
		idStr = _templateMap[form.id + '-' + form.pageId];
		if (idStr !== undefined) {
			return idStr;
		}
	}
	// try mapped with <formid>-<key> (field type)
	idStr = pre + form.id + '-' + key + post;
	if (_templateMap[form.id + '-' + key] !== undefined) {
		return idStr;
	}
	// try normal specificity
	idStr = pre + form.id + '-' + form.pageId + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// try low specificity
	idStr = pre + form.id + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// return id string with very low specificity (default)
	idStr = pre + key + post;
	return idStr;
};

/**
 * FormField
 */
__.FormField = function FormField(data, form) {
	var key;
	for (key in data) {
		// TODO: (#field-002) refactor this into a helper method (?)
		if (data.hasOwnProperty(key)) {
			this[key] = data[key];
		}
	}
	this.formConstructorName = 'FormField';
	this.domId = __.sanitizeId(this.id);
	// ad name attr if not defined
	if (this.name === undefined) {
		this.name = this.id;
	}
	this.form = form;

	// TODO: (#field-003) refactor to either of one
	// 'type' (probably from 'opts') or 'templateKey'
	//
	this.templateKey = this.type;
	return this;
};

__.FormField.prototype.initializeTemplatesAsync = function() {
	return new Promise((resolve)=> {
		this.getTemplateFuncAsync().then(templateFunction => {
			this.getString = templateFunction;
			this.registerChange();
			resolve(templateFunction);
		});
	});
}

__.FormField.prototype.getTemplateFuncAsync = __.getTemplateFuncAsync;

__.FormField.prototype.registerChange = function() {
	var field = this,
		context,
		selector,
		type = this.type.toLowerCase();

		

	if (type === 'radio') {
		context = '#' + this.form.id;
		selector = 'input[type="radio"][name="' + this.domId + '"]';
		const hiddenSelector = 'input[type="hidden"][name="' + this.domId + '"]';
		jQuery(context).on('change', selector, function() {
			jQuery(hiddenSelector).remove();
			// FIXME: DRY
			var callFn = !!field.auto_submit ? field.form.autoSubmit : field.form.changed;
			callFn.apply(field.form, [field.action]);
		});
	}
	else if (type === 'select') {
		context = '#' + this.form.id;
		selector = '#' + this.domId;
		jQuery(context).on('change', selector, function() {
			// FIXME: DRY
			var callFn = !!field.auto_submit ? field.form.autoSubmit : field.form.changed;
			callFn.apply(field.form, [field.action]);
		});
	}
	else if (type === 'checkbox') {
		context = '#' + this.form.id;
		selector = '#' + this.domId;
		jQuery(context).on('change', selector, function() {
			// FIXME: DRY
			var callFn = !!field.auto_submit ? field.form.autoSubmit : field.form.changed;
			callFn.apply(field.form, [field.action]);
		});
	}
	else {
		context = '#' + this.form.id;
		selector = '#' + this.domId;
		jQuery(context).on('blur', selector, function() {
			// FIXME: DRY
			var callFn = !!field.auto_submit ? field.form.autoSubmit : field.form.changed;
			callFn.apply(field.form, [field.action]);
		});
	}
};

__.FormField.prototype.toString = function() {
	return this.getString(this);
};
const api = __.FormField;
export {api as formField};
