import {appEvents as EVENTS} from 'core-application';
import {device as DEVICE} from 'core-utils';
// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
const __ = {},
	/**
	 * Public API
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};

__.device = DEVICE;
__.oDefaults = {
	classIsWallet: 'nm-agent-is-wallet'
};
__.eventBus = null;

/**
 * addEvents - adds dom event
 * @return {void} returns nothing
 */
__.addEvents = function () {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
};

/**
 * handlePageLoaded - on page loaded event, it sets css class if device is wallet enabled
 * @returns {void} returns nothing
 */
__.handlePageLoaded = function () {
	if (__.isWalletEnabled()) {
		document.querySelector('body').classList.add(__.oDefaults.classIsWallet);
	}
};

/**
 * isWalletEnabled - checks wether current device is wallet enabled
 * @returns {boolean} returns true if device OS is iOS otherwise false
 */
__.isWalletEnabled = function () {
	return (__.device.isiPad() || __.device.isiPhone());
};

exports.initialize = function (eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
	__.handlePageLoaded();
};

export {exports as wallet};
