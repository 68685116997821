export default class EngineCompareControls extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this.changeEngineButton = this.querySelector('.nm-engine-compare__button-change');
		this.addEvents();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.changeEngineButton.addEventListener('click', event => this.showSearchWidget(event));
	}

	removeEvents() {
		this.changeEngineButton.removeEventListener('click', event => this.showSearchWidget(event));
	}

	showSearchWidget(event) {
		event.preventDefault(); // prevent form submit

		const widgets = document.querySelectorAll('.nm-engine-compare__widget');
		[...widgets].forEach(widget => {
			if (widget.contains(this)) {
				widget.classList.add('nm-engine-compare__widget--show-search');
			}
		});
	}
}

if (!window.customElements.get('audi-configurator-enginecompare-controls')) {
	window.customElements.define('audi-configurator-enginecompare-controls', EngineCompareControls);
}
