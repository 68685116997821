import {appEvents as EVENTS} from 'core-application';
import {dpuApi as CONFIGURATOR_API} from './dpu/api';
// Private
const __ = {},
	// Public API
	exports = {
		__: __
	};
// lookup for CSS selectors etc.
__.oDefaults = {
	classBasketMatchingContainer: '.nm-basket-matching'
};
// reference to the global eventEmitter
__.eventBus = null;
// default number uf matching cars to load
__.matchingSize = 6;
// svd version
__.svdVersion = null;
// stck url setups
__.stckSetups = null;
/**
 * add listeners to events of interest for this modeule
 * @returns {void}
 */
__.addEvents = function() {
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.triggerStckRequest);
	__.eventBus.on(EVENTS.PAGE_READY, __.triggerStckRequest);
};
/**
 * check whether matching is active, i.e. Scopes STCK and STCK_MATCHING are active
 * @returns {boolean} true, if matching active, false else
 */
__.isMatchingActive = function() {
	const hasStockcarsMatching = !!SETUPS.get('scope.nemo.scs-matching');
	const isStockcarsPage = SETUPS.get('stockcar.context');
	const hasStockcarsBaseUrl = !!SETUPS.get('stockcar.scs.base.url');
	const hasStockcarsMarketPath = !!SETUPS.get('stockcar.scs.market.path');

	return hasStockcarsMatching && !isStockcarsPage && hasStockcarsBaseUrl && hasStockcarsMarketPath;
};
/**
 * main initialization method (global EventEmitter)
 * @param {Object} eventBus_ - global EventEmitter
 * @returns {void}
 * */
__.initialize = function() {
	// is stockcar market?
	if (__.isMatchingActive() === true) {
		__.getSVDVersion().then(function() {
			__.triggerStckRequest();
			__.addEvents();
		});
	}
};

/**
 * getStckUrlSetup - prepare object with stck url setups
 * @param {String} requestType_ - type of request
 * @returns {Object} __.stckSetups - stck url setups
 */
__.getStckUrlSetup = function(requestType_) {
	__.stckSetups = {};
	__.stckSetups.baseUrl = SETUPS.get('stockcar.scs.base.url');
	__.stckSetups.market = SETUPS.get('stockcar.scs.market.path');
	__.stckSetups.versionId = __.getStckVersionString(requestType_);
	return __.stckSetups;
};

/**
 * getStckVersionString - prepare version string
 * @param {String} requestType_ - type of request
 * @returns {String} version
 */
__.getStckVersionString = requestType_ => {
	let version = SETUPS.get('stockcar.scs.default.version') ? SETUPS.get('stockcar.scs.default.version') + '/' : '',
		mapping = SETUPS.get('stockcar.scs.version.mapping');
	if (!!mapping && Object.keys(mapping).length > 0) {
		version = typeof mapping[requestType_] === 'string' ? mapping[requestType_] + '/' : version;
	}
	return version;
};
/**
 * trigger Stockcar matching request on pageLoad and configuration updates
 * starts request to STCK and emits Event on result
 * @returns {Promise} request Promise
 */
__.triggerStckRequest = function() {
	if (__.isMatchingActive() === true) {
		return __.startStckRequests()
			.then(response => {
				__.svdVersion =
					response && response.header && response.header.dataVersion
						? response.header.dataVersion
						: __.svdVersion;
				return __.broadcastStckMatching(response);
			})
			.catch(error => console.log(error));
	}
};
/**
 * start STCK request
 *@returns {Promise} - SVD response wrapped in a Promise
 */
__.startStckRequests = async function() {
	var matchUrl = __.getMatchingUrl(),
		params;
	if (!!matchUrl) {
		const svdVersion = await __.getSVDVersion();
		params = __.getRequestParams(svdVersion);
		if (!params) {
			return Promise.reject('missing SCS Attributes…');
		}
		const queryParams = new URLSearchParams(params);
		const url = matchUrl + (matchUrl.indexOf('?') < 0 ? '?' : '&') + queryParams.toString();

		return fetch(url, {credentials: 'omit'}).then(response => {
			if (response.ok) {
				return response.json();
			}
			else {
				throw new Error(response.statusText);
			}
		});
	}
	else {
		return Promise.reject('no version and matchUrl…');
	}
};

/**
 * getBasketContainer
 * @returns {HTMLElement} returns matching basket container
 */
__.getBasketContainer = function() {
	return document.querySelector(__.oDefaults.classBasketMatchingContainer);
};
/**
 * get SCS Mathcing attributes for this configuration
 * @returns {string} matching attributes
 */
__.getMatchingAttributes = function() {
	var matchingAttr,
		configurationMatchingAttr,
		basketContainer = __.getBasketContainer();
	configurationMatchingAttr = CONFIGURATOR_API.getSCSmatchingAttributes();
	// read scs matching attributes from configuration
	if (!!configurationMatchingAttr && configurationMatchingAttr.length > 0) {
		matchingAttr = configurationMatchingAttr.join(',');
		return matchingAttr;
	}
	// read scs matching attributes from basket data-attr
	if (basketContainer) {
		matchingAttr = basketContainer.getAttribute('data-attributes');
		matchingAttr = matchingAttr.replace(/[[\s\]']+/g, '');
		return !!matchingAttr ? matchingAttr : null;
	}
	return null;
};

/**
 * getRequestParams for scs matching request
 * @param {string} svdVersion_ - svd version
 * @return {string} param request string
 */
__.getRequestParams = function(svdVersion_) {
	// {{protocol}}://{{host}}:{{port}}/scs/search/v1/match/es/es?svd={{svd}}&size=3&attributes=color-type.pearl,carline.a3sb,carline.a4avant
	var paramString = [],
		matchingAttr = __.getMatchingAttributes();
	if (!!matchingAttr && matchingAttr.length && !!svdVersion_) {
		paramString.push('svd=' + svdVersion_);
		paramString.push('size=' + __.matchingSize);
		paramString.push('attributes=' + matchingAttr);
		paramString = paramString.join('&');
		return paramString;
	}
	else {
		return null;
	}
};

/**
 * broadcast Matching result via EventEmitter
 *@param {Object} payload_ - event Payload
 *@returns {void}
 */
__.broadcastStckMatching = function(payload_) {
	__.eventBus.emit(EVENTS.STOCKCAR_UPDATE, payload_);
	return payload_;
};

/**
 * getVersionUrl prepare url for stck version request
 * @returns {string} url - returns version url
 */
__.getVersionUrl = function() {
	var url,
		stckSetups = __.stckSetups ? __.stckSetups : __.getStckUrlSetup('version');
	if (!!stckSetups.baseUrl && !!stckSetups.versionId && !!stckSetups.market) {
		url = stckSetups.baseUrl + stckSetups.versionId + 'version/' + stckSetups.market;
	}
	return url;
};

/**
 * getMatchingUrl (get url to the scs matching request)
 *@returns {string} url - matching url
 */
__.getMatchingUrl = function() {
	var url,
		stckSetups = __.stckSetups ? __.stckSetups : __.getStckUrlSetup('search');
	if (!!stckSetups.baseUrl && !!stckSetups.versionId && !!stckSetups.market) {
		url = stckSetups.baseUrl + stckSetups.versionId + 'search/match/' + stckSetups.market;
	}
	return url;
};

/**
 * getSVDVersion method (calls fetchVersion if version has not yet existed)
 * @returns {Promise} svd version string wrapped in a Promise
 */
__.getSVDVersion = (function() {
	let promise;
	return function() {
		if (!promise) {
			if (!__.svdVersion) {
				promise = __.fetchSVDVersion();
			}
			else {
				promise = Promise.resolve(__.svdVersion);
			}
		}
		else {
			if (__.svdVersion) {
				promise = Promise.resolve(__.svdVersion);
			}
		}
		return promise;
	};
}());

/**
 * fetch current SVD Version
 * @return {Promise} with SVD version value
 */
__.fetchSVDVersion = function() {
	var url = __.getVersionUrl();
	__.svdVersion = null;

	return fetch(url, {credentials: 'omit'})
		.then(response => {
			if (response.ok) {
				return response.json();
			}
			else {
				throw new Error(response.statusText);
			}
		})
		.then(function(response) {
			__.svdVersion =
				response && response.header && response.header.dataVersion ? response.header.dataVersion : null;
			if (!__.svdVersion) {
				throw new TypeError('could not load svd-version');
			}
			return __.svdVersion;
		})
		.catch(function(error) {
			console.error('SVD Version error. Using stockcar.static.svd ', error);
		})
		.then(function() {
			return __.svdVersion;
		});
};

// module initialization
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();
};
export {exports as scsMatching};
