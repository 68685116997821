import {appEvents as EVENTS, content as CONTENT} from 'core-application';
import {dpuApi as DPU_API} from './api';
import {dpuModel as DPU_MODEL} from './model';
import {startlayer as START_LAYER} from '../startlayer';
import {carlineMapping as CARLINE_MAPPING} from '../carline-mapping';
import {userConfigurations as USER_CONFIGURATIONS} from './user-configurations';
import {dpuTransfer as TRANSFER_LAYER} from './transfer';
import {dpuConflict as CONFLICT_LAYER} from './conflict';
import {stateRegistry} from 'microkernel';
import {AUDICODE_STORE_ID} from '../stores/audicode-store';
import {useConfigurationTrackingEvent} from '../tracking/utils';

const __ = {},
	exports = {
		__: __
	};
let _initialized = false;
/**
 * private exports methods
 */
// Version and Carinfo.json loaded?
__.dataInitialized = false;
__.initMaxTries = 20;
__.oDefaults = {
	sSelectorDelegateClick: '.nm-j-configurator-delegate-click',
	sSelectorDelegateChangeCount: '.nm-quantity',
	sSelectorDelegateNewClick: '.nm-configuration-new',
	sSelectorPrintBtn: '.nm-configuration-print'
};
__.startLayerActivated = false;
__.openedLayer = null;
__.configurationContinued = false;
__.unprocessedTrackingChange = null;
__.addEvents = function() {
	// =========== Configuration Events ===
	// handle click on configuration item
	__.legacyEventBus.on(
		'click.configuration.item',
		__.oDefaults.sSelectorDelegateClick,
		{
			triggerAction: 'click'
		},
		__.handleConfigurationItemChanges
	);
	// handle change Events (numeric stepper or input fields)
	__.legacyEventBus.on(
		'change.configuration.count',
		__.oDefaults.sSelectorDelegateChangeCount,
		{
			triggerAction: 'changeCount'
		},
		__.handleConfigurationItemChanges
	);

	// start new (==reset to default) configuration and open implicit startlayer
	__.legacyEventBus.on('click.configuration.new', __.oDefaults.sSelectorDelegateNewClick, __.handleNewConfigurationClick);
	__.addModeSwitchEventAfterReset();
	// =========== Layer Events ===
	// handle Conflict layer close (Cancel or submit)
	__.eventBus.on(EVENTS.CONFLICT_CLOSE, __.handleConflictClose);
	// handle Transfer layer close (transfer abort)
	__.eventBus.on(EVENTS.TRANSFER_CLOSE, __.handleTransferClose);
	// handle Startlayer close
	__.eventBus.on(EVENTS.STARTLAYER_CLOSE, __.handleStartlayerClose);
	__.legacyEventBus.on('click.nemo.configurator.print', __.oDefaults.sSelectorPrintBtn, __.callPrint);
	__.eventBus.on(EVENTS.FORM_FINISHED, __.receiveAudicodeForm);

	// custom-event for configuration change (NEMOBA-11472)
	document.addEventListener('changeItemInConfiguration', __.handleEventForConfigurationChange);

	// change triggert by changes within cola
	document.addEventListener('configurationUpdateFromCoLa', __.handleEventForConfigurationChange);
	document.addEventListener('configurationUpdateFromOneGraph', __.handleEventForConfigurationChange);

	document.addEventListener('resetConfiguration', __.handleNewConfigurationClick);
};
__.callPrint = function(/* event_ */) {
	__.eventBus.emit(EVENTS.CONFIG_PDF);
	//  _cancelEvent(event);
	//  #api-dpu-001.5
	let url = SETUPS.get('nemo.core.configurator.dpu.oAjaxRESTConfigurationURIPrint'),
		$link = jQuery(this),
		context = DPU_API.getContext(),
		subsessionParam = DPU_API.getSubsessionID() ? '&subsession=' + DPU_API.getSubsessionID() : '',
		prstring = DPU_API.getPrString();
	url = url + '?context=' + context + subsessionParam + '&ids=' + encodeURIComponent(prstring);
	const envkvLabel = $link.attr('data-envkv-label');
	if (envkvLabel === 'true') {
		url += '&envkv-label-only=true';
	}
	$link.attr({
		href: url,
		target: '_blank'
	});
	return true;
};
/*
 * check if a clicked Item is a selected RaidoButton
 */
__.isSelectedRadioButton = function($clickedItem_) {
	var $configuratorElement = $clickedItem_.closest('.nm-j-configurator-item');
	if (
		$configuratorElement.is('.nm-j-configurator-status_11010, .nm-j-configurator-status_100110') &&
		!$configuratorElement.is('.nm-md-bicolor-chooser-el-options') &&
		$clickedItem_.data('is-deselectable') === false
	) {
		return true;
	}
	return false;
};
__.isPreselectedConflictItem = function($clickedItem_) {
	var $configuratorElement, status, _sClassName;
	if (!DPU_API.getConflicts()) {
		return false;
	}
	else {
		$configuratorElement = $clickedItem_.closest('.nm-j-configurator-item');
		_sClassName = $configuratorElement.get(0).className;
		if (_sClassName.indexOf('nm-j-configurator-status_') === -1) {
			return false;
		}
		else {
			status = _sClassName.split('nm-j-configurator-status_')[1].split(' ')[0];
			return status.length === 5 && status[3] === '1';
		}
	}
};
/*
 * Handle Mouseclicks on any Configuration Item on the page
 * and  * handle count changes for a configurator item (e.g. via numeric stepper or input field)
 */
// eslint-disable-next-line max-statements, complexity
__.handleConfigurationItemChanges = function(event_) {
	__.cancelEvent(event_);
	let _$clickedItem = jQuery(event_.target);
	if (__.isSelectedRadioButton(_$clickedItem) || __.isPreselectedConflictItem(_$clickedItem)) {
		return false;
	}
	let _$element = _$clickedItem.closest('.nm-j-configurator-item');
	let _sId = _$element.attr('data-configurator-id');
	let _count = _$element.find(__.oDefaults.sSelectorDelegateChangeCount).val();
	let requestAction;
	let userAction = event_.data.triggerAction || 'no-GUI-action-defined';
	let isSelectedOption = DPU_API.isOptionSelectedInConfiguration(_sId);
	switch (userAction) {
		case 'changeCount':
			if (!isSelectedOption) {
				return false;
			}
			requestAction = 'itemAdd';
			break;
		case 'click':
			if (__.isSelectableItem(_$element) === false) {
				return;
			}
			requestAction = isSelectedOption ? 'itemRemove' : 'itemAdd';
			requestAction = __.isIndividualColorConfiguration(_sId) ? 'itemAdd' : requestAction;
			break;
		case 'dropDown':
			_$element = jQuery(this);
			_sId = _$element.val();
			isSelectedOption = DPU_API.isOptionSelectedInConfiguration(_sId);
			requestAction = isSelectedOption ? 'itemRemove' : 'itemAdd';
			break;
		default:
			return;
	}
	let _change = {
		id: _sId,
		count: _count,
		action: requestAction,
		itemInfo: DPU_API.getItem(_sId),
		configurationItems: DPU_API.getConfiguration()?.items
	};
	// add infos to change object
	const changeEventdata = jQuery.extend(true, _change, {
		triggerAction: userAction,
		$element: _$element
	});
	/**
	 * dispatch beginning of an DPU request
	 * e.g. for showing loadingindicator
	 */
	__.eventBus.emit(EVENTS.CONFIG_CHANGEREQUEST_START, changeEventdata);
	// trigger Ajax request for change of configuration (using Promise)
	__.triggerChangeRequest(_change);
};
/**
 * handle custom-event for the configuration change
 * @param {event} event  with pr-id in detail.id
 * @returns {void} nothing
 */
__.handleEventForConfigurationChange = function(event) {
	try {
		if (event.detail.id) {
			__.triggerConfigurationChange(event.detail.id);
		}
	}
	catch (e) {
		console.error('handleEventForConfigurationChange: ', '<id> is not defined');
	}
};
/**
 * trigger the configuration change event
 * @param {string} id pr-id
 * @returns {void} nothing
 */
__.triggerConfigurationChange = function(id) {
	var change = {},
		isSelectedOption = DPU_API.isOptionSelectedInConfiguration(id);
	change.id = id;
	change.count = 0;
	change.productInfo = DPU_API.getItem(id);
	if (isSelectedOption) {
		change.action = 'itemRemove';
	}
	else {
		change.action = 'itemAdd';
	}

	__.triggerChangeRequest(change);
};
/**
 * handle AudiCode Form Data
 * @param {Object} data_ - custom event data
 * @returns {void} nothing
 */
__.receiveAudicodeForm = function(data_) {
	var promise, lockChanged;
	__.cancelEvent(this);
	if (!!data_.id && data_.id.indexOf('audicode') > -1) {
		__.lockPrString(DPU_API.getPrString());
		promise = Promise.resolve(data_.response); // exports.loadConfigurationByAudiCode(data_.response.audicode.id);
		promise
			.then(function(config) {
				lockChanged = __.detectConfigurationChanges(DPU_API.getPrString());
				if (lockChanged) {
					throw new TypeError('receiveAudicodeForm:configuration changed while processing, loaded configuration was rejected');
				}
				else {
					exports.handleExternalConfiguration(config, {
						startType: 'audicode-input'
					});
				}
			})
			.then(() => {
				__.dispatchCustomConfigurationStartEvent('audicode-input');
			})
			.catch((err) => {
				console.warn(err);
				// __.showError('nm_error_unknown');
				__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-audicode-error'], err.message]);
			});
	}
};

exports.generateAudiCode = function(params) {
	return DPU_MODEL.generateAudiCode(params);
};

/*
 * store current prtring to detect configuration changes later
 */
__.lockPrString = function(prString_) {
	__.lockedPrString = prString_;
};
/*
 * detect changes in the configuration
 * (compare current prString with locked/stored prString)
 */
__.detectConfigurationChanges = function(prString_) {
	return __.lockedPrString !== prString_;
};

__.isEntryPage = function () {
	return (/tools\/nemo\/entry\.html$/gi).test(window.location.pathname);
};

__.hasValidEntryRedirects = function() {
	if (__.isEntryPage()) {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.has('pr') || searchParams.has('audicode');
	}

	return false;
};

/**
 * adopt externally (e.g. via AudiCode,PrString or Trampoline Page) loaded configurations
 * @param {Object} data - loaded configuration object
 * @param {Object} options - options
 * @returns {void} nothing
 */
// eslint-disable-next-line max-statements, complexity
exports.handleExternalConfiguration = function(data, options) {
	var promise;
	// check for errors within the loaded configuration/response
	if (!data || !!(data && data.audicode && data.audicode.success === false) || !!(data && data.errorMessage)) {
		let errMsg;

		if (!!(data.audicode && !data.audicode.success && (!!data.audicode && !!data.audicode['other-url']))) {
			window.location.href = data.audicode['other-url'];
			return;
		}
		if (data && data.audicode && data.audicode.message) {
			errMsg = data.audicode.message;
		}
		else if (data && data.errorMessage) {
			errMsg = data.errorMessage;
		}
		else if (data && data.header && !!data.header.errormessages) {
			errMsg = data.header.errormessages.join(',');
		}
		else {
			errMsg = 'unknown_error';
		}
		throw new TypeError(errMsg);
	}

	// configuration reload (e.g. by changing price rate)
	if (!options.startType) {
		promise = DPU_MODEL.adoptConfiguration(data);
		return promise
			.then(function(success) {
				__.handleConfigurationUpdate(success);
			})
			.catch(function(err) {
				__.handleConfigurationUpdateError(err);
			});
	}

	__.startLayerActivated = true;
	// don´show startlayer on loaded user carline configurations
	if (options.startType === 'user-configuration') {
		__.startLayerActivated = false;
	}

	// check if carline id matches the current page´s carline ID, if we are on the entry page always use the trampolin functionality
	if (!__.hasValidEntryRedirects() && CARLINE_MAPPING.isMatchingConfiguration(data)) {
		promise = DPU_MODEL.adoptConfiguration(data);
		return promise
			.then(function(success) {
				__.setConfigurationStartType(options.startType);
				__.handleConfigurationUpdate(success);
			})
			.catch(function(err) {
				__.handleConfigurationUpdateError(err);
			});
	}
	else {
		// use trampoline page to redirect page
		//  console.warn("carline does not match with the current page => using trampoline page to redirect...");
		let subsession = DPU_API.getSubsessionID();
		if (!!data.subsession) {
			subsession = data.subsession;
		}
		if (!!data.header && !!data.header.subsession) {
			subsession = data.header.subsession;
		}
		return new Promise(function() {
			CARLINE_MAPPING.useTrampoline(data, subsession, options.startType);
			promise = Promise.reject('using trampoline page');
		}).catch(function(err) {
			console.err('Trampoline failed', err);
		});
	}
};

__.setConfigurationStartType = function(startType_, changeEventdata) {
	const startType = (DPU_MODEL.getConfigurationStartType() === 'default' && startType_ === 'configuration-change') ? 'configuration-start' : startType_;

	DPU_MODEL.setConfigurationStartType(startType_);

	if (!useConfigurationTrackingEvent(startType)) {
		__.dispatchCustomConfigurationStartEvent(startType, changeEventdata);
	}
	else {
		__.configurationStartedByAudiCodeSearch = true;
	}
};

/*
 * handle configuration model update
 * (check for conflicts, update DOM etc.)
 */
__.handleConfigurationUpdate = function(/* success_ */) {
	// console.log("UPDATE:", success_);
	__.eventBus.emit(EVENTS.CONFIG_UPDATE);
	// workaround to prevent configuration-start and configuration-change to be saved twice in tracking
	if (__.configurationStartedByAudiCodeSearch) {
		__.configurationStartedByAudiCodeSearch = false;
	}
	else {
		// Only trigger digitalData cart update for subsequent events
		__.dispatchCustomConfigurationUpdateEvent();
	}

	__.dispatchCustomEvent();

	__.checkLayerQueue();
};

/*
 * dispatches a custom-event when the configuration changes
 */
__.dispatchCustomEvent = function() {
	try {
		let items = DPU_API.getItems();
		let configurationItemsUpdate = new CustomEvent('configurationItemsUpdate', {
			detail: {
				items: items
			}
		});
		//  console.log('dispatch event', configurationItemsUpdate);
		document.dispatchEvent(configurationItemsUpdate);
	}
	catch (e) {
		console.error('dispatchCustomEvent: ', 'the custom-event for the configuration-change could not be dispatched', e);
	}
};

/*
 * dispatches a custom-event when the configuration starts
 */
__.dispatchCustomConfigurationStartEvent = function(startType_, changeEventData) {
	const configurationStartEvent = new CustomEvent('configuration-start-tracking', {
		detail: {
			type: startType_,
			changeEventData
		}
	});

	DPU_MODEL.setConfigurationStartType(startType_);
	document.dispatchEvent(configurationStartEvent);
};

/*
 * The core-tracking lib listens to this event to trigger the update of the cart object in the digitalData
 * @see https://github.com/audi/audi-core-tracking/blob/develop/js/src/events/cart-tracking.js
 */
__.dispatchCustomConfigurationUpdateEvent = function() {
	document.dispatchEvent(new CustomEvent('configuration-update-tracking'));
};

/**
 * check for Layer to open/close after each configuration response
 * or Closing of Start- and TransferLayers
 * @returns {boolean} - queue finished (no new Layer opened)
 */
// eslint-disable-next-line complexity, max-statements
__.checkLayerQueue = function(/* initialCall_ */) {
	let conflicts, transfers;
	transfers = DPU_API.getTransfers();
	conflicts = DPU_API.getConflicts();
	// configuration started! => configuration has not yet been continued, id not an active user click and is not initial configuration
	let noConflictsOrTransfers = !transfers && !conflicts;
	let startType = DPU_API.getConfigurationStartType();
	if (__.startLayerActivated && noConflictsOrTransfers && !DPU_API.isContinuedConfiguration() && startType !== 'default' && startType !== 'configuration-change') {
		__.startLayerActivated = false;
		// show implictit startlayer on configuration reset 'new configuration' button
		if (startType === 'configuration-reset') {
			__.handleStartLayerDemand();
			return false;
		}
		else {
			//  configuration has been started by adopting a loaded configuration (e.g. audicode,prstring,carstore)
			// fix template data
			let data = {
				configuration: DPU_API.getConfiguration(),
				assets: DPU_API.getConfigurationAssets(),
				images: DPU_API.getCarImages(),
				ms_url: DPU_API.getRenderUrl(),
				start_type: startType
			};
			__.handleStartLayerDemand(data);
			return false;
		}
	}
	__.unHandleStartLayerDemand();
	// check for conflicts and transfers durcing configuration
	// start openening new Layer
	// open transfer Layer (only after session was started)
	if (transfers && DPU_API.isContinuedConfiguration()) {
		//  console.log("Configuration has Transfers", transfers);
		TRANSFER_LAYER.handleTransfer(transfers);
		return false;
	}
	// open conflict Layer
	if (conflicts) {
		//  console.log("Configuration has Conflicts", conflicts);
		CONFLICT_LAYER.handleConflict(conflicts);
		return false;
	}
	return true;
};
/**
 * show startlayer if demanded
 * @param {Object|null} data - startlayer content (e.g. loaded configuration info)
 * @returns {void} nothing
 */
__.handleStartLayerDemand = function(data) {

	if (data && data.configuration && data.configuration['audicode-rescue-info']) {
		const rescueMessages = __.getAudicodeRescueLayerMessages(data);
		if (rescueMessages.length > 0) {
			__.triggerAudicodeRescueLayer(data, rescueMessages);
			return; // prevent default startlayer
		}
	}

	let promise = START_LAYER.handleStartLayer(data);
	promise.then(
		function() {
			//  console.log("Showing startlayer as requested...");
		},
		function(err) {
			console.warn('handleStartLayerDemand Error', err.message);
		}
	);
};
__.unHandleStartLayerDemand = function() {
	START_LAYER.unHandleStartLayer();
};

__.getAudicodeRescueLayerMessages = (data) => {
	const availableMessages = SETUPS.get('nemo.audicode.rescue.messages');

	if (!availableMessages) {
		return [];
	}

	const rescueInfo = data.configuration['audicode-rescue-info'];
	const repairInfos = rescueInfo.history
		.reduce((uniqueRepairTypes, repairInfo) => {
			return uniqueRepairTypes.includes(repairInfo['repairType'])
				? uniqueRepairTypes
				: [...uniqueRepairTypes, repairInfo['repairType']];
		}, [])
		.reduce((rescueMessages, repairType) => {
			const message = availableMessages[repairType] ? availableMessages[repairType].trim() : undefined;
			return (message && !rescueMessages.includes(message))
				? [...rescueMessages, message]
				: rescueMessages;
		}, []);

	return repairInfos;
};

__.triggerAudicodeRescueLayer = (data, rescueMessages) => {
	document.dispatchEvent(new CustomEvent('modalLayer:open', {
		detail: {
			layerType: 'AudicodeRescueLayerElement',
			options: {
				addCssClass: 'audicode-rescue-wrapper',
				onEscClick: true,
				onShaderClick: true,
				centerVertically: true
			},
			dataset: {...data, rescueMessages}
		}
	}));
};

exports.resetAudicodeRescueInfo = () => {
	DPU_MODEL.resetAudicodeRescueInfo();
};

/*
 * conflict layer was closed
 * (conflict was submitted or cancelled => send submit/cancelURL to the DPU)
 */
__.handleConflictClose = function(data_) {
	let startTime = new Date();
	//  console.log("Conflict submit or cancel started at " + startTime.toTimeString(), data_.url);
	let promise = DPU_MODEL.requestTransferOrConflictResolve(data_.url);
	// handle successfulResponse or Error and allways ==> onAjaxCompleted
	promise
		.then(__.handleConfigurationUpdate)
		.catch(__.handleConfigurationUpdateError)
		.then(function() {
			__.onAjaxCompleted(startTime);
		});

	if (__.unprocessedTrackingChange !== null && data_.action === "submit") {
		__.setConfigurationStartType(__.unprocessedTrackingChange.startType, __.unprocessedTrackingChange.changeEventData);
		__.unprocessedTrackingChange = null;
	}

	if (data_.action === "cancel"){
		// as the conflict is cancelled, we do not track the untracked item.
		__.unprocessedTrackingChange = null;
	}
};
/*
 * transfer layer was closed
 * (transfer was adopted or cancelled)
 */
__.handleTransferClose = function(data_) {
	// delete transfer object from Model
	DPU_MODEL.transferCompleted();
	// transfer was adopted?
	if (!data_ || !data_.url) {
		__.checkLayerQueue();
	}
	else {
		// transfer was cancelled=>call DPU with abort url
		let startTime = new Date();
		//  console.log("Transfer-abort started at " + startTime.toTimeString(), data_.url);
		let promise = DPU_MODEL.requestTransferOrConflictResolve(data_.url);
		// handle successfulResponse or Error and allways ==> onAjaxCompleted
		promise
			.then(__.handleConfigurationUpdate)
			.catch(__.handleConfigurationUpdateError)
			.then(function() {
				__.onAjaxCompleted(startTime);
			});
	}
};
/**
 * startlayer layer was closed
 * (check for following  transfer or conflict layer to be opened)
 * @returns {void} nothing
 */
__.handleStartlayerClose = function() {
	__.checkLayerQueue();
};
/**
 * add a prnumber to the configuration
 * @param {string} prNumber pr number
 * @returns {Promise} change request
 */
exports.addToConfiguration = function(prNumber) {
	let change = {
		action: 'itemAdd',
		count: null,
		id: prNumber
	};
	let changeEventData = {};
	__.eventBus.emit(EVENTS.CONFIG_CHANGEREQUEST_START, changeEventData);
	return __.triggerChangeRequest(change);
};
/**
 * remove a prnumber from the configuration
 * @param {string} prNumber - the prNumber to remove
 * @returns {Promise} change request result
 */
exports.removeFromConfiguration = function(prNumber) {
	var change = {
		action: 'itemRemove',
		count: null,
		id: prNumber
	};
	var changeEventdata = {};
	__.eventBus.emit(EVENTS.CONFIG_CHANGEREQUEST_START, changeEventdata);
	return __.triggerChangeRequest(change);
};
/**
 * function to trigger DPU request and handle Promise callbacks
 * @param {Object} change_ change object {id:pr-Number,action:'itemAdd'||'removeItem',count:value}
 * @param {Object} changeEventData changed option data
 * @returns {Promise} ajax request
 */
__.triggerChangeRequest = function(change_) {
	var startTime = new Date();
	//  console.log("Request started at " + startTime.toTimeString(), change_);
	// trigger Ajax request for change of configuration (returns Promise)
	var promise = DPU_MODEL.requestConfigurationChange(change_);
	// handle successfulResponse or Error and allways ==> onAjaxCompleted
	return promise
		.then(function(response) {
			__.updateItemSelectionMethod(change_, 'active');

			const conflicts = DPU_API.getConflicts();
			if (!conflicts) {
				__.setConfigurationStartType('configuration-change', change_);
				__.unprocessedTrackingChange = null;
			}
			else {
				__.unprocessedTrackingChange = {startType: 'configuration-change', changeEventData: change_};
			}

			__.handleConfigurationUpdate(response);
		})
		.catch(__.handleConfigurationUpdateError)
		.then(function() {
			__.onAjaxCompleted(startTime);
		});
};
/*
 * update item´s selection method after successful DPU response
 * selection methods are an important tracking indicator
 * @param {string} id_ - item id (prnumber)
 * @param {string} dpuAction_ - itemAdd'||'removeItem'
 * @param {string} selectionMethod_ - slection method ('pre','active','conflict')
 */
__.updateItemSelectionMethod = function(change_, selectionMethod_) {
	var id, dpuAction;
	id = change_.id;
	dpuAction = change_.action;
	// only track active configuration add and ignore remove
	if (dpuAction === 'itemAdd') {
		// set item´s selection method to 'active'
		DPU_MODEL.setItemSelectionMethod(id, selectionMethod_);
		// DPU_MODEL.setItemSelectionMethod(id, isConflictAlternative?'conflict':selectionMethod_);
	}
	else {
		// clear item´s selectionMethod attribute
		DPU_MODEL.setItemSelectionMethod(id, '');
	}
};
/**
 * all preselected items in the carinfo.json get a
 * 'selectionMethod' attribute with the value of 'pre'
 * @returns {void} nothing
 */
__.updatePreselectedItems = function() {
	var items = DPU_API.getItems();
	jQuery.each(items, function(key, value) {
		// item is preselected => add selection method
		if (!!value.status && value.status.length === 5 && value.status.substr(3, 1) === '1') {
			DPU_MODEL.setItemSelectionMethod(key, 'pre');
		}
	});
};
/*
 * handle ALL configuration Errors during Promises!!
 */
__.handleConfigurationUpdateError = function(err) {
	console.error('Configuration Error', err);
	const message = DPU_API.getErrorMessage(err);
	__.eventBus.emit(EVENTS.ERROR, message);
};

/**
 * check if the given id corresponds to an individual-color configuration-id
 * individual-color configuration ids are: Q0Q0, O0O0, BODY_Q0 and BODY_O0
 * @param {String} configurationId configuration id, e.g. 'Q0Q0'
 * @returns {boolean} is individual-color configuration?
 */
__.isIndividualColorConfiguration = function(configurationId) {
	return configurationId === 'Q0Q0' || configurationId === 'O0O0' || configurationId === 'BODY_Q0' || configurationId === 'BODY_O0';
};
/**
 * check if an item´s DOM-status code (nm-j-configurator-status_) is selectable on click-event
 * @param {jQuery-Element} $element_ configurator item
 * @returns {boolean} is selectable?
 */
__.isSelectableItem = function($element_) {
	var _sClassStatus = DPU_API.getDOMStatusCode($element_),
		_aStatusCodes = _sClassStatus.split(''),
		mss = window.mss || null;
	// radiogroup = !!$element_.attr('data-configurator-group');
	// forced selectable? (event is status would not allow a selection)
	if ($element_.hasClass('nm-j-configurator-item-forceselectable')) {
		return true;
	}
	//  check available flag
	if (_aStatusCodes[0] === '0') {
		return false;
	}
	//  check changeable flag
	if (_aStatusCodes[1] === '0') {
		return false;
	}
	//  check standard and selected flag
	if (_aStatusCodes[4] === '1' && _aStatusCodes[3] === '1' && !!mss) {
		return false;
	}
	//  check conflict flag
	if (_aStatusCodes[2] === '1') {
		//  console.warn("CONFIGURATING:confliction Item selected", $element_);
		return true;
	}
	return true;
};
/**
 * prevent default event behavior
 * @param {Object} event_ e.g. e click event
 * @return {void} nothing
 */
__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};
/*
 * handle configuration reset request
 */
__.handleNewConfigurationClick = function(evt_) {
	var promise;
	__.cancelEvent(evt_);

	// trigger Ajax request for change of configuration (returns Promise)
	promise = DPU_MODEL.requestConfigurationReset();
	// handle successfulResponse or Error and allways ==> onAjaxCompleted
	promise
		.then(function(data) {
			__.startLayerActivated = true;
			DPU_MODEL.setConfigurationStartType('configuration-reset');
			__.handleConfigurationUpdate(data);
		})
		.then(() => {
			__.dispatchCustomConfigurationStartEvent('configuration-reset');
		})
		.then(undefined, __.configurationErrors)
		.then(__.onAjaxCompleted);
};
/*
 * handle all configuration Erros
 */
__.configurationErrors = function(err) {
	console.warn('Configuration Error', err.mesage);
};
/*
 * handle mode change click price <=> rate
 */
__.addModeSwitchEventAfterReset = function() {
	var checkbox = jQuery('nm-price-rate-switch');
	if (!!checkbox) {
		checkbox.checked = false;
	}
	__.legacyEventBus.on('change', '.nm-price-rate-switch, .j-rate-switch', __.handleModeSwitchChange);
	// set mode from feature-app
	document.addEventListener('rateSwitch', __.handleModeSwitchChange);
};

__.handleModeSwitchChange = function(e) {
	let mode;

	if (e.detail && e.detail.mode) {
		mode = e.detail.mode;
	}
	else {
		mode = e.target.checked === true ? 'rate' : 'price';
	}

	DPU_MODEL.changeRatePriceMode(mode)
		.then(__.handleConfigurationUpdate)
		.then(undefined, __.configurationErrors)
		.then(__.onAjaxCompleted);
};
/*
 * dispatch end of AJAX request (on successful and/or failure)
 * e.g. for loadingindicator to hide
 */
__.onAjaxCompleted = function(startTime) {
	if (startTime) {
		//  console.log("Request Finished after " + (new Date().getTime() - startTime.getTime()) + " ms");
	}
	__.eventBus.emit(EVENTS.CONFIG_CHANGEREQUEST_END);
};
/*
 * public exports methods
 */
// eslint-disable-next-line complexity, max-statements
__.initialize = function() {
	var initializationPromise, startTime;

	if (!_initialized) {
		__.addEvents();
		_initialized = true;

		//  ======================================
		//  load version and carinfo
		//  ======================================
		startTime = new Date();
		__.dataInitialized = false;
		initializationPromise = DPU_MODEL.fireDataInitializationChain();
		initializationPromise.then(
			function() {
				__.initPreviousConfiguration().then(() => {
					__.updatePreselectedItems();
					DPU_MODEL.dispatchPriceMode(DPU_API.getPriceRateMode());
					__.dispatchCustomEvent();
					__.dataInitialized = true;
					console.info('fireDataInitializationChain completed after ' + (new Date().getTime() - startTime.getTime()) + ' ms');
				});
			},
			function(err) {
				__.initPreviousConfiguration().then(() => {
					__.dataInitialized = true;
					console.warn('fireDataInitializationChain failed after ' + (new Date().getTime() - startTime.getTime()) + ' ms:' + err.message);
				});
			}
		);
	}
};

exports.isDataInitialized = function() {
	return __.dataInitialized;
};

// eslint-disable-next-line complexity, max-statements
__.initPreviousConfiguration = function() {
	var msg, newConfigurationParam, previousConfiguration, configurationRequestParam, previousUserConfigurationPromise;
	// ======================================
	//  check for configuration entries
	// ======================================
	if (window.location.search && window.location.search.indexOf('new_config') > -1) {
		newConfigurationParam = true;
	}

	previousConfiguration = CARLINE_MAPPING.getPreviousWindowConfig();
	configurationRequestParam = CARLINE_MAPPING.getConfigurationRequestParam();
	previousUserConfigurationPromise = USER_CONFIGURATIONS.getPreviousUserConfigurationPrString();

	// check if prstring or audicode was requested vir url params
	if (!!configurationRequestParam) {
		if (!!configurationRequestParam.audicode) {
			return exports
				.loadConfigurationByAudiCode(configurationRequestParam.audicode)
				.then(function(response) {
					exports.handleExternalConfiguration(response, {
						startType: 'teaser-audicode'
					});
				})
				.then(() => {
					__.dispatchCustomConfigurationStartEvent('teaser-audicode');
					stateRegistry.triggerAction(AUDICODE_STORE_ID, 'update', {
						audicode: configurationRequestParam.audicode
					});
				})
				.catch(err => {
					msg = DPU_API.getErrorMessage(err);
					__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-configuration-error'], msg]);
				});
		}
		else if (!!configurationRequestParam.prstring) {
			return exports
				.loadConfigurationByPrString(configurationRequestParam.prstring)
				.then(function(response) {
					exports.handleExternalConfiguration(response, {
						startType: 'teaser-prstring'
					});
				})
				.then(() => {
					__.dispatchCustomConfigurationStartEvent('teaser-prstring');
				})
				.catch(err => {
					msg = DPU_API.getErrorMessage(err);
					__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-configuration-error'], msg]);
				});
		}
	}
	// check if entering from a trampoline page
	if (previousConfiguration) {
		if (previousConfiguration.carline !== DPU_API.getCarline()) {
			// page redirect
			CARLINE_MAPPING.useTrampoline(previousConfiguration, previousConfiguration.subsession, previousConfiguration.entryType);
			return Promise.resolve();
		}

		// previous configuration does match currrent carline => load by prstring and adopt configuration
		return exports.loadConfigurationByPrString(previousConfiguration.prstring, previousConfiguration.subsession).then(
			(response) => {
				exports.handleExternalConfiguration(response, {
					startType: previousConfiguration.entryType
				});
			})
			.then(() => {
				// The entry page for the teaser-audicode and the legacy search (audi.it) load the configuration via audicode
				// and write the config and the entryType in the previousConfiguration cache.
				if (useConfigurationTrackingEvent(previousConfiguration.entryType)) {
					__.dispatchCustomConfigurationStartEvent(previousConfiguration.entryType);
				}
			})
			.catch((err) => {
				//  console.log(err.message);
				msg = DPU_API.getErrorMessage(err);
				__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-configuration-error'], msg]);
			});
	}
	// TODO fix reading usercarline configuration!!:::::::::::::::::::
	// check if a previous usercarline configuration exists
	if (!previousConfiguration && !configurationRequestParam) {
		// if new_config param exists, reset existing configuration
		if (!!newConfigurationParam) {
			return exports.loadConfigurationByPrString(SETUPS.get('nemo.default.prstring')).then(
				function(response) {
					exports.handleExternalConfiguration(response, {
						startType: 'configuration-reset'
					});
				},
				function(err) {
					console.warn(err.message);
				}
			).then(() => {
				__.dispatchCustomConfigurationStartEvent('configuration-reset');
			});
		}
		else {
			console.log('previousUserConfigurationPromise - loadConfiguration');
			return previousUserConfigurationPromise
				.then(async function(previousUserConfigurationPrString) {
					//  load user´s previous configuration for the current carline from storage
					await exports.loadConfigurationByPrString(previousUserConfigurationPrString).then(
						function(response) {
							exports.handleExternalConfiguration(response, {
								startType: 'user-configuration'
							});
						},
						function(err) {
							console.warn(err.message);
						}
					).then(() => {
						__.dispatchCustomConfigurationStartEvent('user-configuration');
					});
				})
				.catch(function(err) {
					console.warn(err.message);
				});
		}
	}

	return Promise.resolve();
};

/**
 * load a configuration by a strig auf pr numbers
 * (sends context and ids)
 * from the DPU Service
 * @param {string} prString prString
 * @param {string|null} subsession - optional subsessionID from previous configuration
 * @returns {Promise} request
 */
exports.loadConfigurationByPrString = function(prString, subsession) {
	return DPU_MODEL.loadConfigurationByPrString(prString, subsession);
};
/*
 * load a configuration from prteconfigurated teaser
 */
exports.loadConfigurationFromTeaser = function(options_, startType_) {
	var msg,
		promise,
		subsession = DPU_API.getSubsessionID();
	if (!!options_.prstring) {
		promise = exports.loadConfigurationByPrString(options_.prstring, subsession);
		return promise
			.then(function(config) {
				exports.handleExternalConfiguration(config, {
					startType: startType_
				});
			})
			.then(() => {
				if (startType_ === 'audicode.visualizer') {
					__.dispatchCustomConfigurationStartEvent(startType_);
				}
			})
			.catch(function(error) {
				msg = DPU_API.getErrorMessage(error);
				__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-configuration-error'], msg]);
			});
	}
	if (!!options_.audicode) {
		promise = exports.loadConfigurationByAudiCode(options_.audicode);
		return promise
			.then(function(config) {
				exports.handleExternalConfiguration(config, {
					startType: startType_
				});
			})
			.then(() => {
				if (startType_ === 'audicode.visualizer') {
					__.dispatchCustomConfigurationStartEvent(startType_);
				}
			})
			.catch(function(error) {
				//  console.error(error);
				msg = DPU_API.getErrorMessage(error);
				__.eventBus.emit(EVENTS.ERROR, [window.i18n['standard-configuration-error'], msg]);
			});
	}
	promise = Promise.reject('No valid config option found');
	return promise;
};
/**
 * load a configuration by an audiCode
 * (sends context and audicode)
 * from the DPU Service
 * @param {string} audiCode Audicode
 * @returns {Promise} request
 */
exports.loadConfigurationByAudiCode = function(audiCode) {
	return DPU_MODEL.loadConfigurationByAudiCode(audiCode);
};
/**
 * get a list of all carstore configurations
 * from the Carstore Service
 * @returns {Promise} request
 */
exports.listCarstoreItems = function() {
	return DPU_MODEL.sendCarstoreRequest('list');
};
/**
 * swith carline, reset configuration and initailaze with new Carline data
 * @param {string} carline_ - carline name
 * @param {string} carinfoUrl_ - url for the carinfo.json
 * @param {string} modelsInfoUrl_ - url for the modelinfo.json
 * @param {string} data_ - optional initialization data
 * @returns {Promise} request
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.switchCarline switchCarline} method
 */
exports.switchCarline = function(carline_, carinfoUrl_, modelsInfoUrl_, data_) {
	var promise = DPU_MODEL.switchCarline(carline_, carinfoUrl_, modelsInfoUrl_);
	return promise.then(function() {
		if (!data_) {
			//  __.handleConfigurationUpdate(true);
			__.eventBus.emit(EVENTS.CONFIG_RESET);
			//  MSS-2019
			DPU_MODEL.setConfigurationStartType('switched-carline');
		}
		else {
			DPU_MODEL.adoptConfiguration(data_)
				.then(function(success) {
					__.handleConfigurationUpdate(success);
				})
				.catch(function(err) {
					__.handleConfigurationUpdateError(err);
				});
		}
	});
};
/**
 * load a configuration from the carstore
 * @deprecated
 * @param {integer} slotId - slot id/index of the selected car configuration
 * @returns {Promise} request
 */
exports.loadCarstoreItem = function(slotId) {
	var promise = DPU_MODEL.sendCarstoreRequest('load', {
		slot: slotId
	});
	return promise.then(function(config) {
		// adopt loaded configuration
		exports.handleExternalConfiguration(config, {
			startType: 'carstore'
		});
	});
};
/**
 * delete slot from carstore
 * @param {integer} slotId - slot id/index of the selected car configuration
 * @returns {Promise} request
 */
exports.deleteCarstoreItem = function(slotId) {
	return DPU_MODEL.sendCarstoreRequest('delete', {
		slot: slotId
	});
};
/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.setSubsessionID setSubsessionID} method
 * @param {string} id_ - subsession id
 * @returns {void} nothing
 */
exports.setSubsessionID = function(id_) {
	DPU_MODEL.setSubsessionID(id_);
};
/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.resetToDefaultParams resetToDefaultParams} method
 * @returns {void} nothing
 */
exports.resetToDefaultSetupParams = function() {
	DPU_MODEL.resetToDefaultSetupParams();
};
/**
 * get marker for ajax calls
 * @returns {Promise} with marker as resolve
 */
exports.getAjaxMarker = function() {
	var result = {
		marker: '',
		overwrites: false
	};
	return new Promise(function(resolve /* , reject */) {
		if (DPU_API.isConfigurable()) {
			result.marker = DPU_API.getMemcacheVersion();
			result.overwrites = true;
		}
		else {
			result.marker = SETUPS.get('nemo.staticversion');
		}
		resolve(result);
	});
};

exports.setDpuExtra = function(dpuExtra) {
	DPU_MODEL.setDpuExtra(dpuExtra);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	CONTENT.registerMarkerProvider(exports.getAjaxMarker);
	__.initialize();
};
export {exports as dpuController};
