import { cache as CACHE } from 'core-application';
import { dpuApi as CONFIGURATOR_API } from './dpu/api';

const __ = {},
	// Public API
	exports = {
		__: __
	};
/**
 * private exports methods
 */
__.oDefaults = {};
/**
	 * get previous configuration params from the
	 * window.name object if entered via trampoline page
	 * @returns {Object|null} previousConfiguration
	 * e.g.{'subsession': 'ihewdbuew', 'carline': 'a6limo', 'prstring': '4G2S3Y0|P5P5|SX'}
	 */
exports.getPreviousWindowConfig = function () {
	let config = null,
		windowJson,
		carlineId = CONFIGURATOR_API.getCarline(),
		cachedData = (window && window.name && window.name !== null) ? window.name : CACHE.getItem("use_trampoline", "data", 'localStorage');
	if (cachedData) {
		try {
			windowJson = JSON.parse(cachedData);
			if (!!windowJson && windowJson.prstring && windowJson.subsession && windowJson.carline === carlineId) {
				config = windowJson;
			}
		}
		catch (ex) {
			// nothing to do (we are not comming from a page were we set the window.name)
			console.log('we are not comming from a page were we set the window.name');
			config = null;
		}
	}
	// remove localStorage item if available
	if (CACHE.getItem("use_trampoline", "data", 'localStorage')) {
		CACHE.removeItem('use_trampoline', "data", 'localStorage');
	}
	return config;
};
/**
	 * check if the url contains a audicode or prstring request
	 * @returns {Object|null} object with audicode or prstring or null
	 */
exports.getConfigurationRequestParam = function () {
	var request = null,
		audicode = __.getUrlVar('audicode'),
		prstring = __.getUrlVar('pr');
	if (!!audicode) {
		request = {};
		request.audicode = audicode;
	}
	if (!!prstring) {
		request = {};
		request.prstring = prstring;
	}
	return request;
};
/**
	 * trampoline machanism to change URL to matching carline
	 * @param {Object} data - configuration data
	 * @param {string} subsession - subsessionID
	 * @param {string} entryType - start/entry-type
	 * @returns {void} nothing
	 */
exports.useTrampoline = function (data, subsession, entryType) {
	let prstring = data.configuration.prstring,
		carline = data.configuration.carline,
		configuratorUrl = __.getCarlineUrl(carline);
	if (!configuratorUrl) {
		return;
	}

	// attach the audicode parameter for the nextgen configurator (quickfix!)
	const audicode = data.audicode?.id;
	const allScopes = SETUPS.get('nemo.env.scopes');
	if (allScopes?.indexOf("NGC_MARKET") > -1) {
		let parameterName = '';
		let parameterValue = '';
		if (audicode) {
			parameterName = 'audicode';
			parameterValue = audicode;
		}
		else if (prstring) {
			parameterName = 'pr';
			parameterValue = prstring;
		}

		if (parameterName && parameterValue) {
			const concatenator = configuratorUrl.indexOf("?") < 0 ? '?' : '&';
			configuratorUrl = `${configuratorUrl}${concatenator}${parameterName}=${parameterValue}`;
		}
	}

	// save audiCodeCorrespondingCarline, audiCodeCorrespondingPrstring and subsession in window.name
	window.name = '{"subsession":"' + subsession + '","carline":"' + carline + '","prstring":"' + prstring + '","entryType":"' + entryType + '"}';
	// save the data returned from dpu into the local db
	CACHE.setItem('carline_mapping', subsession, JSON.stringify(data), 'localStorage');
	// save audiCodeCorrespondingCarline, audiCodeCorrespondingPrstring and subsession in localStorage, because IE11 will delete window object at page switch
	CACHE.setItem('use_trampoline', "data", '{"subsession":"' + subsession + '","carline":"' + carline + '","prstring":"' + prstring + '","entryType":"' + entryType + '"}', 'localStorage');
	// forward (delay bugfix due to hash change events triggered @about 100ms)
	setTimeout(function () {
		window.location.href = configuratorUrl;
	}, 150);
};
/**
	 * get the corresponding carline page url
	 * @param {string} carlineID_ -carline id (e.g. 'a4limo')
	 * @returns {string} - base url of the carline page
	 */
__.getCarlineUrl = function (carlineID_) {
	return CONFIGURATOR_API.getCarlineUrl(carlineID_);
};
/**
	 * check if a previous configuration via trampoline page exists
	 * @returns {boolean} has a previous configuration?
	 */
exports.hasPreviousWindowConfiguration = function () {
	var previousConfig = exports.getPreviousWindowConfig();
	return !!previousConfig;
};

/**
	 * check if a configuration needs redirect via trampoline page
	 * (e.g. current carline/page is not matching the loaded configuration)
	 * @param {Object} data - a loaded configuration object
	 * @returns {boolean} needs trampoline or not?
	 */
exports.isMatchingConfiguration = function (data) {
	let doesMatch, carlineId, isDataDefined, audiCodeCorrespondingCarline, audiCodeCorrespondingPrstring, isCarline2IDMappingAvailable, audiCodeCarlineUrl;
	doesMatch = false;
	isDataDefined = !!data;
	audiCodeCorrespondingCarline = null;
	carlineId = CONFIGURATOR_API.getCarline();
	if (isDataDefined && data.configuration && data.configuration.carline) {
		audiCodeCorrespondingCarline = data.configuration.carline;
	}
	if (isDataDefined && data.configuration && data.configuration.carline) {
		audiCodeCorrespondingCarline = data.configuration.carline;
	}
	audiCodeCorrespondingPrstring = null;
	if (isDataDefined && data.configuration && data.configuration.prstring) {
		audiCodeCorrespondingPrstring = data.configuration.prstring;
	}
	isCarline2IDMappingAvailable = false;
	audiCodeCarlineUrl = __.getCarlineUrl(audiCodeCorrespondingCarline);
	if (audiCodeCarlineUrl) {
		isCarline2IDMappingAvailable = true;
	}
	// change window location if carlineID doesn´t match the current page´s Carline
	if (!!audiCodeCorrespondingCarline && carlineId !== audiCodeCorrespondingCarline && isCarline2IDMappingAvailable) {
		doesMatch = false;
	}
	// adopt configuration if carlinID matches
	if (!!audiCodeCorrespondingCarline && carlineId === audiCodeCorrespondingCarline) {
		doesMatch = true;
	}
	console.log("isMatchingConfiguration: " + doesMatch + '  audiCodeCorrespondingPrstring: ' + audiCodeCorrespondingPrstring);
	return doesMatch;
};
/**
	 * @description Returns all get params.
	 *@param {string} url_ - complete url string
	 *@returns {Object} urls vars as object
	 */
__.getUrlVars = function (url_) {
	let vars = new Map(),
		hash, hashes, i, len;
	//hashes = (url_.slice(url_.indexOf('?') + 1).split("#")[0]).split('&');
	hashes = url_.slice(url_.indexOf('?') + 1).split('&').flatMap(s => s.split("#")[0]);
	for (i = 0, len = hashes.length; i < len; i++) {
		hash = hashes[i].split('=');
		if(hash[0] !== undefined && hash[0] !== null && hash[0] !== "") {
			vars.set(hash[0], hash[1] || '');
		}
	}
	return Object.fromEntries(vars);
};
/**
	 * @description Returns specific get param if defined.
	 * @calls methos __.getUrlVars
	 * @param {name_} name_ - name of the param to extract
	 * @param {string} url_ - url (otional,otherwise window.location.href)
	 * @returns {Object|null} param-object mathing the name_ param otherwise null
	 */
__.getUrlVar = function (name_, url_) {
	const url = url_ || window.location.href;
	return __.getUrlVars(url)[name_] || null;
};
export { exports as carlineMapping };
