// TODO: #pattern-001 - fix dependency to html-template
import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {dpuApi as CONFIGURATOR_API} from './dpu/api';
import {polling} from 'core-utils';

const __ = {},
	exports = {
		__: __
	};

var localizeFrom = '',
	localizeStandardGroupPrice = '';

__.setGroupPrice = function() {
	// #pattern-001
	DOM_UTILS.getElementsArray('.js-configurator-group').forEach(function(el_) {
		var groupInfo = el_,
			patternBlock = DOM_UTILS.closest(groupInfo, '.nm-pattern-block, .nm-pattern-block-dynamic, .nm-tile-block'),
			itemsArr = DOM_UTILS.getElementsArray('.nm-j-configurator-item:not(.nm-j-configurator-status_00000)', patternBlock),
			smallestPrice = Number.MAX_VALUE,
			priceFormatted,
			pricesAreAllEqual = true;
			// console.log('_setGroupPrice', itemsArr);
		itemsArr.forEach(function(item_) {
			var item,
				itemPrice,
				itemPriceRaw;
			item = CONFIGURATOR_API.getItem(item_.getAttribute('data-configurator-id'));
			itemPrice = !!(item && item.price) ? item.price : '';
			itemPriceRaw = exports.getRawPrice(itemPrice, false);

			// round price
			itemPriceRaw = itemPriceRaw.toFixed(2);
			if ((itemPriceRaw * 1) === 0) {
				priceFormatted = localizeStandardGroupPrice;
			}
			// if this item's price is smaller than the current smallest price,
			// we take this item's price as new smallest price
			if ((smallestPrice * 1) > (itemPriceRaw * 1)) {
				// Price String used in the outputfield
				priceFormatted = itemPrice;
				smallestPrice = itemPriceRaw;
			}
			// remembering, if the prices are equal or not
			if (pricesAreAllEqual && smallestPrice !== itemPriceRaw) {
				pricesAreAllEqual = false;
			}
		});
		if (!pricesAreAllEqual) {
			priceFormatted = localizeFrom + ' ' + priceFormatted;
		}
		// console.log('Neuer Gruppenpreis:', priceFormatted);
		DOM_UTILS.getElement('.nm-price', groupInfo).textContent = priceFormatted;
	});
};
/**
	 * get the raw price from a formatted string
	 * @param {string} price - the formatted price
	 * @param {boolean} returnIntValue - whether price shall be parsed as int - else parsed as float
	 * @return {int} - the raw price as int
	 */
exports.getRawPrice = function(price, returnIntValue) {
	var priceRaw;
	var currencyRemoveRegex;
	var getCurrencySeparatorsRegEx = /[,]/g;
	if (SETUPS.get('scope.commaseparator') === 0 || SETUPS.get('scope.commaseparator') === '0') {
		currencyRemoveRegex = /[^0-9,]/g;
	}
	else {
		currencyRemoveRegex = /[^0-9.]/g;
	}
	priceRaw = price.replace(currencyRemoveRegex, '');
	priceRaw.replace(getCurrencySeparatorsRegEx, '.');
	if (returnIntValue === true) {
		priceRaw = parseInt(priceRaw, 10);
	}
	else {
		priceRaw = parseFloat(priceRaw);
	}
	if (isNaN(priceRaw)) {
		priceRaw = 0;
	}
	return priceRaw;
};
__.setSelectedGroup = function() {
	var groups = DOM_UTILS.getElementsArray('.nm-pattern-list-dynamic');
	groups.forEach(function(el_) {
		var activeItems = DOM_UTILS.getElementsArray('li.nm-j-configurator-status_11010, li.nm-j-configurator-status_10011', el_).length;
		el_.classList.remove('nm-pattern-selected');
		if (activeItems) {
			el_.classList.add('nm-pattern-selected');
		}
	});
	return;
};
__.handleUpdates = function() {
	// wait until carinfo is loaded or no carline is selected (startpage)
	polling.wait(function() {
		return (CONFIGURATOR_API.isInitialized() || !CONFIGURATOR_API.getCarline());
	}, 5000, 250).then(function() {
		__.setSelectedGroup();
		__.setGroupPrice();
	}).catch((err) => console.warn("pattern: could not update groups and prices", err));
};

__.subscribe = function() {
	// #pattern-001
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleUpdates);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handleUpdates);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleUpdates);
};
__.initialize = function() {
	// #pattern-002
	localizeFrom = window.i18n.from;
	localizeStandardGroupPrice = window.i18n['standard-group-price'];
	__.subscribe();
	return;
};
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();

};
export {exports as pattern};

