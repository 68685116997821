const template = `{{##def.options_tiles:
<ul class="nm-options-list nm-options-tiles">
	<li>
		<span class="nm-el-pg nm-el-pg-03 nm-at-pg-b">{{=p.header}}</span>

		<div class="nm-tile-block">

			<ul class="nm-tile-list clearfix">

			{{~ p.items :value:i }}
					<li class="nm-j-configurator-item nm-j-configurator-no-update nm-j-configurator-status_{{=value.status}} nm-j-configurator-type_{{=value.type}} nm-conflict-type-{{=value.type}} clearfix"
				data-configurator-id="{{=value.id}}" data-mbvid="{{=value.id}}">
						{{? !!value.image }}
						<div class="nm-tile-element-image">
							<a class="nm-j-configurator-delegate-click" href="#"><img src="{{=value.image}}" /></a>
						</div>
						{{?}}

						<div class="nm-tile-element-info">
							<span class="nm-list-item-text nm-el-pg nm-el-pg-03">{{=value.name}}</span>
							<div class="nm-price nm-el-pg nm-el-pg-03 nm-at-pg-b">
								{{? value.price }}{{=value.price}}{{?}}
							</div>
						</div>
					</li>
			{{~}}
			</ul>

		</div>

	</li>
</ul>
#}}


{{##def.options_list:
<!-- Default options list -->

<ul class="nm-options-list">
	{{~ p.items :value:i }}
	<li class="nm-check-list-item nm-j-configurator-item nm-j-configurator-no-update nm-j-configurator-status_{{=value.status}} nm-j-configurator-type_{{=value.type}} nm-conflict-type-{{=value.type}} clearfix"
	data-configurator-id="{{=value.id}}" data-mbvid="{{=value.id}}">

		<div class="nm-options-list-cell-cb clearfix">
			<a class="nm-checkbox-1 nm-j-configurator-delegate-click" href="#"></a>
		</div>

		<div class="nm-options-cell-additional">
			{{? value.image }}
			<div class="nm-list-item-additional">
				<div class="nm-list-item-image">
					<img src="{{=value.image}}" alt="{{=value.image.name}}" />
				</div>
			</div>
			{{?}}

			<span class="nm-list-item-text nm-el-pg nm-el-pg-03">
				{{? value.type === "add" }}
				<span class="nm-conflict-add-text nm-at-pg-b">
					{{=window.i18n['conflict-layer.add-item']}}
				</span>
				{{??}}
				<span class="nm-conflict-remove-text nm-at-pg-b">
					{{=window.i18n['conflict-layer.remove-item']}}
				</span>
				{{?}} {{=value.name}}
			</span>
		</div>

		{{? typeof value.priceValue !== 'undefined' && value.priceValue < 0 }} 
			<div class="nm-price nm-el-pg nm-el-pg-03 nm-at-pg-b nm-at-pg-indicator">
		{{??}}
			<div class="nm-price nm-el-pg nm-el-pg-03 nm-at-pg-b">
		{{?}}
			{{? value.price }}{{=value.price}}{{?}}
		</div>
	</li>
	{{~}}
</ul>
#}}


<div class="nm-layer-wrapper nm-layer-wrapper-dialogue nm-layer-wrapper-conflict">
	<div class="nm-layer nm-layer-conflict nm-layer-conflict-default">
		<div class="nm-layer-inner">
			<div class="nm-layer-conflict-content clearfix">
				<div class="nm-3col cearfix">
					<div class="nm-module nm-md-conflict-layer" data-module="conflict-layer">
						<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">
							{{=window.i18n['conflict-layer.selection-conflict']}}
						</h2>
						{{? 0 !== it.triggers.length}}
						<span class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">
						{{=window.i18n['conflict-layer.your-selection']}}
						</span>
						<ul class="nm-options-list nm-conflict-trigger">
							{{~it.triggers :value:index}}
							<li class="nm-check-list-item nm-j-configurator-item nm-j-configurator-no-update nm-j-configurator-status_{{=value.status}} nm-j-configurator-type_{{=value.type}} nm-conflict-type-{{=value.type}} clearfix"
							data-configurator-id="{{=value.id}}" data-mbvid="{{=value.id}}">
								<a class="nm-checkbox-1 nm-j-configurator-delegate-click" href="#"></a>
								<div class="nm-options-cell-additional">
									<span class="nm-list-item-text nm-el-pg nm-el-pg-03">
										{{=value.name}}
									</span>
								</div>
								{{? typeof value.priceValue !== 'undefined' && value.priceValue < 0 }} 
									<div class="nm-price nm-el-pg nm-el-pg-03 nm-at-pg-b nm-at-pg-indicator">
								{{??}}
									<div class="nm-price nm-el-pg nm-el-pg-03 nm-at-pg-b">
								{{?}}
									{{? value.price }}{{=value.price}}{{?}}
									</div>
							</li>
							{{~}}
						</ul>
						{{?}} {{? 0 !== it.solutions[0].length}}
						<span class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">
							{{=window.i18n['conflict-layer.mandatory-customizations']}}:
						</span>
						{{~ it.solutions :p }}
						{{? !!p.id}}
						<div class="nm-conflict-solution" data-solution-id="{{=p.id}}">

							{{?!!p.sameTypeItems}}
								{{#def.options_tiles}}
							{{??}}
								{{#def.options_list}}
								{{? 1 < p.items.length}} <a href="" class="nm-j-conflict-options-show nm-el-lbl nm-el-lbl-01">
									<span class="nm-conflict-alternative-text-show">
										{{=window.i18n['conflict-layer.show-alternatives']}}
									</span>
									<span class="nm-conflict-alternative-text-hide">
										{{=window.i18n['conflict-layer.hide-alternatives']}}
									</span>
									</a>
								{{?}}
							{{?}}



						</div>
						{{?}}
						{{~}} {{?}}
						<p class="nm-el-pg nm-el-pg-03 nm-at-pg-b nm-conflict-layer-total-price">
							{{=window.i18n['conflict-layer.overall-cutomizations']}}: {{?
							typeof it.totalPriceValue !== 'undefined' && it.totalPriceValue
							< 0 }} <span class="nm-el-pg nm-el-pg-03 nm-at-pg-b nm-at-pg-indicator">
								{{??}}
								<span class="nm-el-pg nm-el-pg-03 nm-at-pg-b">
									{{?}} {{=it.totalPrice}}
								</span>
						</p>
						<ul class="nm-button-list clearfix">
							{{? !!it.urls.cancel }}
							<li>
								<a class="nm-j-configurator-delegate-conflict-click nm-el-btn nm-el-btn-01 nm-at-btn-inv" data-conflict="cancel"
								href="{{=it.urls.cancel}}">{{=window.i18n['abort-label']}}</a>
							</li>
							{{?}} {{? !!it.urls.submit }}
							<li>
								<a class="nm-j-configurator-delegate-conflict-click nm-el-btn nm-el-btn-02" data-conflict="submit"
								href="{{=it.urls.submit}}">{{=window.i18n['conflict-layer.label.submit']}}</a>
							</li>
							{{?}}
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>`;
export {template as conflictLayerTemplate};
