/**
* @description module to store and user´s last configuration in the Browser´s session storage
*/
import {dpuApi as CONFIGURATOR_API} from './api';
import {appEvents as EVENTS} from 'core-application';
const __ = {},
	// Public API
	exports = {
		__: __
	};
// reference to the global EventBus
__.globalEventBus = null;
// im SETUP definierter Wert (zunächst 72 Stunden)
__.EXPIRED_THRESHOLD = 72;
// create public API
window.audi = window.audi || {};
window.audi.api = window.audi.api || {};
/**
 * check if time expired
 * @param {Date} savedDate_ - last saved configuration date
 * @returns {boolean} configuration is expired
 */
__.isExpired = function(savedDate_) {
	var now = new Date().getTime()/(60*60*60);
	return (now - savedDate_ >= __.EXPIRED_THRESHOLD);
};
/**
 * add Events
 * @returns {void}
 */
__.addEvents = function() {
	if (__.globalEventBus) {
		__.globalEventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigurationUpdate);
	}
};
/**
 * get user´s last configuration from storage (LocalStorage)
 * @returns {Object|false} configuration object or false
 */
__.getLastConfiguration = function() {
	var configuration = __.getStoredConfiguration();
	if (!!configuration && configuration.lastChanged) {
		return __.isExpired(configuration.lastChanged) ? false : configuration;
	}
	else {
		return false;
	}
};
window.audi.api.lastConfiguration = __.getLastConfiguration;
/**
 * handle configuration updates
 * @returns {void}
 */
__.handleConfigurationUpdate = function() {
	var lastStoredConfiguration = __.getConfigurationObject();
	if (__.hasSorage() && lastStoredConfiguration) {
		__.writeEntry(lastStoredConfiguration);
	}
};
/**
 * check if Storage is available
 * @returns {boolean} is available ?
 */
__.hasSorage = function() {
	return !!__.getStorage();
};
/**
 * return storage Object
 * @returns {Object} Storage (LocalStorage)
 */
__.getStorage=function(){
	return window.localStorage||null;
};
/**
 * normalize data and write string to storage (LocalStorage)
 * @param {Object} data_ - user´s last configuration
 * @returns {void}
 */
__.writeEntry = function(data_) {
	var dataString, _storage;
	try {
		dataString = JSON.stringify(data_);
		_storage=__.getStorage();
		_storage.setItem('lastConfiguration', dataString);
	}
	catch (err) {
		console.warn(err);
	}
};
/**
 * @example
 * "assets": {
 *	"exterior_front": "<configuration.assets.exterior_front.stage.hd>",
 *	"closeup_lights": "<configuration.assets.closeup_lights.stage.hd>",
 *	"exterior": "<configuration.assets.exterior.stage.hd>"
 *  },
 *  "assetsMobile": {
 *	"exterior_front": "<configuration.assets.exterior_front.stage.hdx>",
 *	"closeup_lights": "<configuration.assets.closeup_lights.stage.hdx>",
 *	"exterior": "<configuration.assets.exterior.stage.hdx>"
 *  },
 *	"price": "<configuration.price>",
 *	"carlineName": "<configuration.carlineName>",
 *	"description": "<configuration.description>",
 *	"prstring": "<configuration.prstring>",
 *	"carline": "<configuration.carline>",
 *	"lastChanged": "<timestampOfLastConfiguration>",
 *	"mv-version": "<header.marketVersion>",
 *	"context": "<header.context>",
 *	"url": "<urlToConfiguration>"
 *	}
 * @returns {Object} configuration object
 */
__.getConfigurationObject = function() { // eslint-disable-line max-statements, complexity
	var configuration = CONFIGURATOR_API.getConfiguration(), renderBaseURL,
		last_configuration = {
			assets: {},
			assetsMobile: {}
		},
		images_assets =	CONFIGURATOR_API.getConfigurationAssets();
	renderBaseURL=CONFIGURATOR_API.getRenderUrl();
	// do not write empty configuration or configuration  with conflicts!!!
	if (!configuration || !!CONFIGURATOR_API.getConflicts()||!images_assets||Object.keys(images_assets).length === 0) {
		return null;
	}
	last_configuration.assets.exterior_front = images_assets.exterior_front&&images_assets.exterior_front['stage.hd']?renderBaseURL+images_assets.exterior_front['stage.hd'] : '';
	last_configuration.assets.closeup_lights = images_assets.closeup_lights&&images_assets.closeup_lights['stage.hd']?renderBaseURL+images_assets.closeup_lights['stage.hd'] : '';
	last_configuration.assets.exterior = images_assets.exterior&&images_assets.exterior['stage.hd']?renderBaseURL+images_assets.exterior['stage.hd']: '';
	last_configuration.assetsMobile.exterior_front = images_assets.exterior_front&&images_assets.exterior_front['stage.hdx']?renderBaseURL+images_assets.exterior_front['stage.hdx'] : '';
	last_configuration.assetsMobile.closeup_lights = images_assets.closeup_lights&&images_assets.closeup_lights['stage.hdx']?renderBaseURL+images_assets.closeup_lights['stage.hdx'] : '';
	last_configuration.assetsMobile.exterior = images_assets.exterior&&images_assets.exterior['stage.hdx']?renderBaseURL+images_assets.exterior['stage.hdx']: '';
	last_configuration.price = configuration.price;
	last_configuration.carline = configuration.carline;
	last_configuration.carlineName = configuration.carlineName;
	last_configuration.eec = configuration.eec;
	last_configuration.lastChanged = new Date().getTime();
	last_configuration['mv-version'] = CONFIGURATOR_API.getMarketVersion();
	last_configuration.context = CONFIGURATOR_API.getContext();
	last_configuration.url = __.getEntryUrl(configuration.prstring);
	return last_configuration;
};
/**
 * get last stored configuration from session storage
 * @returns {Object} configuation object
 */
__.getStoredConfiguration = function() {
	var _storage=__.getStorage();
	if (!!_storage && _storage.lastConfiguration) {
		try {
			return JSON.parse(_storage.lastConfiguration);
		}
		catch (err) {
			console.warn(err);
			return null;
		}
	}
	else {
		return null;
	}
};
/**
 * get entry url for entrering configuratzion via prString
 * @param {string} prString_ - comma separated string representing the configuration
 * @returns {string} url string
 */
__.getEntryUrl = function(prString_) {
	var baseUrl = window.location.href.split('#')[0],
		url;
	url = baseUrl;
	url += (url.indexOf('?') > 0) ? '&' : '?';
	url += 'pr=' + prString_;
	return url;
};
/**
 * @param {Emitter} eventBus_ - global Event Emitter
 * @returns {Promise} module´s name
 */
exports.initialize = function(eventBus_) {
	var expiredTime;
	return new Promise(function(resolve) {
		__.globalEventBus = eventBus_;
		__.addEvents();
		expiredTime = parseInt(SETUPS.get('nemo.last_config.expires'), 10);
		if (expiredTime > 0) {
			__.EXPIRED_THRESHOLD = expiredTime;
		}
		resolve('configurator/last-configuration.js');
	});
};
export {exports as lastConfiguration};
