const template = `<div class="nm-layer-wrapper nm-layer-wrapper-dialogue">
	<div class="nm-layer nm-layer-dialogue">
		<div class="nm-layer-inner">
			<div class="nm-layer-dialogue-content clearfix">
				<div class="nm-3col clearfix">
					<div class="nm-module nm-start-layer nm-simpleform nm-configurationstart-loaded-layer clearfix">
						<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">
							{{=window.i18n['configurationloaded-layer-info']}}
						</h2>
						<p class="nm-el-pg nm-el-pg-01">
							{{=	window.i18n['configurationloaded-layer-edit']}}
						</p>
						<img src="{{=it.ms_url}}{{=it.images.exterior_front['flyout']}}"
						class="nm-unscaled-image nm-alpha" />
						<div class="nm-start-information-overview">
							<div class="nm-start-inforamation-content">
								<span class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">
									{{=it.configuration.carlineName}}
								</span>
								<span class="nm-engine nm-el-pg nm-el-pg-03">
									{{=it.configuration.description}}
								</span>
								<ul class="nm-car-price clearfix">
									<li class="nm-car-price-total nm-el-hdl nm-el-hdl-04 nm-at-hdl-b clearfix">
										/*<span class="nm-car-price-total-key">
											{{=window.i18n['totalprice-label']}}
										</span>*/
										<span class="nm-car-price-total-value">

											{{? typeof(it.configuration.prices.rotr) !== 'undefined' && parseInt(it.configuration.prices.rotr, 10) > 0 }}
												{{=it.configuration.prices.rotr}}
											{{??}}
												{{=it.configuration.price}}
											{{?}}
										</span>
									</li>
								</ul>
							</div>
							<div class="nm-btn-continue">
								<a href="#" class="nm-configurationstart-layer-close nm-el-btn nm-el-btn-01 nm-el-btn-ast">{{=window.i18n['proceed-label']}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>`;
export {template as configStartLoadedTemplate};
