import {USERDATA_STORE_ID} from '../stores/userdata-store';
import {stateRegistry} from 'microkernel';
import {dpuModel} from '../../configurator-bundle';
import carstoreApi from './carstore-api';
import {template} from 'core-utils';
import {carstoreListHeadlineTemplate} from './carstore-list-headline-template';
import {carstoreListItemTemplate} from './carstore-list-item-template';
import {carstoreListFailTemplate} from './carstore-list-fail-template';
import {carstoreListEmptyTemplate} from './carstore-list-empty-template';
import {fetchImageFromRenderAPI} from "../dpu/fetch-image-render-api";

export default class CarstoreList extends HTMLElement {
	constructor() {
		super();
		this.currentConfigurations = {};
		this.audicodeList = null;

		this.stateController = this.stateController.bind(this);
		this.renderList = this.renderList.bind(this);
		this.hasAudicodeListChanged = this.hasAudicodeListChanged.bind(this);
		this.handleListAudicodesResponse = this.handleListAudicodesResponse.bind(this);
	}

	connectedCallback() {
		stateRegistry.subscribeToStore(USERDATA_STORE_ID, this.stateController);
		this.isToBeInitialized = true;
	}

	async renderList(state) {
		this.currentConfigurations = state.configurations;
		this.wrapper = this.querySelector('.carstore-list__wrapper');
		if (!this.wrapper) {
			return;
		}

		const audicodesList = Object.keys(this.currentConfigurations);
		let html = '';
		if (audicodesList.length === 0) {
			html += template.render(carstoreListEmptyTemplate);
		}
		else {
			for (const audicode of audicodesList) {
				html += await this.renderListItem(audicode);
			}
		}

		this.wrapper.innerHTML = html;
	}

	async renderListItem(audicode) {
		let configuration = this.currentConfigurations[audicode];
		const getImages = await fetchImageFromRenderAPI(configuration.configuration).then((images) => {
			if (!images) { throw (new Error()); }

			return images;
		});


		const images = await getImages;
		const renderData = Object.assign(configuration, {images: images}, {mediaservice: SETUPS.get('nemo.url.mediaservice')});
		const itemTemplate = (configuration.error) ? carstoreListFailTemplate : carstoreListItemTemplate;
		return template.render(itemTemplate, renderData);
	}

	disconnectedCallback() {
		stateRegistry.unsubscribeFromStore(USERDATA_STORE_ID, this.stateController);
	}

	createHeadlines() {
		this.innerHTML = template.render(carstoreListHeadlineTemplate);
	}

	stateController(state) {
		if (!state.loggedIn) {
			this.innerHTML = "";
			this.audicodeList = null;
			this.currentConfigurations = {};
			return;
		}
		if (state.loggedIn && (this.isToBeInitialized || (state.audicodes === null && this.audicodeList === null))) {
			this.createHeadlines();
			carstoreApi.getAudicodeList();
			this.isToBeInitialized = false;
		}
		if (state.audicodes !== this.audicodeList) {
			this.handleListAudicodesResponse(state.audicodes);
		}
		if (!!state.configurations && state.configurations !== this.currentConfigurations) {
			this.renderList(state);
		}
	}

	async handleListAudicodesResponse(audicodesList) {
		const audicodesToLoad = this.filterAudicodesToLoad(audicodesList);
		let newConfigurations = {};

		if (!!audicodesToLoad) {
			const promises = audicodesToLoad.map(audicode => {
				return dpuModel.loadConfigurationByAudiCodeStateless(audicode)
					.catch(error => {
						return {audicode: audicode, error: error};
					});
			});
			const audicodeConfigurations = await Promise.all(promises);

			for (const data of audicodeConfigurations) {
				if (data.error) {
					newConfigurations[data.audicode] = {
						audicode: data.audicode,
						error: data.error
					};
				}
				else {
					newConfigurations[data.audicode.id] = {
						audicode: data.audicode,
						configuration: data.configuration
					};
				}
			}
		}

		this.audicodeList = audicodesList;
		const loadedConfigurations = this.mergeConfigurations(audicodesList, newConfigurations, this.currentConfigurations);
		stateRegistry.triggerAction(USERDATA_STORE_ID, 'update', {configurations: loadedConfigurations});
	}

	mergeConfigurations(audicodesList, newConfigurations, currentConfigurations) {
		let mergedConfigurations = {};
		audicodesList.forEach(audicode => {
			mergedConfigurations[audicode] = newConfigurations[audicode] || currentConfigurations[audicode];
		});
		return mergedConfigurations;
	}

	filterAudicodesToLoad(audicodesList) {
		if (!!audicodesList) {
			return audicodesList.filter(audicode => !this.currentConfigurations[audicode]);
		}
		return null;
	}

	hasAudicodeListChanged(newList, currentList) {
		if (!currentList || newList.length !== currentList.length) {
			return true;
		}

		return !newList.every(audicode => currentList.includes(audicode));
	}

}

if (window.customElements.get('audi-carstore-list') === undefined) {
	window.customElements.define('audi-carstore-list', CarstoreList);
}
