import {dpuApi as CONFIGURATOR_API} from "./dpu/api";
import {dpuController as DPU_CONTROLLER} from "./dpu/controller";
import {router as ROUTER} from "core-application";
import {dom as DOM_UTILS} from "core-utils";
// private vars
const __ = {},
	exports = {
		__: __
	};
__.oDefaults = {
	sColors: 'nm-j-visualizer-tiles-color',
	sRims: 'nm-j-visualizer-tiles-rim',
	sCombiItem: '.nm-j-visualizer-slide',
	sSelectorCombiContainer: '.nm-j-visualizer-slides',
	sTilesSelector: '.nm-j-visualizer-item',
	sTilesblockSelector: '.nm-j-visualizer-tiles-block',
	sMenuSelectedClass: 'nm-selected',
	sButtonSelector: '.nm-j-preconfig-submit',
	sSlideActiveClass: 'active',
	sSlideHiddenClass: 'hidden'
};

__.timeoutID=null;
__.timeoutDelay = 1000;

__.handleConfigurationStartClick = function(event) {
	let options,audiCode,prString,
		combiItem = DOM_UTILS.getElement(__.oDefaults.sCombiItem + '.active');

	__.cancelEvent(event);

	audiCode = DOM_UTILS.getDataAttribute(combiItem, 'audicode');
	prString = DOM_UTILS.getDataAttribute(combiItem, 'prstring');

	if (!!audiCode||!!prString) {
		options={};
		if(!!audiCode){
			options.audicode=audiCode;
		}
		else{
			options.prstring=prString;
		}
		//load configuration by AudiCode
		DPU_CONTROLLER.loadConfigurationFromTeaser(
			options, 'audicode.visualizer').then(function() {
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
		});
	}
};

__.handleTileClick = function(event_) {
	let selectedTile, selectedTileIndex, currentActiveSlide, currentVideo, newActiveSlide, colorAndRimIndex,
		selectedTileWidth = 73, inactiveTileWidth = 55;
	__.cancelEvent(event_);

	selectedTile = DOM_UTILS.closest(event_.target, __.oDefaults.sTilesSelector);
	selectedTileIndex = selectedTile.getAttribute('data-color-index') || selectedTile.getAttribute('data-rim-index');
	__.updateTileSelection(selectedTile);

	colorAndRimIndex = __.getSelectedColorAndRimIndex(selectedTile);
	currentActiveSlide = DOM_UTILS.getElement('.nm-visualizer-slide.active');
	newActiveSlide = DOM_UTILS.getElement('.nm-visualizer-slide[data-matrix-index="' + colorAndRimIndex + '"]');

	__.hideSlide(currentActiveSlide);
	__.resetActiveVideo(currentActiveSlide);
	__.showSlide(newActiveSlide);

	// calc the width and position of the active bar because the css transisiton changes both and takes some time
	__.updateActiveTileBar(selectedTile, selectedTileWidth, (selectedTileIndex - 1) * inactiveTileWidth);
};

__.showSlide = function(slide_) {
	slide_.classList.remove('hidden');
	slide_.classList.add('active');
};

__.hideSlide = function(slide_) {
	slide_.classList.remove('active');
	slide_.classList.add('hidden');
};

// stop active video playing and call load() to show poster image again
__.resetActiveVideo = function(currentSlide_) {
	let currentVideo = DOM_UTILS.getElement('video', currentSlide_),
		mediaplayer;
	if(DOM_UTILS.isElement(currentVideo) && currentVideo.currentTime > 0) {
		currentVideo.load();
		mediaplayer = DOM_UTILS.getElement('.nm-mediaplayer', currentSlide_);
		mediaplayer.classList.remove('nm-video-playing');
	}
};


//update selected Tile
__.updateTileSelection = function(selectedTile_) {
	let currentTilesContainer = DOM_UTILS.closest(selectedTile_, __.oDefaults.sTilesblockSelector),
		currentSelectedTile = DOM_UTILS.getElement('.' + __.oDefaults.sMenuSelectedClass, currentTilesContainer);

	currentSelectedTile.classList.remove(__.oDefaults.sMenuSelectedClass);
	selectedTile_.classList.add(__.oDefaults.sMenuSelectedClass);
};

__.getSelectedColorAndRimIndex = function() {
	let colorIndex, rimIndex;
	colorIndex = DOM_UTILS.getElement('.nm-j-visualizer-tiles-color .' + __.oDefaults.sMenuSelectedClass).getAttribute('data-color-index');
	rimIndex = DOM_UTILS.getElement('.nm-j-visualizer-tiles-rim .' + __.oDefaults.sMenuSelectedClass).getAttribute('data-rim-index');
	return colorIndex + '-' + rimIndex;
};

__.handleTileHover = function(event_) {
	let selectedTile = DOM_UTILS.closest(event_.target, __.oDefaults.sTilesSelector);
	__.updateActiveTileBar(selectedTile, selectedTile.clientWidth, selectedTile.offsetLeft);
	clearTimeout(__.timeoutID);
};

// reset tile active bar position to selected tiles after short timeout
__.handleTileblockLeave = function() {
	var selectedTiles;
	clearTimeout(__.timeoutID);
	__.timeoutID = setTimeout(function(){
		selectedTiles = DOM_UTILS.getElementsArray(__.oDefaults.sTilesSelector + '.' + __.oDefaults.sMenuSelectedClass);
		selectedTiles.forEach(function(selectedTile_) {
			__.updateActiveTileBar(selectedTile_, selectedTile_.clientWidth, selectedTile_.offsetLeft);
		});
	}, __.timeoutDelay);
};

__.updateActiveTileBar = function(selectedTile_, width_, offsetLeft_) {
	let	tilesContainer = DOM_UTILS.closest(selectedTile_, __.oDefaults.sTilesblockSelector),
		tilesActiveBar = DOM_UTILS.getElement('.nm-visualizer-tile-active-bar', tilesContainer);

	tilesActiveBar.style.left = offsetLeft_ + "px";
	tilesActiveBar.style.width = width_ + "px";
};

__.cancelEvent = function(event_) {
	if ( !!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

__.addEvents = function() {
	__.domEventDelegate.on('click', __.oDefaults.sTilesSelector, __.handleTileClick);
	__.domEventDelegate.on('click', __.oDefaults.sButtonSelector, __.handleConfigurationStartClick);
	__.domEventDelegate.on('mouseover', __.oDefaults.sTilesSelector, __.handleTileHover);
	__.domEventDelegate.on('mouseout', __.oDefaults.sTilesblockSelector, __.handleTileblockLeave);
};

__.removeEvents = function() {
	__.domEventDelegate.off('click', __.oDefaults.sTilesSelector, __.handleTileClick);
	__.domEventDelegate.off('click', __.oDefaults.sButtonSelector, __.handleConfigurationStartClick);
};

exports.initialize = function() {
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	if (!__.init){
		__.addEvents();
	}
	__.init=true;
};
export {exports as visializer};

