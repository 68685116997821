import {appEvents, signal} from "core-application";
import {dpuApi} from "./dpu/api";

const selectors = {
	categorizedWrapper: ".nm-module-tiles-categorized-tilelist-container",
	uncategorizedWrapper: ".nm-module-tiles-uncategorized-tilelist-container",
	categorizedHeadlineWrapper:
		"div.nm-module-tiles-categorized-group-headline-wrapper",
	uncategorizedHeadline: ".nm-module-tiles-uncategorized-headline",
	categorizedTile: ".nm-module-tiles-categorized-tile",
	categorizedGroupInfo: "div.nm-module-tiles-categorized-groupinfo",
	tile: ".nm-module-tiles-uncategorized-tile, .nm-module-tiles-categorized-tile",
	categorizedTileInfo: ".nm-module-tiles-categorized-tileinfo",
	uncategorizedTileInfo: ".nm-module-tiles-uncategorized-tileinfo",
	configuratorItems: ".nm-j-configurator-item",
	configuratorItemsList: ".nm-module-tiles-categorized-tilelist, .nm-module-tiles-uncategorized-tilelist",
	categorizedConfiguratorItemsList: ".nm-module-tiles-categorized-tilelist",
	uncategorizedConfiguratorItemsList:	".nm-module-tiles-uncategorized-tilelist",
	categorizedTickMark:"svg.nm-module-tiles-categorized-icon-selected",
	uncategorizedTickMark: "svg.nm-module-tiles-uncategorized-icon-selected",
	fadeOutClass: "nm-tile-fadeout",
	groupMinPrice: ".js-configurator-group .nm-j-configurator-text-price"

};

const notVisibleStatusCodes = [
	"00000",
	"00001",
	"00010",
	"00011",
	"00100",
	"00101",
	"00110",
	"00111",
	"01000",
	"01001",
	"01010",
	"01011",
	"01100",
	"01101",
	"01110",
	"01111",
	"10000"
];

export default class TileList extends HTMLElement {
	get allConfiguratorItems() {
		return [...this.querySelectorAll(selectors.configuratorItems)];
	}

	get allTiles() {
		return [...this.querySelectorAll(selectors.tile)];
	}

	get isSortingEnabled() {
		return (
			!!this.sortOrder && !!this.sortType && this.sortType !== "default"
		);
	}

	constructor() {
		super();
	}

	connectedCallback() {
		this.initElements();
		this.addEvents();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	initElements() {
		this.groupMinPrice = this.querySelector(selectors.groupMinPrice);
		this.sortOrder = this.getAttribute("data-sort-order");
		this.sortType = this.getAttribute("data-sort-type");

		if (dpuApi.isConfigurationStarted()) {
			this.handlePriceUpdate();
		}
		this.updateHeight();

		if (this.allTiles && this.allTiles.length > 0){
			[this.allTiles[0].querySelector(`img`), this.allTiles[this.allTiles.length - 1].querySelector(`img`)]
				.forEach(image => image.addEventListener("load", () => this.updateHeight()));
		}


	}

	addEvents() {

		this.allTiles.forEach((tile) => {
			tile.addEventListener("mouseover", (event) =>
				this.handleTileMouseOver(event)
			);
			tile.addEventListener("mouseout", () => this.handleTileMouseOut());
		});

		const eventEmitter = signal.getEmitter();
		eventEmitter.addListener(appEvents.CARINFO_LOAD_COMPLETE, () =>
			this.handlePriceUpdate()
		);
		eventEmitter.addListener(appEvents.CONFIG_UPDATE, () => {
			this.updateHeightAndPrice();
			this.applyStylesToTiles();
		});
		eventEmitter.addListener(appEvents.DOM_UPDATED, () =>
			this.applyStylesToTiles()
		);
		eventEmitter.addListener(appEvents.LAYER_CLOSED, () => {
			this.updateHeight();
			this.applyStylesToTiles();
		});
		eventEmitter.addListener(appEvents.PAGE_READY, () => {
			this.updateHeight();
		});
		window.addEventListener("resize", () => this.updateHeight());
		window.addEventListener("load", () => this.updateHeight());
	}

	removeEvents() {
		this.allTiles.forEach((tile) => {
			tile.removeEventListener("mouseover", (event) =>
				this.handleTileMouseOver(event)
			);
			tile.removeEventListener("mouseout", () =>
				this.handleTileMouseOut()
			);
		});

		const eventEmitter = signal.getEmitter();
		eventEmitter.removeListener(appEvents.CARINFO_LOAD_COMPLETE, () =>
			this.updateHeightAndPrice()
		);
		eventEmitter.removeListener(appEvents.CONFIG_UPDATE, () =>
			this.updateHeightAndPrice()
		);
		eventEmitter.removeListener(appEvents.DOM_UPDATED, () =>
			this.applyStylesToTiles()
		);
		eventEmitter.removeListener(appEvents.LAYER_CLOSED, () =>
			this.updateHeight()
		);
		eventEmitter.removeListener(appEvents.PAGE_READY, () =>
			this.updateHeight()
		);
		window.removeEventListener("resize", () => this.updateHeight());
		window.removeEventListener("load", () => this.updateHeight());

		if (this.allTiles && this.allTiles.length > 0){
			[this.allTiles[0].querySelector(`img`), this.allTiles[this.allTiles.length - 1].querySelector(`img`)]
				.forEach(image => image.removeEventListener("load", () => this.updateHeight()));
		}
	}

	handleTileMouseOver(event) {
		const activeTile = event.currentTarget;
		this.updateHeight();

		this.allTiles.forEach((tile) => {
			if (tile !== activeTile) {
				this.fadeOutTile(tile);
				tile.nextElementSibling.style.display = 'none';
			}
			else {
				tile.nextElementSibling.style.display = 'flex';
			}
		});
	}

	checkIfUncategorized(className) {
		return className.includes("uncategorized");
	}

	showOrHideTile(tile) {
		const isUncategorized = this.checkIfUncategorized(tile.className);
		const svgSelector = isUncategorized ? selectors.uncategorizedTickMark : selectors.categorizedTickMark;
		const tickMarkDisplay = getComputedStyle(
			tile.querySelector(svgSelector)
		).display;

		if (tickMarkDisplay === "none" || !tickMarkDisplay) {
			tile.nextElementSibling.style.display = 'none';
		}
		else {
			tile.nextElementSibling.style.display = 'flex';
		}
	}

	applyStylesToTiles(){
		this.allTiles.forEach((tile) => {
			this.fadeInTile(tile);
			this.showOrHideTile(tile);
		});
	}

	handleTileMouseOut() {
		this.applyStylesToTiles();
	}

	fadeInTile(tile) {
		tile.classList.remove(selectors.fadeOutClass);
	}

	fadeOutTile(tile) {
		tile.classList.add(selectors.fadeOutClass);
	}

	updateHeightAndPrice() {
		this.handlePriceUpdate();
		this.updateHeight();
	}

	handlePriceUpdate() {
		if (this.isSortingEnabled) {
			const sortedItems = this.getSortedItems();
			this.replaceItems(sortedItems);
		}

		if (this.groupMinPrice) {
			this.updateGroupMinPrice();
		}
	}

	updateTileHeights({
		configuratorItemsList,
		tileInfo
	}) {
		const allTileInfoList = this.querySelectorAll(
			`${configuratorItemsList} > li ${tileInfo}`
		);
		const height = this.querySelector(':scope > div').offsetHeight;

		for (const tileInfoElement of allTileInfoList) {
			if (height > 0) {
				tileInfoElement.style.top = `${height}px`;
			}
		}
	}

	updateHeight() {
		const s = selectors;
		const isUncategorized = this.checkIfUncategorized(
			this.firstElementChild.className
		);
		const tileSelectors = {
			isUncategorized,
			wrapper: isUncategorized
				? s.uncategorizedWrapper
				: s.categorizedWrapper,
			configuratorItemsList: isUncategorized
				? s.uncategorizedConfiguratorItemsList
				: s.categorizedConfiguratorItemsList,
			groupInfo: isUncategorized ? null : s.categorizedGroupInfo,
			headline: isUncategorized
				? s.uncategorizedHeadline
				: `${s.categorizedWrapper}>${s.categorizedHeadlineWrapper}`,
			tileInfo: isUncategorized
				? s.uncategorizedTileInfo
				: s.categorizedTileInfo
		};

		this.updateTileHeights(tileSelectors);
	}

	updateGroupMinPrice() {
		const visibleIds = this.getVisibleConfiguratorItemIds();

		if (visibleIds.length > 0) {
			const minPrice = dpuApi.getMinPriceForItemIds(visibleIds);

			this.groupMinPrice.innerHTML = minPrice
				? `${window.i18n.from} ${minPrice}`
				: `${window.i18n.from} ${window.i18n["standard-group-price"]}`;
		}
	}

	getSortedItems() {
		const items = this.allConfiguratorItems;

		if (this.sortType === "price") {
			const sortedByPrice = this.getSortedItemsByPrice(items);
			return this.getItemsWithStandardToFront(sortedByPrice);
		}

		return items;
	}

	replaceItems(sortedByPriceAndStandardFlag) {
		const wrapper = this.querySelector(selectors.configuratorItemsList);

		const fragment = document.createDocumentFragment();
		sortedByPriceAndStandardFlag.forEach((item) => {
			fragment.appendChild(item);
		});

		wrapper.innerHTML = "";
		wrapper.appendChild(fragment);
	}

	getSortedItemsByPrice(items) {
		return items.sort((itemA, itemB) => {
			const dpuItemA = dpuApi.getItem(
				itemA.getAttribute("data-configurator-id")
			);
			const dpuItemB = dpuApi.getItem(
				itemB.getAttribute("data-configurator-id")
			);

			if (!dpuItemA || !dpuItemB) {
				return 0;
			}

			const priceValueA = TileList.getPriceValue(dpuItemA);
			const priceValueB = TileList.getPriceValue(dpuItemB);

			if (this.sortOrder === "ascending") {
				return priceValueA - priceValueB;
			}
			else if (this.sortOrder === "descending") {
				return priceValueB - priceValueA;
			}
		});
	}

	getItemsWithStandardToFront(items) {
		return items.sort((itemA, itemB) => {
			const dpuItemA = dpuApi.getItem(
				itemA.getAttribute("data-configurator-id")
			);
			const dpuItemB = dpuApi.getItem(
				itemB.getAttribute("data-configurator-id")
			);

			if (!dpuItemA || !dpuItemB) {
				return 0;
			}

			if (TileList.isStatusStandardFlagSet(dpuItemA)) {
				return -1;
			}

			if (TileList.isStatusStandardFlagSet(dpuItemB)) {
				return 1;
			}

			return 0;
		});
	}

	getVisibleConfiguratorItemIds() {
		return this.allConfiguratorItems
			.map((item) => item.getAttribute("data-configurator-id"))
			.filter((id) => {
				const dpuItem = dpuApi.getItem(id);
				return (
					dpuItem &&
					notVisibleStatusCodes.indexOf(dpuItem.status) === -1
				);
			});
	}

	static isStatusStandardFlagSet(item) {
		return item.status.match(/1$/);
	}

	static getPriceValue(item) {
		return item.priceValue ? item.priceValue : 0;
	}
}

if (window.customElements.get("audi-configurator-tile-list") === undefined) {
	window.customElements.define("audi-configurator-tile-list", TileList);
}
