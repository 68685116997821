import {patternMatching as PATTERN} from 'core-global';
import {iph as IPH} from 'core-global';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
const __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * // To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {
	'sourceName': 'IPH'
};
// reference to the global eventEmitter
__.eventBus = null;

__.register = function () {
	PATTERN.register('lead_source', __.getLeadSource);
};

__.getLeadSource = function() {
	return new Promise(function(resolve, reject) {
		// if in IPH mode resolve with IPH string
		if (IPH.inIPHMode()) {
			resolve(__.oDefaults.sourceName);
		}
		else {
			reject(new TypeError('load pattern -leadsource- failed'));
		}
	});
};

exports.initialize = function() {
	__.register();
};

export {exports as patternLeadsource};
