import {stateRegistry} from 'microkernel';
import {appEvents, router, signal} from 'core-application';

import {AUDICODE_STORE_ID} from './stores/audicode-store';
import {dpuController} from './dpu/controller';
import {dpuApi} from './dpu/api';

export default class LoadAudicodeLink extends HTMLElement {

	constructor() {
		super();
		this.eventBus = signal.getEmitter();
		this.configuration = null;
		this.loadConfiguration = this.loadConfiguration.bind(this);
		this.isExternalLink = SETUPS.get('scope.nemo.audicode.externallink');
		stateRegistry.subscribeToStore(AUDICODE_STORE_ID, state => this.getConfigurationFromState(state));
	}

	connectedCallback() {
		this.link = this.querySelector('.nm-j-audicode-accept');
		this.link.addEventListener('click', this.loadConfiguration);
	}

	disconnectedCallback() {
		this.link.removeEventListener('click', this.loadConfiguration);
	}

	loadConfiguration(event) {
		if (!this.configuration) {
			event.preventDefault();
			return;
		}

		this.emitLoadAudicodeEvent();

		if (this.isExternalLink) {
			if (this.hasOtherUrl()) {
				this.link.href = this.configuration.audicode['other-url'];
			}
			return true;
		}
		else {
			event.preventDefault();
			if (this.hasOtherUrl()) {
				window.location = this.configuration.audicode['other-url'];
			}
			this.handleExternalConfiguration();
		}

		this.closeHeaderService();
	}

	handleExternalConfiguration() {
		dpuController.handleExternalConfiguration(this.configuration, {
			startType: 'audicode-search'
		}).then(() => {
			// always go to the defined url to start a configuration
			router.open('page', dpuApi.getCurrentCarlineUrl());
		});
	}

	getConfigurationFromState(state) {
		if (state && !!state.configuration) {
			this.configuration = state.configuration;
		}
	}

	hasOtherUrl() {
		return !!this.configuration && this.configuration.audicode && this.configuration.audicode['other-url'] && this.configuration.audicode['other-url'] !== '';
	}

	emitLoadAudicodeEvent() {
		this.eventBus.emit(appEvents.SEARCH_LOAD_AUDICODE, {
			audicode: this.configuration.audicode.id
		});
	}

	closeHeaderService() {
		const headerService = document.querySelector('header-service');
		if (!!headerService) {
			headerService.click();
		}
	}

}
if (!window.customElements.get('audi-configurator-load-audicode-link')) {
	window.customElements.define('audi-configurator-load-audicode-link', LoadAudicodeLink);
}
