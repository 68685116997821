/* global zlbolt */
import {dom as DOM_UTILS} from 'core-utils';
import {appEvents as EVENTS} from 'core-application';
import {dpuApi as DPU_API} from '../dpu/api';
import {jsLoader as JSLOADER} from 'core-utils';
import {aveDom as AVE_DOM} from './dom';

const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorAnimationListSelector: '.nm-ave-animation-selection'
};

__.debugEnabled = true;

__.zlLoaded = false;
__.zlValidated = false;
__.zlboltURL = '';

__.cameraMappings = {
	closeup_lights: 'FrontRightShare',
	closeup_wheels: 'Rims',
	dashboard: 'Interior',
	exterior: 'MRVSide',
	exterior_back: 'MRV4',
	exterior_front: 'MRV1',
	exterior_straight_back: 'MRV5',
	exterior_straight_front: 'MRV6',
	interior: 'InteriorSide_L0L',
	rearseat: 'Interior_Rear_View'
};
__.defaultCamera = 'MRV1';

/**
 * get the correct zl customer - audi / zerolight
 * (the latter is used for staging env)
 * @returns {string} customer - the customer string to use for API configuration
 */
__.zlGetCustomer = function() {
	const setupsCustomer = SETUPS.get('ave3d.customer');

	if (!__.zlLoaded) {
		__.debug('zlbolt not yet loaded');
	}

	__.debug('zlbolt customer = ' + setupsCustomer);
	return setupsCustomer;
};

/**
 * get the correct zl env - live / staging
 * (currently both live, the differentiation is done via customer)
 * @returns {string} env - the environment string to use for API configuration
 */
__.zlGetEnv = function() {
	const setupsEnv = SETUPS.get('ave3d.env');

	if (!__.zlLoaded) {
		__.debug('zlbolt not yet loaded');
	}

	__.debug('zlbolt env = ' + setupsEnv);
	return setupsEnv;
};

/**
 * call the zerolight validate function (only once!)
 * @returns {void}
 */
__.zlValidate = function() {
	if (typeof zlbolt !== 'undefined' && __.zlValidated === false) {
		const connObj = __.buildUpValidateObject();

		zlbolt.validate(connObj, function(error, result) {
			if (result && result.status === 'valid') {
				__.zlValidated = true;
				__.eventBus.emit(EVENTS.AVE_AVAILABLE);
			}
			else {
				if (result && result.reason) {
					__.debug('zl validation failed: ' + result.reason);
				}
				else {
					__.debug('zl validation failed');
				}
			}
		});
	}
	else {
		__.debug('failed zlbolt.validate');
	}
};

/**
 * set zerolight ready and trigger corresponding events
 * @returns {void}
 */
__.zlSetReady = function() {
	__.debug('zlbolt loaded');
	__.zlLoaded = true;
	__.zlValidate();
};

/**
 * prepare URL of zlbolt library
 * @returns {void}
 */
__.prepareZLboltURL = function() {
	const setupsUrl = SETUPS.get('ave3d.url');

	__.debug('zlbolt url = ' + setupsUrl);
	__.zlboltURL = setupsUrl;
};

/**
 * set pro codes string within zl
 * @param {string} aveString - the ave string to use as pr codes
 * @returns {void}
 */
__.zlSetAveString = function(aveString) {
	if (!__.zlLoaded) {
		__.debug('zlbolt not yet loaded');
	}
	else {
		zlbolt.setPrCodes(aveString, function(data) {
			__.debug('set prcodes with msg: ', data);
		});
	}
};

/**
 * disconnect from zl
 * @param {function} callback - callback function to be executed when disconnect finished
 * @param {string} param - parameter for callback function
 * @returns {void}
 */
__.zlDisconnect = function(callback, param) {
	if (__.zlLoaded && typeof zlbolt !== 'undefined' && __.isConnected === true) {
		__.debug('disconnect from zlbolt');
		try {
			zlbolt.disconnect(function() {
				callback(param);
			});
		}
		catch (err) {
			__.debug('zl exception on disconnect: ' + err);
			callback(param);
		}
		__.isConnected = false;
	}
	else {
		callback(param);
	}
};

/**
 * activate camera
 * @param {string} renderImageView  - the render view type to activate
 * @returns {void}
 */
__.zlActivateCamera = function(renderImageView) {
	const zlCameraName = __.cameraMappings[renderImageView];
	zlbolt.switchCamera(zlCameraName, function(data) {
		__.debug('switched camera to ' + zlCameraName + ' with result', data);
	});
};

__.zlSetConnectCallbacks = function(domElemId, viewPerspective) {
	zlbolt.isSupported(function(error, support) {
		if (error || !support) {
			__.debug('zl this browser is not supported');
			return false;
		}

		zlbolt.connect(
			__.buildUpConnectObject(domElemId, viewPerspective),
			function(data) {
				if (!!data) {
					__.debug('zlbolt connect exited in error state: ', data);
					__.isConnected = false;
				}
				else {
					__.debug('zlbolt connected');
					__.isConnected = true;
				}
			}
		);
	});
	// a set of event-based callbacks
	zlbolt.onStreamReady = function() {
		if (__.streamActive === true) {
			__.debug('stream already active - possible side effect');
		}
		__.streamActive = true;
		__.isConnected = true;

		__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handlerConfigUpdated);

		__.zlBuildOptionsControl();

		zlbolt.enableMouseTracking();
		window.addEventListener('resize', AVE_DOM.resizeStreamWrapper);

		__.dispatchStreamReadyEvent();
		AVE_DOM.resizeStreamWrapper();
	};

	zlbolt.onMouseUp = function(data) {
		__.debug('mouse up with x:' + data.x + ' and y:' + data.y);
	};

	zlbolt.onError = function(err) {
		__.debug('zlbolt Error: ', err);
	};

	zlbolt.onStreamDisconnect = function() {
		__.debug('zlbolt StreamDisconnect');
		__.eventBus.off(EVENTS.CONFIG_UPDATE, __.handlerConfigUpdated);
		window.removeEventListener('resize', AVE_DOM.resizeStreamWrapper);
		__.streamActive = false;
	};

	zlbolt.onClose = function() {
		__.debug('zlbolt stream close');
		__.streamActive = false;
	};
};

/**
 * connect to zl
 * @param {string} domElemId - the dom elem to insert ave
 * @param {string} viewPerspective - the initial view perspective
 * @returns {void}
 */
__.zlConnect = function(domElemId, viewPerspective) {
	if (!__.zlLoaded) {
		__.debug('zlbolt not yet loaded');
		return false;
	}
	else if (typeof zlbolt !== 'undefined') {
		__.debug('zlbolt StreamReady');
		__.zlSetConnectCallbacks(domElemId, viewPerspective);
	}
	else {
		__.debug('failed zlConnect');
		return false;
	}
};

/**
 * build the the option controls (from provided options by zl) and insert them
 * @returns {void}
 */
__.zlBuildOptionsControl = function() {
	__.debug('build options control');

	zlbolt.enumerateAnimations(function(data) {
		const animSelector = DOM_UTILS.getElement(__.oDefaults.sSelectorAnimationListSelector);
		const content = document.createElement('div');

		if (DOM_UTILS.isElement(animSelector)) {
			content.innerHTML = AVE_DOM.renderAnimationControls(data.animations);
			while (content.firstChild) {
				animSelector.appendChild(content.firstChild);
			}
		}
		DOM_UTILS.getEventDelegate('body').on('click', '.nm-avectrl-animation', __.zlTriggerAnimation);
	});

	zlbolt.enumerateEnvironments(function(data) {
		const animSelector = DOM_UTILS.getElement(__.oDefaults.sSelectorAnimationListSelector);
		const content = document.createElement('div');

		if (DOM_UTILS.isElement(animSelector) && data.environments.length > 1) {
			content.innerHTML = AVE_DOM.renderEnvironmentControls(data.environments);
			while (content.firstChild) {
				animSelector.appendChild(content.firstChild);
			}
			DOM_UTILS.getEventDelegate('body').on('click', '.nm-avectrl-environment', __.zlTriggerEnvironmentChange);
		}
	});

	AVE_DOM.enableOptions();
};

/**
 * trigger animation to zl
 * @param {Event} e - the click event
 * @returns {void}
 */
__.zlTriggerAnimation = function(e) {
	const animation = DOM_UTILS.closest(e.target, '.nm-avectrl-animation').getAttribute('data-animation');
	__.debug('trigger animation ' + animation);

	zlbolt.triggerAnimation(animation, function(data) {
		__.debug('triggered ' + animation + ' with result', data);
	});
};

/**
 * trigger environment change to zl
 * @param {Event} e - the click event
 * @returns {void}
 */
__.zlTriggerEnvironmentChange = function(e) {
	const environment = DOM_UTILS.closest(e.target, '.nm-avectrl-environment').getAttribute('data-environment');
	__.debug('trigger environment change ' + environment);

	zlbolt.switchEnvironment(environment, function(data) {
		__.debug('triggered ' + environment + ' with result', data);
	});
};

/**
 * handler for config updated event
 * @returns {void}
 */
__.handlerConfigUpdated = function() {
	__.zlSetAveString(DPU_API.getAveString().split(','));
};

/**
 * mapping function from view perspective (e.g. exterior_front) to camera
 * @param {string} viewPerspective - the view perspective to get camera name for
 * @returns {string} camera name
 */
__.mapViewPerspeciveToCamera = function(viewPerspective) {
	if (viewPerspective in __.cameraMappings) {
		return __.cameraMappings[viewPerspective];
	}
	__.debug('falling back to default camera from ' + viewPerspective);
	return __.defaultCamera;
};

/**
 * get currently active protocol, i.e. https or http
 * @returns {string} the active protocol
 */
__.getActiveProtocol = function() {
	return document.location.protocol.indexOf('https') > -1 ? 'https' : 'http';
};

/**
 * build up validation object to interact with zl
 * @returns {Object} - the validation object
 */
__.buildUpValidateObject = function() {
	return {
		connectionParameters: {
			protocol: __.getActiveProtocol(),
			customer: __.zlGetCustomer(),
			environment: __.zlGetEnv()
		},
		car: {
			manufacturer: 'audi',
			model: DPU_API.getCarline(),
			year: DPU_API.getModelYear()
		},
		consumeMouseScrollEvents: true
	};
};

/**
 * build up connect object to connect with zl
 * @param {string} domElemId - the dom element id
 * @param {string} viewPerspective - the initial view perspective
 * @return {Object} connObj - the connection object
 */
__.buildUpConnectObject = function(domElemId, viewPerspective) {
	const renderWidth = 1112;
	const renderHeight = 668;
	const aveString = DPU_API.getAveString();
	let connObj = __.buildUpValidateObject();
	connObj.resolution = {
		x: renderWidth,
		y: renderHeight
	};
	connObj.parent = domElemId;
	connObj.setupCamera = __.mapViewPerspeciveToCamera(viewPerspective);

	if (aveString) {
		connObj.setupPrCodes = aveString.split(',');
	}

	return connObj;
};

__.dispatchStreamReadyEvent = function () {
	__.eventBus.emit(EVENTS.AVE_STREAM_READY);

	const aveStreamReadyEvent = new CustomEvent('ave-stream-ready-tracking', {});
	document.dispatchEvent(aveStreamReadyEvent);
};

/**
 * custom debug function
 * @param {string} msg - the debug msg
 * @returns {void}
 */
__.debug = function(msg) {
	if (__.debugEnabled) {
		console.log('ave-api: ' + msg);
	}
};

/**
 * check whether ave is active for current carline
 * @returns {boolean} - true if active, false else
 */
exports.isActiveCarline = function() {
	return SETUPS.get('scope.ave3d') === '1' && SETUPS.get('ave3d.aveEnabled') === 'true';
};

/**
 * activate named perspective
 * @param {string} perspective - the perspective to activate
 * @returns {void}
 */
exports.showPerspective = function(perspective) {
	__.zlActivateCamera(perspective);
};

/**
 * check whether ave is available
 * @returns {boolean} - true if ave available, else false
 */
exports.isAveAvailable = function() {
	return __.zlValidated;
};

/**
 * disconnect from zl and remove canvas
 * @param {string} canvasid - the id of the canvas to deactivate ave from
 * @returns {void}
 */
exports.deactivate = function(canvasid) {
	__.zlDisconnect(AVE_DOM.deactivateAveCanvas, canvasid);
};

/**
 * activate ave for certain element
 * @param {string} canvasid - the id of the element to provide the ave into
 * @param {string} viewPerspective - the view perspective to load
 * @returns {void}
 */
exports.activateAVE = function(canvasid, viewPerspective) {
	AVE_DOM.activateAveCanvas(canvasid);
	__.zlConnect(canvasid, viewPerspective);
};

/**
 * move to element to another element with (optional) viewPerspective change
 * @param {string} originId - id of origin html element
 * @param {string} targetId - id of target html element
 * @param {string} viewPerspective - view perspective to open (or false if not used)
 * @returns {void}
 */
exports.moveAVE = function(originId, targetId, viewPerspective) {
	__.debug('moving from ' + originId + ' to ' + targetId);
	zlbolt.moveStream(targetId);
	AVE_DOM.moveAveCanvas(originId, targetId);
	if (typeof viewPerspective !== 'undefined' && viewPerspective !== false) {
		__.zlActivateCamera(viewPerspective);
	}
};

/**
 * initialize
 * @param {Emitter} eventBus_ - the global EventEmitter
 * @returns {void}
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	if (exports.isActiveCarline()) {
		__.prepareZLboltURL();
		JSLOADER.loadURL(__.zlboltURL).then(__.zlSetReady);
	}
};

export {exports as aveApi};
