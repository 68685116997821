(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-application"), require("core-utils"), require("core-global"), require("microkernel"), require("core-vendor"));
	else if(typeof define === 'function' && define.amd)
		define("configurator", ["core-application", "core-utils", "core-global", "microkernel", "core-vendor"], factory);
	else if(typeof exports === 'object')
		exports["configurator"] = factory(require("core-application"), require("core-utils"), require("core-global"), require("microkernel"), require("core-vendor"));
	else
		root["configurator"] = factory(root["core-application"], root["core-utils"], root["core-global"], root["microkernel"], root["core-vendor"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__424__, __WEBPACK_EXTERNAL_MODULE__139__, __WEBPACK_EXTERNAL_MODULE__369__, __WEBPACK_EXTERNAL_MODULE__729__, __WEBPACK_EXTERNAL_MODULE__848__) => {
return 