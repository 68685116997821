import {stateRegistry} from 'microkernel';

export const USERDATA_STORE_ID = 'dbadUserdataStore';

class UserdataStore {
	constructor() {
		if (!UserdataStore.instance) {
			this._initStore();
			UserdataStore.instance = this;
		}
		return UserdataStore.instance;
	}

	_initStore() {
		const initialData = {
			audicodes: null,
			loggedIn: null,
			csrfToken: null
		};
		const actions = {
			update: this._update,
			logout: this._logout
		};
		stateRegistry.addStore(USERDATA_STORE_ID, initialData, actions);
	}

	_update(state, params) {
		return {...state, ...params};
	}

	_logout(state) {
		return {...state, audicodes: null, loggedIn: false, csrfToken: null};
	}
}

const userdataStoreInstance = new UserdataStore();
Object.freeze(userdataStoreInstance);
