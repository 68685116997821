import {dom as DOM_UTILS} from 'core-utils';
const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorDelegateClick:'.nm-infobox',
	sSelectorInfoboxContainer:'.nm-infobox-container',
	sToggleClass:'nm-infobox-show'
};
__.clickedElement = null;

/**
 * handleOutsideClick - close infobox whenever the user clicks outside of togglelink
 * @param {Event} event_ - element which is clicked
 * @returns {void} - returns nothing
 */
__.handleOutsideClick = function _handleOutsideClick(event_){
	var element = DOM_UTILS.getElement(__.oDefaults.sSelectorInfoboxContainer, __.clickedElement),
		clickedOutside;
	if (element.classList.contains(__.oDefaults.sToggleClass)) {
		clickedOutside = DOM_UTILS.isElementOutsideOfElementWithSelector(event_.target, __.oDefaults.sSelectorDelegateClick);
		if (clickedOutside){
			__.domEventDelegate.off('click', __.handleOutsideClick);
			__.closeInfoBox(element);
			__.clickedElement = null;
		}
	}
};

/**
		 * __.handleToggleClick
		 * add class to open element or close element
		 * @return {void} returns nothing
		 */
__.handleToggleClick = function _handleToggleClick(){
	var element = DOM_UTILS.getElement(__.oDefaults.sSelectorInfoboxContainer, this);
	__.clickedElement = this;
	if (!element.classList.contains(__.oDefaults.sToggleClass)) {
		// show infobox
		element.classList.add(__.oDefaults.sToggleClass);
		// register outside click event
		__.registerOutsideClickEvent();

	}
	else {
		__.closeInfoBox(element);
	}
};

/**
 * registerOutsideClickEvent
 * @returns {void} returns nothing
 */
__.registerOutsideClickEvent = function () {
	// add click outside handler to close infobox on click outside the Infobox
	__.domEventDelegate.on('click', '*', __.handleOutsideClick);
};

/**
 * __.closeInfoBox
 * @param {HTMLnode} element_ - element to close
 * @return {void} returns nothing
 */
__.closeInfoBox = function(element_){
	element_.classList.remove(__.oDefaults.sToggleClass);
};

/**
 * Add Module-specific events
 * @returns {void}
 */
__.addEvents = function() {
	__.domEventDelegate.on('click', __.oDefaults.sSelectorDelegateClick, __.handleToggleClick);
};

__.initialize = function(){
	if (!__.init){
		__.addEvents();
	}
	__.init=true;
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.initialize();

};
export {exports as infobox};
