/* global Ave2CloudApplication */
import {appEvents} from 'core-application';
import {dpuApi} from './dpu/api';
import {jsLoader as JSLOADER} from 'core-utils';

const __ = {};
const exports = {
	__: __
};

__.isAve2CloudAvailable = false;
__.containerId = 'avecanvas';

const getProtocol = () => {
	return document.location.protocol.indexOf('https') > -1 ? 'https' : 'http';
};

const getCustomer = () => {
	return SETUPS.get('ave3d.customer') || 'audi';
};

const getEnvironment = () => {
	return SETUPS.get('ave3d.env') || 'live';
};

const updateStreamResolution = () => {
	console.log('AVE2Cloud updateStreamResolution');
	if (__.controllerFactory) {
		const container = document.getElementById(__.containerId);
		__.controllerFactory.getUIAndApiController(view => {
			view.updateSize(null, container.clientHeight);
		});
	}
};

const registerResizeHandler = () => {
	window.addEventListener('resize', updateStreamResolution); // todo throttle / debounce
};

const closeLayerHandler = () => {
	console.log('AVE2Cloud closeLayerHandler');
	if (__.controllerFactory) {
		__.controllerFactory.getUIAndApiController(view => {
			view.close();
		});
	}
	window.removeEventListener('resize', updateStreamResolution);
};

const initFactoryAndChckFreeInstances = () => {
	__.controllerFactory = new Ave2CloudApplication.ControllerFactory();
	__.controllerFactory.getUIAndApiController((view, api) => {
		api.getFreeInstances()
			.then(result => {
				if (result.freeInstances >= 1) {
					__.isAve2CloudAvailable = true;
					console.log('AVE2Cloud: freeInstances: ', result.freeInstances);
				}
				else {
					console.log('AVE2Cloud: No free sessions');
				}
			})
			.catch(error => {
				console.error(error, 'getFreeInstances');
			});
	});
};

const connectToStream = () => {
	console.log('AVE2Cloud connectToStream');
	const container = document.getElementById(__.containerId);

	container.classList.add('ave2cloud-container');

	const webSiteCar = {
		connectionParameters: {
			protocol: getProtocol(),
			customer: getCustomer(),
			environment: getEnvironment()
		},
		car: {
			manufacturer: 'audi',
			model: dpuApi.getCarline(),
			year: dpuApi.getModelYear()
		},
		consumeMouseScrollEvents: true,
		resolution: {
			x: container.clientWidth,
			y: container.clientHeight
		},
		setupCamera: "MRV1",
		setupPrCodes: dpuApi.getAveString().split(','),
		market: SETUPS.get('ave2cloud.market'),
		service: "web"
	};

	console.log('AVE2Cloud webSiteCar', JSON.stringify(webSiteCar));

	__.controllerFactory.getUIAndApiController((view, api) => {
		view.connect(webSiteCar, __.containerId);

		view.on(event => {
			console.log(event, 'view-event');
			if (event === 'AVEConnected') {
				api.loadCar(webSiteCar);
				// api.changeEnvironment('dynamic');
			}
		});
		api.on(event => {
			console.log(event.message, 'api-event');
			if (event.message === 'LoadCarFinish') {
				view.updateSize(null, container.clientHeight); // todo ausbauen, wenn die View das nativ kann
				// view.hideControlsUI();
				// api.changeCamera('CloudMRV1');
				container.classList.add('ave2cloud-container--ready');
			}
		});
	});
};

const addEvents = () => {
	__.eventBus.on(appEvents.CARINFO_LOAD_COMPLETE, initFactoryAndChckFreeInstances);
	__.eventBus.on(appEvents.CONFIG_UPDATE, initFactoryAndChckFreeInstances);
	__.eventBus.on('AVE2CLOUD.CONTAINER.READY', connectToStream);
	__.eventBus.on(appEvents.RENDERINGLAYER_LOADED, registerResizeHandler);
	__.eventBus.on('jslayer.close', closeLayerHandler);
};

exports.isAve2CloudAvailable = () => {
	return __.isAve2CloudAvailable;
};

exports.isAve2CloudEnabled = () => {
	return SETUPS.get('ave2cloud.enabled');
};

exports.initialize = (eventBus_) => {
	__.eventBus = eventBus_;
	if (exports.isAve2CloudEnabled()) {
		console.log('AVE2Cloud initialize');
		const url = SETUPS.get('ave2cloud.url');

		JSLOADER.loadURL(url).then(addEvents);
	}
};

export {exports as ave2cloud};
