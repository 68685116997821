import {dpuApi as CONFIGURATOR_API} from './api';
import {appEvents as EVENTS} from 'core-application';
import {layerApi as LAYER} from 'core-application';
import {template as TPL_RENDERER, dom as DOM_UTILS} from 'core-utils';
import {conflictLayerTemplate as CONFLICT_TPL} from './conflict-layer-template';

const
	__ = {},
	exports = {
		__: __
	};

__.previousSelectedSolution = null;
__.layerInstance = null;
__.currentCancelLink = '';
__.oDefaults = {
	sSelectorConflictLayer: '.nm-layer-conflict',
	sSelectorConfigLayerOpen: 'nm-config-layer-open',
	sSelectorDelegateConflictClick: '.nm-j-configurator-delegate-conflict-click',
	sSelectorOptionsShow: '.nm-j-conflict-options-show',
	sSelectorConflictSolution: '.nm-conflict-solution',
	sSelectorDelegateClick: '.nm-layer-wrapper-conflict .nm-j-configurator-delegate-click',
	sClassConflictSolutionShow: 'nm-conflict-solution-show'
};

// whitelist for family types to be grouped and displayed as pattern
__.groupingWhitelist = ['extcolor', 'upholstery'];

/**
 * @return {function} which debounces the click event to avoid sending multiple requests to the DPU
 */
__.debouncedConflictResolveHandler = DOM_UTILS.debounce((event) => {
	__.handleConflictResolveClick(event);
}, 500);

/**
 * add event Listener
 * @returns {void}
 */
__.addEvents = function() {
	__.legacyEventBus.on('click.nemo.configurator', __.oDefaults.sSelectorDelegateConflictClick, (event) => {
		// prevent default in order to avoid default (click) behavior on the anchor tag due to async processing
		event.preventDefault();

		// apply debouncing for click event
		__.debouncedConflictResolveHandler(event);
	});

	// show alternative options menu click
	__.legacyEventBus.on('click.alternative-options', __.oDefaults.sSelectorOptionsShow, __.toggleAlternativeOptions);
	// handle conflict Itme Click while conflict layer is open
	__.legacyEventBus.on('click.solution.store', __.oDefaults.sSelectorDelegateClick, __.handleConflictItemClick);
};

/**
 * save last clicked solution item
 * @param {Event} event_ TODO
 * @returns {boolean} TODO
 */
__.handleConflictItemClick = function(event_) {
	var item, solution, solutionID, isSelectedItem;

	__.cancelEvent(event_);
	item = jQuery(this);
	solution = item.closest('div[data-solution-id]');
	solutionID = solution.data('solution-id');
	isSelectedItem = __.isSelectedItem(item.closest('.nm-j-configurator-item'));

	if (isSelectedItem) {
		console.log('ITEM "' + item.closest('.nm-j-configurator-item').attr('[nm-j-configurator-id]') + '" is already part of the configuration', item, solution, solutionID);
		return false;
	}
	else {
		__.previousSelectedSolution = solutionID;
		console.log('ITEM was clicked', item, solution, solutionID, __.previousSelectedSolution);
	}
};

/**
* Extracts the configurator's status code from the dom
* @param {jQuery-Element} $element_ configurator item
* @returns {boolean} - true if item selected, false else
*/
__.isSelectedItem = function($element_) {
	var status;
	var _sClassName = $element_.get(0).className;

	if (_sClassName.indexOf('nm-j-configurator-status_') === -1) {
		return false;
	}
	else {
		status = _sClassName.split('nm-j-configurator-status_')[1].split(' ')[0];
		return (status.length === 5 && status[3] === '1');
	}
};

/**
 * show/hide conflict solution alternative options
 * @returns {void}
 */
__.toggleAlternativeOptions = function() {
	var $this = jQuery(this);
	var $container = $this.closest('.nm-conflict-solution');

	$container.toggleClass(__.oDefaults.sClassConflictSolutionShow);
	return false;
};

/**
 * detect groups within conflict solution
 * @param {Array} solution_ - conflict solution
 * @param {Array} familyItems_ - family items
 * @param {Array} configItems_ - config items
 * @returns {Array} the grouped solution_
 */
__.detectGroups = function(solution_, familyItems_, configItems_) { // eslint-disable-line max-statements
	var i, configItem, familyItem, currentType, header;
	var length = solution_.items.length;

	solution_.sameTypeItems = false;

	if (length < 2) {
		return solution_;
	}

	for (i = length - 1; i >= 0; i--) {
		configItem = configItems_[solution_.items[i].id];

		if (!configItem) {
			return solution_;
		}
		familyItem = familyItems_[configItem.family];

		if (!familyItem) {
			return solution_;
		}
		header = familyItem.name;

		// compare type after the first iteration
		if (i < length - 1) {
			if (currentType !== familyItem.type || __.groupingWhitelist.indexOf(familyItem.type) < 0) {
				return solution_;
			}
		}
		currentType = familyItem.type;
	}
	solution_.sameTypeItems = true;
	solution_.header = header;
	return solution_;
};

/**
* merge conflict data response from the DPU with carinfo JSON
* @param {Object} data_ - conflict data from the dpu
* @param {Object} familyItems_ - return value of CONFIGURATOR_API.getSpecialFamilies()
* @param {Object} configItems_ - return value of CONFIGURATOR_API.getItems()
* @returns {Object} merged conflict data
*/
__.mergeData = function(data_, familyItems_, configItems_) { // eslint-disable-line max-statements
	var conflictsData = data_;
	var solutions = [];
	var item, mergedEffItem, i, len, effItemsArr, configItem, mergedItem, triggers, solution, exclusives;

	// merge solutions with replacedByTriggers
	if (!!conflictsData.replacedByTriggers && conflictsData.replacedByTriggers.length) {
		conflictsData.solutions.unshift(conflictsData.replacedByTriggers);
	}

	// loop through solutions
	jQuery.each(conflictsData.solutions, function(iteration, solution_) {
		solution = {};
		solution.items = [];
		solution.ids = [];
		effItemsArr = [];

		// loop through each solution´s items
		jQuery.each(solution_, function(index, item_) {
			configItem = configItems_[item_.id];

			if (!!configItem) {
				if (!!configItem.name) {
					// only delete name from DPU response if we already have one
					delete item_.name;
				}
				mergedItem = jQuery.extend(true, {}, configItem, item_);
			}
			else {
				// item is missing in the carInfo!!!!
				mergedItem = item_;
			}

			// copy family info to Item
			if (!!mergedItem.family && !!familyItems_[mergedItem.family]) {
				mergedItem.family = familyItems_[mergedItem.family];
			}

			// id item has effItems
			if (!!item_.effItems) {
				// loop through a solution´s effItems if exist
				jQuery.each(item_.effItems, function(idx, effItem_) {
					configItem = configItems_[effItem_.id];

					if (!!configItem.name) {
						delete effItem_.name;
					}
					mergedEffItem = jQuery.extend(true, {}, configItem, effItem_);

					// copy family info to Item
					if (!!mergedEffItem.family && !!familyItems_[mergedEffItem.family]) {
						mergedEffItem.family = familyItems_[mergedEffItem.family];
					}
					// Bugfix: dotJS template erwartet items im Datanmodel :(
					mergedEffItem.items = [mergedEffItem];
					effItemsArr.push(mergedEffItem);
				});
			}
			// collect all item id to generate an unique ID for each solution
			solution.ids.push(mergedItem.id);
			// add items to solution
			solution.items.push(mergedItem);
		});
		// generate an unique ID for the solution (concatenate all item IDs)
		solution.id = solution.ids.join('');
		// add merged solution to solutions array
		solutions.push(solution);

		// add effItems to the solutions array as a single solution
		if (effItemsArr.length > 0) {
			solutions = solutions.concat(effItemsArr);
		}
	});

	conflictsData.solutions = solutions;

	// Detect tile-able solutions
	for (i = conflictsData.solutions.length - 1; i >= 0; i--) {
		conflictsData.solutions[i] = __.detectGroups(conflictsData.solutions[i], familyItems_, configItems_);
	}
	// add exclusives Object to the solutions[0]
	exclusives = [];
	len = conflictsData.solutions[0].items.length;

	while (len--) {
		item = conflictsData.solutions[0].items[len];

		if (!!item.family && !!item.family.id && item.family.id.indexOf('synthetic.audi_exclusive') > -1) {
			// add item to exclusives
			exclusives.push(item);
			// set flag to item, to identify it as exclusive in client-template
			conflictsData.solutions[0].items[len].exclusive = true;
		}
		else {
			conflictsData.solutions[0].items[len].exclusive = false;
		}
	}
	// array wieder umdrehen
	exclusives.reverse();
	conflictsData.solutions[0].exclusives = exclusives;
	// merge conflict triggers items with carinfo data
	triggers = [];
	effItemsArr = [];

	jQuery.each(conflictsData.triggers, function(iteration, trigger_) {
		configItem = configItems_[trigger_.id];

		if (!!configItem) {
			/* alle attribute bis auf 'name' aus dem Conflict trigger uebernehemen
			und mit den Feldern aus der Carinfo erweitern */
			if (!!configItem.name) {
				delete trigger_.name;
			}
			else {
				// DEBUG
				trigger_.name = '#' + configItem.name;
			}
			mergedItem = jQuery.extend(true, {}, configItem, trigger_);
		}
		else {
			// item is missing in the carInfo!!!!
			mergedItem = trigger_;
		}

		// copy family info to Item
		if (!!mergedItem.family && !!familyItems_[mergedItem.family]) {
			mergedItem.family = familyItems_[mergedItem.family];
		}
		triggers.push(mergedItem);

		// id item has effItems
		if (!!trigger_.effItems) {

			// loop through a solution´s effItems if exist
			jQuery.each(trigger_.effItems, function(index, value) {
				configItem = configItems_[value.id];

				if (!!configItem.name) {
					delete value.name;
				}
				mergedEffItem = jQuery.extend(true, {}, configItem, value);
				// Bugfix: dotJS template erwartet items im Datanmodel :(
				mergedEffItem.items = [mergedEffItem];
				effItemsArr.push(mergedEffItem);
			});
		}
	});

	// add effItems to start the solutions array as single solutions
	if (effItemsArr.length > 0) {
		conflictsData.solutions = effItemsArr.concat(conflictsData.solutions);
	}
	conflictsData.triggers = triggers;
	return conflictsData;
};

/**
 * open conflict layer
 * @param {string} layerName - the name of the layer
 * @param {string} tplHtml - the html template
 * @param {Function} closeCallBackFn - the callback function on layer open
 * @returns {Promise} - a Promise (returning a layer instance on resolve)
 */
__.openLayer = function(layerName, tplHtml, closeCallBackFn) {
	var layerPromise;

	// returns promise on open
	return new Promise(function(resolve, reject) {
		layerPromise = LAYER.open(layerName, tplHtml, closeCallBackFn);
		layerPromise.then(function(layerInstance) {
			__.layerInstance = layerInstance;
			__.openSelectedSolutionAlternatives();
			__.currentCancelLink = jQuery([__.oDefaults.sSelectorDelegateConflictClick, '[data-conflict=\'cancel\']'].join('')).attr('href');
			resolve(__.layerInstance);
		}, function(err) {
			reject(err);
		});
	});
};

/**
 * close layer by triggering the close method on the layer instance
 * @returns {Promise} - the layer instance promise
 */
__.closeLayer = function() {
	var promise;

	// Layer Instance returns promise on close
	promise = __.layerInstance.close();
	return promise.then(function() {
		__.layerInstance = null;
		__.onLayerClose();
	}, function(err) {
		throw new TypeError('Error closing conflict Layer: ' + err.message);
	});
};

/**
 * close CallBack (e.g. on shader click)
 * @returns {void}
 */
__.onLayerClose = function() {
	// remove conflict item click handler on layer close
	jQuery('body').removeClass(__.oDefaults.sSelectorConfigLayerOpen);
	__.conflict = null;
};

/**
 * handle conflict layer abort or accept clicks
 * @param {Event} event - the click event
 * @returns {void}
 */
__.handleConflictResolveClick = function(event) {
	var $element, url, cancelLink, action = 'cancel';

	if (event) {
		__.cancelEvent(event);
		// aktuellen Konfliklayer schliessen
		$element = jQuery(event.target);
		url = SETUPS.get('nemo.url.dpu') + $element.attr('href');

		if (!!$element.data('conflict')) {
			action = $element.data('conflict');
		}
	}
	else {
		cancelLink = jQuery([__.oDefaults.sSelectorDelegateConflictClick, '[data-conflict=\'cancel\']'].join('')).attr('href');
		action = 'cancel';

		if (!!cancelLink) {
			url = SETUPS.get('nemo.url.dpu') + cancelLink;
		}
		else {
			url = SETUPS.get('nemo.url.dpu') + __.currentCancelLink;
		}
	}

	__.eventBus.emit(EVENTS.CONFLICT_CLOSE, {
		url: url,
		action: action
	});
	__.closeLayer();
};

/**
 * open solution alternatives if one item was selected previously
 * @returns {void}
 */
__.openSelectedSolutionAlternatives = function() {
	var selectedSolution;

	// no previous selection available
	if (!__.previousSelectedSolution) {
		return;
	}
	// find solution by data-solution-ID
	selectedSolution = jQuery('[data-solution-id=\'' + __.previousSelectedSolution + '\']');
	// delete previous selection if not found

	if (!__.previousSelectedSolution) {
		__.previousSelectedSolution = undefined;
	}
	// open solution´s alternatives
	selectedSolution.addClass(__.oDefaults.sClassConflictSolutionShow);
};

/**
 * prevent default event behavior
 * @param {Object} event_ e.g. e click event
 * @returns {void}
 */
__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};


/**
 * handle a configuration conflict response
 * opens a conflict layer
 * @param {Object} conflict_ - the raw conflict object
 * calls LAYER_API.open
 * @returns {Promise} conflict handling promise
 */
exports.handleConflict = function(conflict_) {
	return new Promise(function(resolve, reject) {
		var mergedData = __.mergeData(conflict_, CONFIGURATOR_API.getSpecialFamilies() || {}, CONFIGURATOR_API.getItems());
		var templateType = 'conflict-layer';
		var templateString = CONFLICT_TPL || jQuery('#nm-id-' + templateType + '-tpl').html();
		var templateHtml = TPL_RENDERER.render(templateString, mergedData);
		var promise = __.openLayer(templateType, templateHtml, __.debouncedConflictResolveHandler);
		promise.then(resolve, reject);
	});
};
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.addEvents();
};
export {exports as dpuConflict};
