import {appEvents as EVENTS} from 'core-application';
import {CONFIGURATION_STORE_ID} from '../stores/configuration-store';
import {stateRegistry} from 'microkernel';
import {polling} from 'core-utils';
import {sendDataToConfiguratorDataService} from '../configurator-data-service-bridge';
import {fetchImageFromRenderAPI} from './fetch-image-render-api';
import {setupAndThrowError} from './setup-and-throw-error';

const __ = {},
	// Public API
	exports = {
		__: __
	};
let _initialized = false;
/**
 * private exports methods
 */
__.oDefaults = {
	actionsLookup: {
		itemAdd: 'set',
		removeItem: 'remove',
		itemRemove: 'remove'
	}
};
__.configurationStartType = 'default';
__.configurationContinued = false;
__.dataInitialized = false;
// configuration endpoint will be overwriten during initialization
__.configurationEndpoint = 'configuration';
__.dpuExtra = '';
__.storage = {
	/**
	 * @property {Object} configuration - configuration storage
	 */
	configuration: null,
	/**
	 * @property {Object} conflicts - conflicting configuration
	 */
	conflicts: null,
	/**
	 * @property {Object} transfers - trnsferred configuration
	 */
	transfers: null,
	/**
	 * @property {Object} items - all configuration items for the current carline
	 */
	items: null,
	/**
	 * @property {string} defaultPrString - default PrString for the current carline
	 */
	defaultPrString: null,
	/**
	 * @property {Object} header - last DPU response header inclusing (subsessionID,context,version,...)
	 */
	header: null,
	/**
	 * @property {Object} families - item families
	 */
	families: null,
	/**
	 * @property {string} searchstopwords - regEx string for filtering the all stopwords from the user´s search input
	 */
	searchstopwords: null,
	/**
	 *@property {Object} modelsInfo - list of objects containing additional information for all model keys within the current carline
	 */
	modelsInfo: null,
	/**
	 *@property {Object} images - list of images that is returned from the render image API
	 */
	images: null
};

/**
 * method called on configuration reset requests.
 * sends default pr-string to receive the default configuration
 * for this carline,context and version
 * @returns {Promise} - promise containing DPU response
 */
__.sendResetRequest = function() {
	__.checkDefaultSetup();

	let data = {
		context: exports.getContext(),
		ids: __.getDefaultPrString()
	};
	data = __.addDpuExtraData(data);
	data = __.addSubsessionData(data);
	const queryParams = new URLSearchParams(data);
	const baseUrl = exports.getDpuUrl() + __.configurationEndpoint + '?';
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

exports.dispatchPriceMode = function(newMode) {
	const evt = new CustomEvent('setPriceMode', {detail: newMode === 'rate' ? 'RATE' : 'PRICE'});
	document.dispatchEvent(evt);
};

/**
 * change mode for price display
 * @param {string} mode - mode for price/rate display ('price' or 'rate')
 * @returns {Promise} - Promise containing changed data
 */
exports.changeRatePriceMode = function(mode) {
	__.checkDefaultSetup();

	return new Promise(function(resolve, reject) {
		const header = exports.getHeader();
		const headerMode = header && !!header.mode ? header.mode : 'price';

		// Stop here if given mode is already the current mode.
		if (headerMode === mode) {
			reject(new TypeError('Rate/Price mode did not change'));
			return null;
		}

		const newMode = mode === 'rate' ? 'rate' : 'price';
		let data = {
			context: exports.getContext(),
			ids: exports.getPrString(),
			mode: newMode
		};
		data = __.addDpuExtraData(data);

		if (!__.isStatelessMode) {
			data = __.addSubsessionData(data);
		}

		const baseUrl = exports.getDpuUrl() + __.configurationEndpoint + '?';
		const queryParams = new URLSearchParams(data);
		const url = baseUrl + queryParams.toString();

		return fetch(url, {credentials: 'include'})
			.then(response => {
				if (!response.ok) {
					setupAndThrowError({statuscode: response.status});
				}
				else {
					return response.json();
				}
			})
			.then(__.checkDPUresponseStatus)
			.then(
				response => {
					__.setConfigurationRaw(response).then(() => {
						exports.dispatchPriceMode(newMode);
						resolve(true);
					}
					);
				},
				error => {
					reject(new TypeError('Error switching priceRateMode: ' + error.message));
				}
			);
	});
};

/**
 * method called on configuration change requests
 * selection/deselection of configuration items
 * and its count value (if item has an available count)
 * @param {Object} change_ - the desired change
 * @returns {Promise} - containing DPU response
 */
__.sendChangeRequest = function(change_) {
	const baseUrl = exports.getDpuUrl() + __.configurationEndpoint + '?';
	let data = __.generateRequestData(change_);
	data = __.addDpuExtraData(data);
	data = __.addSubsessionData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus)
		.catch(error => {
			if (!error.id) {
				error.id = 'NETWORK_ERROR';
			}
			throw error;
		});
};

/**
 * set additional params for stateless mode
 * @param {Object} params - the orginal params
 * @returns {Object} - params enriched with trigger and choices attributes
 */
__.setStatelessParams = function(params) {
	var conflicts = exports.getConflicts();

	if (__.isStatelessMode && !!conflicts) {
		if (!!conflicts.triggerIds) {
			params.trigger = conflicts.triggerIds;
		}
		if (!!conflicts.choiceIds) {
			params.choices = conflicts.choiceIds;
		}
	}
	return params;
};

/**
 * method to generate a request data object
 * with all the params expected by the DPU
 * @param {Object} change_ - the desired change
 * @returns {Object} params
 */
__.generateRequestData = function(change_) {
	var sessionID = exports.getSubsessionID();
	// add action set||remove
	var action = __.oDefaults.actionsLookup[change_.action] || 'missing_action';
	var itemId = change_.id;
	var params = {
		context: exports.getContext(), // add context (catrline, market etc.)
		ids: exports.getPrString() // add current configuration as PrString
	};

	// add item count if available
	if (!!change_.count) {
		itemId += '*' + change_.count;
	}
	params[action] = itemId;
	params = __.setStatelessParams(params);

	// add a subsession if it exists and not in stateless mode
	if (sessionID && !__.isStatelessMode) {
		params.subsession = sessionID;
	}
	if (__.isDebugMode()) {
		params.dpu_extra = 'messages';
	}
	return params;
};

__.isDebugMode = function() {
	var html = document.querySelector('html');
	var className = 'nm-state-is-debug';

	return new RegExp('(^| )' + className + '( |$)', 'gi').test(html.className);
};

/**
 * @returns {string|null} current version for DPU requests
 */
exports.getVersion = function() {
	var header = exports.getHeader();

	return header && header.version ? header.version : null;
};

/**
 * get current static rendertime of cms
 * @returns {string|null} version number
 */
exports.getStaticVersion = function() {
	var staticVersion = SETUPS.get('nemo.staticversion');

	if (!staticVersion) {
		return null;
	}
	return staticVersion;
};

/**
 * data is initialized (carinfo and version request are completed)
 * @returns {boolean} - whether data is initialized
 */
exports.isInitialized = function() {
	return __.dataInitialized;
};

/**
 * @returns {string|null} memcache version
 */
// eslint-disable-next-line max-statements
exports.getMemcacheVersion = function() {
	var version = '';
	var header = exports.getDpuHeader();
	var taggedVersionAndMarketVersion, static_version, build_timestamp;

	// only for model-pages
	if (!!header) {
		if (!!header.version) {
			version = header.version;
		}

		// return the taggedVersion instead of the marketVersion-version
		if (!!header.taggedVersion) {
			version = header.taggedVersion;
		}
		else {
			// return the marketVersion (= memcache-version) instead of the mbv-version
			if (!!header.marketVersion) {
				version = header.marketVersion;
			}
		}
		taggedVersionAndMarketVersion = !!header.taggedVersion && !!header.marketVersion;

		if (taggedVersionAndMarketVersion && !!header.manualRevision && parseInt(header.manualRevision, 10) > 0) {
			version += '.' + header.manualRevision;
		}
	}
	static_version = exports.getStaticVersion();
	build_timestamp = SETUPS.get('nemo.build.time');

	if (!!build_timestamp && !!static_version && build_timestamp !== static_version) {
		version += '.' + static_version;
	}

	// delete leading .
	if (!!version && version.toString().indexOf('.') === 0) {
		version = version.substring(1);
	}
	return version;
};

/**
 * @returns {string} current context for DPU requests
 */
exports.getContext = function() {
	var header = exports.getHeader();

	return header && header.context ? header.context : null;
};

/**
 * @returns {string} current subsession for DPU requests
 */
exports.getSubsessionID = function() {
	var header = exports.getHeader();

	return header && header.subsession ? header.subsession : null;
};

/**
 * set current subsession after DPU responses
 * @param {string} subsessionID - the subsession id
 * @returns {void}
 */
exports.setSubsessionID = function(subsessionID) {
	__.storage.header.subsession = subsessionID;
	__.updateConfigDataForConsumers();
};

/**
 * @returns {string|null} current configuration prString
 */
exports.getPrString = function() {
	var config = exports.getConfiguration();

	return config && !!config.prstring ? config.prstring : null;
};

/**
 * @returns {string|null} default configuration as prString
 */
__.getDefaultPrString = function() {
	return __.storage.defaultPrString || null;
};

/**
 * store default configuration as prString
 * @param {string} prstring_ - the pr string
 * @returns {void}
 */
__.setDefaultPrString = function(prstring_) {
	__.storage.defaultPrString = prstring_ || null;
	__.updateConfigDataForConsumers();
};

/**
 * handles responses from configuration change requests
 * @param {Object} data - json containing the complete or incremtal configuration
 * @returns {Promise<Boolean>} - Configuration changed
 */
exports.handleConfigurationResponse = function(data) {
	// TODO => check if hash changed??? and return true||false
	return __.setConfigurationRaw(data);
};

/**
 * adopt an externally loaded configuration
 * @param {Object} data - loaded configuration
 * @returns {Promise<Boolean>} - Configuration changed
 */
exports.adoptConfiguration = function(data) {
	return __.setConfigurationRaw(data);
};

/**
 * fix for special prices (e.g. rotr-price in UK)
 * @param {Object} data_ - configuration data to patch
 * @returns {void}
 */
__.setPrice = function(data_) {
	// BUGFIX for on the road prices (UK)
	if (!!data_ && !!data_.configuration && data_.configuration.prices && data_.configuration.prices.rotr) {
		__.storage.configuration.rotrprice = data_.configuration.prices.rotr;
	}
};

/**
 * set the configuration raw data (complete response data including header,items, stopwords etc.)
 * @param {Object} data - the raw config data
 * @returns {Promise<Boolean>} - Configuration changed
 */
// eslint-disable-next-line max-statements
__.setConfigurationRaw = async function(data) {
	let changed = true;
	let oldPrString = exports.getPrString();
	let prstring;

	__.setHeader(data);
	__.setAudicode(data);
	__.setConfiguration(data);
	__.setPrice(data);
	__.setItems(data);
	__.setFamilies(data);
	__.storage.searchstopwords = data.searchstopwords || __.storage.searchstopwords || null;
	__.setConflictsAndTransfers(data);
	__.updateConfigDataForConsumers();

	if (!data.configuration) {
		return false;
	}

	exports.sendEngineDisclaimerLayerEvent();

	if (!__.getDefaultPrString()) {
		prstring = SETUPS.get('nemo.default.prstring') || data.configuration.prstring;
		__.setDefaultPrString(prstring);
	}

	if (oldPrString && oldPrString === exports.getPrString()) {
		changed = false;
	}

	try {
		// get the images from API here
		const images = await fetchImageFromRenderAPI(__.storage.configuration);
		__.setImages(images);
	}
	catch (error) {
		console.error(error);
	}

	return changed;
};

__.ENGINE_DISCLAIMER_LAYER_SHOWN_KEY = 'engine-disclaimer-layer-shown';
__.engineDisclaimerLayerShownNoSessionStorage = {};

__.getEngineDisclaimerLayerShown = function() {
	try {
		const shownMotorsData = window.sessionStorage.getItem(__.ENGINE_DISCLAIMER_LAYER_SHOWN_KEY);
		const shownMotors = JSON.parse(shownMotorsData);

		return !!shownMotors[__.storage.configuration.model];
	}
	catch (e) {
		return !!__.engineDisclaimerLayerShownNoSessionStorage[__.storage.configuration.model];
	}
};

__.setEngineDisclaimerLayerShown = function() {
	try {
		const shownMotorsData = window.sessionStorage.getItem(__.ENGINE_DISCLAIMER_LAYER_SHOWN_KEY);

		let shownMotors;
		try {
			shownMotors = JSON.parse(shownMotorsData) || {};
		}
		catch (e) {
			shownMotors = {};
		}

		shownMotors[__.storage.configuration.model] = true;

		window.sessionStorage.setItem(__.ENGINE_DISCLAIMER_LAYER_SHOWN_KEY, JSON.stringify(shownMotors));
	}
	catch (e) {
		__.engineDisclaimerLayerShownNoSessionStorage[__.storage.configuration.model] = true;
	}
};

exports.sendEngineDisclaimerLayerEvent = async function() {
	const contentElement = document.querySelector('.nm-content');
	const dataTemplate = contentElement
		? contentElement.getAttribute('data-template') : '';
	if (__.getEngineDisclaimerLayerShown() || dataTemplate === "home") {
		return;
	}

	await polling.wait(exports.isInitialized, 5000);

	const configuration = exports.getConfiguration();
	const engineDisclaimer = (!!configuration && !!configuration.model) ? exports.getModelDisclaimer(configuration.model) : null;

	if (!!engineDisclaimer && !!engineDisclaimer.headline && !!engineDisclaimer.text) {
		const dataObject = {
			layerType: 'EngineDisclaimerLayerElement',
			options: {
				onEscClick: false,
				onShaderClick: false,
				centerVertically: true
			},
			dataset: {
				headline: engineDisclaimer.headline,
				message: engineDisclaimer.text
			}
		};

		const modalLayerOpenEvent = new CustomEvent('modalLayer:open', {
			detail: dataObject
		});
		document.dispatchEvent(modalLayerOpenEvent);

		__.setEngineDisclaimerLayerShown();
	}
};

/**
 * set item families
 * @param {Object} data - raw configuration response object
 * @returns {void}
 */
__.setFamilies = function(data) {
	if (!!data && !!data.families) {
		// TODO: jQuery.extend(__.storage.families,data.families);
		__.storage.families = jQuery.extend(true, __.storage.families, data.families);
		__.updateConfigDataForConsumers();
	}
};

/**
 * get item families
 * @returns {Object} families
 */
exports.getFamilies = function() {
	return __.storage.families || null;
};

/**
 * set conflicts and transfers
 * @param {Object} data - raw configuration response object
 * @returns {void}
 */
__.setConflictsAndTransfers = function(data) {
	__.storage.conflicts = data.conflicts || null;
	__.storage.transfers = data.transfers || null;
};

/**
 * set audicode if in payload
 * @param {Object} data - raw configuration response object
 * @returns {void}
 */
__.setAudicode = function(data) {
	__.storage.audicode = !!data.audicode && !!data.audicode.id ? data.audicode.id : false;
};

/**
 * set the configuration
 * @param {Object} data - configuration
 * @returns {void}
 */
__.setConfiguration = function(data) {
	// merge new configuration into existing configuration (no deep Copy!!!)
	__.storage.configuration = jQuery.extend(false, __.storage.configuration, data.configuration);
};

__.setImages = function(data){
	__.storage.images = jQuery.extend(false, __.storage.images, data);
};

/**
 * set the header
 * @param {Object} data - header
 * @returns {void}
 */
__.setHeader = function(data) {
	var version;

	if (!!data && !!data.header) {
		version =
			!!__.storage && !!__.storage.header && !!__.storage.header.version
				? {version: __.storage.header.version}
				: {};
		__.storage.header = jQuery.extend(true, __.storage.header, data.header, version);
	}
};

/**
 * set all existing items for this carline
 * @param {Object} data_ - raw response data
 * @returns {void}
 */
// eslint-disable-next-line max-statements, complexity
__.setItems = function(data_) {
	var i, j;

	// itemsUpdateMode='full'||'incremental'
	if (data_.itemsUpdateMode === 'full' && !!data_.items) {
		// full update => set status of all previous items to "00000"
		for (i in __.storage.items) {
			// eslint-disable-next-line no-prototype-builtins
			if (__.storage.items.hasOwnProperty(i) && !!__.storage.items[i].status) {
				// set item status to unselected
				__.storage.items[i].status = '00000';
			}
		}
	}
	else {
		console.log('DPU_MODEL.setItems::incrmental update…');
		// incremental update => overwrite existing properties and add new
		for (j in data_.items) {
			if (!data_.items[j].packageDescription) {
				data_.items[j].packageDescription = '';
			}
		}
	}
	for (j in data_.items) {
		if (data_.items[j].subname) {
			data_.items[j].subname = '(' + data_.items[j].subname + ')';
		}
		else {
			data_.items[j].subname = '';
		}
	}
	// merge new items into existing items
	__.storage.items = jQuery.extend(true, __.storage.items, data_.items);
	// cleanup items ===============
	for (i in __.storage.items) {
		// eslint-disable-next-line no-prototype-builtins
		if (__.storage.items.hasOwnProperty(i)) {
			if (__.storage.items[i].status === '11001' || __.storage.items[i].status === '10011') {
				// set localized String 'Series' for
				__.storage.items[i].price = window.i18n ? window.i18n.standard || '***Serie***' : '***Serie***';
			}
		}
	}
};

/**
 * loads version and returns a promise for callback-handling
 * @returns {Promise} - containing request result
 */
__.sendVersionRequest = function() {
	const baseUrl = exports.getDpuUrl() + 'version?';
	const carline = exports.getCarline();

	// stop calls on pages without carline (e.g. homepage)
	if (!carline) {
		return Promise.reject(new TypeError('no carline defined for version request'));
	}
	let data = {
		context: exports.getContext(),
		carline: carline
	};
	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams;

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * handle returned version from initial DPU request
 * stores version params (included in the response header) for all following requests
 * triggers VERSION_LOAD_COMPLETE
 * @param {Object} response - the version response
 * @returns {void}
 */
__.handleVersionResponse = function(response) {
	var headerItems = response.items;
	var hiddenItem,
		hiddenItems = [];

	if (!!headerItems) {
		for (hiddenItem in headerItems) {
			// eslint-disable-next-line no-prototype-builtins
			if (headerItems.hasOwnProperty(hiddenItem) && headerItems[hiddenItem].hidden === true) {
				hiddenItems.push(hiddenItem);
			}
		}
	}
	__.storage.header = response.header;
	__.storage.headerItems = hiddenItems;
	__.updateConfigDataForConsumers();

	__.eventBus.emit(EVENTS.VERSION_LOAD_COMPLETE);
};

/**
 * loads static base configuration (carinfo.json) for the current carline
 *  and returns a Promise for callback-handling
 * @returns {Promise} - containing car info response
 */
__.sendCarinfoRequest = function() {
	const url = __.getCarinfoUrl();
	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * hande carinfo.json response
 * set initial items
 * @param {Object} response - JSON response from DPU request
 * @returns {void}
 */
__.handleCarinfoResponse = function(response) {
	console.log('handleCarinfoResponse', response, exports.isConfigurationStarted());
	// ignore carinfo response if another configuration has been started during loading process
	if (!exports.isConfigurationStarted()) {
		return exports.handleConfigurationResponse(response);
	}
	else {
		// update items
		__.setFamilies(response);
		__.updateItemsFromCarinfo(response.items);
	}

	__.eventBus.emit(EVENTS.CARINFO_LOAD_COMPLETE);
	return Promise.resolve(false);
};

/**
 * loads static base configuration (<carline>.modelsinfo.json) for the current carline
 *  and returns a Promise for callback-handling
 * @returns {Promise} - containing models info response
 */
__.sendModelsinfoRequest = function() {
	const url = __.getModelsinfoUrl();
	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * hande carinfo.json response
 * set initial items
 * @param {Object} response - JSON response from DPU request
 * @returns {void}
 */
__.handleModelsinfoResponse = function(response) {
	console.log('handleModelsinfoResponse', response, exports.isConfigurationStarted());
	__.storage.modelsInfo = response.models || null;
	__.storage.modelsInfoFootnotes = response.footnotes || null;
	__.storage.modelsInfoSectionFootnotes = response.sectionFootnotes || null;
	__.storage.modelsInfoDisclaimers = response.disclaimers || null;
	__.updateConfigDataForConsumers();
	__.eventBus.emit(EVENTS.MODELSINFO_LOAD_COMPLETE);
};

/**
 * get ModelInfo
 * @param {string }model_ - optional param to return the Info of a specific Model
 * @returns {Object} - the Info of a specific model (of param model_ is set) otherwise all items
 */
exports.getModelsInfo = function(model_) {
	var models, model;

	if (!model_) {
		return __.storage.modelsInfo || null;
	}
	else {
		models = __.storage.modelsInfo;
		model = !!models ? models[model_] : null;
		return model || null;
	}
};

exports.getModelsInfoFootnotes = (model_) => {
	if (!model_) {
		return __.storage.modelsInfoFootnotes || null;
	}
	else {
		return __.storage.modelsInfoFootnotes && __.storage.modelsInfoFootnotes[model_]
			? __.storage.modelsInfoFootnotes[model_]
			: null;
	}
};

exports.getModelsInfoSectionFootnotes = (model_) => {
	if (!model_) {
		return __.storage.modelsInfoSectionFootnotes || null;
	}
	else {
		return __.storage.modelsInfoSectionFootnotes && __.storage.modelsInfoSectionFootnotes[model_]
			? __.storage.modelsInfoSectionFootnotes[model_]
			: null;
	}
};

/**
 * Returns the first model disclaimer for the passed model, if available.
 * @param {string} model_ - The model identifier.
 * @returns {Object|null} the model disclaimer object if available, null otherwise.
 */
exports.getModelDisclaimer = (model_) => {
	if (!!model_) {
		const modelDisclaimers = __.storage.modelsInfoDisclaimers ? __.storage.modelsInfoDisclaimers[model_] : null;
		if (!!modelDisclaimers && Array.isArray(modelDisclaimers) && modelDisclaimers.length > 0) {
			return modelDisclaimers[0];
		}
	}
	return null;
};

/**
 * update Items if carinfo has not been loaded before configurazion start
 * @param {Object} carinfoItems - the carinfo items
 * @returns {void}
 */
__.updateItemsFromCarinfo = function(carinfoItems) {
	var storedItems = jQuery.extend(true, {}, exports.getItems());

	if (!carinfoItems) {
		console.log('Carinfo Items missing!');
		return false;
	}
	// replace items with carinfoItems
	__.storage.items = carinfoItems;
	// merge stored items into carinfo items
	__.storage.items = jQuery.extend(true, __.storage.items, storedItems);
	__.updateConfigDataForConsumers();
};

/**
 * @returns {string} url - url to the Carinfo.json
 */
__.getCarinfoUrl = function() {
	var url = SETUPS.get('nemo.url.carinfo');

	if (!url) {
		return null;
	}
	url = url.split('.json').join('.' + exports.getMemcacheVersion() + '.json');
	return url;
};

/**
 * @returns {string} url - url to the <crline>.modeslinfo.json
 */
__.getModelsinfoUrl = function() {
	var url = SETUPS.get('nemo.url.modelsinfo');

	if (!url) {
		return null;
	}
	url = url.split('.json').join('.' + exports.getMemcacheVersion() + '.json');
	return url;
};

__.setLoginState = function(state) {
	var header = exports.getHeader();

	if (header) {
		header['logged-in'] = state;
	}
};

/**
 * generate carstore requests (list,load,delete)
 * @param {string} actionStr - The `action` to perform. One of 'list', 'load' or 'delete'
 * @param {Object} params - params for request (slot: number|string for item)
 * @returns {Promise} - DPU response
 */
exports.sendCarstoreRequest = function(actionStr = '', params = {}) {
	__.checkDefaultSetup();
	const validActions = ['list', 'delete', 'load'];

	if (validActions.indexOf(actionStr) === -1) {
		return Promise.reject(new TypeError('sendCarestoreRequest: invalid actionStr'));
	}

	if (!params.slot) {
		params.slot = null;
	}

	const baseUrl = exports.getDpuUrl() + 'carstore?';
	const defaultData = {
		action: actionStr,
		context: exports.getContext(),
		subsession: exports.getSubsessionID()
	};
	let data = Object.assign({}, defaultData, params);
	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * get current configuration filtered from given pr numbers
 * @param {Array} prNumbersToFilter - the pr numbers to exclude from result
 * @returns {Array} ids of filtered current configuration
 */
__.getFilteredPrNumbersFromCurrentConfig = function(prNumbersToFilter) {
	var origIds = exports.getPrString().split('|');
	var i,
		j,
		idLength,
		prLength,
		unmatched,
		filteredIds = [];

	for (i = 0, idLength = origIds.length, prLength = prNumbersToFilter.length; i < idLength; i++) {
		unmatched = true;

		for (j = 0; j < prLength; j++) {
			if (origIds[i].indexOf(prNumbersToFilter[j]) > -1) {
				unmatched = false;
			}
		}

		if (unmatched === true) {
			filteredIds.push(origIds[i]);
		}
	}
	return filteredIds;
};

/**
 * send request to stateless render service
 * @param {Array} prNumbersToFilter - pr numbers not to include in config for call
 * @param {string} renderPrNr - pr number to render
 * @param {string} renderViews - render views separated by ,
 * @param {string} renderSizes - renderSizes separated by ,
 * @returns {Promise} - render service call result as promise
 */
exports.sendRenderServiceRequest = function(prNumbersToFilter, renderPrNr, renderViews, renderSizes) {
	__.checkDefaultSetup();
	const baseUrl = exports.getDpuUrl() + 'stateless-render-service?';
	const filteredIds = __.getFilteredPrNumbersFromCurrentConfig(prNumbersToFilter);
	let data = {
		context: exports.getContext(),
		ids: filteredIds.join('|'),
		'render-prnrs': renderPrNr,
		'render-views': renderViews,
		'render-sizes': renderSizes
	};
	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * hgenerate carstore requests (list,load,delete)
 * @param {Object} params_ - params for request (slot: number|string for item)
 * @returns {Promise} DPU response
 */
exports.sendCarstoreHistoryRequest = function(params_) {
	__.checkDefaultSetup();
	const baseUrl = exports.getDpuUrl() + 'history?';
	let params = params_;

	// itemID to be changed
	if (!params_ || params === '' || params === undefined) {
		params = {};
	}
	if (!params.slot || params.slot === '' || params.slot === undefined) {
		params.slot = null;
	}
	let data = {
		context: exports.getContext(),
		subsession: exports.getSubsessionID()
	};

	data = Object.assign(data, params);
	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * set myAudi Login
 * @param {boolean} loginState - the login state
 * @param {string} subsessionID - the subsession id
 * @returns {Promise} loginPromise
 */
exports.setLogin = function(loginState, subsessionID) {
	return new Promise(function(resolve, reject) {
		if (typeof loginState === 'boolean' && !!subsessionID) {
			exports.setSubsessionID(subsessionID);
			__.setLoginState(loginState);
			resolve(loginState);
		}
		else {
			__.setLoginState(false);
			reject(new TypeError('login failure'));
		}
	});
};

/**
 * send a configuration request (set, remove etc.) to the DPU Service
 * @param {Object} change_ - object containing id,action,count of the selected item
 * @returns {Promise} - DPU response
 */
exports.requestConfigurationChange = function(change_) {
	// Return a new promise.
	return new Promise(function(resolve, reject) {
		let requestPromise = __.sendChangeRequest(change_);
		requestPromise.then(
			function(result) {
				resolve(exports.handleConfigurationResponse(result));
			},
			function(error) {
				reject(error);
			}
		).catch(error => { throw new Error(error); });
	});
};

/**
 * send a configuration reset request
 * (sends default pr-string,version and context without subsessionID)
 * to the DPU Service
 * @returns {Promise} - DPU response
 */
exports.requestConfigurationReset = function() {
	// Return a new promise.
	return new Promise(function(resolve, reject) {
		let requestPromise = __.sendResetRequest();

		requestPromise.then(
			function(result) {
				return resolve(exports.handleConfigurationResponse(result));
			},
			function(error) {
				reject(error);
			}
		);
	});
};

/**
 * set item´s selection method (tracking indicator)
 * @param {string} id_ - prnumber
 * @param {string} selectionMethod_ -selection methos ('pre','active','conflict')
 * @returns {void}
 */
exports.setItemSelectionMethod = function(id_, selectionMethod_) {
	var items = exports.getItems();

	if (items && !!items[id_]) {
		if (!!selectionMethod_) {
			items[id_].selectionMethod = selectionMethod_;
		}
		else {
			// clear selectionMethod attribute
			items[id_].selectionMethod = null;
		}
	}
};

/**
 * resolve a transfer or conflict
 * (sends accept - or cancelURL,
 * version and context and subsessionID)
 * to the DPU Service
 * @param {string} baseUrl - the url to call
 * @returns {Promise} - DPU response
 */
exports.requestTransferOrConflictResolve = function(baseUrl) {
	// Return a new promise.
	return new Promise(function(resolve, reject) {
		const url = __.addDpuExtraParam(baseUrl);
		return fetch(url, {credentials: 'include'})
			.then(response => {
				if (!response.ok) {
					setupAndThrowError({statuscode: response.status});
				}
				else {
					return response.json();
				}
			})
			.then(__.checkDPUresponseStatus)
			.then(response => resolve(exports.handleConfigurationResponse(response)), error => { reject(error); });
	});
};

/**
 * load a configuration by an audiCode
 * (sends context and audicode)
 * from the DPU Service
 * @param {string} audiCode_ - the audi code
 * @returns {Promise} - DPU response
 */
exports.loadConfigurationByAudiCode = function(audiCode_) {
	__.checkDefaultSetup();
	const baseUrl = exports.getDpuUrl() + 'audicode?';
	let audiCode = audiCode_;

	if (typeof audiCode !== 'undefined') {
		audiCode = audiCode.toUpperCase();
	}
	let data = {
		context: exports.getContext(),
		audicode: audiCode
	};
	data = __.addDpuExtraData(data);
	data = __.addSubsessionData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * load a configuration by an audiCode
 * from the stateless DPU Service
 * sends context and audicode
 * does not send subsession
 * @param {string} audiCode_ - the audi code
 * @returns {Promise} - DPU response
 */
exports.loadConfigurationByAudiCodeStateless = audiCode_ => {
	if (!audiCode_) {
		return;
	}

	const baseUrl = exports.getDpuUrl() + 'stateless-audicode?';
	const data = __.addDpuExtraData({
		context: exports.getContext(),
		audicode: audiCode_.toUpperCase()
	});
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'omit'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

exports.generateAudiCode = function(params_) {
	__.checkDefaultSetup();
	const baseUrl = exports.getDpuUrl() + 'audicode?';
	let params = params_;

	if (!params || params === '' || params === undefined) {
		params = {};
	}

	// return Promise
	let data = {
		context: exports.getContext(),
		ids: exports.getPrString(),
		subsession: exports.getSubsessionID()
	};
	data = Object.assign(data, params);
	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				return response.json();
			}
		})
		.then(__.checkDPUresponseStatus);
};

/**
 * load a configuration by a strig auf pr numbers
 * (sends context and ids)
 * from the DPU Service
 * @param {string} prString - the pr string
 * @param {subsession|null} subsession - optional subsessionID from previous configuration
 * @returns {Promise} - DPU response
 */
exports.loadConfigurationByPrString = function(prString, subsession) {
	__.checkDefaultSetup();
	// Returns a new promise.
	const baseUrl = exports.getDpuUrl() + __.configurationEndpoint + '?';
	let data = {
		context: exports.getContext(),
		ids: prString
	};

	// add subsession if available
	if (!!subsession && !__.isStatelessMode) {
		data.subsession = subsession;
	}

	data = __.addDpuExtraData(data);
	const queryParams = new URLSearchParams(data);
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				setupAndThrowError({statuscode: response.status});
			}
			else {
				const dataInner = response.json();
				dataInner.then(responseInner => {
					__.setConfiguration(responseInner);
					const DPU_EVENT = new CustomEvent('DPU_DATA_RECIEVED', {});
					document.dispatchEvent(DPU_EVENT);
				});

				return dataInner;
			}
		})
		.then(__.checkDPUresponseStatus)
		.catch(error => {
			// Test&Target
			if (error.id === 'PRSTRING_MODEL_NOT_FOUND') {
				const tNtEvent = new CustomEvent('TNT_ERROR', {
					detail: {
						errorCode: error.id,
						prString: prString
					}
				});
				document.dispatchEvent(tNtEvent);
			}
			throw error;
		});
};

/**
 * @returns {Object} header - last dpu response´s header
 */
exports.getHeader = function() {
	return __.storage.header || null;
};

/**
 * @returns {Array} hidden items from version request
 */
exports.getHiddenItemIDsFromVersion = function() {
	return __.storage.headerItems || null;
};

/**
 * @returns {string} regex string - the regEx string for filtering the all stopwords from the user input
 */
exports.getSearchStopWords = function() {
	return __.storage.searchstopwords || null;
};

/**
 * removes transfer object after a transfer was resolved (accepted or cancelled)
 * @returns {void}
 */
exports.transferCompleted = function() {
	__.storage.transfers = null;
	__.updateConfigDataForConsumers();
};

/**
 * chain of requests (getVersion request followed by getCarinfo request)
 * for the base initialization of data.
 * 'dpu-url' param is needed for the 'getCarinfo' request following the 'getVersion'
 * @returns {Promise} - DPU response
 * @example
 // fire initialization request with new version and context
 MODEL.fireDataInitializationChain(setup);
 */
exports.fireDataInitializationChain = function() {
	__.dataInitialized = false;
	__.checkDefaultSetup();

	return __.sendVersionRequest()
		.then(__.handleVersionResponse)
		.then(__.getSignedLink)
		.then(__.sendCarinfoRequest)
		.then(__.handleCarinfoResponse)
		.then(__.sendModelsinfoRequest)
		.then(__.handleModelsinfoResponse)
		.then(function() {
			__.dataInitialized = true;
		});
};

/**
 * @returns {Object} conflict - object containing triggers ans solutions to a conflictt
 */
exports.getConflicts = function() {
	return __.storage.conflicts || null;
};

exports.getDpuHeader = function() {
	return __.storage.header || null;
};

/**
 * @returns {Object} transfer - object conatining all transfer infos
 */
exports.getTransfers = function() {
	return __.storage.transfers || null;
};

/**
 * @returns {array} array - recomedations array
 */
exports.getRecommendations = function() {
	return __.storage.configuration.recommendations || [];
};

/**
 * @returns {string | false} audicode or false if not existing
 */
exports.getAudicode = function() {
	return __.storage.audicode || false;
};

/**
 * @returns {Object | null} items - object containing complete configuration(assets, items, conflicts, transfers, recommendations, ...)
 */
exports.getConfiguration = function() {
	return __.storage.configuration || null;
};

/**
 * @returns {Object | null} complete storage object
 */
exports.getRawConfigurationData = function() {
	return __.storage || null;
};

/**
 * @returns {Object | null} items - object with all items
 * for the current carline(key: prNumber = > value: item)
 */
exports.getItems = function() {
	return __.storage.items || null;
};

/**
 * @returns {Object|null} images
 */
exports.getImages = function() {

	return __.storage.images || null;
};

/**
 * @returns {string} carlinename
 */
exports.getCarline = function() {
	var carline = null;
	var configuration = exports.getConfiguration();

	// if configuration is available
	if (configuration && !!configuration.carline) {
		carline = configuration.carline;
	}
	else {
		/*
		 * if configuration is not yet available (e.g. before getCarinfo)
		 * read carline from SETUPS (defined inline via CQ)
		 */
		carline = SETUPS.get('nemo.default.carline') || null;
	}
	return carline;
};

/**
 * @returns {string | null} url - url to the DPU - Service
 */
exports.getDpuUrl = function() {
	return SETUPS.get('nemo.url.dpu') || null;
};

exports.isConfigurationStarted = function() {
	return __.configurationStartType && __.configurationStartType !== 'default';
};

/**
 * get item families
 * @returns {Object | null} list - object containing all families
 */
exports.getFamilies = function() {
	return __.storage.families || null;
};

/**
 * @returns {boolean} - has the user started a configuration other than the default ?
 * @default false
 */
exports.hasCustomConfiguration = function() {
	return exports.isConfigurationStarted() && __.getDefaultPrString() !== exports.getPrString();
};

/**
 * has the configuration been continued after the configuration start
 * @returns {boolean} - whether configuration has been continued
 */
exports.isContinuedConfiguration = function() {
	return __.configurationContinued;
};

/**
 * call signed link for preview mode sessionAuth
 * @returns {Promise} - DPU response
 */
__.getSignedLink = function() {
	const signedPreviewUrl = SETUPS.get('nemo.url.preview');

	if (!!signedPreviewUrl) {
		return fetch(signedPreviewUrl, {credentials: 'include'})
			.then(response => {
				if (!response.ok) {
					setupAndThrowError({statuscode: response.status});
				}
				else {
					return response.json();
				}
			})
			.then(__.checkDPUresponseStatus)
			.then(
				response => __.setConfigurationRaw(response),
				error => console.warn('loading signedPreviewUrl failed: ' + error.message)
			);
	}
	else {
		return Promise.resolve('no signedPreviewUrl available');
	}
};

/**
 * switch carline, reset configuration and initailize with new Carline data
 * without page reload (=> MSSC)
 * @param {string} carline_ - carline name
 * @param {string} carinfoUrl_ - url for the carinfo.json
 * @param {string} modelsInfoUrl_ - url for the modelsinfo.json
 * @returns {Promise} - init chain result
 */
exports.switchCarline = function(carline_, carinfoUrl_, modelsInfoUrl_) {
	__.checkDefaultSetup();
	let context, data;

	if (!!carinfoUrl_ && !!carline_) {
		// set SETUP values
		SETUPS.set('nemo.default.carline', carline_);
		SETUPS.set('nemo.url.carinfo', carinfoUrl_);
		SETUPS.set('nemo.url.modelsinfo', modelsInfoUrl_);
		// store previous context
		context = exports.getContext();
		// reset configuration
		data = {
			configuration: {
				carline: carline_
			},
			header: {
				context: context
			}
		};
		__.clearConfigurationData(data);
		return exports.fireDataInitializationChain();
	}
	else {
		return Promise.reject('switchCarline: missing params for initialization!');
	}
};

/**
 * reset configuration data
 * @param {Object} data_ - optional initialization data
 * @returns {void}
 */
__.clearConfigurationData = function(data_) {
	var item;

	__.configurationContinued = false;
	__.configurationStartType = 'default';
	// clear storage
	__.clearStorage();
	// set initial values (optional)
	if (!!data_ && !!data_) {
		for (item in data_) {
			if (data_[item]) {
				__.storage[item] = data_[item];
			}
		}
	}
	__.updateConfigDataForConsumers();
};

/**
 * clear all stored objects
 * @returns {void}
 */
__.clearStorage = function() {
	__.storage = {
		configuration: null,
		conflicts: null,
		transfers: null,
		items: null,
		defaultPrString: null,
		header: null,
		families: null,
		searchstopwords: null
	};
};
/**
 * set configuration startType
 * @param {string} startType_ - configuration start type
 * @returns {void}
 */
exports.setConfigurationStartType = function(startType_) {
	// user started a configuration by avtively changing an item/clicking item
	if (exports.getConfigurationStartType() === 'default' && startType_ === 'configuration-change') {
		__.configurationContinued = false;
		__.configurationStartType = startType_;
		__.eventBus.emit(EVENTS.CONFIG_START, __.configurationStartType);
		return;
	}
	if (startType_ !== 'configuration-change') {
		__.configurationStartType = startType_;
		__.configurationContinued = false;
		__.eventBus.emit(EVENTS.CONFIG_START, __.configurationStartType);
	}
	else {
		// configuration was not started but continued by the user
		__.configurationContinued = true;
	}
};
/*
 * check DPU response for valid header status
 * @param {Object} data - DPU response in JSON format
 * @returns {Object} - the initial DPU response
 */
__.checkDPUresponseStatus = function(data) {
	if (!!data.header && !!data.header.statuscode) {
		const sCode = Math.floor(data.header.statuscode / 100);
		if (sCode === 3 || sCode === 5) {
			setupAndThrowError(data);
		}
	}
	return data;
};


/**
 * get configuration startType
 * @default {string} 'default'
 * @returns {string} - the configuration start type
 */
exports.getConfigurationStartType = function() {
	return __.configurationStartType;
};
/**
 * reset all configuration values if none available
 * for basic interaction with the DPU (e.g. testDriveForm on the startpage)
 * @param {Object} data - base data (configuration, header,prstring)
 * @returns {void}
 */
exports.resetToDefaultSetupParams = function() {
	var baseParams = {
		prstring: SETUPS.get('nemo.default.prstring'),
		header: SETUPS.get('nemo.default.header'),
		configuration: SETUPS.get('nemo.default.configuration'),
		items: SETUPS.get('nemo.minimal.carinfoitems') || {}
	};

	__.clearConfigurationData(baseParams);
};
/**
 * check and reset deafult SETUPS on initialization
 * @return {void} nothing
 */
__.checkDefaultSetup = function() {
	if (!_initialized) {
		__.isStatelessMode = SETUPS.get('scope.nemo.configuration.stateless');
		__.configurationEndpoint = __.isStatelessMode ? 'stateless-configuration' : 'configuration';
		_initialized = true;
		// set initial configuration Items via SETUPS
		exports.resetToDefaultSetupParams();
	}
};

exports.setDpuExtra = function(dpuExtra) {
	__.dpuExtra = dpuExtra;
	if (!!window.sessionStorage) {
		if (!!dpuExtra) {
			window.sessionStorage.setItem('dpu_extra', dpuExtra);
		}
		else {
			window.sessionStorage.removeItem('dpu_extra');
		}
	}
};

exports.getDpuExtra = function() {
	let dpuExtra = '';
	if (!!__.dpuExtra) {
		dpuExtra = __.dpuExtra;
	}
	else if (__.isDebugMode()) {
		dpuExtra = 'messages';
	}
	return dpuExtra;
};

__.updateConfigDataForConsumers = function() {
	stateRegistry.triggerAction(CONFIGURATION_STORE_ID, 'update', __.storage);

	sendDataToConfiguratorDataService(__.storage);
};

__.restoreDpuExtra = function() {
	if (!!window.sessionStorage) {
		__.dpuExtra = window.sessionStorage.getItem('dpu_extra');
	}
};

__.addDpuExtraData = function(data) {
	const dpuExtra = exports.getDpuExtra();
	if (dpuExtra) {
		data.dpu_extra = dpuExtra;
	}
	return data;
};

__.addDpuExtraParam = function(url) {
	const dpuExtra = exports.getDpuExtra();
	if (dpuExtra) {
		if (url.indexOf('?') >= 0) {
			return url + '&dpu_extra=' + dpuExtra;
		}
		else {
			return url + '?dpu_extra=' + dpuExtra;
		}
	}
	return url;
};

__.addSubsessionData = function(data) {
	const subsession = exports.getSubsessionID();
	if (subsession) {
		data.subsession = subsession;
	}
	return data;
};

exports.resetAudicodeRescueInfo = () => {
	__.storage.configuration['audicode-rescue-info'] = undefined;
	__.updateConfigDataForConsumers();
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_READY, exports.sendEngineDisclaimerLayerEvent);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.checkDefaultSetup();
	__.restoreDpuExtra();
	__.addEvents();
};
export {exports as dpuModel};
