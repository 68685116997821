const template =`{{~ it.cars :car :index }}
<input type="radio" id="nm-basket-matching-list-car{{=index+1}}" name="nm-basket-matching-list-slider" class="nm-basket-matching-list-slider" data-index="{{=index}}" {{? index === 0}}checked="checked" {{?}} />
{{~}}
<label class="nm-basket-toggle-widgets-label-fullarea" for="nm-basket-toggle-widgets"></label>
<label class="nm-basket-toggle-widgets-label" for="nm-basket-toggle-widgets">
	<span class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">{{=window.i18n['basket.matching.headline'] || "Verfügbare Neuwagen"}}</span>
	<svg class="nm-icon-arrow">
		<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
	</svg>
</label>
<div class="nm-basket-matching-wrap">
	<div class="nm-basket-matching-car-slider">

		<div class="nm-basket-matching-next"></div>
		<div class="nm-basket-matching-prev"></div>
		<div class="nm-basket-matching-counter nm-el-lbl nm-el-lbl-01">
			<span class="nm-basket-matching-counter-current">1</span>/<span class="nm-basket-matching-counter-total">{{=it.totalCount}}</span>
		</div>
		<ul>
			{{~ it.cars :car :index }}
				<li data-index="{{=index}}">
					<div class="nm-basket-matching-car-container">
						<a class="nm-layerLink" href="{{=car.link}}">
							<div {{? car.image !== 'undefined' && car.image !== '' }} style="background-image: url({{=car.image}});" class="nm-basket-matching-car-image" {{??}} class="nm-basket-matching-car-image nm-basket-matching-car-fallback" {{?}} ></div>
						</a>
					</div>
					<a class="nm-layerLink" href="{{=car.link}}">
						<div class="nm-basket-matching-car-info">
							<span class="nm-basket-matching-car-model nm-el-lbl nm-el-lbl-01 nm-at-lbl-b">{{=car.model}}</span>
							<span class="nm-basket-matching-car-description nm-el-lbl nm-el-lbl-01">{{=car.engine}}</span>
							<span class="nm-basket-matching-car-price nm-el-lbl nm-el-lbl-01">{{=car.price}}</span>

							<div class="nm-basket-matching-circle" data-progress="{{=car.percentage}}">
								<style>
								{{var circle_degree = parseInt(car.percentage * 1.8);}}
								{{var circle_degree_fix = parseInt(circle_degree * 2);}}
								{{var full_percentage = car.percentage<50 ? 0 : car.percentage;}}

									.nm-basket-matching-circle .circle-{{=index}} .mask.full .fill {
										clip: rect(0px, 25px, {{=full_percentage}}px, 0px);
									}
									.nm-basket-matching-circle .circle-{{=index}} .mask.full, .nm-basket-matching-circle .circle-{{=index}} .mask .fill {
									    -webkit-transform: rotate({{=circle_degree}}deg);
									    -ms-transform: rotate({{=circle_degree}}deg);
									    transform: rotate({{=circle_degree}}deg);
									    animation: fill-{{=index}}  ease-in-out 3s;
									}
									.nm-basket-matching-circle .circle-{{=index}} .mask .fill.fix {
									    -webkit-transform: rotate({{=circle_degree_fix}}deg);
									    -ms-transform: rotate({{=circle_degree_fix}}deg);
									    transform: rotate({{=circle_degree_fix}}deg);
									    animation: fill-fix-{{=index}} ease-in-out 3s;
									}
									.nm-basket-matching-circle .circle-{{=index}} .mask.full, .nm-basket-matching-circle .circle-{{=index}} .mask.full .fill {
										border-radius: 0;
									}
									@keyframes fill-{{=index}}  {
										0% {
										    -webkit-transform: rotate(0deg);
										    -ms-transform: rotate(0deg);
											transform: rotate(0deg);
										}
										100% {
										    -webkit-transform: rotate({{=circle_degree}}deg);
										    -ms-transform: rotate({{=circle_degree}}deg);
											transform: rotate({{=circle_degree}}deg);
										}
									}
									@keyframes fill-fix-{{=index}}  {
										0% {
										    -webkit-transform: rotate(0deg);
										    -ms-transform: rotate(0deg);
											transform: rotate(0deg);
										}
										100% {
										    -webkit-transform: rotate({{=circle_degree_fix}}deg);
										    -ms-transform: rotate({{=circle_degree_fix}}deg);
											transform: rotate({{=circle_degree_fix}}deg);
										}
									}
								</style>
								<div class="outer-circle"></div>
								<div class="circle-{{=index}}">
									<div class="mask full">
										<div class="fill"></div>
									</div>
									<div class="mask half">
										<div class="fill"></div>
										<div class="fill fix"></div>
									</div>
									<div class="inner-circle">{{=car.percentage}}%</div>
								</div>
							</div>
						</div>
					</a>
				</li>
			{{~}}
		</ul>
	</div>
	<div class="nm-basket-matching-list-small-wrap">
		<div class="nm-basket-matching-list-small">
			<ul>
				{{~ it.cars :car :index }}
					<li>
						<label for="nm-basket-matching-list-car{{=index+1}}" {{? !!car.image }} style="background-image: url({{=car.image}});" {{??}} class="nm-basket-matching-car-fallback" {{?}} data-index="{{=index}}"></label>
					</li>
				{{~}}
			</ul>
		</div>
	</div>
	<div class="nm-basket-matching-more-btn">
		<a href="{{=it.result_link}}" class="nm-el-btn nm-el-btn-01 nm-el-btn-ast nm-at-btn-inv"><span>{{=window.i18n['basket.matching.button.label'] || "Weitere Neuwagen"}}</span></a>
	</div>
</div>`;
export {template as scsMatchingBasketTemplate};
