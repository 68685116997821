import {AUDICODE_STORE_ID} from './stores/audicode-store';
import {USERDATA_STORE_ID} from './stores/userdata-store';
import {stateRegistry} from 'microkernel';

export default class LoginLink extends HTMLElement {
	constructor() {
		super();
		this.updateTargetParam = this.updateTargetParam.bind(this);
		this.handleLoginStatus = this.handleLoginStatus.bind(this);
		this.handleLogoutStatus = this.handleLogoutStatus.bind(this);
		this.logoutHandler = this.logoutHandler.bind(this);
		this.showLoginLink = this.showLoginLink.bind(this);
		this.showLogoutLink = this.showLogoutLink.bind(this);
		this.hideHeader = this.hideHeader.bind(this);
		this.showHeader = this.showHeader.bind(this);
		this.csrfToken = null;
	}

	connectedCallback() {
		this.loginLinkElement = this.querySelector('.myaudi-login__link');
		this.logoutLinkElement = this.querySelector('.myaudi-logout__link');
		this.logoutLinkElement.addEventListener('click', this.logoutHandler);
		stateRegistry.subscribeToStore(AUDICODE_STORE_ID, this.updateTargetParam);
		this.getLoginStatus();
		this.updateTargetParam();
		this.loginHeadline = document.querySelector('.audi-login-headline-container');
	}

	disconnectedCallback() {
		this.logoutLinkElement.removeEventListener('click', this.logoutHandler);
	}

	updateTargetParam(state) {
		const loginLinkElement = this.querySelector('.myaudi-login__link');
		const loginUrl = new URL(loginLinkElement.getAttribute('href'));
		const targetUrl = new URL(window.location.href);

		if (state && !!state.audicode) {
			targetUrl.searchParams.set('audicode', state.audicode);
		}

		loginUrl.searchParams.set('target_url', targetUrl.href);
		loginLinkElement.setAttribute('href', loginUrl.href);
	}

	handleLoginStatus(loginStatusData) {
		if (loginStatusData.loggedIn) {
			this.csrfToken = loginStatusData.csrfToken;
			stateRegistry.triggerAction(USERDATA_STORE_ID, 'update', loginStatusData);
			this.showLogoutLink();
		}
		else {
			this.showLoginLink();
		}
	}

	handleLogoutStatus(logoutStatusData) {
		if (logoutStatusData.logoutDone) {
			this.csrfToken = null;
			stateRegistry.triggerAction(USERDATA_STORE_ID, 'logout');
			this.showLoginLink();
		}
		else {
			this.showLogoutLink();
		}
	}

	getLoginStatus() {
		const loginStatusUrl = SETUPS.get('myaudi.uds.url') + 'loginStatus';
		fetch(loginStatusUrl, {credentials: 'include'})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				else {
					throw new Error(response.statusText);
				}
			})
			.then(this.handleLoginStatus)
			.then(this.updateTargetParam)
			.catch(error => console.log('netzwerk problem/performance problem: ', error));
	}

	logoutHandler(event){
		event.preventDefault();
		const logoutUrl = SETUPS.get('myaudi.uds.url') + 'logout';
		const options = {
			credentials: 'include',
			headers: {
				'X-CSRF-Token': this.csrfToken
			}
		};
		fetch(logoutUrl, options)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
			})
			.then(this.handleLogoutStatus);
	}

	showLoginLink(){
		this.loginLinkElement.classList.remove('nm-hidden');
		this.logoutLinkElement.classList.add('nm-hidden');
		this.showHeader();
	}

	showLogoutLink(){
		this.loginLinkElement.classList.add('nm-hidden');
		this.logoutLinkElement.classList.remove('nm-hidden');
		this.hideHeader();
	}

	showHeader() {
		if (!!this.loginHeadline) {
			this.loginHeadline.classList.remove('nm-hidden');
		}
	}

	hideHeader(){
		if (!!this.loginHeadline) {
			this.loginHeadline.classList.add('nm-hidden');
		}
	}
}

if (window.customElements.get('audi-login') === undefined) {
	window.customElements.define('audi-login', LoginLink);
}
