const template = `<div class="nm-ave-animation-selector-wrapper">
	<div class="nm-ave-animation-selector nm-el-btn nm-el-btn-01 nm-icon-settings nm-icon-expand-white nm-hidden">
		<div class="options-text">{{=window.i18n['rendering-layer.options']}}</div>
	</div>
	<ul class="nm-ave-animation-selection">
		<li class="nm-button-close">
			<svg class="nm-icon-cancel">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
			</svg>
		</li>
	</ul>
</div>`;
export {template as aveCanvasTemplate};
