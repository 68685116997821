// TODO: #container-001 - resolve dependency to html-template
// TODO: remove 'eval'!
import {dom as DOM_UTILS} from 'core-utils';
import {dpuApi as CONFIGURATOR_API} from './dpu/api';
import {appEvents as EVENTS} from 'core-application';

const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorOptionList: '.nm-options-list',
	sSelectorOptionListAlternative: '.nm-j-options-list-alternative',
	sSelectorConfiguratorContainer: '.nm-configurator-container',
	sClassOptionListCollapsed: 'nm-options-list-collapsed',
	sClassConfiguratorContainerShow: 'nm-j-configurator-container-show',
	sClassConfiguratorContainerHide: 'nm-j-configurator-container-hide'
};

__.toggleAlternativeOptions = function(event) {
	var container = DOM_UTILS.getElement(__.oDefaults.sSelectorOptionList, event.target.parentNode);

	if (DOM_UTILS.isElement(container)) {
		container.classList.toggle(__.oDefaults.sClassOptionListCollapsed);
	}
};

__.getSelectedIdString = function(items, optionsIncluded) {
	var selectedIdString = '';

	if (!!optionsIncluded) {
		optionsIncluded.forEach(function(value) {
			if (value !== 'idid' && !!items[value] && !!items[value].status && items[value].status === '11010') {
				selectedIdString = selectedIdString + value;
			}
		});
	}
	return selectedIdString;
};

__.tilesExclusive = () => {
	// .nm-md-tiles-exclusive is the old module name for 'tiles',
	// it can be removed from the query-string once it is not used anymore.
	const exclusiveTiles = document.querySelectorAll('.nm-module-tiles-exclusive, .nm-md-tiles-exclusive');

	[...exclusiveTiles].forEach(element => {
		const items = CONFIGURATOR_API.getItems() || {};
		const optionsIncluded = eval(element.getAttribute('data-configurator-id-included')); // eslint-disable-line no-eval
		const selectedIdString = __.getSelectedIdString(items, optionsIncluded);

		element.setAttribute('data-tile-selected', selectedIdString);
	});
};

__.hasSelectedItem = function(items, optionsIncluded) {
	var item,
		i,
		length = optionsIncluded.length;

	for (i = 0; i < length; i++) {
		item = items[optionsIncluded[i]] || {};

		if (!!item.status && (item.status === '11010' || item.status === '10011')) {
			return true;
		}
	}
	return false;
};

__.isHiddenByVersion = function(configuratorItemId) {
	var isHiddenByVersion = false;

	if (!!__.hiddenItemsFromVersion && __.hiddenItemsFromVersion !== null) {
		isHiddenByVersion = __.hiddenItemsFromVersion.indexOf(configuratorItemId) > -1;
	}

	return isHiddenByVersion;
};

__.isVisible = function(items, optionsIncluded) {
	var visible = false,
		statusCheck = false,
		versionCheck = false,
		configuratorItem;

	if (!!optionsIncluded) {
		optionsIncluded.forEach(function(itemId_) {
			if (visible === false) {
				if (itemId_ === 'idid') {
					return true;
				}
				else if (!!items[itemId_]) {
					// item is loaded from DPU - check status and if is it hidden by version
					if (!!items[itemId_].status && items[itemId_].status !== '00000' && items[itemId_].status !== '10000' && items[itemId_].status !== '10010') {
						statusCheck = true;
					}

					if (!!items[itemId_].id && __.isHiddenByVersion(items[itemId_].id) === false) {
						versionCheck = true;
					}

					visible = statusCheck && versionCheck;
					return false;
				}
				else {
					// no items are loaded from DPU - check DOM elements instead
					if (__.isHiddenByVersion(itemId_) === false) {
						configuratorItem = DOM_UTILS.getElement('.nm-j-configurator-item[data-configurator-id="' + itemId_ + '"]', document);
						if (
							!configuratorItem.classList.contains('nm-j-configurator-status_00000') &&
							!configuratorItem.classList.contains('nm-j-configurator-status_10000') &&
							!configuratorItem.classList.contains('nm-j-configurator-status_10010')
						) {
							visible = true;
						}
					}
					return false;
				}
			}
		});
	}
	return visible;
};

__.isVisibleSummary = function(items, optionsIncluded) {
	var visible = false,
		statusCheck = false,
		versionCheck = false,
		configuratorItem;

	if (!!optionsIncluded) {
		optionsIncluded.forEach(function(itemId) {
			if (!visible) {
				if (!!items[itemId]) {
					if (
						!!items[itemId].status &&
						items[itemId].status !== '11000' &&
						items[itemId].status !== '00000' &&
						items[itemId].status !== '11100' &&
						items[itemId].status !== '10000' &&
						items[itemId].status !== '10010'
					) {
						statusCheck = true;
					}

					if (!!items[itemId].id && __.isHiddenByVersion(items[itemId].id) === false) {
						versionCheck = true;
					}

					visible = statusCheck && versionCheck;

					return true;
				}
				else {
					if (__.isHiddenByVersion(itemId) === false) {
						configuratorItem = DOM_UTILS.getElement('.nm-j-configurator-item[data-configurator-id="' + itemId + '"]', document);

						if (
							DOM_UTILS.isElement(configuratorItem) &&
							(
								!configuratorItem.classList.contains('nm-j-configurator-status_11000') &&
								!configuratorItem.classList.contains('nm-j-configurator-status_00000') &&
								!configuratorItem.classList.contains('nm-j-configurator-status_11100') &&
								!configuratorItem.classList.contains('nm-j-configurator-status_10000') &&
								!configuratorItem.classList.contains('nm-j-configurator-status_10010')
							)
						) {
							visible = true;
						}
					}

					return false;
				}
			}
		});
	}
	return visible;
};

__.handler = function() {
	const items = CONFIGURATOR_API.getItems() || {};

	__.hiddenItemsFromVersion = CONFIGURATOR_API.getHiddenItemIDsFromVersion();

	__.handleConfiguratorContainer(items);
	__.handleConfiguratorSummary(items);
	__.handleEquipmentContainer(items);
	__.handleOptionsListContainer(items);
};

/*
 * show/hide items in configuration container
 */
__.handleConfiguratorContainer = function(items) {
	let configuratorContainer = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorConfiguratorContainer);

	configuratorContainer.forEach(function(element) {
		var optionsIncluded = eval(element.getAttribute('data-configurator-id-included')); // eslint-disable-line no-eval

		if (__.isVisible(items, optionsIncluded) === false) {
			element.classList.add(__.oDefaults.sClassConfiguratorContainerHide);
			element.classList.remove(__.oDefaults.sClassConfiguratorContainerShow);
		}
		else {
			element.classList.remove(__.oDefaults.sClassConfiguratorContainerHide);
			element.classList.add(__.oDefaults.sClassConfiguratorContainerShow);
		}
	});
};

/*
 * show/hide items in configuration summary
 */
__.handleConfiguratorSummary = function(items) {
	let configuratorContainerSummary = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorConfiguratorContainer + '.nm-summary-list-container');

	configuratorContainerSummary.forEach(function(element) {
		let optionsIncluded = eval(element.getAttribute('data-configurator-id-included')); // eslint-disable-line no-eval

		if (__.isVisibleSummary(items, optionsIncluded) === false) {
			element.classList.add(__.oDefaults.sClassConfiguratorContainerHide);
			element.classList.remove(__.oDefaults.sClassConfiguratorContainerShow);
		}
		else {
			element.classList.remove(__.oDefaults.sClassConfiguratorContainerHide);
			element.classList.add(__.oDefaults.sClassConfiguratorContainerShow);
		}
	});
};

/*
 * show/hide items in standard equipment container
 */
__.handleEquipmentContainer = function(items) {
	let equipmentContainers = DOM_UTILS.getElementsArray('.nm-j-selected-standard-equipment-container');

	equipmentContainers.forEach(function(container) {
		let optionsIncluded = eval(container.getAttribute('data-configurator-id-included')); // eslint-disable-line no-eval

		if (__.containsSelectedStandardEquipment(items, optionsIncluded)) {
			container.classList.remove(__.oDefaults.sClassConfiguratorContainerHide);
			container.classList.add(__.oDefaults.sClassConfiguratorContainerShow);
		}
		else {
			container.classList.add(__.oDefaults.sClassConfiguratorContainerHide);
			container.classList.remove(__.oDefaults.sClassConfiguratorContainerShow);
		}
	});
};

__.containsSelectedStandardEquipment = function(items, optionsIncluded) {
	let contains = false;

	optionsIncluded.forEach(function(id) {
		// Only 10011 (containedStandard) is visible
		if (!!items[id] && !!items[id].status && items[id].status === '10011') {
			contains = true;
		}
	});

	return contains;
};

/*
 * collapse alternative item options lists
 */
__.handleOptionsListContainer = function(items) {
	let optionsListContainer = DOM_UTILS.getElementsArray('.nm-md-options-list');

	optionsListContainer.forEach(function(element) {
		let container = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorConfiguratorContainer, element),
			optionsIncluded,
			isCollapsible,
			optionsList;

		if (container.length === 0) {
			return true;
		}

		optionsIncluded = eval(container[0].getAttribute('data-configurator-id-included')); // eslint-disable-line no-eval

		// container is collapsible?
		isCollapsible = false;
		container.forEach(function(cont) {
			let el = DOM_UTILS.getElement('.nm-j-options-list-alternative', cont);
			if (DOM_UTILS.isElement(el) && !DOM_UTILS.isHidden(el)) {
				isCollapsible = true;
			}
		});

		optionsList = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorOptionList, element);
		if (__.hasSelectedItem(items, optionsIncluded) && isCollapsible) {
			optionsList[0].classList.add(__.oDefaults.sClassOptionListCollapsed);
		}
		else {
			optionsList[0].classList.remove(__.oDefaults.sClassOptionListCollapsed);
		}
	});
};

__.addEvents = function() {
	// #container-001
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handler);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handler);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handler);
	__.eventBus.on(EVENTS.MODELSINFO_LOAD_COMPLETE, __.handler);

	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.tilesExclusive);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.tilesExclusive);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.tilesExclusive);

	// toggle options list alternative button
	__.domEventDelegate.on('click', __.oDefaults.sSelectorOptionListAlternative, __.toggleAlternativeOptions);
};

exports.initialize = function(eventBus) {
	__.eventBus = eventBus;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
};

export {exports as container};
