import QRCODE from 'qrcode-generator';

import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';

import {dpuApi as CONFIGURATOR_API} from '../dpu/api';
import {stateRegistry} from 'microkernel';
import {AUDICODE_STORE_ID} from '../stores/audicode-store';
import {USERDATA_STORE_ID} from '../stores/userdata-store';

const __ = {},
	exports = {
		__: __
	};

// private vars
__.audicode = null;
__.eventBus = null;
__.selectors = {
	audicodeLink: '.nm-j-audicode-link'
};

/**
 * clickCallback - callback function for legacyEventBus
 * @param {Event} event - Passed click event
 * @returns {void} - nothing
 */
__.clickCallback = function (event) {
	event.preventDefault();

	const link = DOM_UTILS.closest(event.target, __.selectors.audicodeLink);

	if (link.getAttribute('target') === '_blank') {
		const newOpenedWindow = window.open('', '_blank');
		__.clickAudicodeLink(link, newOpenedWindow);
	}
	else {
		__.clickAudicodeLink(link, window);
	}
};

/**
 * addEvents - adding of event handlers
 * @returns {void} - nothing
 */
__.addEvents = function () {
	__.eventBus.addListener(EVENTS.CONFIG_UPDATE, __.deleteAudicode);
	__.eventBus.addListener(EVENTS.LAYER_LOADED, __.onLayerLoaded);

	__.eventBus.addListener(EVENTS.LAYER_LOADED, __.removeAudiCodeHref);
	__.eventBus.addListener(EVENTS.PAGE_LOADED, __.removeAudiCodeHref);

	__.legacyEventBus.on('click', __.selectors.audicodeLink, __.clickCallback);
};

/**
 * removeEvents - removing of event handlers
 * @returns {void} - nothing
 */
__.removeEvents = function () {
	__.eventBus.removeListener(EVENTS.CONFIG_UPDATE, __.deleteAudicode);
	__.eventBus.removeListener(EVENTS.LAYER_LOADED, __.onLayerLoaded);

	__.eventBus.removeListener(EVENTS.LAYER_LOADED, __.removeAudiCodeHref);
	__.eventBus.removeListener(EVENTS.PAGE_LOADED, __.removeAudiCodeHref);

	__.legacyEventBus.off('click', __.selectors.audicodeLink, __.clickCallback);
};

/**
 * deleteAudicode - delete current audicode after each configuration update
 * @returns {void} - nothing
 */
__.deleteAudicode = function () {
	__.audicode = null;
	stateRegistry.triggerAction(AUDICODE_STORE_ID, 'invalidate');
	__.eventBus.emit(EVENTS.AUDICODE_DELETED);
};

/**
 * loadAudiCodeRequest - load audicode
 * @param {string|null} prString - configuration as prString
 * @returns {Promise} - JSON request promise
 */
__.loadAudiCodeRequest = async function (prString) {
	const baseUrl = CONFIGURATOR_API.getDpuUrl() + 'audicode?';
	const queryParams = new URLSearchParams({
		context: CONFIGURATOR_API.getContext(),
		subsession: CONFIGURATOR_API.getSubsessionID() || '',
		ids: prString || CONFIGURATOR_API.getPrString()
	});
	const url = baseUrl + queryParams.toString();

	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				throw new Error(response.statusText);
			}
			else {
				return response.json();
			}
		})
		.catch(error => console.log('Error fetching AudiCode - server responded with: ', error));
};

__.hasAudicode = function (data) {
	return !!data && !!data.header && data.header.statuscode === 200 && data.audicode && !!data.audicode.id;
};

/**
 * loadAudiCode - load audicode function
 * @returns {Promise} - load audicode promise
 */
exports.loadAudiCode = function () {
	return new Promise(function (resolve, reject) {
		if (!!__.audicode && !!__.prString && __.prString === CONFIGURATOR_API.getPrString()) {
			__.updateAudicodeStore();
			resolve(__.audicode);
		}
		else {
			__.audicode = null;
			__.loadAudiCodeRequest().then(
				function (data) {
					if (__.hasAudicode(data)) {
						__.audicode = data.audicode.id;
						__.updateAudicodeStore();
						if (!!data.header.subsession) {
							CONFIGURATOR_API.setSubsessionID(data.header.subsession);
						}
						__.prString = CONFIGURATOR_API.getPrString();
						__.eventBus.emit(EVENTS.AUDICODE_SUCCESS);
						resolve(__.audicode);
					}
					else {
						__.eventBus.emit(EVENTS.AUDICODE_FAILED);
						reject(new TypeError('load AudiCode failed'));
					}
				},
				function (err) {
					console.error(err);
					__.eventBus.emit(EVENTS.AUDICODE_FAILED);
					reject(new TypeError('load AudiCode error: ' + err.message));
				}
			);
		}
	});
};

/**
 * isAudicodeFieldOnPage - check whether audicode field is on current page
 * @returns {boolean} - true if present, false else
 */
__.isAudicodeFieldOnPage = function () {
	return DOM_UTILS.isElement('.nm-j-audicode');
};

/**
 * getAudicode - get current audicode
 * @returns {string} audicode
 */
exports.getAudicode = function () {
	return __.audicode;
};

/**
 * onLayerLoaded - check for audicode fields when opening a new layer
 * @returns {void}
 */
__.onLayerLoaded = function () {
	// load audicode if an audicode field exits
	if (__.isAudicodeFieldOnPage()) {
		const audicodeFromUrl = __.getAudicodeFromUrl();
		if (!!audicodeFromUrl) {
			__.audicode = audicodeFromUrl;
			__.updateAudicodeStore();
			__.updateAudicodeDomElements();
			__.removeAudicodeFromUrl();
		}
		else {
			exports
				.loadAudiCode()
				.then(__.updateAudicodeDomElements)
				.catch(function (err) {
					__.error(err);
				});
		}
	}
};

__.getAudicodeFromUrl = () => {
	const url = new URL(window.location.href);
	return url.searchParams.get('audicode');
};

__.removeAudicodeFromUrl = () => {
	const url = new URL(window.location.href);
	url.searchParams.delete('audicode');
	window.history.replaceState({}, document.title, url.href);
};

__.updateAudicodeStore = () => {
	stateRegistry.triggerAction(AUDICODE_STORE_ID, 'update', {
		audicode: __.audicode
	});
};

/**
 * updateAudicodeDomElements - handle successful load audicode results
 * @returns {void}
 */
__.updateAudicodeDomElements = function () {
	if (__.audicode) {
		__.generateQrCode();

		DOM_UTILS.getElementsArray('.nm-j-audicode').forEach(element => (element.innerHTML = __.audicode));
		DOM_UTILS.getElementsArray('.nm-j-audicode-show').forEach(element =>
			element.classList.remove('nm-j-audicode-failure', 'nm-j-audicode-loading')
		);
		stateRegistry.triggerAction(USERDATA_STORE_ID, 'update', {currentAudicode: __.audicode});
	}
};

/**
 * generateQrCode - uses qrcode.js library to generate qrcode
 * @return {void} - returns nothing
 */
__.generateQrCode = function () {
	const element = DOM_UTILS.getElement('.nm-j-audicode-qrcode');
	let qrUrl, qrCode;
	if (DOM_UTILS.isElement(element)) {
		element.innerHTML = '';
		qrUrl = element.getAttribute('data-qr-url');
		qrCode = new QRCODE(4, 'L');
		qrCode.addData(qrUrl + __.audicode);
		qrCode.make();

		element.innerHTML = qrCode.createSvgTag(4, 0);
	}
};

/**
 * clickAudicodeLink
 * @param {Object} link - the clicked audicode link
 * @param {Object} windowForOpeningLink - window that open the link url
 * @returns {void}
 */
__.clickAudicodeLink = function (link, windowForOpeningLink) {
	if (__.audicode === null) {
		exports
			.loadAudiCode()
			.then(function () {
				__.setAudiCodeUrlAsLocation(link, windowForOpeningLink);
			})
			.catch(function (err) {
				__.error(err);
			});
	}
	else {
		__.setAudiCodeUrlAsLocation(link, windowForOpeningLink);
	}
};

/**
 * @param {Object} linkEl - Object containing the audicode
 * @param {Object} windowForOpeningLink - Window that open the link url
 * @returns {void}
 */
__.setAudiCodeUrlAsLocation = function (linkEl, windowForOpeningLink) {
	let linkUrl;

	linkUrl = linkEl.getAttribute('data-link-pattern');
	linkUrl = linkUrl.replace('{{=audicode}}', __.audicode);

	windowForOpeningLink.location.href = encodeURI(linkUrl);
};

/**
 * @description remove href from Audicode links
 * @returns {void}
*/
__.removeAudiCodeHref = function () {
	const links = document.querySelectorAll(__.selectors.audicodeLink);

	links.forEach((link) => {
		link.removeAttribute('href');
	});
};

/**
 * error - error handling
 * @param {Error} error - error
 * @returns {void} nothing
 */
__.error = function (error) {
	const audicodeDisplay = DOM_UTILS.getElement('.nm-j-audicode-show');
	audicodeDisplay.classList.add('nm-j-audicode-failure');
	console.log(error);
};

/**
 * initialize - Constructor
 * @param {EventEmitter} eventBus_ - eventEmitter2
 * @returns {void} nothing
 */
exports.initialize = function (eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = DOM_UTILS.getEventDelegate('body');
	__.removeAudiCodeHref();
	__.addEvents();
};

export {exports as audicode};
