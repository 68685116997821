import {appEvents as EVENTS} from 'core-application';
const __ = {},
	exports = {
		__: __
	};
__.oDefaults = {
	sSelectorItemLoading: '.nm-j-configurator-item-loading',
	sItemLoadingClass: 'nm-j-configurator-item-loading'
};
/**
 * show loading indicator for a configuration item
 * @param {Event} data_ - EventObject
 * @Example
		data:{
		'id':'prNumber',
		'count': 'count',
		'action': 'itemAdd'||'removeItem',
		'triggerAction': 'click'||'select',
		'$element': 'triggering jQuery element'
	});
 * @returns {void} nothing
 */
__.showLoadingIndicator = function(data_) {
	var $element = data_.$element;
	if ($element && $element.length) {
		$element.addClass(__.oDefaults.sItemLoadingClass);
		// console.log("added indicator "+new Date().getTime());
	}

};
/*
 * remove all loading indicators on AJAX request end (success or failure/abort)
 */
__.hideLoadingIndicator = function() {
	// remove loading status from triggred items
	var $items = jQuery(__.oDefaults.sSelectorItemLoading);
	$items.removeClass(__.oDefaults.sItemLoadingClass);
	// console.log("removed indicator "+new Date().getTime());
};
/*
 * add event listener for module specific events
 */
__.addEvents = function() {
	__.eventBus.on(EVENTS.CONFIG_CHANGEREQUEST_START, __.showLoadingIndicator);
	__.eventBus.on(EVENTS.CONFIG_CHANGEREQUEST_END, __.hideLoadingIndicator);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};
/**
 * public api methods
 */
export {exports as loadingIndicator};
