import {dom as DOM_UTILS} from "core-utils";
import {patternMatching as PATTERN_MATCHING} from "core-global";

const __ = {},
	// Public API
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorModule: 	'.j-pdf-configuration'
};

__.addEvents = function() {
	DOM_UTILS.getEventDelegate('body').on('click', __.oDefaults.sSelectorModule, __.callPDFServiceWithAudicode);
};

__.callPDFServiceWithAudicode = function(e) {
	var pdfUrl; // "https://pdf.eu-west-1.apps.msi.audi.com"
	var windowReference;
	pdfUrl = SETUPS.get("nemo.pdf.url"); // $ENV.PdfServiceBaseUrl
	console.log("PDFURl: ", pdfUrl);

	if (!!pdfUrl) {
		windowReference = window.open();
		PATTERN_MATCHING
			.getReplacedUrl(pdfUrl+"/audicode/{{audicode}}", "audi.de")
			.then(
				function(urlWithAudiCode) {
					windowReference.location = urlWithAudiCode;
				}
			)
			.catch(
				function(e) {
					console.warn("PDF SERVICE LINK ERROR: something went wrong in pattern matching: ", e);
				}
			);
	}

};

/**
 * public initialize method
 * @param {EventBus} eventBus_ - tbd
 * @return {void} nothing
 */
exports.initialize = function(eventBus_) {
	__.addEvents();
};
export {exports as pdfServiceLink};
