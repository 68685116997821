const dotTemplate = `
{{?!!it.data.configuration.extColorCode && !!it.data.configuration.extColorCode.selected}}
	{{var selected = it.data.configuration.extColorCode.selected;}}
	{{var backgroundcolor = 'extcolor--' + selected;}}
	{{var textcolor = 'extcolor-text--' + selected;}}
{{??}}
	{{var selected = "";}}
	{{var backgroundcolor = "";}}
	{{var textcolor = "";}}
{{?}}
<div class="rescue-audicode">
	<div class="rescue-audi__close">
		<div class="nm-layer__close-button j-close" style="display: inline-flex;">
			<svg class="nm-layer__close-button-icon">
				<use xlink:href="{{=SETUPS.get('coreci.url.assets')}}/icons/svg/cancel-newci-large.svg#audi-icon-cancel-newci-large"></use>
			</svg>
		</div>
	</div>
	<div class="{{=textcolor}}">
		<div class="rescue-audicode__text-container {{=backgroundcolor}}">
			<p class="rescue-audicode__audicode audi-copy-s">{{=window.i18n['audicode.rescue.your_audicode']}} {{=it.data.configuration['audicode-rescue-info'].audicode}}</p>
			<h3 class="rescue-audicode__carline audi-headline-order-3">{{=it.data.configuration.carlineName}}</h3>
			<p class="rescue-audicode__description audi-copy-s">{{=it.data.configuration.description}}</p>
			<p class="rescue-audicode__price audi-copy-m">{{=it.data.configuration.prices.total}}</p>
		</div>
		<div class="rescue-audicode__image-container">
			<div class="rescue-audicode__image-background {{=backgroundcolor}}"></div>
			<div class="rescue-audicode__image-picture">
                {{?!!it.data.images && it.data.images['exterior'] && it.data.images['exterior']['flyout']}}
                    <picture>
                        <source media="(max-width: 427px)" srcset="{{=SETUPS.get('nemo.url.mediaservice')}}{{=it.data.images['exterior']['flyout']}}?width=470">
                        <img class="rescue-audicode__image" src="{{=SETUPS.get('nemo.url.mediaservice')}}{{=it.data.images['exterior']['flyout']}}?width=654">
                    </picture>
                {{??}}
                    <img class="rescue-audicode__image" src="{{=SETUPS.get('nemo.url.assets')+"/img/fallback/fb-audicode-flyout.png"}}" alt="Fallback Image">
                {{?}}
            </div>
		</div>
	</div>

	<div class="rescue-audicode__text-options-container">
		<p class="rescue-audicode__options_text audi-copy-m">{{=window.i18n['audicode.rescue.cant_load_audicode']}}</p>
		<p class="rescue-audicode__options-changed audi-copy-s">
			{{=window.i18n['audicode.rescue.alternative_configuration']}}<br/>
			{{=it.whatIsChanged}}
		</p>

		<a href="#" class="rescue-audicode__options-load-configuration audi-link-m audi-link--arrow j-close">{{=window.i18n['audicode.rescue.load_alternative_configuration']}}</a><br />
		{{?it.pdfLink}}
			<a href="{{=it.pdfLink}}" class="rescue-audicode__options-pdf audi-link-m audi-link--arrow">{{=window.i18n['audicode.rescue.pdf']}}</a>
		{{?}}

		{{? !SETUPS.get('scope.nemo.hide.consumption-entries')}}
			<div class="rescue-audicode__disclaimer-entries audi-copy-s">
				{{? it.data.configuration.techdata && (it.data.configuration.techdata['consumption-combined'] || it.data.configuration.techdata['carbon-dioxide-emissions'] || it.data.configuration.techdata['efficiency-class'])}}
					{{? it.data.configuration.techdata['consumption-combined']}}
						<dl class="rescue-audicode__disclaimer">
							<dt class="rescue-audicode__disclaimer-label">{{=window.i18n['nemo.ui.consumption-combined']}}*:</dt>
							<dd class="rescue-audicode__disclaimer-value">{{=it.data.configuration.techdata['consumption-combined']}}</dd>
						</dl>
					{{?}}
					{{? it.data.configuration.techdata['carbon-dioxide-emissions']}}
						<dl class="rescue-audicode__disclaimer">
							<dt class="rescue-audicode__disclaimer-label">{{=window.i18n['nemo.ui.emission-combined']}}*:</dt>
							<dd class="rescue-audicode__disclaimer-value">{{=it.data.configuration.techdata['carbon-dioxide-emissions']}}</dd>
						</dl>
					{{?}}
					{{? !SETUPS.get('scope.nemo.hide.eec-entry')}}
						{{? it.data.configuration.techdata['efficiency-class']}}
							<dl class="rescue-audicode__disclaimer">
								<dt class="rescue-audicode__disclaimer-label">{{=window.i18n['nemo.ui.efficiency-class']}}:</dt>
								<dd class="rescue-audicode__disclaimer-value">{{=it.data.configuration.techdata['efficiency-class']}}</dd>
							</dl>
						{{?}}
					{{?}}
				{{?}}
			</div>
		{{?}}

	</div>
</div>`;

export {dotTemplate as AudicodeRescueLayer};
