import {patternMatching as PATTERN} from 'core-global';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
const __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};
// reference to the global eventEmitter
__.eventBus = null;
/**
 * register register pattern in pattern-matching module
 * @return {Void} returns nothing
 */
__.register = function() {
	PATTERN.register('language', __.getLanguage);
};
/**
 * getLanguage get langugage from setups
 * @return {Promise} returns promise which resolves language
 */
__.getLanguage = function() {
	var language;
	return new Promise(function(resolve, reject) {
		language = SETUPS.get('nemo.locale.language');
		if (!!language) {
			resolve(language);
		}
		else {
			reject(new TypeError('load pattern -language- failed'));
		}
	});
};
/*
 * initializePromise initialize module
 */
exports.initialize = function() {
	__.register();
};
export {exports as patternLanguage};
