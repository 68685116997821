import {patternMatching as PATTERN} from 'core-global';

import {audicode as AUDICODE} from '../audicode/audicode';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
const __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};
__.pendingPromise=null;
__.resolved = false;

// reference to the global eventEmitter
__.eventBus = null;
__.register = function() {
	PATTERN.register('audicode', __.getAudiCode);
	PATTERN.register('conf_audicode', __.getAudiCode);
};

/**
 * checks if an audicode is currently getting fetched
 * and returns this promise -> to prevent multiple
 * loadAudiCode calls / different audicodes
 * @returns {Promise} audicode request
 */
__.getAudiCode = function() {
	if (!__.resolved && !!__.pendingPromise) {
		return __.pendingPromise;
	}
	else {
		__.pendingPromise = __.fetchAudiCode().catch(function(error) {
			__.resolved = true;
			__.pendingPromise = null;
			return error;
		}).then(function(audicode) {
			__.resolved = true;
			__.pendingPromise = null;
			return audicode;
		});
		return __.pendingPromise;
	}
};

__.fetchAudiCode = function() {
	return new Promise(function(resolve, reject) {
		var promise = AUDICODE.loadAudiCode();
		promise.then(function(data) {
			resolve(data);
		}, function(err) {
			reject(err);
			console.error(err);
		});
	});
};

exports.initialize = function() {
	__.register();
};
export {exports as patternAudicode};
