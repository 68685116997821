import {dom} from 'core-utils';
import {appEvents} from 'core-application';
import {dpuApi} from './dpu/api';
import {dpuController as DPU_CONTROLLER} from './dpu/controller';
import {router} from 'core-application';
import {template} from 'core-utils';
import {carstoreListTemplate} from './carstore-list-template';
import {carstoreFailTemplate} from './carstore-fail-template';
import {carstoreEmptyTemplate} from './carstore-empty-template';

const __ = {
		selectedItemID: null
	},
	exports = {
		__: __
	};

/**
 * oDefaults default objects for strings and classes
 * @type {Object}
 */
__.oDefaults = {
	selectorCarstoreDeleteSection: '.nm-j-carstore-delete-section',
	selectorAbortButton: '.nm-j-abort-button',
	selectorDeleteButton: '.nm-j-delete-button',
	selectorCarDetails: '.nm-j-carstore-item-details',
	selectorForm: '.nm-j-carstore-modify',
	selectorCarstoreItem: '.nm-module-carstore .nm-j-carstore-item',
	selectorCarstoreList: '.nm-j-carstore-list'
};

/* TODO: check wether this gets used at all */
__.loadAgents = function(imageArray) {
	imageArray.forEach(image => {
		const img = new Image();
		img.src = image;
	});
};

/**
 * addEvents adds common and custom events
 * @type {function}
 * @returns {void} returns nothing
 */
__.addEvents = function() {
	__.legacyEventBus.on('submit', __.oDefaults.selectorForm, __.handleFormSubmit);

	__.eventBus.on(appEvents.FORM_FINISHED, __.handleFormFinished);
	__.eventBus.on(appEvents.FORM_ERROR, __.handlePossibleLoginStateChange);
	__.eventBus.on(appEvents.MYAUDI_LOGIN, __.list);
	__.eventBus.on(appEvents.LAYER_LOADED, __.list);
};

__.triggerLoginStateEvent = function(loginState) {
	if (loginState) {
		const visitorId = dpuApi.getVisitorId();
		if (visitorId) {
			__.eventBus.emit(appEvents.MYAUDI_LOGIN, visitorId);
		}
		else {
			__.eventBus.emit(appEvents.MYAUDI_LOGIN);
		}
	}
	else {
		__.eventBus.emit(appEvents.MYAUDI_LOGOUT);
	}
	__.updateLoginState();
};

__.handlePossibleLoginStateChange = function(data) {
	if (
		data &&
		data.response &&
		data.response.header &&
		data.response.header['logged-in'] &&
		data.response.header.subsession
	) {
		dpuApi
			.setLogin(data.response.header['logged-in'], data.response.header.subsession)
			.then(__.triggerLoginStateEvent)
			.catch(err => {
				__.triggerErrorLayer(err, 'error.message.LOGIN_FAILED');
			});
	}
};

__.updateLoginState = function() {
	const body = dom.getElement('body');
	if (dpuApi.isLoggedIn()) {
		body.classList.add('nm-logged-in');
	}
	else {
		body.classList.remove('nm-logged-in');
	}
};

__.handleFormSubmit = function(event_) {
	event_.preventDefault();
	const form = this;
	__.selectedItemID = form.getAttribute('data-form-id');
	const select = dom.getElement('#carstore-select-' + __.selectedItemID);
	const action = select && select.options ? select.options[select.selectedIndex].value : 'none';

	if (action === 'configuration-delete') {
		__.deleteCarstoreItem();
	}
	else if (action === 'configuration-load') {
		__.loadCarstoreItem();
	}
};

__.renderCarstoreList = function(data) {
	const element = dom.getElement(__.oDefaults.selectorCarstoreList);
	element.innerHTML = __.renderListTemplate(data.carstore);
	element.classList.remove('nm-loader');
};

__.renderListTemplate = function(carstoreData) {
	if (!!carstoreData.list) {
		carstoreData.altImage = dpuApi.getAltImagePath('selection-compare', 'none');
		return template.render(carstoreListTemplate, carstoreData);
	}
	else if (!!carstoreData.statuscode && carstoreData.statuscode === 800) {
		return template.render(carstoreFailTemplate, {});
	}
	return template.render(carstoreEmptyTemplate, {});
};

__.handleFormFinished = function(data) {
	if (data.id === 'login') {
		__.handleLoginFormFinished(data);
	}
	else if (data.id === 'carstore') {
		__.handleSaveFormFinished();
	}
};

__.handleLoginFormFinished = function(data) {
	__.loadAgents(data.response.login.agents.standard);
	__.loadAgents(data.response.login.agents.secure);
	__.handlePossibleLoginStateChange(data);
};

__.handleSaveFormFinished = function() {
	const element = dom.getElement('.nm-carstore-save .nm-j-carstore-save');
	element.classList.remove('nm-j-carstore-save');
	element.classList.add('nm-j-carstore-list');
	element.innerHTML = '';
	__.list('carstore');
};

__.list = function(type) {
	__.updateLoginState();

	if (dpuApi.isLoggedIn() && __.isCarstoreListOpen()) {
		__.showLoader();
		DPU_CONTROLLER.listCarstoreItems()
			.then(function(data) {
				__.renderCarstoreList(data);
				if (type && type === 'carstore') {
					const audicode = dom.getElement('.nm-j-audicode').innerText;
					__.eventBus.emit(appEvents.CONFIG_SAVED, audicode);
				}
			})
			.catch(function(err) {
				__.triggerErrorLayer(err, 'carstore-layer.error-loading-slots');
			})
			.then(function() {
				__.hideLoader();
			});
	}
};

__.isCarstoreListOpen = function() {
	return dom.getElementsArray(__.oDefaults.selectorCarstoreList).length > 0;
};

__.showLoader = function() {
	const elements = dom.getElementsArray(__.oDefaults.selectorCarstoreList);
	elements.forEach(element => {
		element.innerHTML = '';
		element.classList.add('nm-loader');
	});
};

__.hideLoader = function() {
	const elements = dom.getElementsArray(__.oDefaults.selectorCarstoreList);
	elements.forEach(element => {
		element.classList.remove('nm-loader');
	});
};

__.deleteCarstoreItem = function() {
	__.toggleDeleteSectionVisibiblity(true);
	__.addDeleteButtonEvents();
};

__.handleDeleteAbort = function(event_) {
	event_.preventDefault();
	__.toggleDeleteSectionVisibiblity(false);
	__.removeDeleteButtonEvents();
	__.selectedItemID = null;
	return false;
};

__.handleDeleteSubmit = function(event_) {
	event_.preventDefault();

	if (__.selectedItemID) {
		DPU_CONTROLLER.deleteCarstoreItem(__.selectedItemID)
			.then(function(data) {
				__.list(data);
			})
			.catch(function(err) {
				__.triggerErrorLayer(err, 'carstore-layer.error-deleting-slot');
			});
	}

	__.removeDeleteButtonEvents();
	__.selectedItemID = null;

	return false;
};

__.toggleDeleteSectionVisibiblity = function(isVisible = true) {
	const item = __.oDefaults.selectorCarstoreItem + "[data-item-position='" + __.selectedItemID + "'] ";

	dom.getElement(item + __.oDefaults.selectorCarstoreDeleteSection).classList.toggle('nm-hidden', !isVisible);
	dom.getElement(item + __.oDefaults.selectorForm).classList.toggle('nm-hidden', isVisible);
	dom.getElement(item + __.oDefaults.selectorCarDetails).classList.toggle('nm-hidden', isVisible);
};

__.addDeleteButtonEvents = function() {
	const item = __.oDefaults.selectorCarstoreItem + "[data-item-position='" + __.selectedItemID + "'] ";

	__.legacyEventBus.on('click', item + __.oDefaults.selectorAbortButton, __.handleDeleteAbort);
	__.legacyEventBus.on('click', item + __.oDefaults.selectorDeleteButton, __.handleDeleteSubmit);
};

__.removeDeleteButtonEvents = function() {
	const item = __.oDefaults.selectorCarstoreItem + "[data-item-position='" + __.selectedItemID + "'] ";

	__.legacyEventBus.off('click', item + __.oDefaults.selectorAbortButton, __.handleDeleteAbort);
	__.legacyEventBus.off('click', item + __.oDefaults.selectorDeleteButton, __.handleDeleteSubmit);
};

__.loadCarstoreItem = function() {
	DPU_CONTROLLER.loadCarstoreItem(__.selectedItemID)
		.catch(function(err) {
			__.triggerErrorLayer(err, 'carstore-layer.error-loading-car');
		})
		.then(function() {
			router.open('page', dpuApi.getCurrentCarlineUrl());
			setTimeout(function() {
				const clickEvent = document.createEvent('HTMLEvents');
				clickEvent.initEvent('click', true, false);
				const closeButton = dom.getElement('.nm-j-layer-close-button');
				closeButton.dispatchEvent(clickEvent);
			}, 100);
		});
	return false;
};

__.triggerErrorLayer = function(err, errorTypeKey) {
	console.error(errorTypeKey, err);
	const message = dpuApi.getErrorMessage(err);
	__.eventBus.emit(appEvents.ERROR, [window.i18n[errorTypeKey], message]);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = dom.getEventDelegate('body');
	__.addEvents();
};

export {exports as carstore};
