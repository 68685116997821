export const sendModalWltpErrorLayerEvent = () => {
	const dataObject = {
		layerType: 'MessageLayerElement',
		clickOnShaderClosesModal: false,
		isLayerClosable: false,
		dataset: {
			headline: window.i18n['error.no_wltp_data.modal_layer.headline'],
			messageType: 'string',
			message: window.i18n['error.no_wltp_data.modal_layer.message'],
			actionButtonLinkText: window.i18n['error.no_wltp_data.modal_layer.link.text'],
			actionButtonLinkUrl: window.i18n['error.no_wltp_data.modal_layer.link.url']
		}
	};

	const modalLayerOpenEvent = new CustomEvent('modalLayer:open', {detail: dataObject});
	document.dispatchEvent(modalLayerOpenEvent);
};
export const setupAndThrowError = (data) => {
	const err = new Error();
	err.status = data.statuscode;

	if (!!data.audicode && !!data.audicode.message) {
		err.name = 'custom-dpu-error';
	}

	if (!!data.header && data.header.error) {
		err.id = data.header.error.id;

		if (err.id === 'HIDDEN_CARLINE') {
			sendModalWltpErrorLayerEvent();
			return;
		}

		if (!!data.header.error.message) {
			err.message = data.header.error.message;
		}
		else if (!!data.header.statusmessage) {
			err.message = data.header.statusmessage;
		}
	}
	throw err;
};
