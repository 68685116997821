import {state as STATE} from 'core-global';

import {dpuModel as DPU_MODEL} from './model';

const __ = {},
	// Public API
	exports = {
		__: __
	};

/**
 * @property {Object} store for all API values
 */
__.stored = {};

/**
 * merge families with special info
 * @returns {Object|null} object of familiy items (key=name,value=family)
 */
exports.getSpecialFamilies = function() {
	// #api-003.2 - resolve dependency
	var specialFamilies = SETUPS.get('nemo.families');
	var configFamilies = exports.getFamilies();
	var id;

	if (!specialFamilies && !!configFamilies) {
		specialFamilies = {};
		// #api-003.3 - resolve dependency
		for (id in configFamilies) {
			if (!!configFamilies[id].showstandard && configFamilies[id].showstandard === 'true') {
				specialFamilies[id] = configFamilies[id];
			}
		}
	}
	return !!specialFamilies ? specialFamilies : null;
};

/**
 * get item families
 * calls {@link module:configurator/dpu-model.getFamilies DPU_MODEL.getFamilies}
 * @returns {Object|null} families
 */
exports.getFamilies = function() {
	return DPU_MODEL.getFamilies();
};

/**
 * send request to stateless render service
 * @param {string} prNumbersToFilter_ - pr numbers for filtering
 * @param {string} renderPrNr_ - pr number to render
 * @param {string} renderViews_ - render views separated by ,
 * @param {string} renderSizes_ - renderSizes separated by ,
 * @returns {Promise} - render service call result as promise
 */
exports.sendRenderServiceRequest = function(prNumbersToFilter_, renderPrNr_, renderViews_, renderSizes_) {
	return DPU_MODEL.sendRenderServiceRequest(prNumbersToFilter_, renderPrNr_, renderViews_, renderSizes_);
};

/**
 * search for items matching the search string
 * @param {string} searchString_ - search field input value
 * @param {boolean} hideInvisible_ - hide invisible items from search (staus 00000 and 10000)
 * @returns {Array} itemsFound - list of all the found items
 * @default []
 */
exports.search = function(searchString_, hideInvisible_) {
	var itemsFound = exports.searchItems(searchString_, hideInvisible_);

	if (itemsFound.length > 0) {
		return __.groupByFamily(itemsFound);
	}
	else {
		return [];
	}
};

/**
 * Groups list of given search-results based on its family
 * and returns an array in the following format:
 * @param {Array} items_ - found items to group
 * @returns {Array} items - items grouped by families
 * [
 *  { key:'Family-Name', value:{ family: {}, items: [ ... ] }},
 *  { key:'Family-Name', value:{ family: {}, items: [ ... ] }}
 * ]
 */
__.groupByFamily = function(items_) {
	var results = {
		search: []
	};
	var families = exports.getFamilies();
	var resultList;
	var keys = {};
	var family;
	var carlineName = exports.getCarlineName();
	var items = items_ || {};

	jQuery.each(items, function(key, item) {
		if (item.family === undefined) {
			console.log('Found item without family: ', item);
			return true;
		}
		family = families[item.family];

		if (!!family) {
			if (!family.name) {
				console.log('Found family without family.name: ', family);
				return true;
			}
			family.group = family.group || family.name || family.id;

			if (!keys[family.group]) {
				keys[family.group] = 1;
				resultList = {
					key: family.group,
					value: {
						carlineName: carlineName,
						family: family,
						items: []
					}
				};

				results.search.push(resultList);
			}

			resultList.value.items.push(item);
		}
	});
	return results.search;
};

/**
 * Groups list of given search-results based on its colorKey
 * and returns a formatted array
 * @param {Array} items_ - found items to group
 * @returns {Array} items - items grouped by families
 */
__.groupByExtColorKey = function(items_) {
	var families = exports.getFamilies();
	var keys = [
		'extcolors.uni',
		'extcolors.metallic',
		'extcolors.pearl',
		'extcolors.exclusive',
		'extcolors.o',
		'extcolors.m',
		'extcolors.p'
	];
	var i;
	var family;
	var resultLists = [];
	var returnLists = [];
	var carlineName = exports.getCarlineName();
	var items = items_ || {};

	jQuery.each(items, function(key, item) {
		if (item.family === undefined) {
			console.log('Found item without family: ', item);
			return true;
		}

		family = families[item.family];
		if (!!family) {
			if (!family.name) {
				console.log('Found family without family.name: ', family);
				return true;
			}
			family.group = family.group || family.id || family.name;
			family.group = family.group.trim();
			if (keys.indexOf(family.group) === -1) {
				console.log('Found unknown family group: ', family.group);
			}
			else {
				if (!resultLists[family.group]) {
					resultLists[family.group] = {
						key: family.group,
						value: {
							carlineName: carlineName,
							family: family,
							items: []
						}
					};
				}
				resultLists[family.group].value.items.push(item);
			}
		}
	});

	for (i = 0; i < keys.length; i++) {
		if (resultLists[keys[i]]) {
			returnLists.push(resultLists[keys[i]]);
		}
	}
	return returnLists;
};

/**
 * @returns {string|null} url - url to the renderservice
 */
exports.getRenderUrl = function() {
	return SETUPS.get('nemo.url.mediaservice') || null;
};

/**
 * getSelectedItems
 * @returns {Object|null} - object of all selected items (key=id,value=item)
 */
exports.getSelectedItems = function() {
	var items = exports.getItems() || {};
	var specialFamilies = exports.getSpecialFamilies();
	var selectedItems = {};
	var i = 0;
	var id;

	for (id in items) {
		if (!!items[id].status && items[id].status.charAt(3) === '1') {
			if (items[id].status.charAt(4) === '0') {
				selectedItems[id] = items[id];
				i++;
			}
			if (items[id].status.charAt(4) === '1' && !!items[id].family && !!specialFamilies[items[id].family]) {
				selectedItems[id] = items[id];
				i++;
			}
		}
	}
	return i > 0 ? selectedItems : null;
};

/**
 * get current trimline from configuration
 * @returns {string|null} trimelinename
 */
exports.getSelectedTrimline = function() {
	var configuredItems = exports.getSelectedItems();
	var keysArr = [];
	var len;

	if (configuredItems) {
		keysArr = Object.keys(configuredItems);
		len = keysArr.length;
		while (len--) {
			if (keysArr[len].indexOf('trimline_') > -1) {
				return keysArr[len];
			}
		}
	}
	return null;
};

/**
 * get all selected items (with attribute 'selectionMethod')
 * from current configuration
 * @returns {Object} items
 */
exports.getSelectedItemsArray = function() {
	var items = {};

	jQuery.each(exports.getItems(), function(key, value) {
		if (!!value.selectionMethod) {
			items[value.selectionMethod] = items[value.selectionMethod] || [];
			items[value.selectionMethod].push(value);
		}
	});
	return items;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getCarline getCarline} method
 * @returns {string|null} name - carline name
 */
exports.getCarline = function() {
	return DPU_MODEL.getCarline();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguration getConfiguration} method
 * @returns {string|null} name - carline name
 */
exports.getCarlineName = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.carlineName ? configuration.carlineName : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguration getConfiguration} method
 * @returns {string|null} engine - engine prstring
 */
exports.getEngine = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.model ? configuration.model : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguration getConfiguration} method
 * @returns {Object|null} techdata - techdata object
 */
exports.getTechdata = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.techdata ? configuration.techdata : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguratedItems getConfiguratedItems} method
 * @returns {string|null} exterior color code (prstring)
 */
exports.getExtColor = function() {
	var items = exports.getSelectedItemsIDsArray();
	var i;
	var len;

	for (i = 0, len = items.length; i < len; i++) {
		if (items[i].length === 4) {
			return items[i];
		}
	}
	return null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguration getConfiguration} method
 * @returns {string|null} modelyear
 */
exports.getModelYear = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.modelyear ? configuration.modelyear : null;
};

/**
 * uses DPU Model`s {@linkcode module:configurator/dpu-model.getHeader getHeader} method
 * @returns {string} pricemode - 'price' or 'rate'
 * @default {string} 'price'
 */
exports.getPriceRateMode = function() {
	var header = DPU_MODEL.getHeader();

	return header && !!header.mode ? header.mode : 'price';
};

/**
 * uses DPU Model`s {@linkcode module:configurator/dpu-model.getHeader getHeader} method
 * @returns {string} visitor-id or null
 */
exports.getVisitorId = function() {
	var header = DPU_MODEL.getHeader();

	return header && !!header['visitor-id'] ? header['visitor-id'] : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getHiddenItemIDsFromVersion getHiddenItemIDsFromVersion} method
 * @returns {array|null} - hidden items from version request
 */
exports.getHiddenItemIDsFromVersion = function() {
	return DPU_MODEL.getHiddenItemIDsFromVersion();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getPrString getPrString} method
 * @returns {string|null} - prstring for the current configuration
 */
exports.getPrString = function() {
	return DPU_MODEL.getPrString();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfigurationStartType getConfigurationStartType} method
 * @returns {string} value - indicating how the current configuration has been started
 */
exports.getConfigurationStartType = function() {
	return DPU_MODEL.getConfigurationStartType();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.isContinuedConfiguration isContinuedConfiguration} method
 * @returns {boolean} value - indicating if the cuurent configuration has been changed after its start
 */
exports.isContinuedConfiguration = function() {
	return DPU_MODEL.isContinuedConfiguration();
};

/**
 * uses DPU Model`s {@linkcode module:configurator/dpu-model.getHeader getHeader} method
 * @returns {string|null} - content version
 */
exports.getContentVersion = function() {
	var header = DPU_MODEL.getHeader();
	var version = header && !!header.version ? header.version : null;

	return version;
};

/**
 * ueses DPU Model`s {@linkcode module:configurator/dpu-model.getHeader getHeader} method
 * @returns {string|null} - market version
 */
exports.getMarketVersion = function() {
	var header = DPU_MODEL.getHeader();
	var marketVersion = header && !!header.marketVersion ? header.marketVersion : null;

	return marketVersion;
};

/**
 * uses DPU Model`s {@linkcode module:configurator/dpu-model.getHeader getHeader} method
 * currently logged in to 'myAudi' (carstore)
 * @returns {boolean} - whether logged or not
 * @default false
 */
exports.isLoggedIn = function() {
	var authenticated = DPU_MODEL.getHeader() && DPU_MODEL.getHeader()['logged-in'] === true;

	return !!authenticated;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.setLogin setLogin} method
 * try logging in to 'myAudi' (carstore)
 * @param {boolean} loginState_ - the login state
 * @param {string} subsession_ - the subsession id
 * @returns {Promise} - loginPromise
 */
exports.setLogin = function(loginState_, subsession_) {
	return DPU_MODEL.setLogin(loginState_, subsession_);
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getSearchStopWords getSearchStopWords} method
 * @returns {string|null} - regEx to filter user search-inputs
 */
exports.getSearchStopWords = function() {
	return DPU_MODEL.getSearchStopWords();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConflicts getConflicts} method
 * @returns {Object|null} - conflict object
 */
exports.getConflicts = function() {
	return DPU_MODEL.getConflicts();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConflicts getConflicts} method
 * @returns {array|null} - triggers array
 */
exports.getConflictTriggers = function() {
	var triggers = null;
	var conflict = exports.getConflicts();

	if (conflict && conflict.triggers && conflict.triggers.length) {
		triggers = conflict.triggers;
	}
	return triggers;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getTransfers getTransfers} method
 * @returns {Object|null} - transfer object
 */
exports.getTransfers = function() {
	var transfers = DPU_MODEL.getTransfers();

	return transfers && transfers.deleted && transfers.deleted.length ? transfers : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getAudicode getAudicode} method
 * @returns {string|false} - audicode if exists, false else
 */
exports.getAudicode = function() {
	return DPU_MODEL.getAudicode();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getConfiguration getConfiguration} method
 * @returns {Object|null} - configuration object
 */
exports.getConfiguration = function() {
	return DPU_MODEL.getConfiguration();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getVersion getVersion} method
 * @returns {string|null} - version
 */
exports.getVersion = function() {
	return DPU_MODEL.getVersion();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getMemcacheVersion getMemcacheVersion} method
 * @returns {string|null} - version
 */
exports.getMemcacheVersion = function() {
	return DPU_MODEL.getMemcacheVersion();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getDpuUrl getDpuUrl} method
 * @returns {string|null} - version
 */
exports.getDpuUrl = function() {
	return DPU_MODEL.getDpuUrl();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getContext getContext} method
 * @returns {string|null} - version
 */
exports.getContext = function() {
	return DPU_MODEL.getContext();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getSubsessionID getSubsessionID} method
 * @returns {string|null} - version
 */
exports.getSubsessionID = function() {
	return DPU_MODEL.getSubsessionID();
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.setSubsessionID setSubsessionID} method
 * @param {string} subsession_ - subsession
 * @returns {void} - version
 */
exports.setSubsessionID = function(subsession_) {
	return DPU_MODEL.setSubsessionID(subsession_);
};

/**
 * @returns {string|null} code - motorcode
 */
exports.getMotorcode = function() {
	var configuration = exports.getConfiguration();

	return !!(configuration && configuration.model) ? configuration.model : null;
};

/**
 * @returns {Object|null} assets
 */
exports.getConfigurationAssets = function() {
	var configuration = exports.getConfiguration();

	return !!(configuration && configuration.assets) ? configuration.assets : null;
};

/**
 * @returns {Object|null} images
 */
exports.getCarImages = function() {
	return DPU_MODEL.getImages();
};

/**
 * @returns {Object|null} ext colors grouped by families
 */
exports.getExtColors = function() {
	var items = __.getItemsByFamily('extcolors');

	return !!items ? __.groupByExtColorKey(items) : null;
};

/**
 * @returns {string} url
 */
exports.getDocrootUrl = function() {
	var url = window.location.protocol + '//' + window.location.host + (SETUPS.get('nemo.url.static') || '');

	if (url.charAt(url.length - 1) === '/') {
		url = url.slice(0, -1);
	}
	return url;
};

/**
 * if no carinfo.json exists the nemo mandant is not configurable
 * (e.g. EOS mandant)
 * @returns {boolean} configurable
 */
exports.isConfigurable = function() {
	return !!SETUPS.get('nemo.url.carinfo');
};

/**
 * nemo mandant is in preview mode
 * @returns {boolean} previewMode
 */
exports.isInPreviewMode = function() {
	return !!SETUPS.get('nemo.url.preview');
};

/**
 * get pages and nivigation tree (json)
 * @returns {Object|null} pageTree
 */
exports.getPageTree = function() {
	return SETUPS.get('pagetree');
};

/**
 * @returns {string|null} url for Audi-GoogleSearch
 */
exports.getSearchResultsPageUrl = function() {
	return SETUPS.get('searchResultsLink') || null;
};

/**
 * Search for Items (name,search,url) within the Navigation parts (Model-, Sitemap- Footernavigation)
 * @param {string} inputString_ - Input from search field
 * @param {string} navigationPart_ - where to search (model||siteMap||footer)
 * @returns {Array} {name search url}
 * @dafault []
 */
exports.searchNavigation = function(inputString_, navigationPart_) {
	var navigationItems = [];
	var whereArr = [];
	var searchString = inputString_.toLowerCase();

	switch (navigationPart_) {
		case 'model':
			whereArr = __.getModelNavigationStorage();
			break;
		case 'siteMap':
			whereArr = __.getSiteNavigationStorage();
			break;
		case 'footer':
			whereArr = __.getFooterNavigationStorage();
			break;
		default:
			whereArr = [];
	}

	jQuery.each(whereArr, function(index, value) {
		if (!value.search) {
			return true;
		}
		if (value.search.indexOf(searchString) !== -1 && !!value.url) {
			navigationItems.push(value);
		}
	});
	return navigationItems;
};

/**
 * calls DPU-Model´s {@linkcode module:configurator/dpu-model.getItems getItems} method
 * @returns {Object|null} items - object with all items for the current carline
 */
exports.getItems = function() {
	return DPU_MODEL.getItems() || null;
};

/**
 * calls {@link module:configurator/dpu-model.isConfigurationStarted DPU_MODEL.isConfigurationStarted}
 * @returns {boolean} - has the configuration been started?
 * @default false
 */
exports.isConfigurationStarted = function() {
	return DPU_MODEL.isConfigurationStarted();
};

/**
 * calls {@link module:configurator/dpu-model.hasCustomConfiguration DPU_MODEL.hasCustomConfiguration}
 * @returns {boolean} has the user started a configuration other than the dafault?
 * @default false
 */
exports.hasCustomConfiguration = function() {
	return DPU_MODEL.hasCustomConfiguration();
};

/**
 * @param {string} id_ - prNumber of the item to check
 * @returns {boolean} - whether the id belongs to the selected Equipments or not
 * @default false
 */
exports.isOptionSelectedInConfiguration = function(id_) {
	var itemsArr = exports.getSelectedItemsIDsArray();

	return itemsArr.indexOf(id_) > -1;
};

/**
 * get all items which have been selected
 * @returns {Array} - array of pr-numbers
 * @default []
 */
exports.getSelectedItemsIDsArray = function() {
	var selectedItems = exports.getSelectedItems() || {};
	var selectedKeys = Object.keys(selectedItems);

	return selectedKeys;
};

/**
 * @returns {Object|null} - price object containing additional price ifo (e.g. OTR price)
 */
exports.getPrices = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.prices ? configuration.prices : null;
};

/**
 *  * get items by their status * get items by their status
 * @param {string} id_ - pr-number
 * @returns {Object|null} - the Item
 */
exports.getItem = function(id_) {
	var items = exports.getItems();

	return !!(items && items[id_]) ? items[id_] : null;
};

/**
 * get items by their status
 * @param {string|RegEx} statusPattern_ - explicit 5-digit staus code or a equivalent RegEx
 * @returns {Object|null} - items matching the staus pattern
 * @example
 * var selectedStandardMatcher = new RegExp("(0|1){3}11" );
 * var items = getItemsByStatus(selectedStandardMatcher);
 */
exports.getItemsByStatus = function(statusPattern_) {
	var itemsOrig = exports.getItems();
	var items = null;
	var regEXPattern = statusPattern_;

	if (!!regEXPattern && itemsOrig) {
		if (typeof regEXPattern.compile !== 'function') {
			regEXPattern = new RegExp(regEXPattern);
		}

		jQuery.each(itemsOrig, function(key, value) {
			if (!!value.status && regEXPattern.test(value.status)) {
				items = items || {};
				items[key] = value;
			}
		});
	}
	return items;
};

/**
 * get items by their family
 * @param {string|RegEx} familyPattern_ - explicit family name or a equivalent RegEx
 * @returns {Object|null} - items matching the family pattern
 * @example
 * var selectedStandardMatcher=new RegExp("(0|1){3}11" );
 * var items=getItemsByFamily(selectedStandardMatcher);
 */
__.getItemsByFamily = function(familyPattern_) {
	var itemsOrig = exports.getItems();
	var items = null;
	var regEXPattern = familyPattern_;

	if (!!regEXPattern && itemsOrig) {
		if (typeof regEXPattern.compile !== 'function') {
			regEXPattern = new RegExp(regEXPattern);
		}
		jQuery.each(itemsOrig, function(key, value) {
			if (!!value.family && regEXPattern.test(value.family)) {
				items = items || {};
				items[key] = value;
			}
		});
	}
	return items;
};

/**
 * public call for private function
 */
exports.getItemsByFamily = __.getItemsByFamily;

/**
 * @returns {string|null} - price rate
 */
exports.getConfigurationCarPriceRate = function() {
	var configuration = exports.getConfiguration();

	return !!configuration && !!configuration.rate ? configuration.rate : null;
};

/**
 * @returns {Object|null} - Link Object der Neuwagenboerse
 * @example
 * //object containing entrylinks for the VTP (Neuwagenboerse)
 * {"plainLink":"http://vtp.audi.com/anwb/controller.do?appId=ak&op=directEntry&embed=none",
 * "entryLink":"http://vtp.audi.com/anwb/controller.do?prString=50000|51614\\2014|4G209C\\1|F14 P5P5|F56VC|GPQDPQD|GPX4PX4|MEIL7M8&appId=ak&op=directEntry&fixHaendler=false&t_petr=D&t_gearext=Zg2&embed=none",
 * "queryLink":"http://vtp.audi.com/anwb/controller.do?act=search&v=5&prString=50000|51614\\2014|4G209C\\1|F14 P5P5|F56VC|GPQDPQD|GPX4PX4|MEIL7M8&appId=ak&fixHaendler=false&t_petr=D&t_gearext=Zg2&embed=none&callback=?"}"
 */
exports.getNwbLink = function() {
	var configuration = exports.getConfiguration();

	return !!(configuration && configuration.nwbLink) ? configuration.nwbLink : null;
};

/**
 * get JSON requestLink's base url for the VTP (Neuwagenboerse)
 * @returns {string} - url
 */
exports.getVtpRequestUrl = function() {
	return SETUPS.get('nemo.vtp.request.url');
};

/**
 * get entryLink's base url for the VTP (Neuwagenboerse)
 * @returns {string} - url
 */
exports.getVtpEntryUrl = function() {
	return SETUPS.get('nemo.vtp.entry.url');
};

/**
 * Extracts the configurator's status code from the dom
 * @param {jQuery-Element} $element_ - configurator item
 * @returns {string|null} - the configurator status
 */
exports.getDOMStatusCode = function($element_) {
	var _sClassName = !!$element_ ? $element_.get(0).className : '';

	if (_sClassName.indexOf('nm-j-configurator-status_') === -1) {
		return null;
	}
	return _sClassName.split('nm-j-configurator-status_')[1].split(' ')[0];
};

/**
 * check for local storage (modernizr)
 * @returns {boolean} - does the browser suppert the local.storage
 */
exports.supportsLocalStorage = function() {
	// TODO: #api-rfc-001 - what about following 'tell don't ask' using something like: Header.isAdobeAir() ?
	// fix for the poa
	if (window.navigator.userAgent.toLowerCase().match(/adobeair/i) !== null) {
		return true;
	}
	try {
		localStorage.setItem('test', 'test');
		localStorage.removeItem('test');
		return true;
	}
	catch (e) {
		if (e.code === window.DOMException.QUOTA_EXCEEDED_ERR && localStorage.length === 0) {
			return true;
		}
		return false;
	}
};

/**
 * getConfigurationFamilies
 * uses {@link module:configurator/dpu-model.getConfiguration DPU_MODEL.getConfiguration}
 * @returns {Object|null} - configuration families
 */
exports.getConfigurationFamilies = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.families ? configuration.families : null;
};

/**
 * getRecommendations
 * uses {@link module:configurator/dpu-model.getConfiguration DPU_MODEL.getConfiguration}
 * @returns {Array} - recommendations array
 */
exports.getRecommendations = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.recommendations ? configuration.recommendations : [];
};

/**
 * getDynamicRecommendations
 * uses {@link module:configurator/dpu-model.getConfiguration DPU_MODEL.getConfiguration}
 * @returns {Array} - recommendations array
 */
exports.getDynamicRecommendations = function() {
	var configuration = exports.getConfiguration();

	return configuration && !!configuration.empfehlungenPoC && !!configuration.empfehlungenPoC.recommendations
		? configuration.empfehlungenPoC.recommendations
		: [];
};


exports.resolvePackagesInPrString = function(prString) {
	let resolvedArray = [];
	if (prString){
		const splitted = prString.split('|');
		const configurationItems = this.getItems();

		for (let i=0; i < splitted.length; i++){
			const regex = new RegExp("([\\d\\w]{2,})_in_" + splitted[i], "gi");
			const resolvedPrStrings = Object.getOwnPropertyNames(configurationItems).reduce((filtered, item)=> {
				const match = regex.exec(item);
				if (match){
					if (!filtered.find(x=> x === match[1])) {
						filtered.push(match[1]);
					}
				}

				return filtered;
			}, [splitted[i]]);

			resolvedArray = [...resolvedArray, ...resolvedPrStrings];
		}

		return resolvedArray.join("|");
	}
};

// remove packages for configured items
exports.replacePackagesInPrString = function(prString) {
	let prStringLocal = prString;

	if (prString) {
		const regPackageRemoval = /([\d\w]{2,})_in_[\d\w]{2,}(|$)?/gi;
		let match;

		while ((match = regPackageRemoval.exec(prStringLocal)) !== null) {
			if (match && match.length > 1) {
				prStringLocal = prStringLocal.replace(match[0], match[1]);
			}
		}
	}

	return prStringLocal;
};

/**
 * @returns {Array} items - site navigation items
 * @default []
 */
__.getSiteNavigationStorage = function() {
	var storage = SETUPS.get('siteNavigation');

	return !!storage ? storage : [];
};

/**
 * @returns {Array} items - model navigation items
 * @default []
 */
__.getModelNavigationStorage = function() {
	// #api-003.7 - resolve dependency
	var storage = SETUPS.get('modelNavigation');

	return !!storage ? storage : [];
};

/**
 * Search for Items within the Config Itams
 * @param {string} searchString_ - search string
 * @param {boolean} hideInvisible_ - ignore invisible Items in the search (with status 10000 and 00000)
 * @returns {Object} - found items for the search term
 */
exports.searchItems = function(searchString_, hideInvisible_) {
	// allow search for PrStrings in the MSSC project
	var allowPrSearch = !!window.mss || STATE.getStateOfModeFor('debug') || false;
	var items = exports.getItems() || {};
	var itemsFound = [];
	var searchContent;
	var families = exports.getFamilies();
	var searchString = searchString_.toLowerCase();

	searchString = searchString
		.replace(/^\w\s/, ' ')
		.replace(/\s\w$/, ' ')
		.replace(/\s\w\s/, ' ')
		.trim();

	jQuery.each(items, function(index, value) {
		// eslint-disable-line complexity
		var family;

		if (!value.search || typeof value.search !== 'string') {
			return true;
		}
		searchContent = value.search;
		// additional search within prstrings?
		if (allowPrSearch && !!value.mbvId) {
			searchContent += ' | ' + value.mbvId.toLowerCase();
		}
		// match?
		if (searchContent.indexOf(searchString) !== -1) {
			// ignore invisible items (status 00000 and 10000)
			if (hideInvisible_ && (value.status === '00000' || value.status === '10000')) {
				return true;
			}
			// ignore items without family
			if (value.family === undefined) {
				return true;
			}
			// ignore items in families without name
			family = families[value.family];
			if (!family || !family.name) {
				return true;
			}
			itemsFound.push(value);
		}
	});
	return itemsFound;
};

/**
 * data is initialized (carinfo and version request are completed)
 * calls {@link module:configurator/dpu-model.isInitialized DPU_MODEL.isInitialized}
 * @returns {boolean} - whether the data is initialized or not
 */
exports.isInitialized = function() {
	return DPU_MODEL.isInitialized();
};

/**
 * get the corresponding carline page url
 * @param {string} carlineID_ -carline id (e.g. 'a4limo')
 * @returns {string|undefined} - base url of the carline page
 */
exports.getCarlineUrl = function(carlineID_) {
	var carlineID2Url = SETUPS.get('nemo.carlineID2Url') || {};
	var carlineUrl = carlineID2Url[carlineID_];

	if (!carlineUrl) {
		carlineID2Url = SETUPS.get('mssc.carlinemapping') || {};
		if (!!carlineID2Url) {
			carlineUrl = carlineID2Url[carlineID_];
			return !!carlineUrl ? carlineUrl.posterViewUrl : undefined;
		}
	}
	return !!carlineUrl ? carlineUrl.baseurl : undefined;
};

/**
 * get the carline page url for the current carline
 * @returns {string} - base url of the carline page
 */
exports.getCurrentCarlineUrl = function() {
	var carline = exports.getCarline();

	return exports.getCarlineUrl(carline);
};

__.d4 = function() {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); // eslint-disable-line no-bitwise
};
/**
 * @description Return a pseudo guid
 * @returns {string} uId
 */
__.guid = function() {
	return __.d4() + __.d4() + __.d4() + __.d4() + __.d4();
};

/**
 * get a unique ID for the current configuration
 * @returns {string} - the unique ID (the already set or the new generated)
 */
exports.getConfigurationId = function() {
	__.configurationID = __.configurationID || __.guid();

	return __.configurationID;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getModelsInfo getModelsInfo} method
 * @param {string} model_ - optional param to return the info of a specific model
 * @returns {Object} model info of a specific item (or all)
 */
exports.getModelsInfo = function(model_) {
	return DPU_MODEL.getModelsInfo(model_);
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getModelsInfoFootnotes getModelsInfoFootnotes} method
 * @param {string} model_ - optional param to return the info of a specific model
 * @returns {Object} model info footnotes of a specific item (or all)
 */
exports.getModelsInfoFootnotes = function(model_) {
	return DPU_MODEL.getModelsInfoFootnotes(model_);
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getModelsInfoSectionFootnotes getModelsInfoSectionFootnotes} method
 * @param {string} model_ - optional param to return the info of a specific model
 * @returns {Object} model info section #footnotes of a specific item (or all)
 */
exports.getModelsInfoSectionFootnotes = function(model_) {
	return DPU_MODEL.getModelsInfoSectionFootnotes(model_);
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getModelsInfo getModelsInfo} method
 * @param {string} model_ - optional param to return the info of a specific model
 * @returns {Object} model info of a specific item (or all)
 */
exports.getCurrentModelInfo = function() {
	var currentModel = exports.getEngine();

	return !!currentModel ? exports.getModelsInfo(currentModel) : null;
};

/**
 * calls DPU Model`s {@linkcode module:configurator/dpu-model.getModelDisclaimer getModelDisclaimer} method
 * @param {string} model_ - The model identifier.
 * @returns {Object|null} the model disclaimer object if available, null otherwise.
 */
exports.getModelDisclaimer = function(model_) {
	return DPU_MODEL.getModelDisclaimer(model_);
};

/**
 * get AVE String (for AudiRenderService)
 * @returns {string|null} - ave
 */
exports.getAveString = function() {
	var configuration = exports.getConfiguration();

	return !!(configuration && configuration.ave) ? configuration.ave : null;
};

/**
 * get MatchingAttributes String for Stock Car Search (for SCS API)
 * @returns {Array} - matching attributes array
 */
exports.getSCSmatchingAttributes = function() {
	var stockCarAttributes = exports.getSCSAttributes();

	return !!(stockCarAttributes && stockCarAttributes.attrs) ? stockCarAttributes.attrs : [];
};

/**
 * get Stock Car Attributes (for SCS API)
 * @returns {Object|null} - stock car search attributes
 */
exports.getSCSAttributes = function() {
	var configuration = exports.getConfiguration();

	return !!(configuration && configuration['stock-car-attrs']) ? configuration['stock-car-attrs'] : null;
};

/**
 * get translated ErrorMessage by its DPU ERROR_CODE
 * @param {Object|string} error - DPU-Error
 * @returns {string} translated error message
 */
exports.getErrorMessage = function(error) {
	const i18nErrorMessage = __.getErrorMessageFromI18N(error);
	const dpuErrorMessage = __.getErrorMessageFromDpuError(error);
	const errorMessage = i18nErrorMessage ? i18nErrorMessage : dpuErrorMessage;

	if (!errorMessage) {
		return __.getErrorMessageFromI18N('DEFAULT');
	}

	return errorMessage;
};

__.getErrorMessageFromI18N = function(error) {
	const errorCode = typeof error === 'string' ? error : error.id;
	return window.i18n['error.message.' + errorCode];
};

__.getErrorMessageFromDpuError = function(error) {
	return error.message ? error.message : null;
};

/**
 * generate fallback image path
 * @param {string} view_ - carline view (exterior,exterior_front,exterior_back...)
 * @param {string} size_ - image size (thumbnail,thumbnail.new,stage,stage.new)
 * @returns {string|boolean} - alt image path
 */
exports.getAltImagePath = function(view_, size_) {
	var carline;
	var altImagePath;
	var carlineFallbackGroup;

	carline = exports.getCarline();
	if (!carline || !view_ || !size_) {
		return false;
	}

	carlineFallbackGroup = __.setFallbackGroup(carline);
	altImagePath = __.setImagePath(carlineFallbackGroup, view_, size_);

	return altImagePath;
};

/**
 * set the right fallback group for the given carline
 * @param {string} carline_ - the carline to proof
 * @returns {string} - the fallback
 */
__.setFallbackGroup = function(carline_) {
	var fallbackGroup;

	fallbackGroup = carline_.substring(0, 1);

	// RS- and S-Models get the same Fallback-Renderings as A-Models
	if (carline_.indexOf('rs') === 0 || carline_.indexOf('s') === 0) {
		fallbackGroup = 'a';
	}

	// TT-Models are shown as R(8)-Models
	if (carline_.indexOf('tt') === 0) {
		fallbackGroup = 'r';
	}

	return fallbackGroup;
};

/**
 * set the right image path for the given view and carline
 * @param {string} carlineFallbackGroup_ - the given carline fallback group for the url
 * @param {string} view_ - carline view (exterior,exterior_front,exterior_back...)
 * @param {string} size_ - image size (thumbnail,thumbnail.new,stage,stage.new)
 * @returns {string} - the alt image path for the public function
 */
__.setImagePath = function(carlineFallbackGroup_, view_, size_) {
	var fileExtension = '.jpg';
	var imagePath;

	if (view_ !== 'selection-compare') {
		imagePath = carlineFallbackGroup_ + '-' + size_.split('.')[0] + '-' + view_ + fileExtension;
	}
	else {
		fileExtension = '.jpg';
		imagePath = carlineFallbackGroup_ + '-' + view_ + fileExtension;
	}

	if (typeof mss !== 'undefined') {
		imagePath = exports.getDocrootUrl() + '/cms4i-nemo-mss/fallback-renderings/' + imagePath;
	}
	else {
		imagePath = exports.getDocrootUrl() + '/cms4i-nemo/fallback-renderings/' + imagePath;
	}

	return imagePath;
};

exports.getMinPriceForItemIds = function(itemIds) {
	const items = itemIds.map(id => exports.getItem(id));
	const minPricedItem = items.reduce((minPrice, item) => {
		if (!minPrice
			|| item.priceValue === undefined && minPrice.priceValue !== undefined
			|| item.priceValue < minPrice.priceValue) {
			return item;
		}
		return minPrice;
	}, false);
	return minPricedItem && minPricedItem.priceValue ? minPricedItem.price : '';
};

/**
 * @returns {Array} items - footer navigation items
 * @default []
 */
__.getFooterNavigationStorage = function() {
	var storage = SETUPS.get('footerNavigation');

	return !!storage ? storage : [];
};

exports.openEngineDisclaimerLayer = function() {
	DPU_MODEL.sendEngineDisclaimerLayerEvent();
};

export {exports as dpuApi};
