import {appEvents as EVENTS} from 'core-application';
import {dpuApi as DPU_API} from './dpu/api';
import {dom as DOM_UTILS} from 'core-utils';
const __ = {},
	exports = {
		'__': __
	};
/**
 * add events
 * @returns {void}
 */
__.addEvents = function() {
	__.eventBus.on(EVENTS.VERSION_LOAD_COMPLETE, __.setHiddenItemsFromVersionResponse);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.setHiddenItemsFromVersionResponse);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.setHiddenItemsFromVersionResponse);
};
/**
 * set hidden items from version response
 * @param {Object} eventData - the domElement from the event to operator on
 * @returns {void}
 */
__.setHiddenItemsFromVersionResponse = function(eventData) {
	var domElement = !!eventData && !!eventData.domElement ? eventData.domElement : document;
	var items = DOM_UTILS.getElementsArray('.nm-j-configurator-item', domElement);
	var hiddenItems = DPU_API.getHiddenItemIDsFromVersion();
	var i, j;

	if (!!hiddenItems && hiddenItems !== null) {
		for (i=0; i<hiddenItems.length; i++) {
			for (j=0; j<items.length; j++) {
				if (hiddenItems[i] === items[j].getAttribute('data-configurator-id')) {
					items[j].setAttribute('data-visibility', 'hidden');
				}
			}
		}
	}
};
/**
 * initialize
 * @param {EventEmitter} eventBus_ - the event bus
 * @returns {void} nothing
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();

};

export {exports as hiddenVersionItems};

