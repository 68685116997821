import {appEvents as EVENTS} from 'core-application';
import {dpuModel as DPU_MODEL} from './dpu/model';
import {dpuApi as DPU_API} from "./dpu/api";

const __ = {},
	exports = {
		__: __
	};


__.handleLayerLoaded = function () {
	const wrapper = document.querySelector('.nm-md-media-library-configuration-buttons');

	if (wrapper) {
		const configurationButton = wrapper.querySelector('.nm-md-media-library-configuration-button');
		const currentId = wrapper.getAttribute('data-configurator-id');
		const enginesObject = DPU_MODEL.getModelsInfo(currentId);

		if (enginesObject) {
			const item = DPU_API.getItem(currentId);

			if (item?.status === '11010') {
				configurationButton.classList.add('nm-md-media-library-configuration-button--inactive');
			}
		}
	}
};

__.addEvents = function () {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.MODELSINFO_LOAD_COMPLETE, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleLayerLoaded);
};

exports.initialize = function (eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};

export {exports as mediaLibrary};
