import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';

const __ = {},
	exports = {
		__: __
	};

__.defaults = {
	expertContainerSelector: '.nm-mode-expert',
	selectorDelegateClick: 'nm-toggle',
	toggleClass: 'nm-toggle-open',
	optionsListHeadSelector: '.nm-options-list-head, .nm-module-options-container-headline',
	optionsGroupListWrapperSelector: '.nm-mode-expert-group-list-wrap',
	optionsGroupWrap: '.nm-mode-expert-group-wrap',
	statusClassPrefix: 'nm-j-configurator-status_',
	listClosedClass: 'nm-closed',
	configurationItemSelector: '[class*="nm-j-configurator-status_"]',
	hiddenClass: 'nm-hidden',
	counterWrapperSelector: '.nm-module-options-container-selected-items',
	counterSelector: '.nm-selected-items'
};

__.handleToggleClick = function(event_) {
	const toggleBtn = DOM_UTILS.closest(event_.target, '.' + __.defaults.selectorDelegateClick),
		optionsGroupListContainer = DOM_UTILS.closest(toggleBtn, __.defaults.optionsGroupWrap),
		optionsGroupListWrappers = DOM_UTILS.getElementsArray(__.defaults.optionsGroupListWrapperSelector, optionsGroupListContainer);

	if (toggleBtn.classList.contains(__.defaults.toggleClass)) {
		toggleBtn.classList.remove(__.defaults.toggleClass);
	}
	else {
		toggleBtn.classList.add(__.defaults.toggleClass);
	}

	optionsGroupListWrappers.forEach(__.toggleGroupList);
	__.eventBus.emit(EVENTS.CONTENT_RESIZE);
};

__.toggleGroupList = function(groupList) {
	if (groupList.classList.contains(__.defaults.listClosedClass)) {
		groupList.classList.remove(__.defaults.listClosedClass);
	}
	else {
		groupList.classList.add(__.defaults.listClosedClass);
	}
};

__.getStatusCodeForItem = function(item) {
	if (item.className.indexOf(__.defaults.statusClassPrefix) < 0) {
		return '';
	}
	return item.className.split(__.defaults.statusClassPrefix)[1].split(' ')[0];
};

__.isItemSelected = function(item_) {
	const statusCode = __.getStatusCodeForItem(item_),
		pattern = /[0,1]{3}1[0,1]/; // fourth position in the status code is equal 1 => selected?

	return pattern.test(statusCode);
};

__.isItemSelectedStandard = function(item_) {
	const statusCode = __.getStatusCodeForItem(item_),
		pattern = /1001[0,1]/;

	return pattern.test(statusCode);
};

__.isItemSelectedAndNotStandard = function(item) {
	const isSelected = __.isItemSelected(item),
		isStandard = __.isItemSelectedStandard(item);

	return isSelected && !isStandard;
};

__.getSelectedItemsCountFromList = function(listWrapper) {
	const items = DOM_UTILS.getElementsArray(__.defaults.configurationItemSelector, listWrapper);

	return items.reduce((counter, item) => (__.isItemSelectedAndNotStandard(item) ? counter + 1 : counter), 0);
};

__.updateListCounter = function(listHeader) {
	const counter = DOM_UTILS.getElement(__.defaults.counterSelector, listHeader);
	if (!DOM_UTILS.isElement(counter)) {
		return;
	}

	const listWrapper = DOM_UTILS.closest(listHeader, __.defaults.optionsGroupWrap),
		counterWrapper = DOM_UTILS.closest(counter, __.defaults.counterWrapperSelector),
		selected = __.getSelectedItemsCountFromList(listWrapper);

	counter.textContent = selected;

	__.setTitleForCounter(counterWrapper, counter, selected);
	__.toggleCounterVisibility(counterWrapper, selected);
};

__.toggleCounterVisibility = function(counterWrapper, selected) {
	if (selected > 0) {
		counterWrapper.classList.remove(__.defaults.hiddenClass);
	}
	else {
		counterWrapper.classList.add(__.defaults.hiddenClass);
	}
};

__.setTitleForCounter = function(counterWrapper, counter, selected) {
	let title = '';
	if (selected > 0) {
		title = selected + ' ';
		title += selected === 1 ? counter.getAttribute('data-text-selected-single') : counter.getAttribute('data-text-selected-multi');
	}
	counterWrapper.setAttribute('title', title);
};

__.updateLists = function() {
	const expertContainer = DOM_UTILS.getElementsArray(__.defaults.expertContainerSelector);
	const optionsListHeads = DOM_UTILS.getElementsArray(__.defaults.optionsListHeadSelector);

	if (expertContainer.length < 1) {
		return;
	}

	optionsListHeads.forEach(__.updateListCounter);
};

/**
 * Add Module-specific events
 * @returns {void} nothing
 */
__.addEvents = function() {
	__.domEventDelegate.on('click', __.defaults.optionsListHeadSelector, __.handleToggleClick);
	__.eventBus.on(EVENTS.DOM_UPDATED, __.updateLists);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.updateLists);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
	__.updateLists();
};
export {exports as expertmode};
