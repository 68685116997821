
export default class CarstoreListItem extends HTMLElement {
	constructor() {
		super();
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
	}

	connectedCallback() {
		this.audicode = this.getAttribute('data-audicode');
		this.form = this.querySelector('form');
		this.formSelect = this.querySelector('.carstore-list-item__form-select');
		this.submitButton = this.querySelector('.j-carstore-submit');
		this.cancelButton = this.querySelector('.j-carstore-cancel');

		this.addEvents();
	}

	addEvents() {
		if (this.submitButton) {
			this.submitButton.addEventListener('click', this.handleSubmitClick);
		}
		if (this.cancelButton) {
			this.cancelButton.addEventListener('click', this.handleCancelClick);
		}
	}

	handleSubmitClick(event) {
		event.preventDefault();
		if (this.formSelect.value === 'configuration-delete') {
			this.classList.toggle('carstore-list-item--show-dialogue');
		}
		else if (this.formSelect.value === 'configuration-load') {
			window.location.href = window.i18n['audicode.direct-url'] + this.audicode;
		}
	}

	handleCancelClick() {
		this.classList.remove('carstore-list-item--show-dialogue');
	}
}

if (window.customElements.get('audi-configurator-carstore-list-item') === undefined) {
	window.customElements.define('audi-configurator-carstore-list-item', CarstoreListItem);
}


