const template=`{{?it.length}}
	{{~ it :group }}
		{{? !!group.family}}
			{{? !!group.items}}


			/*hide groups where all elements have hidden status*/
			{{var $hideGroup = false;}}
			{{~ group.items :itemcheck }}
				{{? !!itemcheck.status && itemcheck.status == "00000"}}
					{{$hideGroup = true;}}
				{{?}}
			{{~}}

			{{? !$hideGroup}}
			<ul class="nm-equipment-search-result-group">
				<li>
					<div class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">{{=group.family.name}}</div>
					<ul class="nm-equipment-search-result-list">
					{{~ group.items :item }}
						<li class="nm-j-configurator-item nm-j-configurator-status_{{=item.status}} clearfix"
						data-configurator-id="{{=item.id}}">
							<div class="nm-equipment-search-result-item-wrap">
								<div class="nm-equipment-search-result-image">
									{{? !!item.image}}
									<img src="{{=item.image}}" alt="{{=item.name}}" class="nm-image" />
									{{?}}
								</div>

								<div class="nm-equipment-search-result-content-wrap">
									<div class="nm-equipment-search-result-desc">
										<p class="nm-j-configurator-name nm-el-lbl nm-el-lbl-01 nm-at-lbl-b">
											{{=item.name}}
											{{? !!item.nonrenderable && item.nonrenderable == true }}
												<span class="nm-el-pg nm-el-pg-04"><br />{{=window.i18n['configuration.not-rendable-message']}}</span>
											{{?}}
										</p>
										{{? !!item.description}}
										<p class="nm-equipment-search-result-description nm-el-pg nm-el-pg-04">
											{{= item.description || ''}}
										</p>
										{{?}}
										{{? SETUPS.get('scopes.nemo.SHOW_EU_TYRE_LABEL') && item.family.includes('Raeder_Reifen')}}
											{{ var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); }}
											<feature-app id="tyre-label-link:equipment-search:{{=randomId}}" base-url="https://fa-tyre-label-link.cdn.prod.arcade.apps.one.audi/v2.1.2/fh/" src="app.js" server-src="app.node.js" config='{"optionId": "{{=item.id}}"}'></feature-app>
										{{?}}
									</div>
									<div class="nm-equipment-search-result-icons">
										{{?!!item.url}}
										<a class="nm-info nm-layerLink" href="{{=item.url}}" title="{{=window.i18n['more-information']}}"></a>
										{{?}}
										<span class="nm-conflict-icon" title="#I18N("nemo.ui.common.conflict" "Konflikt")"></span>
									</div>
									<div class="nm-equipment-search-result-price nm-el-lbl nm-el-lbl-01">
										{{? !!item.price}}
										<span class="nm-j-configurator-text-price">{{=item.price}}</span>
										{{?}}
									</div>
									<div class="nm-equipment-search-result-add-btn">
										<a class="nm-el-btn nm-el-btn-02 nm-j-configurator-delegate-click" href="#">
										    <span class="nm-at-btn-state01">{{=window.i18n['search-layer.add-item']}}</span>
										    <span class="nm-at-btn-state02">{{=window.i18n['search-layer.item-included']}}</span>
										    <span class="nm-at-btn-state03">{{=window.i18n['search-layer.remove-item']}}</span>
										</a>
									</div>
								</div>
							</div>
						</li>
					{{~}}
					</ul>
				</li>
			</ul>
			{{?}}
			{{?}}
		{{?}}
	{{~}}
{{?}}`;
export {template as equipmentSearchResultsTemplate};
