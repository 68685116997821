import {dpuApi} from './api';
import {appEvents} from 'core-application';
import {layerApi} from 'core-application';
import {template} from 'core-utils';
import {dom} from 'core-utils';
import {transferLayerTemplate} from './transfer-layer-template';

const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorDelegateTransferClick: '.nm-layer-transfer .nm-j-configurator-delegate-transfer-click'
};

__.transfer = null;
__.layerInstance = null;
__.layerIsOpening = false;
__.layerIsClosing = false;

/**
 * merge configuration responses containing a transfer conflict
 * with the CarinfoJSON data
 * @param {Object} data_ transfer data from the dpu
 * @returns {Object} merged transfer data
 */
__.mergeData = function(data_) {
	return {
		deleted: data_.deleted ? __.mergeItemList(data_.deleted) : [],
		transferred: data_.transferred ? __.mergeItemList(data_.transferred) : [],
		standard: data_.standard ? __.mergeItemList(data_.standard) : [],
		urls: data_.urls
	};
};

__.mergeItemList = function(items) {
	return items.map(item => dpuApi.getItem(item.id));
};

__.openLayer = function(layerName, tplHtml, closeCallBackFn) {
	let promise;

	if (__.layerIsClosing) {
		//  we have an old conflict which is still opening and not closed
		setTimeout(function() {
			__.openLayer(layerName, tplHtml, closeCallBackFn);
		}, 510);
		return;
	}

	__.layerIsOpening = true;
	promise = layerApi.open(layerName, tplHtml, closeCallBackFn);

	return promise.then(
		function(layerInstance) {
			__.layerIsOpening = false;
			return Promise.resolve(layerInstance);
		},
		function(err) {
			__.layerIsOpening = false;
			return Promise.reject(err);
		}
	);
};

__.closeLayer = function() {
	let promise;

	__.layerIsClosing = true;

	if (__.layerInstance === null && __.layerIsOpening) {
		//  the layer is being opened but we already had a click triggering a close
		//  delay the close for the time it takes to fully open the layer
		setTimeout(__.closeLayer, 510);
		return;
	}

	// Layer Instance returns promise on close
	promise = __.layerInstance.close();

	promise.then(
		function() {
			__.layerInstance = null;
			__.layerIsClosing = false;
			__.onLayerClose();
		},
		function() {
			__.layerIsClosing = false;
		}
	);
};

/*
 * close CallBack (e.g. on shader click)
 */
__.onLayerClose = function() {
	__.transfer = null;
};

/*
 * handle transfer layer abort or accept clicks
 */
__.handleTransferResolveClick = function(event) {
	let clickedButton, url, cancelLink, submitLink;

	if (event) {
		__.cancelEvent(event);
		// aktuellen Transfer schliessen
		clickedButton = dom.closest(event.target, __.oDefaults.sSelectorDelegateTransferClick);
		if (!!clickedButton) {
			url = clickedButton.getAttribute('href');
		}
	}
	else {
		cancelLink = dom.getElement(__.oDefaults.sSelectorDelegateTransferClick + '[data-transfer=\'cancel\']');
		if (dom.isElement(cancelLink)) {
			url = cancelLink.getAttribute('href');
		}
		else {
			submitLink = dom.getElement(__.oDefaults.sSelectorDelegateTransferClick + '[data-transfer=\'submit\']');
			url = submitLink.getAttribute('href');
		}
	}

	if (!!url) {
		url = SETUPS.get('nemo.url.dpu') + url;
		__.eventBus.trigger(appEvents.TRANSFER_CLOSE, {
			url: url
		});
	}

	__.closeLayer();
};

/**
 * prevent default event behavior
 * @param {Object} event_ e.g. e click event
 * @return {void} nothing
 */
__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

// fire conflict 'close'/abort if all layers are closed globally
// Event=> LAYER_BEFORE_CLOSE_ALL:'layer.before.closeall')
__.handleTransferClose = function() {
	// close Layer if opened and fire abort request!!!
	if (!!__.layerName) {
		// submit abort request upon before closing transfer layer
		__.handleTransferResolveClick();
	}
};

/**
 * handle configuration responses with transfer objects
 * @param {Object} transfer_ - transfer object
 * @returns {void} nothing
 */
exports.handleTransfer = function(transfer_) {
	var mergedData, templateType, templateHtml;

	mergedData = __.mergeData(transfer_);
	templateType = 'transfer-layer';
	templateHtml = template.render(transferLayerTemplate, mergedData);

	__.openLayer(templateType, templateHtml, __.handleTransferResolveClick).then(
		function(instance) {
			__.layerInstance = instance;
		},
		function(error) {
			console.warn('TRANSFER Layer open failed', error.message);
		}
	);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = dom.getEventDelegate('body');
	__.legacyEventBus.on('click', __.oDefaults.sSelectorDelegateTransferClick, __.handleTransferResolveClick);
};

export {exports as dpuTransfer};
