import {patternMatching as PATTERN} from 'core-global';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
const __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};
__.register = function() {
	PATTERN.register('vehicle_id', __.getVehicleId);
};

/**
 * checks for vehicle id in url
 * @method getVehicleId
 * @returns {Promise} promise
 */
__.getVehicleId = function() {
	return new Promise(function(resolve, reject) {
		const vehicleId = __.getUrlParameterByName('vehicleid', location.href);
		if (vehicleId) {
			resolve(vehicleId);
		}
		else {
			reject(new TypeError('load pattern -vehicleid- failed'));
		}
	});
};

/**
 * get url parameter by name
 * @method getUrlParameterByName
 * @param {string} name - get parameter
 * @param {string} url - url
 * @returns {string} values of get parameter
 */
__.getUrlParameterByName = function(name, url) {
	let regex, results, _name, _url;
	_url = url || window.location.href;
	_name = (name || '').replace(/[[\]]/g, '\\$&');
	regex = new RegExp('[?&]' + _name + '(=([^&#]*)|&|#|$)');
	results = regex.exec(_url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent((results[2] || '').replace(/\+/g, ' '));
};

exports.initialize = function() {
	__.register();
};
export {exports as patternVehicleId};
