import {appEvents as EVENTS} from 'core-application';
import {dpuApi as CONFIGURATOR_API} from './dpu/api';
const __ = {},
	exports = {
		'__': __
	};

let _instance = false;

// private vars
__.oDefaults = {
	sSelectorCart: '.nm-basket',
	sSelectorSnippetText: '.nm-j-configurator-text-'
};

/**
	 * Updates the values within the cart
	 * @param {Element} $el_ - the element
	 * @param {string} price_ - the price
	 * @returns {void}
	 */
__.updateAndAnimatePrice = function ($el_, price_) {
	$el_.stop(true, true)
		.animate({opacity:'0.01'}, 250, 'easeInSine', function(){ jQuery(this).text(price_); })
		.delay(400)
		.animate({opacity:'1'}, 300, 'easeOutSine');
};


__.hidePriceAndRate = function(_$el) {
	var _sKey, _$elItem;
	var _prop = {
		'price': {},
		'rate': {}
	};
	for (_sKey in _prop) {
		// eslint-disable-next-line no-prototype-builtins
		if (_prop.hasOwnProperty(_sKey)) {
			_$elItem = _$el.find([__.oDefaults.sSelectorSnippetText, _sKey].join(''));
			if (_$elItem.text() === '') {
				_$elItem.closest('div').addClass('nm-hidden');
			}
		}
	}
	// hide configuration start button
	// _$el.find(".nm-configuration-start").addClass("nm-hidden");
};

__.hideRateIfUnavailable = function(_prop) {
	var totalRateRaw;

	// no rate available => hide 'change rate' button
	// First we check for the `prices` property. If it's not there, something
	// else went wrong which we just ignore.
	if (!!_prop.prices) {
		totalRateRaw = _prop.prices.totalRateRaw;
		// We check for both undefined and a zero value
		if (typeof totalRateRaw === 'undefined' || parseFloat(totalRateRaw) === 0) {
			jQuery('body').addClass('nm-no-rate');
		}
		else {
			jQuery('body').removeClass('nm-no-rate');
		}
	}
};

__.updatePrice = function(_$el, _prop, event_) {
	var _sKey, _$elItem;
	var _price = _prop.price;

	for (_sKey in _prop) {
		// eslint-disable-next-line no-prototype-builtins
		if (_prop.hasOwnProperty(_sKey)) {
			_$elItem = _$el.find([__.oDefaults.sSelectorSnippetText, _sKey].join(''));
			// Preis und Rate wird uebersprungen
			if (_sKey === 'price' && !!_price && !!event_ && !!event_.data && !!event_.data.animate) {
				__.updateAndAnimatePrice(_$elItem, _price);
				continue;
			}
			_$elItem.text(_prop[_sKey]);
		}
	}
};

__.updateCartText = function (event_) {
	var _prop;
	var _$el = jQuery(__.oDefaults.sSelectorCart);

	// Configuration possible?
	if (CONFIGURATOR_API.isConfigurable()) {

		_prop = CONFIGURATOR_API.getConfiguration();
		// configuration available?
		if (!_prop) {
			return;
		}
		__.updatePrice(_$el, _prop, event_);
		__.hideRateIfUnavailable(_prop);
	}
	else {
		// hide price, rate and link when no configuration is possible
		__.hidePriceAndRate(_$el);
	}
};

__.updateEngineLinks = function () {
	var $patternLinks, motorCode;

	// #car-002
	if (CONFIGURATOR_API.isConfigurable()) {

		$patternLinks = jQuery('[data-link-pattern]');
		// #car-002
		motorCode = CONFIGURATOR_API.getMotorcode();
		// update the url of all engine links
		$patternLinks.each(function () {
			var $this = jQuery(this),
				patternLink=$this.attr('data-link-pattern');
			if (!!patternLink && patternLink.match(/{{=it.model}}/g)) {
				patternLink = patternLink.replace(/{{=it.model}}/, motorCode);
				$this.attr('href', patternLink);
			}

		});

	}
};

__.updateSummaryStatus = function(dataTemplate) {
	var isSummary = (dataTemplate === 'summary' || dataTemplate === 'onepage_configurator');

	if (isSummary) {
		jQuery('body').addClass('nm-is-summary');
	}
	else {
		jQuery('body').removeClass('nm-is-summary');
	}
};

__.updateBasketBodyClass = function($content, dataTemplate) {
	var isBasketThere = (jQuery('.nm-basket').length > 0);
	var isNemoPage = ($content.attr('data-type') === 'nemo');
	var isHomePage = (dataTemplate === 'home');

	if (isBasketThere && isNemoPage && !isHomePage) {
		jQuery('body').addClass('nm-has-basket');
	}
	else {
		// nemo homepage and nomo pages have no basket
		jQuery('body').removeClass('nm-has-basket');
	}
};

__.updateClasses = function (data) {
	var $content, dataTemplate;

	if (typeof data !== 'undefined' && typeof data.domElement !== 'undefined') {
		$content = jQuery(data.domElement).find('.nm-content');
	}
	else {
		$content = jQuery('.nm-content');
	}
	dataTemplate = $content.attr('data-template');

	__.updateSummaryStatus(dataTemplate);
	__.updateBasketBodyClass($content, dataTemplate);
};

__.handlerAnimated = function() {
	var event_ = {
		data: {
			animate: true
		}
	};
	__.updateCartText(event_);
	__.updateEngineLinks();
};
__.handlerNotAnimated = function() {
	var event_ = {
		data: {
			animate: false
		}
	};
	__.updateCartText(event_);
	__.updateEngineLinks();
};

__.addEvents = function () {
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handlerAnimated);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handlerNotAnimated);
	__.eventBus.on(EVENTS.CONFIG_START, __.handlerNotAnimated);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlerNotAnimated);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.updateEngineLinks);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.updateClasses);
};

exports.dispose = function() {

	__.eventBus.off(EVENTS.CONFIG_UPDATE, __.handlerAnimated);
	__.eventBus.off(EVENTS.LAYER_LOADED, __.handlerNotAnimated);
	__.eventBus.off(EVENTS.CONFIG_START, __.handlerNotAnimated);
	__.eventBus.off(EVENTS.PAGE_LOADED, __.updateEngineLinks);
	__.eventBus.off(EVENTS.PAGE_LOADED, __.updateClasses);
};

__.initialize = function () {
	// force singleton
	if (!_instance) {
		__.addEvents();
		_instance = true;
		__.updateClasses();
	}
};
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();
};
export {exports as cart};

