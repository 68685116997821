import {stateRegistry} from 'microkernel';

export const AUDICODE_STORE_ID = 'dbadAudicodeStore';

class AudicodeStore {
	constructor() {
		if (!AudicodeStore.instance) {
			this._initStore();
			AudicodeStore.instance = this;
		}
		return AudicodeStore.instance;
	}

	_initStore() {
		const initialData = {
			audicode: null,
			configuration: null
		};
		const actions = {
			update: this._update,
			invalidate: this._invalidate
		};
		stateRegistry.addStore(AUDICODE_STORE_ID, initialData, actions);
	}

	_update(state, params) {
		return {...state, audicode: params.audicode, configuration: params.configuration};
	}

	_invalidate(state) {
		return {...state, audicode: null, configuration: null};
	}
}

const audicodeStoreInstance = new AudicodeStore();
Object.freeze(audicodeStoreInstance);
