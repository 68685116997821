import {appEvents as EVENTS} from 'core-application';
import {template as RENDER} from 'core-utils';
import {dom as DOM_UTILS} from 'core-utils';
import {scsMatchingBasketTemplate as MATCHING_TPL} from './scs-matching-basket-template';
import {swipe as SWIPE} from 'core-utils';


const
	// Private
	__ = {},
	// Public API
	exports = {
		__: __
	};
// lookup for CSS selectors etc.
__.oDefaults = {
	'classBasketMatchingContainer': '.nm-basket-matching',
	'classBasketMatchingCarousel': '.nm-basket-matching-car-slider',
	'classBasketMatchingListSlider': '.nm-basket-matching-list-slider',
	'classBasketArrowLeft': '.nm-basket-matching-prev',
	'classBasketArrowRight': '.nm-basket-matching-next',
	'classBasketThumb': '.nm-basket-matching-list-small ul li label'
};
__.currentMatchingVehicles = null;
// reference to the global eventEmitter
__.eventBus = null;
// detail link
__.scDetailLinkPattern = null;

// global carousel variables
__.carouselItemCount = null;
__.carouselItemWidth = null;
__.carouselItemHeight = null;
__.carouselWidth = null;
__.carouselIndex = 0;
__.carouselLabelStep = false;
__.carouselItemWidthTransform = null;
/**
 * add listeners to events of interest for this modeule
 * @returns {void}
 */
__.addEvents = function () {
	__.eventBus.on(EVENTS.STOCKCAR_UPDATE, __.handleStockcarResults);
	__.addClickEvents();
};
__.addClickEvents = function () {
	var domDelegate = DOM_UTILS.getEventDelegate('body');
	domDelegate.on('click', __.oDefaults.classBasketArrowLeft, __.prevArrowClickHandler);
	domDelegate.on('click', __.oDefaults.classBasketArrowRight, __.nextArrowClickHandler);
	domDelegate.on('click', __.oDefaults.classBasketThumb, __.thumbClickHandler);
};
__.removeClickEvents = function () {
	var domDelegate = DOM_UTILS.getEventDelegate('body');
	domDelegate.off('click', __.oDefaults.classBasketArrowLeft, __.prevArrowClickHandler);
	domDelegate.off('click', __.oDefaults.classBasketArrowRight, __.nextArrowClickHandler);
	domDelegate.off('click', __.oDefaults.classBasketThumb, __.thumbClickHandler);
};
/**
 * handle page load and check if no mathcing is available
 * @returns {void}
 */
__.handlePageLoaded = function () {
	// only render if STCK matching-result exist
	if (__.currentMatching && document.querySelector(__.oDefaults.selectorModuleContainer)) {
		__.handleStockcarResults(__.currentMatching);
	}
};
/**
 * handleStockcarResults on stockcar.update event
 * stockcar.update handler
 * @param {Object} payload_ - Stockcar EventEmitter payload
 * @return {void}
 */
__.handleStockcarResults = function (payload_) {
	var renderData;
	if (!!payload_) {
		__.currentMatchingVehicles = payload_.vehicleBasic ? payload_.vehicleBasic : [];
		renderData = __.parseResonse(payload_);
		__.renderTemplate(renderData);
	}
};
/**
 * parse VTP response/prepare data for the template model
 * @param {Object} response_ - JSON rsponse
 * @returns {Object} cleaned oject for use in the template model
 */
__.parseResonse = function (response_) {
	var parsed = {
			'cars': []
		},
		i, j, car,
		carItem,
		len = response_.vehicleBasic.length;
	for (i = 0, j = len; i < j; i++) {
		car = response_.vehicleBasic[i];
		if (!!car) {
			carItem = {
				id: car.id,
				model: car.symbolicCarline.description,
				engine: car.modelCode.description,
				image: __.setImageSrc(car),
				price: __.getFormattedPrice(car.typedPrices, 'retail'),
				link: __.setDetailsURL(car.id),
				svdVersion: car.svdVersion,
				percentage: Math.round(car.percentage || car.displayPercentage)
			};
			parsed.cars.push(carItem);
		}
	}
	parsed.result_link = __.setResultsURL(response_.filterExpression);
	parsed.totalCount = len;
	return parsed;
};
__.setImageSrc = function (car_ = {}) {
	let image, renderImage, fallbackImage;
	if (car_.pictures && car_.pictures.length && (renderImage = car_.pictures.find(pic => pic.key === "n5c01"))) {
		image = renderImage.url;
	}
	else if (car_.fallbackPictures && car_.fallbackPictures.length && (fallbackImage = car_.fallbackPictures.find(pic => pic.key === "render_16x96"))) {
		image = fallbackImage.url;
	}
	return image;
};
/**
 * sets Results url
 *
 * @method setResultsURL
 * @param {string} _filterExpression - filter expression
 * @return {void} returns nothing
 */
__.setResultsURL = function (_filterExpression) {
	var url = __.getResultsLinkUrl() || '';
	url = url.replace(/SC_ATTR_FILTERS/i, _filterExpression);
	return url;
};
/**
 * returns result link url
 *
 * @method getResultLinkUrl
 * @return {string} returns url
 */
__.getResultsLinkUrl = function () {
	var el = document.querySelector(__.oDefaults.classBasketMatchingContainer);
	return el ? el.getAttribute('data-result-url') : null;
};
/**
 * sets Details url
 *
 * @method setDetailsURL
 * @param {string} _vehicleId - id of the current vehicle
 * @return {void} returns nothing
 */
__.setDetailsURL = function (_vehicleId) {
	var url = __.getDetailsLinkUrl() || '';
	url = url.replace(/SC_VEHICLE_ID/i, _vehicleId);
	return url;
};
/**
 * returns detail link url
 *
 * @method getDetailsLinkUrl
 * @return {string} returns url
 */
__.getDetailsLinkUrl = function () {
	var el = document.querySelector(__.oDefaults.classBasketMatchingContainer);
	return el ? el.getAttribute('data-detail-url') : null;
};
__.getFormattedPrice = function (array, type) {
	var len = array.length;
	while (len--) {
		if (array[len].type === type) {
			return array[len].formatted;
		}
	}
	return null;
};

/**
 * render template to the dom
 * @param {Object} renderData_ [template data model
 * @returns {void}
 */
__.renderTemplate = function (renderData_) {
	var module = document.querySelector(__.oDefaults.classBasketMatchingContainer),
		tpl_string;
	if (module) {
		module.innerHTML = '';
		if (renderData_.cars.length > 1) {
			tpl_string = RENDER.render(MATCHING_TPL, renderData_);
			module.innerHTML = tpl_string;
			__.initCarousel();
		}
	}
};
/**
 * init carousel
 * @returns {void}
 *
 */
__.initCarousel = function () {
	var carousel = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul'),
		itemLast = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul li:last-child');
	__.carouselItemCount = parseInt(carousel.childNodes.length, 10);
	__.carouselItemWidthTransform = (100 / __.carouselItemCount);
	__.carouselItemWidth = '100';
	__.carouselWidth = (100 * __.carouselItemCount) + '%';
	carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(-' + __.carouselItemWidthTransform + '%);');
	carousel.insertBefore(itemLast, carousel.firstChild);
	SWIPE.register(__.onSwipeFinishHandler, __.oDefaults.classBasketMatchingCarousel, {
		swipedistance: 50
	});
};
/**
 * get current index
 * @returns {Number} index of last selected item
 * */
__.getCurrentIndex = function () {
	var inputs = DOM_UTILS.getElementsArray(__.oDefaults.classBasketMatchingListSlider),
		c_index = 0;
	inputs.forEach(function (itm, index) {
		if (itm.checked) {
			c_index = index;
		}
	});
	return c_index;
};
/**
 * set current index
 * @param {Number} index_ - index of new selected element
 * @returns {void}
 * */
__.setCurrentIndex = function (index_) {
	var oldIndex = __.getCurrentIndex();
	if (index_ === oldIndex) {
		return;
	}
	__.updateThumbPosition(index_);
	__.updateCounter(index_ + 1);
	__.animation(oldIndex, index_);
};
/**
 * next arrow click handler
 * @returns {void}
 * */
__.nextArrowClickHandler = function () {
	var index = __.getCurrentIndex();

	index = ++index % __.carouselItemCount;
	__.setCurrentIndex(index);
};
/**
 * prev arrow click handler
 * @returns {void}
 * */
__.prevArrowClickHandler = function () {
	var index = __.getCurrentIndex();

	index = --index;
	if (index < 0) {
		index = __.carouselItemCount - 1;
	}
	__.setCurrentIndex(index);
};

/**
 * onSwipeFinishHandler
 * @param {tbd} data_ - tbd
 * @param {tbd} target_ - tbd
 * @return {null} returns nothing
 */
__.onSwipeFinishHandler = function (data_) {
	if (data_.direction && data_.move !== 0) {
		switch (data_.direction.horizontal) {
			case SWIPE.LEFT:
				__.nextArrowClickHandler();
				break;
			default:
				__.prevArrowClickHandler();
				break;
		}
	}
};
/**
 * animation
 * @param {Number} oldIndex_ - index of last selected element
 * @param {Number} newIndex_ - index of new selected element
 * @returns {void}
 * */
__.animation = function (oldIndex_, newIndex_) {
	var direction;
	__.removeClickEvents();
	if (oldIndex_ < newIndex_) {
		direction = 'next';
		if (oldIndex_ === 0 && newIndex_ === __.carouselItemCount - 1) {
			direction = 'prev';
		}
	}
	else {
		direction = 'prev';
		if (oldIndex_ === __.carouselItemCount - 1 && newIndex_ === 0) {
			direction = 'next';
		}
	}
	if (direction === 'prev') {
		__.animatePrev(oldIndex_, newIndex_);
	}
	else {
		__.animateNext(oldIndex_, newIndex_);
	}
};
/**
 * animate prev
 * @param {Number} oldIndex_ - index of last selected element
 * @param {Number} newIndex_ - index of new selected element
 * @returns {void}
 * */
__.animatePrev = function (oldIndex_, newIndex_) {
	var carousel = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul'),
		itemLast = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul li:last-child');

	DOM_UTILS.handleTransition(carousel).then(function (el) {
		el.classList.remove('slide-transition');
		if (oldIndex_ - newIndex_ > 1) {
			itemLast = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul li:last-child');
		}
		carousel.insertBefore(itemLast, carousel.firstChild);
		carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(-' + __.carouselItemWidthTransform + '%);');
		__.addClickEvents();
	});

	if (oldIndex_ - newIndex_ > 1) {
		carousel.insertBefore(itemLast, carousel.firstChild);
		carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(-' + __.carouselItemWidthTransform * 2 + '%);');
		carousel.classList.add('slide-transition');
		carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(0)');
	}
	else {
		carousel.classList.add('slide-transition');
		carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(0)');
	}
};
/**
 * animate next
 * @param {Number} oldIndex_ - index of last selected element
 * @param {Number} newIndex_ - index of new selected element
 * @returns {void}
 * */
__.animateNext = function (oldIndex_, newIndex_) {
	var carousel = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul'),
		itemfirst = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul li:first-child');
	DOM_UTILS.handleTransition(carousel).then(function (el) {
		el.classList.remove('slide-transition');
		el.appendChild(itemfirst, el.firstChild);
		if (newIndex_ - oldIndex_ > 1) {
			itemfirst = document.querySelector(__.oDefaults.classBasketMatchingCarousel + ' ul li:first-child');
			el.appendChild(itemfirst, el.firstChild);
		}
		el.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(-' + __.carouselItemWidthTransform + '%);');
		__.addClickEvents();
	}).catch(function (err) {
		console.log('Promise not completed ', err);
	});
	carousel.classList.add('slide-transition');
	carousel.setAttribute('style', 'width:' + __.carouselWidth + ';transform: translate(-' + __.carouselItemWidthTransform * ((newIndex_ - oldIndex_) < 0 ? 2 : (newIndex_ - oldIndex_ + 1)) + '%);');
};
/**
 * thumb click handler
 * @param {Target} target_ - current thumbnail target
 * @returns {void}
 * */
__.thumbClickHandler = function (target_) {
	var index = parseInt(target_.target.getAttribute('data-index'), 10);
	__.setCurrentIndex(index);
};
/**
 * update thumb position
 * @param {Number} index_ - set correct index
 * @returns {void}
 * */
__.updateThumbPosition = function (index_) {
	var inputs = DOM_UTILS.getElementsArray('.nm-basket-matching-list-slider');
	inputs.map(function (el) {
		el.checked = false;
	});
	inputs[index_].checked = true;
};
/**
 * update counter method
 * @param {Number} counter_ - current carousel count
 * @returns {void}
 * */
__.updateCounter = function (counter_) {
	document.querySelector('.nm-basket-matching-counter-current').innerHTML = counter_;
};
/**
 * main initialization method
 * @param {Object} eventBus_ - global EventBus/EventEmitter
 * @returns {void}
 * */
exports.initialize = function (eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};
export {exports as scsMatchingBasket};
