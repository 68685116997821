/**
 * @description Controller to handle all DOM updates for configurator Items (text, images, selectedItems etc.).
 */

import {dpuApi as CONFIGURATOR_API} from './api';
import {appEvents as EVENTS} from 'core-application';
import {dom} from 'core-utils';

const __ = {},
	exports = {
		__: __
	};
__.$stage = null;
__.oDefaults = {
	sStageID: '#nm-id-stage',
	sSelectorCarPriceRate: '.nm-car-price-rate',
	sSelectorRenderImage: '.nm-j-configurator-renderimage',
	sSelectorConfigurationSummary: '.nm-j-configuration-summary',
	sSelectorQuantityPicker: '.nm-quantity',
	sSelectorSnippetText: '.nm-j-configurator-text-',
	sSelectorSnippetTextContainer: '.nm-j-configurator-container-text-',
	sSelectorSnippetTextEec: '.nm-j-configurator-text-eec-',
	selectorSnippetTextEecCo2Costs: '.nm-j-configurator-text-eec-co2costs-',
	selectorModelText: '.js-configurator-model-text-',
	selectorModelImage: '.js-configurator-model-image',
	selectorEecImage: '.nm-j-configurator-image-eec',
	selectorTechdata: '.nm-j-configurator-techdata-',
	equipmentList: '.nm-module-summary-standard-equipment-list ul.nm-navigation'
};

__.textReplaceKeys = [
	'bpm',
	'carlineName',
	'count',
	'description',
	'eec-co2emission',
	'eec-consumption',
	'eec-consumptioninner',
	'eec-efficiency',
	'eec-electricitycost',
	'eec-energycost',
	'eec-yearlytaxcost',
	'financeGlobalDisclaimer',
	'financeParameters',
	'financeProductDisclaimer',
	'financeProductName',
	'modelPrice',
	'nettoBase',
	'nettoOptions',
	'optionsPrice',
	'pa',
	'packageDescription',
	'price',
	'pricenm-price',
	'rate',
	'rotrprice',
	'rotrRate',
	'servicePackage',
	'subname',
	'taxableBenefit1',
	'taxableBenefit2',
	'taxTotal',
	'totalRate',
	'totalWithCharges',
	'vat'
];

/**
 * Add Module-specific events
 * @returns {void}
 */
__.addEvents = function() {
	// handle view state updates
	__.eventBus.on(EVENTS.UPDATE_VIEW_STATE, __.handleViewStateUpdates);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.updateAllDomItems);
	// handle page update (bofore animation starts)
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.updateAllDomItems);
	// handle closing of a conflict or transfer layer
	__.eventBus.on(EVENTS.TRIGGER_DOM_UPDATE, __.handleConflictAndTransferResolves);
	__.eventBus.on(EVENTS.CONFIG_SEND, __.hideRenderImages);

	document.addEventListener('FOOTNOTES_RENDERED', __.handleFootnotesRendered);
};

__.handleFootnotesRendered = () => {
	__.updateEffiencyClass();
	__.updateTechdata();
	__.updateFinancialFootnote();
};

__.updateFinancialFootnote = () => {
	const configuration = CONFIGURATOR_API.getConfiguration();
	if (configuration && configuration.financeParameters) {
		__.setDOMText(jQuery('.audi-footnotes'), configuration);
	}
};

/**
 * Remove Module-specific events
 * @returns {void}
 */
__.removeEvents = function() {
	// handle view state updates
	__.eventBus.off(EVENTS.UPDATE_VIEW_STATE, __.handleViewStateUpdates);
	__.eventBus.off(EVENTS.CONFIG_UPDATE, __.updateAllDomItems);
	__.eventBus.off(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.off(EVENTS.LAYER_LOADED, __.updateAllDomItems);
	__.eventBus.off(EVENTS.TRIGGER_DOM_UPDATE, __.handleConflictAndTransferResolves);
	__.eventBus.off(EVENTS.CONFIG_SEND, __.hideRenderImages);
};
/**
 * Updates the status string on multiple DOM-elements
 * @param {HTMLElement} context_ context element
 * @returns {void} nothing
 */
__.updateDomStatusItems = function(context_) {
	var context = context_ ? context_ : jQuery('body');
	context.find('.nm-j-configurator-item').each(function() {
		var $item = jQuery(this);
		var itemID = $item.attr('data-configurator-id');
		var currentItem = CONFIGURATOR_API.getItem(itemID);

		if ($item.hasClass('nm-j-configurator-no-update')) {
			return true;
		}

		if (itemID && currentItem && currentItem.status) {
			__.setDOMStatusCode($item, currentItem.status);

			if (currentItem.message && currentItem.message[0].text && currentItem.message[0].text !== '') {
				__.setDebugOutput($item, currentItem.message[0].text);
			}
		}
	});
};

/*
 * update status Items when a page  opens
 */
__.handlePageLoaded = function(data) {
	let context = jQuery('body');
	if (!!data && 'domElement' in data) {
		context = jQuery(data.domElement);
	}

	__.updateDomStatusItems(context);
	__.updateDomTextItems(context);
	__.updateDomTextPrices();
	__.updateDomTextSummary();
	__.updateDomRenderImages();
	__.updateModeChangeItems();
	__.updateEffiencyClass();
	__.updateTechdata();
	__.updateFinancialFootnote();
	__.updateEnergyEfficiencyImage();
	__.eventBus.emit(EVENTS.DOM_UPDATED);
};

/**
 * Sets the new DOM status code
 * @param {Object} $element  - configurator item (jQuery element)
 * @param {string} newStatus new configuration status
 * @returns {void} nothing
 */
__.setDOMStatusCode = function($element, newStatus) {
	var domElement, className, oldClassName;
	if (!!newStatus) {
		domElement = $element.get(0);
		className = domElement.className;
		oldClassName = className;
		className = className.replace(
			/nm-j-configurator-status_[01]{5}/,
			['nm-j-configurator-status_', newStatus].join('')
		);

		if (oldClassName !== className) {
			domElement.className = className;
			// when item selection changes, update aria-checked attribute for screen readers as well
			const clickHandleWrapper = $element.find('.nm-j-configurator-delegate-click');
			const checkedAttrValue = clickHandleWrapper.attr('aria-checked');
			clickHandleWrapper.attr('aria-checked', !(checkedAttrValue === "true"));
		}
	}
};

/**
 * Sets DPU Debug Output Message
 * @param {jobject} $element  - configurator item (jQuery element)
 * @param {string} debugMessage_ message
 * @returns {void} nothing
 */
__.setDebugOutput = function($element, debugMessage_) {
	var item,
		debugMessage = debugMessage_;

	if (!!debugMessage) {
		debugMessage = ' (' + debugMessage + ')';
		item = $element.find('.nm-debug-item').length === 1 ? jQuery($element.find('.nm-debug-item')[0]) : $element;
		item.attr('data-message', debugMessage);
	}
};

/*
 * reset all status items after closing/cancelling a conflict layer
 */
__.handleConflictAndTransferResolves = function() {
	// reset all Status Items
	__.updateAllDomItems();
};

/**
 * handle all changes of the view states (e.g. switching the car´s perspective)
 * @param {Object} data_  - CustomEvent {"currentView":{String},"context":{String}}
 * @returns {void}
 */
__.handleViewStateUpdates = function(data_) {
	var $context;
	if (!!data_ && data_.domElement && data_.domElement.length) {
		$context = data_.domElement;
	}
	__.updateDomRenderImages($context);
};

__.changeLinkForTechDataLayer = function() {
	const navigationLinks = document.querySelectorAll('.nm-module-summary-standard-equipment-list ul.nm-navigation li a');

	if (navigationLinks.length === 0){
		return null;
	}

	const modelId = CONFIGURATOR_API.getConfiguration().model;

	navigationLinks.forEach(link => {
		if (link.href.includes('summary_layer_techdata.html')) {
			const url = new URL(link.href);
			url.pathname = url.pathname.replace('summary_layer_techdata.html', `summary_layer_techdata.${modelId}.html`);
			link.href = url.pathname; // Set the updated pathname as the new href
		}
	});
};

__.updateAllDomItems = function() {
	__.updateDomStatusItems();
	__.updateDomTextItems();
	__.updateDomTextPrices();
	__.updateDomTextSummary();
	__.updateDomRenderImages();
	__.updateModeChangeItems();
	__.updateEffiencyClass();
	__.updateTechdata();
	__.updateFinancialFootnote();
	__.updateEnergyEfficiencyImage();
	__.changeLinkForTechDataLayer();
	__.eventBus.emit(EVENTS.DOM_UPDATED);
};

/*
* update all prices within the "body"
*/
__.updateDomTextPrices = function() {
	const configuration = CONFIGURATOR_API.getConfiguration();

	if (!!configuration && !!configuration.prices) {
		const body = jQuery('body');
		__.setDOMText(body, configuration.prices);
		__.setConfigDataContainersVisibility(body, configuration.prices);
	}
};

/*
 * Updates Display of price or rate in basket.
 */
__.updateModeChangeItems = function() {
	var mode, modeClass, tableColumnLabelSearch, tableColumnLabelReplace, tableColumnHtml, tableColumnHeader;
	mode = CONFIGURATOR_API.getPriceRateMode();
	modeClass = 'nm-mode-' + mode;
	jQuery('body').removeClass('nm-mode-rate nm-mode-price');
	jQuery('body').addClass(modeClass);
	// update engine list
	tableColumnHeader = jQuery('.nm-engine-list a[data-module-maw-criteria=price]');
	if (tableColumnHeader.length > 0) {
		/** @TODO localizee price */
		tableColumnLabelSearch = mode === 'rate' ? 'Preis' : 'Rate';
		tableColumnLabelReplace = mode === 'rate' ? 'Rate' : 'Preis';
		tableColumnHtml = tableColumnHeader.html();
		tableColumnHeader.html(tableColumnHtml.replace(tableColumnLabelSearch, tableColumnLabelReplace));
	}
	// set checkbox in basket, sets checkbox in rate switch
	jQuery('.nm-price-rate-switch, .j-rate-switch').each(function() {
		this.checked = mode === 'rate';
	});
};

/**
 * Updates dom items according to canfigutator status stored in
 * @param {Object} $element_ jQuery Element
 * @returns {void} nothing
 */
__.updateDomTextItems = function($element_) {
	var $element = $element_ ? $element_ : jQuery('body');
	$element.find('.nm-j-configurator-item').each(function() {
		var $item = jQuery(this);
		var itemID = $item.attr('data-configurator-id');
		var configItem = CONFIGURATOR_API.getItem(itemID);
		if (configItem) {
			__.setDOMText($item, configItem);
		}
	});
};

/**
 * Sets the value of a DOM-element
 * @param {Object} $element jQuery Item
 * @param {Object} configData data to be patched
 * @returns {void} nothing
 */
__.setDOMText = function($element, configData) {
	// eslint-disable-next-line no-prototype-builtins
	const validKeys = __.textReplaceKeys.filter(key => configData.hasOwnProperty(key));
	validKeys.forEach(__.changeText($element, configData));
};

__.changeText = function($element, configData) {
	return key => {
		const $keyElement = $element.find(__.oDefaults.sSelectorSnippetText + key);
		const $footnote = $keyElement.find("a.audi-j-footnote-reference");

		if (key === "price") {
			$keyElement.html(configData['priceWithFees'] || configData[key]);
		}
		else {
			$keyElement.html(configData[key]);
		}

		if ($footnote.length > 0) {
			$keyElement.append($footnote);
		}
	};
};

__.setConfigDataContainersVisibility = function($element, configData) {
	__.textReplaceKeys.forEach(key => {
		const el = $element.find(__.oDefaults.sSelectorSnippetTextContainer + key);
		if (el) {
			// eslint-disable-next-line no-prototype-builtins
			if (configData.hasOwnProperty(key)
				&& configData[key] !== ''
				&& configData[key] !== null) {
				el.removeClass('nm-hidden');
			}
			else {
				el.addClass('nm-hidden');
			}
		}
	});
};

__.hideRenderImages = function() {
	jQuery(__.oDefaults.sSelectorRenderImage).each(function() {
		jQuery(this).addClass('nm-fade-out');
	});
};

/**
 * update all renderimages according to the current view state (car´s perspective)
 * @param {Object} $context_ - context within  renderimages have to be updated
 * @returns {void}
 */
__.updateDomRenderImages = function($context_) {
	var assets = CONFIGURATOR_API.getConfigurationAssets(),
		$context,
		imageType,
		imageView;
	if (!assets) {
		return;
	}
	$context = $context_ || jQuery('body');
	// data-renderimage-type='thumbnail' data-renderimage-view='$item.View'
	// eslint-disable-next-line complexity,max-statements
	jQuery(__.oDefaults.sSelectorRenderImage, $context).each(function() {
		var $item = jQuery(this);
		var src;
		var newURL;
		var altImg;
		var resizeParam;

		// size of the image (thumbnail etc.)
		imageType = $item.attr('data-renderimage-type');
		// current view of the car (exterior,exterior_front,exterior_back...) or default ('exterior')
		imageView = $item.attr('data-renderimage-view');
		// es gibt kein thumbanil.new image fuer interior und dashboard!!!
		// siehe NEMOBA-6345
		// if (imageType.indexOf('thumbnail')!==-1 && (imageView === "interior" || imageView === "dashboard" || imageView === "rearseat")) {
		//	imageType = imageType.split(".")[0];
		// }

		resizeParam = $item.attr('data-resize-param');

		if (!!imageType && !!imageView) {
			// is a specific view defined?else use currently selected view from the stage
			src = assets[imageView];

			// Image has to have a size
			if (!!src && !!src[imageType]) {
				newURL = CONFIGURATOR_API.getRenderUrl() + src[imageType];

				if (src[imageType].indexOf('/preview/') !== 0) {
					altImg = CONFIGURATOR_API.getAltImagePath(imageView, imageType);
					if (!!altImg) {
						newURL += '?alt=' + altImg;
					}
				}

				if (!!resizeParam) {
					newURL += newURL.indexOf('?') !== -1 ? '&' : '?';
					newURL += resizeParam;
				}

				if ($item.is('img')) {
					if (newURL !== $item.attr('src')) {
						$item
							.removeClass('nm-fade-out nm-fade-in')
							.addClass('nm-fade-out')
							.delay(300)
							.on('load', function(event) {
								jQuery(event.target)
									.removeClass('nm-fade-out nm-fade-in')
									.addClass('nm-fade-in')
									.delay(300)
									.removeClass('nm-fade-in');
							})
							.attr('src', newURL);
					}
					else {
						$item
							.removeClass('nm-fade-out nm-fade-in')
							.addClass('nm-fade-in')
							.delay(300)
							.removeClass('nm-fade-in');
					}
				}
				else if ($item.is('source')) {
					$item.attr('srcset', newURL);
				}
				else if ($item.attr('data-url') !== newURL) {
					$item.attr('data-url', newURL);
					$item.css('backgroundImage', 'url(' + newURL + ')');
				}
			}
			else {
				$item
					.removeClass('nm-fade-out nm-fade-in')
					.addClass('nm-fade-in')
					.delay(300)
					.removeClass('nm-fade-in');
			}
		}
		else {
			$item
				.removeClass('nm-fade-out nm-fade-in')
				.addClass('nm-fade-in')
				.delay(300)
				.removeClass('nm-fade-in');
		}
	});
};

__.updateTechdata = function() {
	const configuration = CONFIGURATOR_API.getConfiguration();
	if (configuration && configuration.techdata) {
		Object.entries(configuration.techdata).forEach(techdataEntry => {
			__.updateTechdataEntry(techdataEntry[0], techdataEntry[1]);
		});
	}
};

__.updateTechdataEntry = function(techdataKey, techdataValue) {
	[...document.querySelectorAll(__.oDefaults.selectorTechdata + techdataKey)].forEach(techdataElement => {
		techdataElement.innerHTML = techdataValue;
	});
};

__.updateEffiencyClass = function() {
	const configuration = CONFIGURATOR_API.getConfiguration();
	const efficiencyClasses = document.querySelectorAll('.nm-j-configurator-techdata-efficiency-class');

	if (configuration && configuration.eec && configuration.eec.efficiency && efficiencyClasses.length > 0) {
		[...efficiencyClasses].forEach(element => {
			element.innerHTML = configuration.eec.efficiency;
			element.classList.remove('nm-hidden');
			if (element.hasAttribute('data-has-techdata-container')) {
				__.updateTechdataContainerVisibility(element);
			}
		});
	}
};

__.updateTechdataContainerVisibility = function(element) {
	const container = dom.closest(element, '.nm-j-configurator-techdata-container');
	if (container) {
		container.classList.remove('nm-hidden');
	}
};

/**
 * Update configuration item, a.k.a "cart". Gets values from
 * STORAGE.get('complete','configurator')
 * @returns {void}
 */
// eslint-disable-next-line complexity, max-statements
__.updateDomTextSummary = function() {
	// update the efficiency-details for the engine
	let motorcode = CONFIGURATOR_API.getMotorcode();
	let engineItem = CONFIGURATOR_API.getItem(motorcode);
	let modelItem = CONFIGURATOR_API.getModelsInfo(motorcode);
	let configuration = CONFIGURATOR_API.getConfiguration();
	if (!configuration) {
		console.warn('updateDomTextSummary: no configuration available!');
	}
	let eecDetails = configuration && !!configuration.eec ? configuration.eec : {};
	let key, eecImage;

	if (configuration) {
		__.setDOMText(jQuery(__.oDefaults.sSelectorConfigurationSummary), configuration);
	}

	// update summary content
	let $summaryContainer = jQuery("[data-template='summary']");
	// merge engine model data (static) with specific configuration engine data (dynamic)
	engineItem = jQuery.extend(modelItem, engineItem);
	// patch for markets without dynamic EEC (e.g. CH) => use modelInfo 'energy-efficiency-category' instead!!!
	if (!!eecDetails && !eecDetails.efficiency && !!engineItem && engineItem['energy-efficiency-category']) {
		eecDetails.efficiency = engineItem['energy-efficiency-category'];
	}
	if ($summaryContainer.length) {
		if (configuration && !!configuration.description) {
			$summaryContainer.find('.nm-j-configurator-text-description').html(configuration.description);
		}
		// update the model-specific informations in the dom
		if (!!engineItem) {
			key = '';
			for (key in engineItem) {
				// eslint-disable-next-line no-prototype-builtins
				if (engineItem.hasOwnProperty(key)) {
					// eslint-disable-next-line max-depth
					if (key === 'image') {
						$summaryContainer.find(__.oDefaults.selectorModelImage).attr('src', engineItem[key]);
					}
					else {
						$summaryContainer.find([__.oDefaults.selectorModelText, key].join('')).html(engineItem[key]);
					}
				}
			}
		}

		// update the configuration-specific informations in the dom (within the summary context)
		if (!!eecDetails) {
			key = '';
			for (key in eecDetails) {
				// eslint-disable-next-line no-prototype-builtins
				if (eecDetails.hasOwnProperty(key)) {
					$summaryContainer.find([__.oDefaults.sSelectorSnippetTextEec, key].join('')).html(eecDetails[key]);
				}
			}

			// change the efficiency-image
			if (!!eecDetails.efficiency && !!eecDetails['efficiency-images']) {
				eecImage = eecDetails['efficiency-images']['eec_image'];
				$summaryContainer.find(__.oDefaults.selectorEecImage).attr('src', eecImage);
			}

			const co2Costs = eecDetails.co2Costs;
			if (!!co2Costs) {
				for (key in co2Costs) {
					// eslint-disable-next-line no-prototype-builtins, max-depth
					if (co2Costs.hasOwnProperty(key)) {
						$summaryContainer.find([__.oDefaults.selectorSnippetTextEecCo2Costs, key].join('')).html(co2Costs[key]);
					}
				}

			}
		}
	}

	if (CONFIGURATOR_API.isConfigurable()) {
		const carPriceRateElements = [...document.querySelectorAll(__.oDefaults.sSelectorCarPriceRate)];
		const hiddenClass = 'nm-hidden';
		const hasPriceRate = !!CONFIGURATOR_API.getConfigurationCarPriceRate();

		if (hasPriceRate) {
			carPriceRateElements.forEach((element) => {
				element.classList.remove(hiddenClass);
			});
		}
		else {
			carPriceRateElements.forEach((element) => {
				element.classList.add(hiddenClass);
			});
		}

		__.toggleFinancingFootnoteVisibility(hasPriceRate);
	}
};

__.toggleFinancingFootnoteVisibility = (isVisible) => {
	const footnote = document.querySelector('.nm-footnote-finance');
	const hiddenClass = 'nm-hidden';
	const isFootnoteEngineActive = SETUPS.get('nemo.footnote-engine.active');

	if (footnote && !isFootnoteEngineActive) {
		if (isVisible) {
			footnote.classList.remove(hiddenClass);
		}
		else {
			footnote.classList.add(hiddenClass);
		}
	}
};

__.updateEnergyEfficiencyImage = function() {
	const configuration = CONFIGURATOR_API.getConfiguration();
	const summaryContainer = document.querySelector('[data-template="summary"]');
	if (summaryContainer && !!configuration && !!configuration.eec && configuration.eec['efficiency-images'] && configuration.eec['efficiency-images'].eec_character) {
		const imageElements = document.querySelectorAll('.nm-j-configurator-efficiency-class-image');
		[...imageElements].forEach((image) => {
			image.src = configuration.eec['efficiency-images'].eec_character;
		});
	}
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.$stage = jQuery(__.oDefaults.sStageID);
	// Configuration possible?
	if (CONFIGURATOR_API.isConfigurable()) {
		__.addEvents();
	}
	// check for login via SSO-Token (#NEMOBA-5)
	if (document.cookie.indexOf('myAudi=') > -1) {
		// set logged-in body class
		jQuery('body').addClass('nm-logged-in');
	}
};
export {exports as domState};


