import {signal} from 'core-application';

import {audicode} from './configurator/audicode/audicode';
import './configurator/audicode/audicode-rescue-layer';
import {AUDICODE_STORE_ID} from './configurator/stores/audicode-store';
import {ave2cloud} from './configurator/ave2cloud';
import {aveApi} from './configurator/ave/api';
import {aveDom} from './configurator/ave/dom';
import './configurator/basket';
import './configurator/car-compare';
import {carlineMapping} from './configurator/carline-mapping';
import {carstore} from './configurator/carstore';
import './configurator/carstore/carstore-delete-audicode';
import './configurator/carstore/carstore-list';
import './configurator/carstore/carstore-list-item';
import './configurator/carstore/carstore-save-audicode';
import {cart} from './configurator/cart';
import './configurator/configurator-data-service-bridge';
import {CONFIGURATION_STORE_ID} from './configurator/stores/configuration-store';
import './configurator/enginecompare-controls';
import './configurator/enginecompare-filter';
import './configurator/enginecompare-form';
import {container} from './configurator/container';
import {domState} from './configurator/dpu/dom-state';
import {dpuApi} from './configurator/dpu/api';
import {dpuConflict} from './configurator/dpu/conflict';
import {dpuController} from './configurator/dpu/controller';
import {dpuModel} from './configurator/dpu/model';
import {dpuSearch} from './configurator/search';
import {dpuTransfer} from './configurator/dpu/transfer';
import './configurator/dynamic-recommendation';
import './configurator/dynamic-recommendation-bff';
import './configurator/engine-selection';
import './configurator/current-consumption';
import {equipmentSearch} from './configurator/equipment-search';
import {expertmode} from './configurator/expertmode';
import {formCore} from './configurator/form/core';
import {formField} from './configurator/form/field';
import {formGroup} from './configurator/form/group';
import {formItem} from './configurator/form/item';
import {formSelect} from './configurator/form/select';
import {formSimple} from './configurator/form/simple';
import {hiddenVersionItems} from './configurator/hidden-version-items';
import {infobox} from './configurator/infobox';
import {inlinerenderingAoz} from './configurator/inlinerendering-aoz';
import {inlinerendering} from './configurator/inlinerendering';
import {lastConfiguration} from './configurator/dpu/last-configuration';
import {mediaLibrary} from './configurator/media-library';
import './configurator/load-audicode-link';
import './configurator/login-link';
import '../../../components/light-experience/light-experience';
import {loadingIndicator} from './configurator/dpu/loading-indicator';
import './configurator/offerrate';
import {patternAudicode} from './configurator/pattern-matching/pattern-audicode';
import {patternCarlineId} from './configurator/pattern-matching/pattern-carlineId';
import {patternConfiguration} from './configurator/pattern-matching/pattern-configuration';
import {patternLanguage} from './configurator/pattern-matching/pattern-language';
import {patternLeadsource} from './configurator/pattern-matching/pattern-leadsource';
import {patternPartnerId} from './configurator/pattern-matching/pattern-partnerId';
import {patternPrString} from './configurator/pattern-matching/pattern-prstring';
import {patternVehicleId} from './configurator/pattern-matching/pattern-vehicleId';
import {pattern} from './configurator/pattern';
import {pdfServiceLink} from './configurator/pdf-service-link';
import {preconfigurationTeaser} from './configurator/preconfiguration-teaser';
import {preconfsTeaser} from './configurator/preconfs-teaser';
import {recommendations} from './configurator/recommendations';
import {renderinginjector} from './configurator/renderinginjector';
import {renderinglayer} from './configurator/renderinglayer';
import {scsAttributesInitial} from './configurator/scs-attributes-initial';
import {scsMatchingBasket} from './configurator/scs-matching-basket';
import {scsMatching} from './configurator/scs-matching';
import {startlayer} from './configurator/startlayer';
import {setFlagsSearch} from '../../../components/search-set-flags/search-set-flags';
import {summaryTechdata} from './configurator/summary-techdata';
import './configurator/tilelist';
import {tooltip} from './configurator/tooltip';
import {userConfigurations} from './configurator/dpu/user-configurations';
import {USERDATA_STORE_ID} from './configurator/stores/userdata-store';
import {visializer} from './configurator/visualizer';
import {vtp} from './configurator/vtp';
import {wallet} from './configurator/wallet';
import EngineDisclaimerLayerElement from '../../../components/engine-disclaimer-layer/engine-disclaimer-layer';
import {yourPayment} from './configurator/your-payment';
import {zipDownload} from './configurator/zip-download';

/**
 * create public API accessable
 * via the console (nemo.configurator.api.<methodname>(<params>))
 */
window.nemo = window.nemo || {};
window.nemo.configurator = window.nemo.configurator || {};
window.nemo.configurator.api = dpuApi;

if (SETUPS) {
	window.nemo.setups = SETUPS.__.stored;
}

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	audicode,
	ave2cloud,
	aveApi,
	carstore,
	cart,
	container,
	domState,
	dpuConflict,
	dpuController,
	dpuModel,
	dpuSearch,
	dpuTransfer,
	equipmentSearch,
	expertmode,
	formCore,
	formSelect,
	formSimple,
	hiddenVersionItems,
	infobox,
	inlinerendering,
	inlinerenderingAoz,
	lastConfiguration,
	loadingIndicator,
	mediaLibrary,
	pattern,
	patternAudicode,
	patternCarlineId,
	patternConfiguration,
	patternLanguage,
	patternLeadsource,
	patternPartnerId,
	patternPrString,
	patternVehicleId,
	pdfServiceLink,
	preconfigurationTeaser,
	preconfsTeaser,
	recommendations,
	renderinginjector,
	renderinglayer,
	scsMatching,
	scsMatchingBasket,
	startlayer,
	summaryTechdata,
	tooltip,
	userConfigurations,
	visializer,
	vtp,
	wallet,
	yourPayment,
	zipDownload
];

/**
 * initialize modules and inject signal dependency
 */
// TODO wait for SETUPS to be available
const eventEmitter = signal.getEmitter();
toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	audicode,
	AUDICODE_STORE_ID,
	ave2cloud,
	aveApi,
	aveDom,
	carlineMapping,
	cart,
	CONFIGURATION_STORE_ID,
	container,
	domState,
	dpuApi,
	dpuConflict,
	dpuController,
	dpuModel,
	dpuSearch,
	dpuTransfer,
	equipmentSearch,
	expertmode,
	formCore,
	formField,
	formGroup,
	formItem,
	formSelect,
	formSimple,
	hiddenVersionItems,
	infobox,
	inlinerendering,
	inlinerenderingAoz,
	lastConfiguration,
	loadingIndicator,
	mediaLibrary,
	pattern,
	patternAudicode,
	patternCarlineId,
	patternConfiguration,
	patternLanguage,
	patternLeadsource,
	patternPartnerId,
	patternPrString,
	patternVehicleId,
	pdfServiceLink,
	preconfigurationTeaser,
	preconfsTeaser,
	recommendations,
	renderinginjector,
	scsAttributesInitial,
	scsMatching,
	scsMatchingBasket,
	setFlagsSearch,
	startlayer,
	summaryTechdata,
	tooltip,
	userConfigurations,
	USERDATA_STORE_ID,
	visializer,
	vtp,
	wallet,
	EngineDisclaimerLayerElement,
	yourPayment,
	zipDownload
};
