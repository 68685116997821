/*
define([
	'utils/common',
	'app-events',
	'configurator/dpu-api',
	'configurator/dpu-model',
	'utils/dom',
	'utils/polling'
],

function(COMMON, EVENTS, DPU_API, DPU_MODEL, DOM_UTILS, POLLING) { // eslint-disable-line max-params
	*/
// private vars
import {appEvents as EVENTS} from 'core-application';
import {dpuApi as DPU_API} from './dpu/api';
import {dpuModel as DPU_MODEL} from './dpu/model';
import {dom as DOM_UTILS} from 'core-utils';
const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	ssButtonCompactMode: '.nm-summary-list-toggle-btn',
	sSelectorSummaryTechDataContainer: '.nm-summary-list-techdata-container',
	sSelectorSummaryTechDataMultiContainer: '.nm-mediathek-description',
	sSelectorSummaryContainer: '.nm-module-summary-engine',
	sSelectorSummaryContainerEngineIcons: '.nm-summary-tech-icons',
	sSelectorSummaryListContainer: '.nm-summary-list-container.nm-techdata-container',
	sSelectorSummaryWltpLink: '.nm-module-summary-techdata-list .nm-wltp-link',
	sSelectorTechRow: '.nm-techrow, .nm-j-techrow-data-entry',
	sSelectorCompactMode: 'nm-is-compact-mode',
	hiddenClass: 'nm-hidden',
	maxModelsinfoWaitTime: 30000,
	sButtonFullTechData: '.nm-foldout-techdata-list-button-full-techdata',
	sButtonEngineCompare: '.nm-foldout-techdata-list-button-engine-compare'
};

__.handlePageLoaded = function(payload) {
	var context = !!payload && !!payload.domElement ? payload.domElement : document;
	__.bindClicks(context);
	__.patchMotorInfo(context);
};

__.bindClicks = function(context_) {
	__.summaryTechDataContainer = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorSummaryTechDataContainer, context_);
	__.buttonCompactMode = DOM_UTILS.getElementsArray(__.oDefaults.ssButtonCompactMode, context_);

	if (__.summaryTechDataContainer.length > 0 && __.buttonCompactMode.length > 0) {
		__.domDelegate.off('click', __.oDefaults.ssButtonCompactMode, __.handleToggleBtnClick);
		__.domDelegate.on('click', __.oDefaults.ssButtonCompactMode, __.handleToggleBtnClick);
	}
};

__.handleLayerLoaded = function(payload) {
	var context = !!payload && !!payload.element ? payload.element : document;
	__.bindClicks(context);
	__.patchMotorInfo(context);
};

__.handleConfigurationUpdates = function() {
	exports.wait(DPU_API.isInitialized, __.oDefaults.maxModelsinfoWaitTime).then(function() {
		__.patchMotorInfo(document);
	});
};
/*
 * The actual polling method.
 */
__.run = function(fn, timeout, intervalTime, resolve, reject) {
	var interval, runsFor, returnValue;
	runsFor = 0;
	returnValue = fn();
	if (!!returnValue) {
		resolve(returnValue);
	}
	else {
		interval = setInterval(function() {
			returnValue = fn();
			if (!!returnValue) {
				clearInterval(interval);
				resolve(returnValue);
			}
			else if (runsFor > timeout) {
				clearInterval(interval);
				reject(new Error());
			}
			runsFor += intervalTime;
		}, intervalTime);
	}
};

/**
 * Starts polling.
 * @param {Function} fn - A function that gets called until it returns a truthy value.
 * @param {Number} timeout - The timeout. Rejects the promise if elapsed.
 * @param {Number} intervalTime_ - The duration (ms) between each call
 * (default 50).
 * @returns {Promise} A Promise that either:
 *	- resolves with the return value of fn
 *	- or rejects with an Error object.
 */
exports.wait = function(fn, timeout, intervalTime_) {
	// If no intervalTime is set, use the default.
	var intervalTime = intervalTime_ || 50;
	// Return a new Promise and start polling.
	return new Promise(function(resolve, reject) {
		__.run(fn, timeout, intervalTime, resolve, reject);
	});
};

__.initialize = function() {
	__.addEvents();
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigurationUpdates);
	__.eventBus.on(EVENTS.MODELSINFO_LOAD_COMPLETE, __.handlePageLoaded);
};

// handle compact mode switch
__.handleToggleBtnClick = function(event_) {
	event_.preventDefault();
	const elem = event_.target;
	const container = DOM_UTILS.closest(elem, __.oDefaults.sSelectorSummaryTechDataMultiContainer);

	[...container.querySelectorAll(__.oDefaults.sSelectorSummaryTechDataContainer)].forEach(techDataContainer => {
		if (DOM_UTILS.isElement(techDataContainer)) {
			if (techDataContainer.classList.contains(__.oDefaults.sSelectorCompactMode)) {
				techDataContainer.classList.remove(__.oDefaults.sSelectorCompactMode);
			}
			else {
				techDataContainer.classList.add(__.oDefaults.sSelectorCompactMode);
			}
		}
	});
};

/**
 * patch all motor infos for the current model
 * updates all DOM elements with the classname prefix 'nm-j-summary-text-'
 */
__.patchMotorInfo = function(context_) {
	var context = context_;
	var summaryContainer = DOM_UTILS.getElement('[data-template="summary"]', context);
	var info,
		i,
		elems,
		infoKey,
		currentModel = DPU_API.getEngine(),
		currentModelInfo = __.getMotorTechdata(currentModel),
		buttonFullTechData,
		buttonEngineCompare,
		enginesObject;

	if (DOM_UTILS.isElement(summaryContainer) && currentModelInfo) {
		for (infoKey in currentModelInfo) {
			info = currentModelInfo[infoKey];
			elems = DOM_UTILS.getElementsArray(
				'.nm-j-summary-text-' + infoKey + ', .nm-j-configurator-techdata-' + infoKey,
				summaryContainer
			);
			for (i = 0; i < elems.length; i++) {
				if (DOM_UTILS.isElement(elems[i])) {
					__.patchDOMfragment(elems[i], info);
				}
			}
		}

		__.updateVisibilityWltpLinks(currentModelInfo);
		__.patchModelFootnotes(currentModel, context_);
	}

	__.hideInvisibleCategories();

	// for foldout-techdata.vm
	// check if there is more than 1 engine show different button
	buttonFullTechData = DOM_UTILS.getElement(__.oDefaults.sButtonFullTechData, context);
	buttonEngineCompare = DOM_UTILS.getElement(__.oDefaults.sButtonEngineCompare, context);
	enginesObject = DPU_MODEL.getModelsInfo();
	if (DOM_UTILS.isElement(buttonFullTechData) && DOM_UTILS.isElement(buttonEngineCompare)) {
		if (enginesObject && Object.keys(enginesObject).length > 1) {
			buttonFullTechData.classList.add(__.oDefaults.hiddenClass);
			buttonEngineCompare.classList.remove(__.oDefaults.hiddenClass);
		}
	}
};

__.patchModelFootnotes = (currentModel, context = document) => {
	const footnotes = DPU_MODEL.getModelsInfoFootnotes(currentModel);
	const sectionFootnotes = DPU_MODEL.getModelsInfoSectionFootnotes(currentModel);
	const footnoteItems = DOM_UTILS.getElementsArray('.nm-j-summary-footnote-text', context);

	footnoteItems.forEach(footnote => {
		const key = footnote.getAttribute('data-key');
		if (key !== '') {
			if (footnotes && footnotes[key]) {
				footnote.innerHTML = footnotes[key]
			}
			else if (sectionFootnotes && sectionFootnotes[key]) {
				footnote.innerHTML = sectionFootnotes[key]
			}
		}
	});
};

// after configuration we need to update the visibility of the link
__.updateVisibilityWltpLinks = function(currentModelInfo) {
	const wltpLinks = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorSummaryWltpLink);

	if (wltpLinks.length > 0) {
		const wltpKeys = Object.keys(currentModelInfo).filter(key => key.indexOf('wltp-') != -1);

		if (wltpKeys.length > 0) {
			const wltpValues = wltpKeys.map(key => currentModelInfo[key]);
			const hasWLTPData = wltpValues.some(value => value != '' && value != undefined);

			if (hasWLTPData) {
				wltpLinks.forEach(wltpLink => wltpLink.classList.remove('nm-hidden'));
			}
			else {
				wltpLinks.forEach(wltpLink => wltpLink.classList.add('nm-hidden'));
			}
		}
	}
};

// merge MotorInfo with TechData from current configuration if available
__.getMotorTechdata = function(currentModel_) {
	const modelInfo = DPU_API.getModelsInfo(currentModel_);
	const techData = DPU_API.getTechdata();
	return Object.assign({}, modelInfo, techData);
};

// update DOM text fragments
__.patchDOMfragment = function(elem_, content_) {
	var surroundingTechRow = DOM_UTILS.closest(elem_, __.oDefaults.sSelectorTechRow);

	if (DOM_UTILS.isElement(elem_)) {
		if (!!content_) {
			// show item (remove class __.oDefaults.hiddenClass if available)
			if (!!surroundingTechRow && !!surroundingTechRow.classList) {
				surroundingTechRow.classList.remove(__.oDefaults.hiddenClass);
			}
			elem_.innerHTML = content_;
		}
		else {
			// add class __.oDefaults.hiddenClass to hide item from list
			if (!!surroundingTechRow && !!surroundingTechRow.classList) {
				surroundingTechRow.classList.add(__.oDefaults.hiddenClass);
			}
		}
	}
};

// hide table is no visible <dl> available
__.hideInvisibleCategories = function() {
	var container = DOM_UTILS.getElementsArray(
			__.oDefaults.sSelectorSummaryListContainer + ', ' + __.oDefaults.sSelectorSummaryContainer
		),
		visibleElements,
		i,
		j,
		hiddenCount,
		iconsContainer;

	if (container.length > 0) {
		for (i = 0; i < container.length; i++) {
			hiddenCount = 0;
			visibleElements = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorTechRow, container[i]);

			for (j = 0; j < visibleElements.length; j++) {
				if (visibleElements[j].classList.contains(__.oDefaults.hiddenClass)) {
					hiddenCount++;
				}
			}

			if (visibleElements.length !== hiddenCount) {
				container[i].classList.remove(__.oDefaults.hiddenClass);
			}
			else {
				// there is a new flag for the summary-engine module --> "HideTechData", this flag is there to hide the techdata-table
				// the problem is if there is no table, the whole module is put to hidden, which in the case of the summary-engine
				// is not wanted, because the summary-engine can have more content than just a table, it has a list of icons above the table
				// to avoid that the whole module is not shown, because of the "HideTechData" flag, there is now an if-statement who looks,
				// if there is an icon container, if not the whole module can be hidden, if there is an icon-container,
				// the icon-container should still be shown.
				// Websupport 18835: limit the preventing of icon filtering to the summary-engine module
				if(container[i].classList.contains("nm-module-summary-engine")) {
					iconsContainer = DOM_UTILS.getElement(__.oDefaults.sSelectorSummaryContainerEngineIcons, container[i]);
					if (DOM_UTILS.isElement(iconsContainer)) {
						// if module-container has icons (tech-icons), dont do anything, dont hide the whole module
						continue;
					}
				}

				container[i].classList.add(__.oDefaults.hiddenClass);
			}
		}
	}
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domDelegate = DOM_UTILS.getEventDelegate('body');
	__.initialize();
};

export {exports as summaryTechdata};
