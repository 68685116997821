import {appEvents, signal} from 'core-application';
import {dom} from 'core-utils';

export default class LightExperience extends HTMLElement {
	static get selectors() {
		return {
			counter: 'nm-light-experience-list__item_',
			containers: '.nm-light-experience-list__item-container',
			items: '.nm-light-experience-list__item',
			visible: [':not(.nm-j-configurator-container-hide)',
				':not(.nm-j-configurator-status_00000)',
				':not(.nm-j-configurator-status_00001)',
				':not(.nm-j-configurator-status_00010)',
				':not(.nm-j-configurator-status_00011)',
				':not(.nm-j-configurator-status_00100)',
				':not(.nm-j-configurator-status_00101)',
				':not(.nm-j-configurator-status_00110)',
				':not(.nm-j-configurator-status_00111)',
				':not(.nm-j-configurator-status_01000)',
				':not(.nm-j-configurator-status_01001)',
				':not(.nm-j-configurator-status_01010)',
				':not(.nm-j-configurator-status_01011)',
				':not(.nm-j-configurator-status_01100)',
				':not(.nm-j-configurator-status_01101)',
				':not(.nm-j-configurator-status_01110)',
				':not(.nm-j-configurator-status_01111)',
				':not(.nm-j-configurator-status_10000)']
		};
	}

	constructor() {
		super();
		this.eventBus = signal.getEmitter();
		this.setViewportWidth_ = this.setViewportWidth.bind(this);
	}

	connectedCallback() {
		this.visibleSelector = LightExperience.selectors.visible.join('');
		this.itemsPerRow = 1;

		this.addEvents();
		this.setViewportWidth();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.eventBus.on(appEvents.DOM_UPDATED, () => {
			this.updateElements();
		});

		this.onResizeHandler = dom.throttle(this.setViewportWidth.bind(this), 100);
		window.addEventListener('resize', this.onResizeHandler);
	}

	removeEvents() {
		this.eventBus.off(appEvents.DOM_UPDATED, () => {
			this.updateElements();
		});

		window.removeEventListener('resize', this.onResizeHandler);
	}

	getItemsPerRow() {
		if (this.viewportWidth < 768) {
			return 1;
		}
		else if (this.viewportWidth >= 768 && this.viewportWidth < 1280) {
			return 2;
		}
		else if (this.viewportWidth >= 1280 && this.viewportWidth < 1440) {
			return 3;
		}
		return 4;
	}

	setViewportWidth() {
		this.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		const oldItemsPerRow = this.itemsPerRow;
		this.itemsPerRow = this.getItemsPerRow();
		if (oldItemsPerRow !== this.itemsPerRow) {
			this.updateElements();
		}
	}

	updateElements() {
		const itemContainers = [...this.querySelectorAll(LightExperience.selectors.containers)];
		const visibleItemsSelector = LightExperience.selectors.items + this.visibleSelector;

		itemContainers.forEach((container) => {
			[...container.querySelectorAll(visibleItemsSelector)].forEach((item, index, visibleItems) => {
				item.classList.remove(LightExperience.selectors.counter + 'last');
				if ((index % this.itemsPerRow === this.itemsPerRow - 1) || (index === visibleItems.length - 1)) {
					item.classList.add(LightExperience.selectors.counter + 'last');
				}
			});
		});
	}
}

if (!window.customElements.get('audi-configurator-light-experience')) {
	window.customElements.define('audi-configurator-light-experience', LightExperience);
}
