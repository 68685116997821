const template = `
<div class="audi-headline-order-3 nm-module-summary-dynamic-recommendation-headline-main">
	{{=window.i18n['dynamic-recommendations.others-choose-headline']}}
</div>
<p class="audi-copy-s nm-module-summary-dynamic-recommendation-paragraph">
	{{=window.i18n['dynamic-recommendations.others-choose']}}
</p>

<!-- list -->
<div class="nm-module-summary-dynamic-recommendation-itemlist">
	<!-- configurator item -->
	{{~it :value}}
		{{##def.dataConfiguratorId :
			{{? value.id}}
				data-configurator-id="{{=value.id}}"
			{{?}}
		#}}
		{{##def.dataConfiguratorGroup :
			{{? value.RadioGroup}}
				data-configurator-group="{{=value.RadioGroup}}"
			{{?}}
		#}}
		<div class="nm-j-configurator-item nm-j-configurator-status_{{=value.status}}" {{#def.dataConfiguratorId}} {{#def.dataConfiguratorGroup}}>
			<div class="nm-module-summary-dynamic-recommendation-item">

				<!-- checkbox -->
				<div class="nm-module-summary-dynamic-recommendation-checkbox audi-checkbox nm-j-configurator-delegate-click">
					<span class="audi-checkbox">
						<span class="audi-checkbox-symbol nm-module-summary-dynamic-recommendation-checkbox-symbol">
							<svg class="nm-icon-select-small nm-module-summary-dynamic-recommendation-symbol">
								<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/select-small.svg#nm-icon-select-small"}}></use>
							</svg>
						</span>
					</span>
				</div>
				<!-- item text -->
				<div class="nm-module-summary-dynamic-recommendation-itemtext">
					<p class="audi-copy-m nm-module-summary-dynamic-recommendation-itemname-text">{{=value.name}}</p>
					<!-- non renderable sets a remark text -->
					{{? value.nonrenderable}}
						<p class="audi-copy-m nm-module-summary-dynamic-recommendation-additional-text">
							{{=window.i18n['nemo.ui.common.note']}}:
							{{=window.i18n['configuration.not-rendable-message']}}
						</p>
					{{?}}
					{{? !value.RadioGroup}}
						<p class="audi-copy-m nm-module-summary-dynamic-recommendation-additional-text nm-j-configurator-text-packageDescription"></p>
					{{?}}
				</div>

				<div class="nm-module-summary-dynamic-recommendation-image-offerrate-wrapper">
					<!-- image or video -->
					{{##def.videoClass :
						{{? value.video}}
							nm-module-summary-dynamic-recommendation-video
						{{?}}
					#}}

					{{? value.image}}
						<div class="nm-module-summary-dynamic-recommendation-image {{#def.videoClass}}">
							{{? value.url}}
								<a class="nm-layerLink" href="{{=value.url}}">
									<img src="{{=value.image}}" alt="{{=value.name}}" />
									{{? value.video}}
										<svg class="nm-icon-play-large nm-module-summary-dynamic-recommendation-video-play-large">
											<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-play-large.svg#system-play-large"}}></use>
										</svg>
										<svg class="nm-icon-play-large nm-module-summary-dynamic-recommendation-video-play-small">
											<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-play-small.svg#system-play-small"}}></use>
										</svg>
									{{?}}
								</a>
							{{??}}
								<img src="{{=value.image}}" alt="{{=value.name}}" />
								{{? value.video}}
									<svg class="nm-icon-play-large nm-module-summary-dynamic-recommendation-video-play-large">
										<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-play-large.svg#system-play-large"}}></use>
									</svg>
									<svg class="nm-icon-play-large nm-module-summary-dynamic-recommendation-video-play-small">
										<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-play-small.svg#system-play-small"}}></use>
									</svg>
								{{?}}
							{{?}}
						</div>
					{{?}}

					<div class="nm-module-summary-dynamic-recommendation-offerrate-wrapper">
						<audi-configurator-offerrate data-rate="{{=parseInt(parseFloat(value.einbaurate) * 100, 10)}}" class="nm-offer-rate">
							<div class="nm-offer-rate-container">
								<div class="nm-offer-rate-text audi-copy-s"><span class="nm-offer-rate-value">0</span>%</div>
								<div class="nm-offer-rate-image">
									<svg class="nm-offer-rate-icon" viewbox="0 0 48 48">
										<path d="M3,24a21,21 0 1,0 42,0a21,21 0 1,0 -42,0" stroke-opacity="0.6" stroke="#333" fill="none" />
										<path class="nm-offer-rate-path" d="M3,24a21,21 0 1,0 42,0a21,21 0 1,0 -42,0" stroke="#f50537" stroke-width="3" fill="none" />
									</svg>
								</div>
							</div>
						</audi-configurator-offerrate>
					</div>
				</div>

				<div class="nm-module-summary-dynamic-recommendation-price-icons">
					<!-- price -->
					{{? value.Series || value.price}}
						<div class="audi-copy-m nm-module-summary-dynamic-recommendation-price nm-j-configurator-text-price">
							{{? value.Series}}
								{{=window.i18n['nemo.common.serie']}}
							{{?}}
							{{? value.price}}
								{{=value.price}}
							{{?}}
						</div>
					{{?}}

					<!-- infolayer -->
					{{? value.url}}
						<div class="nm-module-summary-dynamic-recommendation-iconwrapper">
							<a class="nm-module-summary-dynamic-recommendation-iconlink nm-layerLink" href="{{=value.url}}" title="{{=window.i18n['more-information']}}">
								<svg class="nm-icon-system-info-small nm-module-summary-dynamic-recommendation-icon-system-info-small">
									<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
								</svg>
							</a>
						</div>
					{{?}}
				</div>
			</div>
		</div>
	{{~}}
</div>

<!-- legend -->
<div class="audi-copy-m nm-module-summary-dynamic-recommendation-legend-headline">
	{{=window.i18n['dynamic-recommendations.legend-headline']}}
</div>
<div class="nm-module-summary-dynamic-recommendation-legend-wrapper">
	<svg class="nm-icon-offer-rate-small nm-module-summary-dynamic-recommendation-icon-offer-rate-small">
		<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/offer-rate-small.svg#nm-icon-offer-rate-small"}}></use>
	</svg>
	<p class="audi-copy-s">
		{{=window.i18n['dynamic-recommendations.legend-info']}}
	</p>
</div>`;
export {template as dynamicRecommendationTemplate};
