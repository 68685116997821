const template = `<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">
	{{=window.i18n['recommendations-layer.your-recommendations-for']}}
</h2>
<p class="nm-el-pg nm-el-pg-03">
	{{=window.i18n['recommendations-layer.message']}}
</p>
{{~it.recommendations :value :index}}
<div class="nm-recommendation-container nm-recommendation-{{=index+1}}">
	<div class="nm-module nm-md-recommendations">
		<strong class="nm-el-hdl nm-el-hdl-04">
			{{=window.i18n['recommendations-layer.recommendation-index']}} {{=index+1}}
		</strong>
		<p class="nm-recommendation-title nm-el-pg nm-el-pg-03">
			{{=value.explanation}}
		</p>
		{{~value.recommendation :r}}
		<div class="nm-module nm-md-teaser-equipment nm-md-teaser clearfix nm-j-configurator-item nm-j-configurator-status_{{=r.status}}"
		data-configurator-id="{{=r.id}}">
			<div class="nm-md-teaser-img">
				<img src="{{=r.image}}" alt="" />
			</div>
			<div class="nm-md-teaser-copy">
				<strong class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">
					{{=r.name}}
				</strong>
				<div class="nm-info-wrap">
					<a class="nm-info nm-image-replace nm-layerLink" href="{{=r.url}}">{{=window.i18n['more-information']}}</a>
				</div>
				<span class="nm-price nm-el-hdl nm-el-hdl-04">
					{{=r.price}}
				</span>
				<div class="nm-el-pg nm-el-pg-02">
					{{=r.description || "No descriptive text found"}}
				</div>
				<a class="nm-el-btn nm-el-btn-02 nm-j-configurator-delegate-click" href="#">
				    <span class="nm-at-btn-state01">{{=window.i18n['configuration.add-item']}}</span>
				    <span class="nm-at-btn-state02">{{=window.i18n['configuration.item-included']}}</span>
				    <span class="nm-at-btn-state03">{{=window.i18n['configuration.remove-item']}}</span>
				</a>
			</div>
		</div>
		{{~}} {{? 0 !== value.chosen.length}}
		<p class="nm-el-pg nm-el-pg-03 nm-at-pg-b">
			{{=window.i18n['recommendations-layer.explanation']}}:
		</p>
		{{~value.chosen :c}}
		<ul class="nm-options-list">
			<li class="clearfix">
				<div class="nm-options-cell-additional">
					<div class="nm-list-item-text nm-el-pg nm-el-pg-04">
						{{=c.name}}
					</div>
				</div>
				<div class="nm-info-wrap">
					<a class="nm-info nm-layerLink" href="{{=c.url}}" title="{{=window.i18n['more-information']}}"></a>
				</div>
				<div class="nm-price nm-el-pg nm-el-pg-04 clearfix">
					<span class="nm-price">
						{{=c.price}}
					</span>
				</div>
			</li>
		</ul>
		{{~}} {{?}}
	</div>
</div>
{{~}}`;
export {template as recommendationsListTemplate};
