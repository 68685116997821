import {formCore as FORM} from './core';
import {dpuApi as CONFIGURATOR_API} from '../dpu/api';
import {appEvents as EVENTS} from 'core-application';
import {router as ROUTER} from 'core-application';

const //  options
	_opts = {
		formSelector: '.nm-j-simpleform'
	},
	//  internals
	__ = {},
	//  api
	exports = {
		__: __
	};
/*
 * Scan context for forms.
 */
__.scanSource = function(context) {
	[...context.querySelectorAll(_opts.formSelector)].forEach(form => __.initForm(form));
};
/*
 * Initialize a form.
 */
__.initForm = function(formContainer) {
	const id = formContainer.getAttribute('id');
	const form = formContainer.querySelector('form');
	//  bind button click and form submit
	form.addEventListener('submit', e => {
		e.preventDefault();
		__.onSubmit(id, form);
	});
};

__.onSubmit = function(id, form) {
	let formData = __.serializeForm(form);
	if (id === 'audicode-input') {
		let code = formData.match(/audicode=([Aa]{1}[a-zA-Z0-9]{7})/i);
		if (code) {
			formData = 'audicode=' + formData.match(/audicode=([Aa]{1}[a-zA-Z0-9]{7})/i)[1].toUpperCase();
		}
	}
	__.call(id, formData);
};

__.serializeForm = function(form) {
	const data = new FormData(form);
	return [...data].reduce(
		(serialized, entry) => (serialized ? serialized + '&' : '') + entry[0] + '=' + entry[1],
		''
	);
};

__.getUrl = function(id) {
	const args = [];
	//  try to add context
	const context = CONFIGURATOR_API.getContext();
	if (context) {
		args.push('context=' + context);
	}
	//  try to add the subsession
	const subsession = CONFIGURATOR_API.getSubsessionID();
	if (subsession) {
		args.push('subsession=' + subsession);
	}
	//  try to add the prstring
	const prstring = CONFIGURATOR_API.getPrString();
	if (prstring) {
		args.push('ids=' + encodeURIComponent(prstring));
	}

	let url = SETUPS.get('nemo.url.form.' + id);
	url += url.indexOf('?') === -1 ? '?' : '&';
	return url + args.join('&');
};

__.call = async function(id, formData) {
	const container = document.getElementById(id);
	container.classList.add('nm-j-simpleform-loading');
	container.classList.remove('nm-j-simpleform-error', 'nm-j-simpleform-success');

	const dpuId = container.getAttribute('data-dpu-form-id') || id;

	const baseUrl = __.getUrl(dpuId);
	const url = baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '&') + formData;

	return await fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				throw new Error(response.statusText);
			}
			else {
				return response.json();
			}
		})
		.then(response => {
			__.handleSuccess(response, id, dpuId);
		})
		.catch(error => console.log('Error fetching simple-form request - server responded with: ', error));
};

__.handleSuccess = function(data_, id, dpuId) {
	const container = document.getElementById(id);
	let data = FORM.responseToObj(data_);
	if (!data || !data[dpuId]) {
		return __.handleFail(data, id, dpuId);
	}
	if (Math.floor(data[dpuId].statuscode / 100) === 2) {
		[...container.querySelectorAll('.nm-error')].forEach(errorWrapper => (errorWrapper.innerHTML = ''));
		if (!!data[dpuId].message) {
			[...container.querySelectorAll('.nm-success')].forEach(
				successWrapper => (successWrapper.innerHTML = data[dpuId].message)
			);
		}
		container.classList.remove('nm-j-simpleform-error');
		container.classList.add('nm-j-simpleform-success');
		__.eventBus.trigger(EVENTS.FORM_FINISHED, {
			id: id,
			response: data
		});
		//  mark form as finished
		container.setAttribute('data-form-is-finished', '1');
		if (id === 'audicode-input') {
			//  always go to the defined url to start a configuration
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
			// close Layer (trigger close button click)
			document.querySelector('.nm-j-layer-close-button').click();
		}
	}
	else {
		__.handleFail(data, id, dpuId);
		__.eventBus.emit(EVENTS.FORM_ERROR, {
			id: id,
			response: data
		});
	}
	container.classList.remove('nm-j-simpleform-loading');
};

__.handleFail = function(data, id, dpuId) {
	const container = document.getElementById(id);

	const message = __.isDefaultErrorWithDpuMessage(data, id, dpuId)
		? data[dpuId].message
		: __.getFormErrorMessage(data);

	const errorElements = container.querySelectorAll('.nm-error');
	if (errorElements) {
		[...errorElements].forEach(errorWrapper => (errorWrapper.innerHTML = message));
	}

	container.classList.add('nm-j-simpleform-error');
	container.classList.remove('nm-j-simpleform-success', 'nm-j-simpleform-loading');

	console.log('form/simple.handleFail', message);
};

__.isDefaultErrorWithDpuMessage = function(data, id, dpuId) {
	return (
		!!data &&
		!!data.header.error &&
		data.header.error.id === 'DEFAULT' &&
		!!dpuId &&
		!!data[dpuId] &&
		!!data[dpuId].message
	);
};

__.getFormErrorMessage = function(data) {
	if (!!data.login && !data.login.success) {
		return CONFIGURATOR_API.getErrorMessage('LOGIN_FAILED');
	}
	return CONFIGURATOR_API.getErrorMessage(data.header.error);
};

__.addEvents = function() {
	//  bind to layer.loaded
	__.eventBus.on(EVENTS.LAYER_LOADED, function(data) {
		if (!!data && !!data.element) {
			__.scanSource(data.element);
		}
	});
	// check for forms without layer context
	__.scanSource(document);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};
export {exports as formSimple};
