import {appEvents as EVENTS} from "core-application";
import {template as TPL_RENDERER} from "core-utils";
import {dom as DOM_UTILS} from 'core-utils';
import {dpuApi as API} from "./dpu/api";
import {clientLayer as JSLAYER} from "core-application";
import {equipmentSearchTemplate as EQUIPMENTSEARCH_TPL} from './equipment-search-template';
import {equipmentSearchResultsTemplate as EQUIPMENTSEARCH_RESULTS_TPL} from './equipment-search-results-template';
const __ = {},
	exports = {
		'__': __
	};
exports.initialized = false;
__.oDefaults = {
	// selectors
	sSelectorLayerOuter: '.nm-layer-equipment-search-layer',
	sSelectorSnippetText: '.nm-j-configurator-text-',
	sSelectorFooter: '.nm-equipment-search-footer__inner-wrapper',
	sSelectorResultsWrap: '.nm-equipment-search-result-container',
	sSelectorSearchInput: '.nm-equipment-search-input',
	sSelectorSearchForm: '.nm-equipment-search-form form'
};
__.searchListArr = [];
/**
 * Retrieve all available Items from configuration for Search Result List and parse into Array for later use
 * @returns {Array} Array of Families with items
 */
__.getAllItems = function () {
	var family, items, item, group, i, families = API.getFamilies();
	__.searchListArr = [];
	if (families) {
		for (family in families) {
			if (family && families[family].type === "equipment") {
				group = {
					'family': families[family]
				};
				items = API.getItemsByFamily(family);
				if (items) {
					group.items = [];
					for (item in items) {
						if (item) {
							i = API.getItem(item);
							group.items.push(i);
						}
					}
					__.searchListArr.push(group);
				}
			}
		}
	}
	return __.searchListArr;
};
/**
 * handle Configuration Update - update result list based on current configuration and footer section
 * @returns {void}
 */
__.handleConfigUpdate = function () {
	var items, layer = __.getLayer(),
		searchString, resultList, parsedItems;
	// if layer doesn't exist. don't do anything
	if (!layer) {
		return;
	}
	// set carline infos
	__.updateFooterText(layer);
	items = __.getAllItems();
	if (items) {
		// in case configuration was made during a search, populate according to search input
		searchString = __.getSearchString();
		if (searchString.length > 1) {
			resultList = __.validateSearchString(searchString);
			parsedItems = __.parseResults(resultList.search);
			__.populateResultList(parsedItems);
		}
		else {
			__.populateResultList(items);
		}
	}
};
/**
 * parse result list based on form input to match tpl setup
 * @param {Array} results_ - Array of config items
 * @returns {Array} items -  Array of parsed items
 */
__.parseResults = function (results_) {
	var result, items = [];
	if (results_) {
		for (result in results_) {
			if (result && results_[result].value) {
				items.push(results_[result].value);
			}
		}
	}
	return items;
};
/**
 * fill results template part
 * @param {Array} items_ - Array of config items
 * @returns {void}
 */
__.populateResultList = function (items_) {
	var innerHtml, wrap = __.getResultsWrapContainer();
	if (items_ && wrap) {
		innerHtml = TPL_RENDERER.render(EQUIPMENTSEARCH_RESULTS_TPL, items_);
		wrap.innerHTML = innerHtml;
		
		// trigger content:rendered to inform the integrator to initialize the feature apps
		const customEvent = new CustomEvent('content:rendered', {detail: {element: wrap}});
		document.dispatchEvent(customEvent);
	}
};
/**
 * Returns Layer Container
 * @returns {Object} Results Layer Object (HTML) if existent
 */
__.getLayer = function () {
	return (document.querySelector(__.oDefaults.sSelectorLayerOuter)) ? document.querySelector(__.oDefaults.sSelectorLayerOuter) : false;
};
/**
 * Returns empty Results Wrap Container
 * @returns {Object} Results Container (HTML) if existent
 */
__.getResultsWrapContainer = function () {
	var wrap = document.querySelector(__.oDefaults.sSelectorResultsWrap);
	if (wrap) {
		// empty just in case
		while (wrap.firstChild) {
			wrap.removeChild(wrap.firstChild);
		}
		return wrap;
	}
	return false;
};
/**
 * retrieves configuration and updates display in footer (carline, description, price, rate)
 * @param {Object} layer_ - HTML Markup
 * @returns {void}
 */
__.updateFooterText = function(layer_) {
	let hasRate = false;
	const isRateHidden = SETUPS.get('scope.nemo.hide_financing_rate') || false;

	if (!layer_) {
		return;
	}
	// Configuration possible && configuration available?
	if (API.isConfigurable() && API.getConfiguration()) {
		const prop = API.getConfiguration();
		for (const searchKey in prop) {
			if (prop[searchKey]) {
				// console.log("searchkey ", searchKey, prop[searchKey]);
				if (searchKey === 'rate') {
					hasRate = true;
				}
				const item = layer_.querySelector(
					__.oDefaults.sSelectorFooter + ' ' + __.oDefaults.sSelectorSnippetText + searchKey
				);
				if (item) {
					item.textContent = prop[searchKey];
				}
			}
		}
		layer_.querySelector(__.oDefaults.sSelectorFooter + ' > span').classList.remove('nm-hidden');
		if (isRateHidden || !hasRate) {
			layer_.querySelector('.nm-equipment-search-rate').classList.add('nm-hidden');
		}
		else {
			layer_.querySelector('.nm-equipment-search-rate').classList.remove('nm-hidden');
		}
	}
	else {
		// hide fields if no configuration is available
		layer_.querySelector(__.oDefaults.sSelectorFooter + ' > span').classList.add('nm-hidden');
	}
};
/**
 * Retrieve Search String from Form Input
 * @returns {string} Searchstring
 */
__.getSearchString = function () {
	// get the user input
	var searchString, input = document.querySelector(__.oDefaults.sSelectorSearchInput);
	searchString = (input) ? input.value : "";
	// remove leading and trailing whitespaces
	searchString = searchString.replace(/^\s+/g, "").replace(/</g, "").replace(/>/g, "").replace(/:\/\//g, "").replace(/%3c/ig, "").replace(/\\/g, "").replace(/&quot/ig, "").replace(/&#/g, "").replace(/'/g, "").replace(/"/g, "").replace(/`/g,"");

	return searchString;
};
/**
 * handle search input change
 * @param {Event} event - jQuery Event
 * @returns {void}
 */
__.handleSearch = function (event) {
	var parsedItems, searchString = __.getSearchString(),
		currentInput, changed,
		resultList = {},
		form = document.querySelector(__.oDefaults.sSelectorSearchForm);
	if (form) {
		// check if input changed
		currentInput = form.getAttribute("data-current") || '';
		changed = (currentInput !== searchString) || event.type === "click";
		event.preventDefault();
		event.stopImmediatePropagation();
		if (event.keyCode === 16 || event.keyCode === 13) {
			return;
		}
		form.setAttribute("data-current", searchString);
		if (searchString.length >= 2 && changed) {
			resultList = __.validateSearchString(searchString);
			parsedItems = __.parseResults(resultList.search);
			__.populateResultList(parsedItems);
		}
		else if (searchString.length === 0 && changed) {
			__.resetSearch();
		}
	}
	return;
};

/**
 * searchDebounceHandler
 * @return {Functioon} returns search debounce function
 */
__.searchDebounceHandler = DOM_UTILS.debounce(__.handleSearch, 300);

/**
 * validateSearchString - checks and cleans up search input
 * @param {string} searchString_ - Search String
 * @returns {Object} resultList - Result Object List
 */
__.validateSearchString = function (searchString_) {
	var searchString = searchString_,
		replaceSearchStopWordsRegExp, resultList = {};
	if (!searchString || searchString.length === 0) {
		return [];
	}
	if (searchString.indexOf('|') !== -1) {
		resultList.prstring = searchString;
	}
	// remove stop words from search string
	if (!!API.getSearchStopWords()) {
		replaceSearchStopWordsRegExp = new RegExp("\\b(?:i|" + API.getSearchStopWords() + ")\\b\\s*", "gi");
		searchString = searchString.replace(replaceSearchStopWordsRegExp, "");
	}
	// console.log("<<" + searchString, API.search(searchString, true));
	// show search result only for inputs with at least 2 Chars
	resultList.search = (!!searchString && searchString.length) > 1 ? API.search(searchString, true) : [];
	resultList.searchString = !!searchString ? searchString : __.getSearchString();
	return resultList;
};
/**
 * resets search result list  to all currently availble items from current configuration, stored in searchlistArray
 * @returns {void}
 */
__.resetSearch = function () {
	if (__.searchListArr&&__.searchListArr.length) {
		__.populateResultList(__.searchListArr);
	}
};
/**
 * generate base html for layer
 * @returns {string} html for layer
 */
__.generateLayerHtml = function () {
	return '<div class="nm-layer-wrapper nm-layer-wrapper-equipment-search-layer"><div class="nm-layer-equipment-search-layer nm-j-jslayer nm-layer nm-default-layer"></div></div>';
};
/**
 * upon layer Loaded event create inner markup and add event listeners to search form
 * @returns {void}
 */
__.handleLayerLoaded = function () {
	var tplContent = TPL_RENDERER.render(EQUIPMENTSEARCH_TPL, null),
		layerWrapper = document.querySelector('.nm-layer-wrapper-equipment-search-layer'),
		container = document.querySelector(__.oDefaults.sSelectorLayerOuter);
	if (container) {
		container.innerHTML = tplContent;
		__.legacyEventBus.on('keyup', '.nm-equipment-search-input', __.searchDebounceHandler);
		__.legacyEventBus.on('paste', '.nm-equipment-search-input', __.handleSearch);
		//__.legacyEventBus.on('click', '.nm-equipment-search-input', __.handleSearch);
		__.legacyEventBus.on('click', '.nm-equipment-search-submit', __.searchDebounceHandler);
		__.legacyEventBus.on('click', '.nm-equipment-search-reset', __.resetSearch);
		__.legacyEventBus.on('submit', '.nm-equipment-search-form form', __.searchDebounceHandler);
	}
	__.handleConfigUpdate();
};
/**
 * register jslayer and add event listeners
 * @returns {void}
 */
__.addEvents = function () {
	JSLAYER.register('equipmentSearchlayer', __.generateLayerHtml);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigUpdate);
	exports.initialized = true;
};
/**
 * remove listeners from search form and remove all other listeners, too
 * @returns {void}
 */
exports.dispose = function () {
	var layerWrapper = document.querySelector('.nm-layer-wrapper-equipment-search-layer');
	console.log("dispose esmd");
	__.legacyEventBus.off('keyup', '.nm-equipment-search-input', __.searchDebounceHandler);
	__.legacyEventBus.off('paste', '.nm-equipment-search-input', __.handleSearch);
	//__.legacyEventBus.off('click', '.nm-equipment-search-input', __.handleSearch);
	__.legacyEventBus.off('click', '.nm-equipment-search-submit', __.searchDebounceHandler);
	__.legacyEventBus.off('click', '.nm-equipment-search-reset', __.resetSearch);
	__.legacyEventBus.off('submit', '.nm-equipment-search-form form', __.searchDebounceHandler);
	__.eventBus.off(EVENTS.LAYER_LOADED, __.handleLayerLoaded);
	__.eventBus.off(EVENTS.CONFIG_UPDATE, __.handleConfigUpdate);
	if (layerWrapper) {
		layerWrapper.removeEventListener("scroll");
	}
};
exports.initialize = function (eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.addEvents();
};
export {exports as equipmentSearch};

