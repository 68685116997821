import {dpuApi as CONFIGURATOR_API} from './dpu/api';
import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
// Private Methods
const __ = {},
	// Public API
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorModule:                '.nm-md-yourpayment',
	sSelectorRateSwitchCart:        '#nm-price-rate-switch',
	sSelectorRateSwitchYP:          '#nm-price-rate-switch-yp',
	sSelectorTable:                 '.nm-yourpayment-costs-list',
	sSelectorSnippetText:           '.nm-j-configurator-text-',
	sShowErrorClass:                'nm-j-yourpayment-show-error'
};
__.eventBus = null;
__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handleConfigUpdate);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleConfigUpdate);
};

/**
 * react to config update event
 * @param {Object} event_ - the Event data to collect data from
 * @returns {void}
 */
__.handleConfigUpdate = function() {
	var configurationData;

	if (!document.querySelector(__.oDefaults.sSelectorModule)) {
		return;
	}

	// Configuration possible?
	if (CONFIGURATOR_API.isConfigurable()) {

		configurationData = CONFIGURATOR_API.getConfiguration();
		// configuration available?
		if (!configurationData) {
			return;
		}

		if (configurationData.rate === '') {
			document.querySelector(__.oDefaults.sSelectorModule).classList.add(__.oDefaults.sShowErrorClass);
		}
		else {
			document.querySelector(__.oDefaults.sSelectorModule).classList.remove(__.oDefaults.sShowErrorClass);
			__.updatePriceMode(configurationData);
		}

	}
};

/**
 * updates price mode displayed via checkboxes
 * @param {Object} configData_ - the configuration data received from the DPU
 * @returns {void}
 */
__.updatePriceMode = function(configData_) {
	var financeParameterList;
	var checkboxYP = document.querySelector(__.oDefaults.sSelectorRateSwitchYP),
		checkboxCart = document.querySelector(__.oDefaults.sSelectorRateSwitchCart);

	if (!checkboxYP || !checkboxCart) {
		return;
	}

	// update price mode for YP checkbox in case it was updated via cart
	if (checkboxCart.getAttribute('checked') !== checkboxYP.getAttribute('checked')) {
		checkboxYP.setAttribute('checked', checkboxCart.getAttribute('checked'));
	}

	financeParameterList = (configData_.financeParameterList)? configData_.financeParameterList : null;
	__.popuplatePricesByRate(financeParameterList);
};

/**
 * update price list to show rates, received from DPU
 * @param {Array} ratesList_ - array of objects with rates
 * @returns {void}
 */
__.popuplatePricesByRate = function(ratesList_) {
	var l, item, id, value, i = 0;
	var listContainer = document.querySelector(__.oDefaults.sSelectorTable);

	if (!listContainer || !ratesList_) {
		return;
	}

	l = ratesList_.length;

	while (i < l) {
		id = ratesList_[i].id;
		value = ratesList_[i].textAndUnit;
		if (id && value) {
			item = DOM_UTILS.getElement(__.oDefaults.sSelectorSnippetText + id, listContainer);
			item.text(value);
		}
		i++;
	}
};

/**
 * public initialize method
 * @param {EventBus} eventBus_ - tbd
 * @return {Promise} returns Promise
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
	__.handleConfigUpdate();

};
export {exports as yourPayment};

