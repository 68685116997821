import carstoreApi from './carstore-api';
export default class CarstoreDeleteAudicode extends HTMLElement {
	constructor() {
		super();
		this.audicode = null;
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
	}

	connectedCallback() {
		this.audicode = this.getAttribute('data-audicode');
		this.deleteButton = this.querySelector('.j-carstore-delete');
		this.deleteButton.addEventListener('click', this.handleDeleteClick);
	}

	disconnectedCallback() {
		if (this.deleteButton) {
			this.deleteButton.removeEventListener('click', this.handleSaveClick);
		}
	}

	handleDeleteClick(event) {
		event.preventDefault();
		carstoreApi.deleteAudicode(this.audicode);
	}
}

if (window.customElements.get('audi-carstore-delete-audicode') === undefined) {
	window.customElements.define('audi-carstore-delete-audicode', CarstoreDeleteAudicode);
}

