import {modalLayer, ModalLayerContentBase} from 'core-application';

export default class EngineDisclaimerLayerElement extends ModalLayerContentBase {
	constructor(data_ = {}) {
		super();
		this._closeLayer = this._closeLayer.bind(this);
		this.data = Object.assign({}, data_.dataset);
	}

	static get type() {
		return 'EngineDisclaimerLayerElement';
	}

	static async getContent(data_) {
		return new EngineDisclaimerLayerElement(data_);
	}

	connectedCallback() {
		this._render();
		this.addEvents();
	}

	addEvents() {
		const closeButton = this.querySelector('.j-close');
		closeButton.addEventListener('click', this._closeLayer);
	}

	static get buttonText() {
		return window.i18n['engine_disclaimer_layer.button.text'];
	}

	_closeLayer(event_) {
		event_.preventDefault();
		event_.stopPropagation();
		this._triggerClose();
	}

	_render() {
		const {headline, message} = this.data;
		const buttonText = EngineDisclaimerLayerElement.buttonText;

		this.innerHTML = `<div class="modal-layer__inner nm-modal-layer__inner" role="alert">
							<div class="nm-modal-layer__header">
								<div class="nm-modal-layer__headline-wrap">
									<h2 class="nm-modal-layer__headline audi-headline-order-2">${headline}</h2>
								</div>
							</div>
							<div class="nm-modal-layer__inner-content">
								<p class="nm-modal-layer__message audi-copy-m">${message}</p>
							</div>
							<div class="nm-modal-layer__footer">
								<button class="audi-button nm-modal-layer__confirm-button j-close">
									<span class="audi-button__text">${buttonText}</span>
								</button>
							</div>
						</div>`;
	}
}

modalLayer.registerLayerType(EngineDisclaimerLayerElement);

if (window.customElements.get('audi-engine-disclaimer-layer-element') === undefined) {
	window.customElements.define('audi-engine-disclaimer-layer-element', EngineDisclaimerLayerElement);
}
