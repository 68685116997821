const template= `
<div class="nm-layer-toolbar">
	<div class="nm-layer__close-button nm-j-layer-close-button">
		<svg class="nm-layer__close-button-icon">
			<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel-newci-large.svg#audi-icon-cancel-newci-large"}}></use>
		</svg>
	</div>
</div>
<div class="nm-layer-inner nm-equipment-search-layer-wrapper">
	<div class="nm-md-equipment-search">
		<header class="nm-equipment-search-header">
			<h2 class="nm-el-hdl nm-el-hdl-02">{{=window.i18n['nemo.ui.equipment-search.headline']}}</h2>

			<div class="nm-equipment-search-form">
				<form action="#" method="GET">
					<fieldset>
						<input name="q" class="nm-equipment-search-input" type="text" placeholder='{{=window.i18n["search-layer.equipment-search-input-placeholder"]}}'/>
						<input type="reset" class="nm-equipment-search-reset" value="X" />
					</fieldset>
				</form>
				<div class="nm-equipment-search-result-container">
				</div>
			</div>
		</header>
		<footer class="nm-equipment-search-footer">
			<div class="nm-equipment-search-footer__inner-wrapper">
				<span class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b"><span class="nm-j-configurator-text-carlineName"></span> <span class="nm-j-configurator-text-description nm-el-lbl nm-el-lbl-01"></span></span>
				<span class="nm-equipment-search-footer__text-price-rate"><span class="nm-j-configurator-text-price nm-el-hdl nm-el-hdl-04 nm-at-hdl-b"></span> <span class="nm-el-lbl nm-el-lbl-01 nm-equipment-search-rate">({{=window.i18n["nemo.ui.equipment-search.rate"]}} <span class="nm-j-configurator-text-rate"></span>)</span></span>
			</div>
		</footer>
		<main class="nm-equipment-search-main">
		</main>
	</div>
</div>`;
export {template as equipmentSearchTemplate};
