export default class CurrentConsumption extends HTMLElement {

	constructor() {
		super();
	}

	connectedCallback() {
		document.body.classList.add('nm-has-current-consumption');
	}

	disconnectedCallback() {
		document.body.classList.remove('nm-has-current-consumption');
	}
}

if (!window.customElements.get('audi-configurator-current-consumption')) {
	window.customElements.define('audi-configurator-current-consumption', CurrentConsumption);
}
