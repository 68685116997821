import {template} from 'core-utils';
import {modalLayer, ModalLayerContentBase} from 'core-application';
import {dpuController} from "../dpu/controller";
import {AudicodeRescueLayer} from './audicode-rescue-layer-template';

export default class AudicodeRescueLayerElement extends ModalLayerContentBase {
	constructor(layerData = {}) {
		super();
		this._closeLayer = this._closeLayer.bind(this);
		const rescueInfo = layerData.dataset.configuration['audicode-rescue-info'];
		this.data = {
			data: layerData.dataset,
			whatIsChanged: this._getIsWhatChanged(layerData.dataset.rescueMessages),
			pdfLink: this._getPdfLink(rescueInfo)
		};
	}

	static get type() {
		return 'AudicodeRescueLayerElement';
	}

	static async getContent(data) {
		return new AudicodeRescueLayerElement(data);
	}

	connectedCallback() {
		this._render();
		this.addEvents();
		dpuController.resetAudicodeRescueInfo();
	}

	addEvents() {
		const closeButtons = this.querySelectorAll('.j-close');
		[...closeButtons].map(button => button.addEventListener('click', this._closeLayer));
	}

	_getIsWhatChanged(rescueMessages) {
		return rescueMessages.join(', ');
	}

	_getPdfLink(rescueInfo) {
		return (SETUPS.get('scope.nemo.audicode.not_valid_pdflink'))
			? SETUPS.get('nemo.pdf.url') + '/audicode/' + rescueInfo.audicode
			: '';
	}

	_closeLayer(event) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this._triggerClose();
	}

	_render() {
		this.innerHTML = template.render(AudicodeRescueLayer, this.data);
	}
}

modalLayer.registerLayerType(AudicodeRescueLayerElement);

if (window.customElements.get('audi-audicode-rescue-layer-element') === undefined) {
	window.customElements.define('audi-audicode-rescue-layer-element', AudicodeRescueLayerElement);
}
