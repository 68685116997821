import {patternMatching as PATTERN} from 'core-global';

import {dpuApi as CONFIGURATOR_API} from '../dpu/api';

/**
 * Private API
 *
 * @var {Object} __
 */
var __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * // To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};
__.register = function() {
	PATTERN.register('conf_finance', __.getConfigurationAttribute('financeProductName'));
	PATTERN.register('conf_name', __.getConfigurationAttribute('carlineName'));
	PATTERN.register('conf_id', __.getConfigurationAttribute('carline'));
	PATTERN.register('conf_description', __.getConfigurationAttribute('description'));
	PATTERN.register('conf_modelyear', __.getConfigurationAttribute('modelyear'));
	PATTERN.register('conf_power_primary', __.getModelInfoAttribute('power-primary'));
	PATTERN.register('conf_power_secondary', __.getModelInfoAttribute('power-secondary'));
};
__.getConfigurationAttribute = function(attr_) {
	return function() {
		var configuration = CONFIGURATOR_API.getConfiguration();
		return new Promise(function(resolve, reject) {
			if (!!configuration && !!configuration[attr_]) {
				resolve(configuration[attr_]);
			}
			else {
				reject(attr_ + ' was not found within the configuration');
			}
		});
	};
};
__.getModelInfoAttribute = function(attr_) {
	return function() {
		var modelInfo = CONFIGURATOR_API.getCurrentModelInfo();
		return new Promise(function(resolve, reject) {
			if (!!modelInfo && !!modelInfo[attr_]) {
				resolve(modelInfo[attr_]);
			}
			else {
				reject(attr_ + ' was not found within the model Info');
			}
		});
	};
};
exports.initialize = function() {
	__.register();
};
export {exports as patternConfiguration};
