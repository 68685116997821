import {USERDATA_STORE_ID} from '../stores/userdata-store';
import {stateRegistry} from 'microkernel';

class CarstoreApi {
	constructor() {
		if (!CarstoreApi.instance) {
			this._init();
			CarstoreApi.instance = this;
		}
		return CarstoreApi.instance;
	}

	_init() {
		this.csrfToken = null;
		this._getCsrfToken = this._getCsrfToken.bind(this);
		this._requestAudicodeOperation = this._requestAudicodeOperation.bind(this);
		stateRegistry.subscribeToStore(USERDATA_STORE_ID, this._getCsrfToken);
	}

	_getCsrfToken(state) {
		if (state && state.csrfToken) {
			this.csrfToken = state.csrfToken;
		}
	}

	async _requestAudicodeOperation(method, audicode) {
		const carstoreUrl = SETUPS.get('myaudi.uds.url') + 'carstore/' + audicode;
		const options = {
			credentials: 'include',
			headers: {
				'X-CSRF-Token': this.csrfToken
			},
			method: method
		};
		return fetch(carstoreUrl, options)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				else {
					throw new Error(response.statusText);
				}
			})
			.then(response => {
				stateRegistry.triggerAction(USERDATA_STORE_ID, 'update', {audicodes: response});
			})
			.catch(error => console.log('CarstoreApi ERROR - audicode - ', error));
	}

	async deleteAudicode(audicode) {
		return this._requestAudicodeOperation('DELETE', audicode);
	}

	async saveAudicode(audicode) {
		return this._requestAudicodeOperation('PATCH', audicode);
	}

	async getAudicodeList() {
		const listAudicodesUrl = SETUPS.get('myaudi.uds.url') + 'carstore/listAudicodes';
		const options = {
			credentials: 'include',
			headers: {
				'X-CSRF-Token': this.csrfToken
			}
		};

		return fetch(listAudicodesUrl, options)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				else {
					throw new Error(response.statusText);
				}
			})
			.then((response) => {
				stateRegistry.triggerAction(USERDATA_STORE_ID, 'update', {audicodes: response});
			})
			.catch(error => console.log('CarstoreApi ERROR - listAudicodes - ', error));
	}
}

const carstoreApi = new CarstoreApi();
export default carstoreApi;
