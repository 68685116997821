/* jshint smarttabs: true */

// FIXME: #item-001, #item-002
// TODO: is this the best structure ? (RFC)
// TODO: what about an 'Item-Factory' ?
//
import {doT} from 'core-vendor';
import {dynamicDot} from 'core-application';

import {formField as FormField} from './field';

// mapped templates
const _templateMap = {};

// doT template functions; lazily loaded and cached;
const _templateFuncs = {};

// Internal module methods
const __ = {};


__.getTemplateFuncAsync = function() {
	var tplId, tplDOMid;
	// This func is added to the prototype of all __.FormX instances.
	// 'this' is assumed to be the instance. To prevent errors, if
	// templateKey is not defined a function returning an empty string
	// is returned.
	var key = this.templateKey || null,
		tpl,
		fallbackFunc = function() {
			return '';
		};
	if (key === null) {
		return Promise.resolve(fallbackFunc);
	}
	tplId = __.getTemplateId(this, key);
	if (_templateFuncs[tplId] === undefined) {
		if(dynamicDot.requiresDynamicDot()) {
			return new Promise((resolve, reject) => {
				dynamicDot.getTemplateFromBackend(tplId).then(content => {
					_templateFuncs[tplId] = doT.template(content);
					resolve(_templateFuncs[tplId]);
				})
				.catch(error=> {
					console.warn('Error reading dot-template with id: ' + tplId, error);
					resolve(fallbackFunc);
				});
			});
		}
		else {
			tpl = document.querySelector('#' + tplId);
			if (tpl && tpl.innerHTML) {
				_templateFuncs[tplId] = doT.template(tpl.innerHTML.trim());
			} else {
				return Promise.resolve(fallbackFunc);
			}
		}
	}
	return Promise.resolve(_templateFuncs[tplId]);
};

/**
 * Sanitize Id to get a DOM-usable id string
 */
__.sanitizeId = function(id) {
	if (id !== undefined) {
		return id.replace(/[^a-z0-9_-]/gi, '');
	}
	return '';
};

/**

// TODO: (#item-002)
// move to separate helper as this is shared over all form related modules (!?)

 * Chose the correct template id in this order of specificity:
 * 1. <form.id>-<obj.id> found in _templateMap
 * 2. <form.id>-<form.pageId> found in _templateMap
 * 3. <form.id>-<key> found in _templateMap
 * 4. <form.id>-<form.pageId>-<obj.id>
 * 5. <form.id>-<obj.id>
 * 6. <form.id>-<form.pageId>-<key>
 * 7. <form.id>-<key>
 * 7. <key>
 */
__.getTemplateId = function(obj, key) {
	let pre = 'nm-id-form-',
		post = '-tpl',
		form = obj.form || obj,
		customTemplateIds = form.customTemplateIds || {},
		pageId = form.pageId,
		objDOMId,
		idStr;
	key = key.toLowerCase();
	if (obj.id !== undefined) {
		objDOMId = __.sanitizeId(obj.id);
		// try very very very high specificity (mapped)
		idStr = _templateMap[form.id + '-' + objDOMId];
		if (idStr !== undefined) {
			return idStr;
		}
		// try very high specificity
		idStr = pre + form.id + '-' + form.pageId + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
		// try high specificity
		idStr = pre + form.id + '-' + objDOMId + post;
		if (customTemplateIds[idStr] !== undefined) {
			return idStr;
		}
	}
	// try mapped page
	if (obj.formConstructorName === 'Form') {
		idStr = _templateMap[form.id + '-' + form.pageId];
		if (idStr !== undefined) {
			return idStr;
		}
	}
	// try mapped with <formid>-<key> (field type)
	idStr = pre + form.id + '-' + key + post;
	if (_templateMap[form.id + '-' + key] !== undefined) {
		return idStr;
	}
	// try normal specificity
	idStr = pre + form.id + '-' + form.pageId + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// try low specificity
	idStr = pre + form.id + '-' + key + post;
	if (customTemplateIds[idStr] !== undefined) {
		return idStr;
	}
	// return id string with very low specificity (default)
	idStr = pre + key + post;
	return idStr;
};


var _copyValues = function(source, target) {

	if (!source || !target) {return false;}

	let key;
	for (key in source) {
		if (source.hasOwnProperty(key)) {
			target[key] = source[key];
		}
	}
};


/**
 * FormItem
 */
__.FormItem = function FormItem(data, form) {

	_copyValues(data, this);

	this.formConstructorName = 'FormItem';
	this.domId = __.sanitizeId(this.id);

	if (data && 'fields' in data) {
		this.dataFields = data.fields;
	}
	else {
		this.dataFields = [];
	}

	this.form = form;
	this.templateKey = 'item';
	this.fields = [];
	this.namedFields = {};
	return this;
};

__.FormItem.prototype.initializeTemplatesAndFieldsAsync = function()  {
	return new Promise(resolve=> {
		this.getTemplateFuncAsync().then(templateFunction=> {
			this.getString = templateFunction;
			this.processFieldsAsync().then((fields)=> {
				resolve(fields);
			})
		});
	});
}

__.FormItem.prototype.getTemplateFuncAsync = __.getTemplateFuncAsync;

__.FormItem.prototype.processFieldsAsync = function() {
	return new Promise((resolve) => {
		var i, l, field;
		if (this.dataFields !== undefined) {
			// move label and info to field if only a fake label exists
			// and the item contains only one field.
			if (this.dataFields.length === 1) {
				if (this.dataFields[0].label === undefined) {
					this.dataFields[0].label = this.name;
					delete this.name;
					if (!!this.info) {
						this.dataFields[0].info = this.info;
						delete this.info;
					}
				}
			}
			// create fields
			const promises = [];
			for (i = 0, l = this.dataFields.length; i < l; i++) {
				field = new FormField(this.dataFields[i], this.form);
				promises.push(field.initializeTemplatesAsync());
				this.fields.push(field);
				this.namedFields[field.id] = field;
			}

			Promise.allSettled(promises).then(() => {
				resolve(this.fields);
			});
		}
		else {
			resolve(this.fields);
		}
	});
};

__.FormItem.prototype.toString = function toString() {
	return this.getString(this);
};
const api = __.FormItem;
export {api as formItem};

