import {appEvents as EVENTS} from "core-application";
import {template as TPL_RENDERER} from "core-utils";
import {renderingLayerTemplate as RENDERINGLAYER_TPL} from './renderinglayer-template';
import {dpuApi as API} from "./dpu/api";
import {clientLayer as JSLAYER} from 'core-application';
const __ = {},
	exports = {
		'__': __
	};

exports.initialized = false;

__.activeImageSrc = false;

__.interiorImages = [];
__.exteriorImages = [];
__.exteriorOpenImages = [];

__.min360ImgCount = 6;

__.oDefaults = {
// classes
	'sClassActive': 'nm-active',
	'sClassNoThumbnailAnimation': 'nm-no-animation',
	'sClassAnimate': 'nm-animate',
	'sClassCabrioOpen': 'nm-cabrio-open',

	'sClassSelectorClosed': 'nm-icon-expand-white',
	'sClassSelectorOpen': 'nm-icon-collapse-white',

	'sClassItemExterior': 'nm-js-exterior',
	'sClassItemExteriorOpen': 'nm-js-exterior-open',
	'sClassItemInterior': 'nm-js-interior',

	'sAttrDisplayedRenderingView': 'data-renderimage-view',

	'sIdToggleExterior': 'nm-renderinglayer-exteriortoggle',
	'sIdToggleExteriorOpen': 'nm-renderinglayer-exterioropentoggle',
	'sIdToggleInterior': 'nm-renderinglayer-interiortoggle',


	// selectors
	'sSelectorColorItems': '#nm-renderinglayer-extcolor-selection-color li',
	'sSelectorOpenButton': '.nm-renderinglayer-open',

	'sSelectorRenderingLayerOuter': '.nm-layer-renderinglayer',
	'sSelectorRenderingLayer': '.nm-renderinglayer',

	'sSelectorItemExterior': '.nm-js-exterior',
	'sSelectorItemExteriorOpen': '.nm-js-exterior-open',
	'sSelectorItemInterior': '.nm-js-interior',

	'sSelectorDisplayedRenderingImage': '.nm-basket-image .nm-j-configurator-renderimage',

	'sSelectorRenderingCounterCurrent': '.nm-renderingcounter-current',
	'sSelectorRenderingCounterTotal': '.nm-renderingcounter-total',

	'sSelectorBack': '.nm-renderinglayer .nm-backarrow',
	'sSelectorNext': '.nm-renderinglayer .nm-nextarrow',
	'sSelectorStageImage': '.nm-renderinglayer-image',

	'sSelectorStageImages': '.nm-renderinglayer-stageimages',
	'sSelectorActiveStageImageWrapper': '.nm-renderinglayer-imagewrapper.nm-active',
	'sSelectorFirstStageImageWrapper': '.nm-renderinglayer-imagewrapper:first-child',
	'sSelectorActiveStageImage': '.nm-renderinglayer-imagewrapper.nm-active .nm-renderinglayer-image',

	'sSelectorThumbnailArea': '.nm-renderinglayer-thumbnails',
	'sSelectorThumbnail': '.nm-renderinglayer-item .nm-rl-placeholder',
	'sSelectorPlusButton': '.nm-renderinglayer-item .nm-icon-plus',

	'sSelectorThumbnailActive': '.nm-renderinglayer-item .nm-rl-placeholder.nm-active',

	'sSelectorColorSelector': '.nm-render-color-selector',
	'sSelectorColorSelectorWrapper': '.nm-render-color-selector-wrapper',
	'sSelectorOptionsSelector': '.nm-render-animation-selector',

	'sSelectorCurrentColorIndicator': '.selector-current-selected .nm-render-active-color'
};

__.isInteriorActive = function(currentRenderingView) {
	var interiorActive = false;

	if (currentRenderingView.indexOf('interior') === 0 || currentRenderingView.indexOf('dashboard') === 0 || currentRenderingView.indexOf('rearseat') === 0) {
		interiorActive = true;
	}
	return interiorActive;
};

__.isOpenActive = function(currentRenderingView) {
	var open = false;

	if (currentRenderingView.indexOf('_open') > 0) {
		open = true;
	}
	return open;
};

__.getActiveImageSrc = function(params) {

	if (params.interiorActive && __.interiorImages.length > 0) {
		__.activeImageSrc = __.interiorImages[0].image;
	}
	else if (params.open && __.exteriorOpenImages.length > 0) {
		__.activeImageSrc = __.exteriorOpenImages[0].image;
	}
	else if (__.exteriorImages.length > 0) {
		__.activeImageSrc = __.exteriorImages[0].image;
	}
	else {
		__.activeImageSrc = '';
	}
};

/**
 * get configuration assets from DPU API / SETUPS
 * @returns {void}
 */
__.getConfigurationAssets = function() {
	var configurationAssets = API.getConfigurationAssets();

	if (!configurationAssets || Object.keys(configurationAssets).length <= 0) {
		configurationAssets = SETUPS.get('nemo.default.data').configuration.assets;
	}

	__.generateRenderingLayerInnerHtml(configurationAssets);
	__.initListeners();
};

/**
 * poll configuration assets
 * @returns {void}
 */
__.pollConfigurationAssets = function() {

	if (jQuery(__.oDefaults.sSelectorRenderingLayer).length > 0) {
		console.log("RL: abort poll request");
		return;
	}
	__.getConfigurationAssets();
};

__.parseRenderImageTypeFromHash = function(hashString) {
	var renderImageType,
		renderinglayerRegExp,
		match;

	if (hashString) {
		renderinglayerRegExp =(/(renderingLayer)(\.(\S)+)?/);
		// check for 'renderingLayer' param within the hashString
		match=hashString.match(renderinglayerRegExp);
		if (match){
		// remove following url params (if any)
			match = match[0].split('&')[0];
			match = match.split('.');
			// has renderview segment?
			renderImageType = match.length === 2 ? match[1]:false;
		}
		return renderImageType;
	}
	else {
		return false;
	}
};

__.setAllowedItems = function(configurationAssets) {
	var property;
	var hiddenAssets = !!SETUPS.get('nemo.hiddenAssets') ? SETUPS.get('nemo.hiddenAssets').replace(/"/g, "") : "";
	__.activeRenderings = [];

	hiddenAssets = (hiddenAssets.length > 0) ? hiddenAssets.split(",") : [];
	console.log('hiddenAssets: ' + hiddenAssets);

	for (property in configurationAssets) {
	// additionally check if property is NOT set in hiddenAssets
		if (property && hiddenAssets.indexOf(property) === -1) {
			__.activeRenderings.push(property);
		}
	}
};

/**
 * generate inner html of layer
 */
__.generateRenderingLayerInnerHtml = function(configurationAssets) {

	var carline = API.getCarline();

	var activeColor = API.getExtColor();

	var configuration = API.getConfiguration();

	var currentRenderingView = __.parseRenderImageTypeFromHash(window.location.hash);

	var params = {};
	var property;

	if (!currentRenderingView) {
		currentRenderingView = jQuery(__.oDefaults.sSelectorDisplayedRenderingImage).attr(__.oDefaults.sAttrDisplayedRenderingView);
	}

	__.setAllowedItems(configurationAssets);

	if (__.activeRenderings.indexOf(currentRenderingView) === -1) {
		currentRenderingView = 'exterior_front';
	}

	params.interiorActive = __.isInteriorActive(currentRenderingView);
	params.open = __.isOpenActive(currentRenderingView);

	__.preloadImages = [
		'exterior_straight_front',
		'exterior_front',
		'exterior',
		'exterior_back',
		'exterior_straight_back'
	];

	__.preloadImagesOpen = [
		'exterior_straight_front_open',
		'exterior_front_open',
		'exterior_open',
		'exterior_back_open',
		'exterior_straight_back_open'
	];

	__.exteriorImages = __.extractAssets(carline, configurationAssets, currentRenderingView, 'exterior');
	__.exteriorImages = __.exteriorImages.concat(__.extractAssets(carline, configurationAssets, currentRenderingView, 'closeup'));
	__.exteriorOpenImages = __.extractAssets(carline, configurationAssets, currentRenderingView, 'exterior_open');
	__.exteriorOpenImages = __.exteriorOpenImages.concat(__.extractAssets(carline, configurationAssets, currentRenderingView, 'closeup_open'));
	params.openavailable = __.exteriorOpenImages.length > 0;
	__.interiorImages = __.extractAssets(carline, configurationAssets, currentRenderingView, 'interior');
	__.interiorImages = __.interiorImages.concat(__.extractAssets(carline, configurationAssets, currentRenderingView, 'dashboard'));
	__.interiorImages = __.interiorImages.concat(__.extractAssets(carline, configurationAssets, currentRenderingView, 'rearseat'));

	if (!__.activeImageSrc) {
		__.getActiveImageSrc(params);
	}

	if (!currentRenderingView) {
		currentRenderingView = 'exterior_front';
	}

	params.exterior = __.exteriorImages;
	params.exterior_open = __.exteriorOpenImages;
	params.interior = __.interiorImages;
	params.active_image_src = __.activeImageSrc;
	params.currentRenderingView = currentRenderingView;
	params.config = configuration;
	params.activeColor = activeColor;

	var innerHtml = TPL_RENDERER.render(RENDERINGLAYER_TPL, params);

	jQuery(__.oDefaults.sSelectorRenderingLayerOuter).html(innerHtml);
};


/**
 * generate base html for rendering layer
 * @returns {string} html for rendering layer
 */
__.generateRenderingLayerHtml = function() {

	var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	var maxLayerHeight = 975; // 840 for image + 135 for thumbnails
	var maxLayerWidth = 1400;
	var ratio = maxLayerWidth / maxLayerHeight;

	var customStyle = '';

	if (maxLayerHeight > height) {
		customStyle = ' style="width: ' + height * ratio + 'px;"';
	}

	return '<div class="nm-layer-wrapper nm-layer-wrapper-renderinglayer"><div class="nm-layer-renderinglayer nm-j-jslayer nm-layer"' +
	customStyle + '></div></div>';
};


__.addEvents = function() {
	JSLAYER.register('renderingLayer', __.generateRenderingLayerHtml);
	// __.eventBus.on(EVENTS.PAGE_LOADED, __.handler);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handler);
	exports.initialized = true;
};

exports.dispose = function () {
// __.eventBus.off(EVENTS.PAGE_LOADED, __.handler);
	__.eventBus.off(EVENTS.LAYER_LOADED, __.handler);
};

exports.disableFor3D = function() {
	jQuery(__.oDefaults.sSelectorOpenButton).off('click', __.handlerOpenToggle);
	jQuery(__.oDefaults.sSelectorBack).off('click', __.handlerBackClick);
	jQuery(__.oDefaults.sSelectorNext).off('click', __.handlerNextClick);
	jQuery(__.oDefaults.sSelectorThumbnail).off('click', __.handlerThumbnailClick);
	// jQuery(__.oDefaults.sSelectorPlusButton).off('click', __.handlerPlusButtonClick);

	jQuery(__.oDefaults.sSelectorStageImage).off('mousedown', __.activateSwipe);
	jQuery(__.oDefaults.sSelectorStageImage).off('touchstart', __.activateSwipe);
};

/**
 * layer handler for layer loaded event
 * proceeds in mechanism, if it is a rendering layer
 * @returns {void}
 */
__.handler = function() {
	if (jQuery(__.oDefaults.sSelectorRenderingLayerOuter).length > 0) {
		__.pollConfigurationAssets();
	}
};

__.initListeners = function() {

	__.interiorToggle = document.getElementById(__.oDefaults.sIdToggleInterior);
	__.exteriorOpenToggle = document.getElementById(__.oDefaults.sIdToggleExteriorOpen);
	__.exteriorToggle = document.getElementById(__.oDefaults.sIdToggleExterior);

	// remove possibly already active events
	jQuery(__.oDefaults.sSelectorOpenButton).off('click', __.handlerOpenToggle);
	jQuery(__.oDefaults.sSelectorBack).off('click', __.handlerBackClick);
	jQuery(__.oDefaults.sSelectorNext).off('click', __.handlerNextClick);
	jQuery(__.oDefaults.sSelectorThumbnail).off('click', __.handlerThumbnailClick);
	jQuery(__.oDefaults.sSelectorPlusButton).off('click', __.handlerPlusButtonClick);

	// refresh content, if still empty
	if (jQuery(__.oDefaults.sSelectorStageImage).first().attr('src') === '') {
		__.pollConfigurationAssets();
	}
	__.updateCounter();

	jQuery(__.oDefaults.sSelectorOpenButton).on('click', __.handlerOpenToggle);
	jQuery(__.oDefaults.sSelectorBack).on('click', __.handlerBackClick);
	jQuery(__.oDefaults.sSelectorNext).on('click', __.handlerNextClick);
	jQuery(__.oDefaults.sSelectorThumbnail).on('click', __.handlerThumbnailClick);
	jQuery(__.oDefaults.sSelectorPlusButton).on('click', __.handlerPlusButtonClick);

	if (__.exteriorOpenToggle && __.exteriorOpenToggle.checked) {
		__.preloadDefinedImages(__.preloadImagesOpen, 'exterior_open');
	}
	if (__.exteriorToggle && __.exteriorToggle.checked) {
		__.preloadDefinedImages(__.preloadImages, 'exterior');
	}

	__.activateSwipeOrDrag();

	// exports.enableColorSelector();

	jQuery(window).on('resize', __.resizeLayer);
	__.resizeLayer();
	__.eventBus.emit(EVENTS.RENDERINGLAYER_LOADED);
};

__.determineImageObjectsForArea = function(area) {

	var imageObjects = [];

	if (area === 'interior') {
		imageObjects = __.interiorImages;
	}
	else if (area === 'exterior_open') {
		imageObjects = __.exteriorOpenImages;
	}
	else {
		imageObjects = __.exteriorImages;
	}
	return imageObjects;
};

/**
* @param imgKeys:  array of image keys to load (e.g. exterior_front)
* @param area:     area to proload in (e.g. exterior)
*/
__.preloadDefinedImages = function(imgKeys, area) {

	var i, l, img;
	var imageObjects = __.determineImageObjectsForArea(area);

	for (i=0, l=imageObjects.length; i<l; i++) {

		if (imgKeys.indexOf(imageObjects[i].name) >= 0) {
			img = new Image();
			img.src = imageObjects[i].image;
		}
	}
};


/**
* activate swipe handler or 360° drag handler if applicable
*/
__.activateSwipeOrDrag = function() {

	var exteriorToggle = document.getElementById(__.oDefaults.sIdToggleExterior);
	var exteriorOpenToggle = document.getElementById(__.oDefaults.sIdToggleExteriorOpen);
	var extThumbnailsCount;

	// cleanup events
	jQuery(__.oDefaults.sSelectorStageImage).off('mousedown', __.activateSwipe);
	jQuery(__.oDefaults.sSelectorStageImage).off('touchstart', __.activateSwipe);
	jQuery(__.oDefaults.sSelectorStageImage).off('mousedown', __.activateDrag);
	jQuery(__.oDefaults.sSelectorStageImage).off('touchstart', __.activateDrag);
	jQuery(document).off('mouseup', __.deactivateDrag);
	jQuery(document).off('touchend', __.deactivateDrag);
	jQuery(document).off('mouseup', __.deactivateSwipe);
	jQuery(document).off('touchend', __.deactivateSwipe);


	if (exteriorOpenToggle && exteriorOpenToggle.checked) {
		extThumbnailsCount = jQuery(__.oDefaults.sSelectorItemExteriorOpen).length;
	}
	else {
		extThumbnailsCount = jQuery(__.oDefaults.sSelectorItemExterior).length;
	}

	// check whether to enable 360°
	if ((exteriorToggle && exteriorToggle.checked || exteriorOpenToggle && exteriorOpenToggle.checked) && extThumbnailsCount >= __.min360ImgCount) {
		jQuery(__.oDefaults.sSelectorStageImage).on('mousedown', __.activateDrag);
		jQuery(__.oDefaults.sSelectorStageImage).on('touchstart', __.activateDrag);
		jQuery(document).on('mouseup', __.deactivateDrag);
		jQuery(document).on('touchend', __.deactivateDrag);
	}
	else {
		jQuery(__.oDefaults.sSelectorStageImage).on('mousedown', __.activateSwipe);
		jQuery(__.oDefaults.sSelectorStageImage).on('touchstart', __.activateSwipe);
		jQuery(document).on('mouseup', __.deactivateSwipe);
		jQuery(document).on('touchend', __.deactivateSwipe);
	}
};

__.getViewportHeight = function() {
	return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

__.getViewportWidth = function() {
	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

__.fitToWidth = function(layerWidth, ratio, viewportWidth, viewportHeight) {
	var natImgWidth = 1400; // predefined
	var newWidth;

	if (natImgWidth > layerWidth) {
		newWidth = viewportHeight * ratio;
		if (newWidth <= viewportWidth) {
			jQuery(__.oDefaults.sSelectorRenderingLayerOuter).outerWidth(viewportHeight * ratio);
		}
		else {
			jQuery(__.oDefaults.sSelectorRenderingLayerOuter).outerWidth(viewportWidth);
		}
	}
};

// resize layer to fit into window
__.resizeLayer = function() {
	var viewportHeight = __.getViewportHeight();
	var layerHeight = jQuery(__.oDefaults.sSelectorRenderingLayerOuter).outerHeight();
	var layerWidth = jQuery(__.oDefaults.sSelectorRenderingLayerOuter).outerWidth();
	var ratio = layerWidth / layerHeight;
	var viewportWidth = __.getViewportWidth();

	if (layerHeight > viewportHeight) {
		jQuery(__.oDefaults.sSelectorRenderingLayerOuter).outerWidth(viewportHeight * ratio);
	}
	else {
		__.fitToWidth(layerWidth, ratio, viewportWidth, viewportHeight);
	}
	__.eventBus.emit(EVENTS.RENDERINGLAYER_RESIZED);
};

__.calculateTotalItems = function() {
	var totalItems = 0;
	var exteriorOpenToggle = document.getElementById(__.oDefaults.sIdToggleExteriorOpen);
	var interiorToggle = document.getElementById(__.oDefaults.sIdToggleInterior);

	if (interiorToggle && interiorToggle.checked) {
		totalItems = jQuery(__.oDefaults.sSelectorItemInterior).length;
	}
	else if (exteriorOpenToggle && exteriorOpenToggle.checked) {
		totalItems = jQuery(__.oDefaults.sSelectorItemExteriorOpen).length;
	}
	else {
		totalItems = jQuery(__.oDefaults.sSelectorItemExterior).length;
	}
	return totalItems;
};

__.calculateCurrentItem = function(totalItems) {
	var currentItem = 0;
	var exteriorOpenToggle = document.getElementById(__.oDefaults.sIdToggleExteriorOpen);
	var interiorToggle = document.getElementById(__.oDefaults.sIdToggleInterior);

	if (interiorToggle && interiorToggle.checked) {
		currentItem = __.calculateCurrentPosition(__.oDefaults.sSelectorItemInterior, totalItems);
	}
	else if (exteriorOpenToggle && exteriorOpenToggle.checked) {
		currentItem = __.calculateCurrentPosition(__.oDefaults.sSelectorItemExteriorOpen, totalItems);
	}
	else {
		currentItem = __.calculateCurrentPosition(__.oDefaults.sSelectorItemExterior, totalItems);
	}
	return currentItem;
};

__.updateCounter = function() {
	var totalItems = __.calculateTotalItems();
	var currentItem = __.calculateCurrentItem(totalItems);

	if (typeof totalItems !== "undefined") {
		totalItems = (totalItems < 10) ? ("0" + totalItems) : totalItems;
		currentItem = (currentItem < 10) ? ("0" + currentItem) : currentItem;
		jQuery(__.oDefaults.sSelectorRenderingCounterTotal).html(totalItems);
		jQuery(__.oDefaults.sSelectorRenderingCounterCurrent).html(currentItem);
	}
};

__.calculateCurrentPosition = function(selector, totalItems) {
	var targetItems = jQuery(selector);
	var i;
	for (i=0; i<totalItems; i++) {
		if (targetItems.eq(i).hasClass(__.oDefaults.sClassActive)) {
			return i+1;
		}
	}
};

__.activateDrag = function(e) {
	if (jQuery(__.oDefaults.sSelectorStageImages).hasClass(__.oDefaults.sClassAnimate)) {
		return;
	}
	if (e.type === 'touchstart') {
		__.swipeX = e.originalEvent.pageX;
	}
	else {
		__.swipeX = e.pageX;
	}
	jQuery(document).on('mousemove', __.drag);
	jQuery(document).on('touchmove', __.drag);
};

__.deactivateDrag = function() {

	var activeMainImageKey = jQuery(__.oDefaults.sSelectorActiveStageImage).attr('data-renderimage-view');

	jQuery(document).off('mousemove', __.drag);
	jQuery(document).off('touchmove', __.drag);
	// set current main image active in thumbnails

	jQuery(__.oDefaults.sSelectorThumbnail).removeClass(__.oDefaults.sClassActive);
	jQuery(__.oDefaults.sSelectorThumbnail + '.' + activeMainImageKey).addClass(__.oDefaults.sClassActive);
};

__.activateSwipe = function(e) {
	if (e.type === 'touchstart') {
		__.swipeX = e.originalEvent.pageX;
	}
	else {
		__.swipeX = e.pageX;
	}
	jQuery(document).on('mousemove', __.swipe);
	jQuery(document).on('touchmove', __.swipe);
// TODO: fix tracking dependency!
// RENDERINGLAYER_TRACKER.swipeHandler("Wipe");
};

__.deactivateSwipe = function() {
	jQuery(document).off('mousemove', __.swipe);
	jQuery(document).off('touchmove', __.swipe);
};


__.retrieveCurrentPos = function(e) {
	var pos;

	if (e.type === 'touchmove') {
		pos = e.originalEvent.pageX;
	}
	else {
		pos = e.pageX;
	}
	return pos;
};

__.doLeftDragAction = function(activeImage) {
	activeImage.removeClass(__.oDefaults.sClassActive);

	if (activeImage.prev().attr('data-view') && activeImage.prev().attr('data-view').indexOf('exterior') >= 0) {
		activeImage.prev().addClass(__.oDefaults.sClassActive);
	}
	else {
		activeImage.prev().prev().addClass(__.oDefaults.sClassActive);
	}
};

__.doRightDragAction = function(activeImage) {
	var firstImage;

	if (activeImage.next().length > 0 && activeImage.next().attr('data-view') && activeImage.next().attr('data-view').indexOf('exterior') >= 0) {
		activeImage.removeClass(__.oDefaults.sClassActive);
		activeImage.next().addClass(__.oDefaults.sClassActive);
	}
	else if (activeImage.attr('data-view') && activeImage.attr('data-view').indexOf('closeup') >= 0) {
		activeImage.removeClass(__.oDefaults.sClassActive);
		firstImage = jQuery(__.oDefaults.sSelectorFirstStageImageWrapper);
		firstImage.addClass(__.oDefaults.sClassActive);
	}
};

__.doDragAction = function(actionNext, actionPrev) {
	var activeImage = jQuery(__.oDefaults.sSelectorActiveStageImageWrapper);

	if (actionPrev && activeImage.prev().length > 0) {
		__.doLeftDragAction(activeImage);
	}
	else if (actionNext) {
		__.doRightDragAction(activeImage);
	}
};

__.drag = function(e) {
	var doAction = false;
	var actionPrev = false;
	var actionNext = false;

	var pos = __.retrieveCurrentPos(e);

	if (pos >= __.swipeX + 55) {
		doAction = true;
		actionPrev = true;
		__.swipeX += 55;
	}
	else if (pos <= __.swipeX - 55) {
		doAction = true;
		actionNext = true;
		__.swipeX -= 55;
	}

	if (doAction) {
		__.doDragAction(actionNext, actionPrev);
	}
};

__.getEventPos = function(e) {
	var pos;
	if (e.type === 'touchmove') {
		pos = e.originalEvent.pageX;
	}
	else {
		pos = e.pageX;
	}
	return pos;
};

__.swipe = function(e) {
	var pos = __.getEventPos(e);
	if (pos >= __.swipeX + 5) {
		__.deactivateSwipe(e);
		__.handlerBackClick(e);
	}
	else if (pos <= __.swipeX - 5) {
		__.deactivateSwipe(e);
		__.handlerNextClick(e);
	}
};


__.determineCarlineFallbackGroup = function(carline) {
	var carlineFallbackGroup = carline.charAt(0);

	// RS- und S-Modelle bekommen die gleichen Fallback-Renderings wie A-Modelle
	if (carline.indexOf('rs') === 0 || carline.indexOf('s') === 0) {
		carlineFallbackGroup = 'a';
	}

	// TT-Modelle zeigen wir als R(8)-Modelle an
	if (carline.indexOf('tt') === 0) {
		carlineFallbackGroup = 'r';
	}
	return carlineFallbackGroup;
};

__.getFallbackThumb = function(carlineFallbackGroup, property) {
	var url = window.location.href;
	var urlParts = url.split("/");
	var fallbackThumb;

	if (urlParts[2].indexOf('audi.local') >= 0) {
		fallbackThumb = urlParts[0] + '//' + urlParts[2] + '/cms4i-nemo/fallback-renderings/' +
	  carlineFallbackGroup + '-thumbnail-' + property + '.jpg';
	}
	else {
		fallbackThumb = urlParts[0] + '//' + urlParts[2] + SETUPS.get('nemo.url.static') + '/cms4i-nemo/fallback-renderings/' +
	  carlineFallbackGroup + '-thumbnail-' + property + '.jpg';
	}
	return fallbackThumb;
};

__.getFallbackImg = function(carlineFallbackGroup, property) {
	var url = window.location.href;
	var urlParts = url.split("/");
	var fallbackImg;

	if (urlParts[2].indexOf('audi.local') >= 0) {
		fallbackImg = urlParts[0] + '//' + urlParts[2] + '/cms4i-nemo/fallback-renderings/' +
	  carlineFallbackGroup + '-fullscreen-' + property + '.jpg';
	}
	else {
		fallbackImg = urlParts[0] + '//' + urlParts[2] + SETUPS.get('nemo.url.static') + '/cms4i-nemo/fallback-renderings/' +
	  carlineFallbackGroup + '-fullscreen-' + property + '.jpg';
	}
	return fallbackImg;
};

__.getRenderImageType = function(assetsObj, property) {
	var renderImageType = 'thumbnail';
	if (assetsObj[property].hasOwnProperty('thumbnail.16-9')) {
		renderImageType = 'thumbnail.16-9';
	}
	else if (assetsObj[property].hasOwnProperty('thumbnail.new')) {
		renderImageType = 'thumbnail.new';
	}
	return renderImageType;
};

__.getThumbnail = function(assetsObj, property, fallbackThumb, renderImageType) {
	var thumbImg = assetsObj[property][renderImageType];

	if (typeof thumbImg !== "undefined") {

		if (thumbImg.indexOf('?') === -1) {
			thumbImg = 'http://mediaservice.audi.com/media' + thumbImg + '?alt=' + fallbackThumb;
		}
		else {
			thumbImg = 'http://mediaservice.audi.com/media' + thumbImg;
		}
	}
	return thumbImg;
};

__.getFullImg = function(assetsObj, property, fallbackImg) {
	let fullImg;
	if (!assetsObj || !property || !(assetsObj.hasOwnProperty(property) && !!assetsObj[property]) ) {
		return '';
	}
	if (assetsObj[property].hasOwnProperty('fullscreen.new')) {
		fullImg = assetsObj[property]['fullscreen.new'];
	}
	else {
		fullImg = assetsObj[property].fullscreen || '';
	}

	if (!fullImg) {
		return null;
	}

	fullImg = 'http://mediaservice.audi.com/media' + fullImg;

	if (fullImg && fullImg.indexOf('?') === -1) {
		fullImg += '?alt=' + fallbackImg;
	}
	return fullImg;
};

__.checkForActiveImage = function(property, currentRenderingView, fullImg) {

	if (property === currentRenderingView) {
		__.activeImageSrc = fullImg;
	}
};

__.compliesWithCondition = function(assetsObj, property, assetType, isOpen) {
	var toReturn = false;

	if (property.indexOf(assetType) === 0 && assetsObj.hasOwnProperty(property) && __.activeRenderings.indexOf(property) >= 0) {
		if (isOpen && property.indexOf('_open') > 0) {
			toReturn = true;
		}
		else if (!isOpen && property.indexOf('_open') < 0) {
			toReturn = true;
		}
	}
	return toReturn;
};

__.determineAssetType = function(assetType) {
	var toReturn;
	if (assetType.indexOf('_open') > 0) {
		toReturn = assetType.substring(0, assetType.indexOf('_open'));
	}
	else {
		toReturn = assetType;
	}
	return toReturn;
};

__.isOpen = function(assetType) {
	var toReturn = false;
	if (assetType.indexOf('_open') >= 0) {
		toReturn = true;
	}
	return toReturn;
};


__.extractAssets = function(carline, assetsObj, currentRenderingView, origAssetType) {

	var toReturn = [];
	var fullImg, thumbImg, fallbackImg, fallbackThumb, renderImageType;

	var carlineFallbackGroup = __.determineCarlineFallbackGroup(carline);
	var assetType = __.determineAssetType(origAssetType);
	var isOpen = __.isOpen(origAssetType);

	var property;

	for (property in assetsObj) {

		if (!!assetsObj[property] && __.compliesWithCondition(assetsObj, property, assetType, isOpen)) {

			fallbackThumb = __.getFallbackThumb(carlineFallbackGroup, property);
			fallbackImg = __.getFallbackImg(carlineFallbackGroup, property);

			renderImageType = __.getRenderImageType(assetsObj, property);
			thumbImg = __.getThumbnail(assetsObj, property, fallbackThumb, renderImageType);
			fullImg = __.getFullImg(assetsObj, property, fallbackImg);

			if (!!fullImg) {
				__.checkForActiveImage(property, currentRenderingView, fullImg);
				toReturn.push({'name': property, 'image': fullImg, 'thumbnail': thumbImg, type: renderImageType});
			}
		}
	}
	return __.sortThumbs(assetType, isOpen, toReturn);
};

__.customArraySort = function(key, isOpen) {

	var sortOrder = [
		'exterior_straight_front',
		'exterior_front',
		'exterior',
		'exterior_back',
		'exterior_straight_back',
		'closeup_lights',
		'closeup_wheels',
		'interior',
		'dashboard',
		'rearseat'
	];

	if (isOpen) {
		sortOrder = [
			'exterior_straight_front_open',
			'exterior_front_open',
			'exterior_open',
			'exterior_back_open',
			'exterior_straight_back_open',
			'closeup_lights_open',
			'closeup_wheels_open',
			'interior',
			'dashboard',
			'rearseat'
		];
	}

	return function(a, b) {
		var toReturn = 0;
		if (sortOrder.indexOf(a[key]) < sortOrder.indexOf(b[key])) {
			toReturn = -1;
		}
		else {
			toReturn = 1;
		}
		return toReturn;
	};
};


/**
* sort thumbnails into desired order
* @param assetType:
* @param openCar:
* @param assets:
*/
__.sortThumbs = function(assetType, openCar, assets) {
	return assets.sort(__.customArraySort('name', openCar));
};

__.toggleCheckAndPreload = function() {
// - set exterior_open radio checked if unchecked and exterior radio else
	if (__.exteriorOpenToggle.checked) {
		__.exteriorToggle.checked = true;
		__.preloadDefinedImages(__.preloadImages, 'exterior');
	}
	else {
		__.exteriorOpenToggle.checked = true;
		__.preloadDefinedImages(__.preloadImagesOpen, 'exterior_open');
	}
};

__.setDefaultActiveThumbnail = function() {

	if (jQuery(__.oDefaults.sSelectorRenderingLayer).hasClass(__.oDefaults.sClassCabrioOpen)) {
		jQuery(__.oDefaults.sSelectorItemExteriorOpen).first().addClass(__.oDefaults.sClassActive);
	}
	else {
		jQuery(__.oDefaults.sSelectorItemExterior).first().addClass(__.oDefaults.sClassActive);
	}
};

__.retrieveCorrespondingCabrioKey = function(activeKey) {
	var correspondingKey;

	if (activeKey.indexOf('_open') > 0) {
		correspondingKey = activeKey.substring(0, activeKey.indexOf('_open'));
	}
	else {
		correspondingKey = activeKey + '_open';
	}
	return correspondingKey;
};

/**
* handle click on open toggle
* @param e:  the click event
*/
__.handlerOpenToggle = function(e) {

	var activeThumbnail = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var activeKey = activeThumbnail.attr('data-key');
	var correspondingKey;
	var correspondingThumbnail;

	e.preventDefault();

	// - set class so that thumbnail animation is inactive
	jQuery(__.oDefaults.sSelectorThumbnailArea).addClass(__.oDefaults.sClassNoThumbnailAnimation);

	__.toggleCheckAndPreload();

	// - remove class so that thumbnail animation is active again
	setTimeout(function() {
		jQuery(__.oDefaults.sSelectorThumbnailArea).removeClass(__.oDefaults.sClassNoThumbnailAnimation);
	}, 50);

	if (activeThumbnail.length === 0) {
		__.setDefaultActiveThumbnail();
	}

	if (jQuery(__.oDefaults.sSelectorRenderingLayer).hasClass(__.oDefaults.sClassCabrioOpen)) {
		jQuery(__.oDefaults.sSelectorRenderingLayer).removeClass(__.oDefaults.sClassCabrioOpen);
		jQuery(__.oDefaults.sSelectorOpenButton).html(window.i18n['rendering-layer.cabrio-open']);
	}
	else {
		jQuery(__.oDefaults.sSelectorRenderingLayer).addClass(__.oDefaults.sClassCabrioOpen);
		jQuery(__.oDefaults.sSelectorOpenButton).html(window.i18n['rendering-layer.cabrio-close']);
	}

	if (activeKey) {
		correspondingKey = __.retrieveCorrespondingCabrioKey(activeKey);
		correspondingThumbnail = jQuery('.' + correspondingKey);
		__.activateImage(correspondingThumbnail, false, false);
	}
	else {
	// console.log("failed to retrieve active thumbnail: ", activeThumbnail);
	}
};


exports.determinePrevFromActive = function(active) {

	var prev;

	if (active.hasClass(__.oDefaults.sClassItemExterior)) {
		prev = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().prev().children(__.oDefaults.sSelectorItemExterior).get(0);
	}
	else if (active.hasClass(__.oDefaults.sClassItemExteriorOpen)) {
		prev = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().prev().children(__.oDefaults.sSelectorItemExteriorOpen).get(0);
	}
	else if (active.hasClass(__.oDefaults.sClassItemInterior)) {
		prev = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().prev().children(__.oDefaults.sSelectorItemInterior).get(0);
	}
	return prev;
};

exports.determineDefaultPrev = function() {

	var prev;

	if (__.interiorToggle.checked) {
		prev = jQuery(__.oDefaults.sSelectorItemInterior).last().get(0);
	}
	else if (__.exteriorOpenToggle && __.exteriorOpenToggle.checked) {
		prev = jQuery(__.oDefaults.sSelectorItemExteriorOpen).last().get(0);
	}
	else {
		prev = jQuery(__.oDefaults.sSelectorItemExterior).last().get(0);
	}
	return prev;
};

/**
* handle click on prev button
* @param e:  the click event
*/
__.handlerBackClick = function(e) {

	var active = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var prev;

	e.preventDefault();

	if (active.length > 0) {
		prev = exports.determinePrevFromActive(active);
	}

	if (typeof prev === 'undefined') {
		prev = exports.determineDefaultPrev();
	}
	__.activateImage(prev, true, 'prev');
};

exports.determineNextFromActive = function(active) {

	var next;

	if (active.hasClass(__.oDefaults.sClassItemExterior)) {
		next = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().next().children(__.oDefaults.sSelectorItemExterior).get(0);
	}
	else if (active.hasClass(__.oDefaults.sClassItemExteriorOpen)) {
		next = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().next().children(__.oDefaults.sSelectorItemExteriorOpen).get(0);
	}
	else if (active.hasClass(__.oDefaults.sClassItemInterior)) {
		next = jQuery(__.oDefaults.sSelectorThumbnailActive).parent().next().children(__.oDefaults.sSelectorItemInterior).get(0);
	}
	return next;
};

exports.determineDefaultNext = function() {

	var next;

	if (__.interiorToggle.checked) {
		next = jQuery(__.oDefaults.sSelectorItemInterior).first().get(0);
	}
	else if (__.exteriorOpenToggle && __.exteriorOpenToggle.checked) {
		next = jQuery(__.oDefaults.sSelectorItemExteriorOpen).first().get(0);
	}
	else {
		next = jQuery(__.oDefaults.sSelectorItemExterior).first().get(0);
	}
	return next;
};

/**
* handle click on next button
* @param e:  the click event
*/
__.handlerNextClick = function(e) {

	var active = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var next;

	e.preventDefault();

	if (active.length > 0) {
		next = exports.determineNextFromActive(active);
	}

	if (typeof next === 'undefined') {
		next = exports.determineDefaultNext();
	}
	__.activateImage(next, true, 'next');
};

/**
 * handle click on thumbnail
 * @returns {void}
 */
__.handlerThumbnailClick = function() {
	if (!jQuery(this).hasClass(__.oDefaults.sClassActive)) {
		__.activateImage(this, true, false);
	}
};

/**
 * handle click on plus button
 * @param {Event} e - the click event
 * @returns {void}
 */
__.handlerPlusButtonClick = function(e) {
	var correspondingThumbnail = jQuery(this).parent().parent().find('.nm-rl-placeholder');
	var correspondingLabel = jQuery(this).parent().parent().find('.nm-renderinglayer-expander');

	e.preventDefault();
	correspondingThumbnail.trigger('click');
	correspondingLabel.trigger('click');
};


__.doStageAnimation = function(targetPos) {

	var ie8 = jQuery('.ie8').length > 0;
	var ie9 = jQuery('.ie9').length > 0;

	if (!ie8 && !ie9) {
		jQuery(__.oDefaults.sSelectorStageImages).css('left', targetPos);
	}
	else {
		jQuery(__.oDefaults.sSelectorStageImages).animate({
			'left': targetPos
		}, 500);
	}
};


__.determineAnimationDirection = function(thumbObj, direction, lastActive) {
	var prevDirection = false;
	if (direction === false) {
		if (lastActive.parent().prevAll().find(thumbObj).length > 0) {
			prevDirection = true;
		}
	}
	else {
		if (direction === 'prev') {
			prevDirection = true;
		}
	}
	return prevDirection;
};


/**
* activate named image
* @param thumbObj:   corresponding target thumbObj
* @param animate:    whether the transition shall be animated
* @param direction:  animation direction
*/
__.activateImage = function(thumbObj, animate, direction) {

	var lastActive = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var newKey = jQuery(thumbObj).attr('data-key');
	var sourceSelector = '.nm-rendersource-' + newKey;
	var newSrcObj = jQuery(sourceSelector);
	var oldImageElement = jQuery('.nm-rendersource-' + lastActive.attr('data-key')).closest('.nm-renderinglayer-imagewrapper');
	var newImageElement = newSrcObj.closest('.nm-renderinglayer-imagewrapper');
	var prevDirection = false;

	// prevent new image selection during running animation
	if (jQuery(__.oDefaults.sSelectorStageImages).hasClass(__.oDefaults.sClassAnimate)) {
		return;
	}

	// change the active thumbnail
	jQuery(__.oDefaults.sSelectorThumbnail).removeClass(__.oDefaults.sClassActive);
	jQuery(thumbObj).addClass(__.oDefaults.sClassActive);

	// find image to activate and make visible

	if (animate === true) {

	// determine animation direction
		prevDirection = __.determineAnimationDirection(thumbObj, direction, lastActive);

		// prepare stage (w/o animation)
		if (prevDirection) {
			jQuery(__.oDefaults.sSelectorStageImages).css('left', '-100%');
			oldImageElement.css('left', '50%');
			newImageElement.css('left', '0');
		}
		else {
			jQuery(__.oDefaults.sSelectorStageImages).css('left', '0');
			oldImageElement.css('left', '0');
			newImageElement.css('left', '50%');
		}
		newImageElement.addClass(__.oDefaults.sClassActive);

		// do animation
		setTimeout(function() {
			jQuery(__.oDefaults.sSelectorStageImages).addClass(__.oDefaults.sClassAnimate);

			if (prevDirection) {
				__.doStageAnimation('0');
			}
			else {
				__.doStageAnimation('-100%');
			}

			setTimeout(function() {

			// cleanup after animation
				oldImageElement.css('left', '0');
				oldImageElement.removeClass(__.oDefaults.sClassActive);
				jQuery(__.oDefaults.sSelectorStageImages).css('left', '0');
				newImageElement.css('left', '0');
				__.activateSwipeOrDrag();
				jQuery(__.oDefaults.sSelectorStageImages).removeClass(__.oDefaults.sClassAnimate);

			}, 500);

		}, 10);

	}
	else {
		newImageElement.addClass(__.oDefaults.sClassActive);
		oldImageElement.removeClass(__.oDefaults.sClassActive);
		__.activateSwipeOrDrag();
	}
	setTimeout(function() {
		__.updateCounter();
	}, 50);
};


exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};

export {exports as renderinglayer};

