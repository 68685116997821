import {signal} from 'core-application';
import {appEvents} from 'core-application';
import {dpuApi} from './dpu/api';
import {entryScroll} from 'core-global';
import {template} from 'core-utils';
import {dynamicRecommendationTemplate} from './dynamic-recommendation-template';

export default class DynamicRecommendation extends HTMLElement {
	static get selectors() {
		return {
			container: '.nm-module-summary-dynamic-recommendation',
			hidden: 'nm-hidden'
		};
	}

	constructor() {
		super();
		this.eventBus = signal.getEmitter();
		entryScroll.register('recommendations', 'summary-dynamic-recommendation');
	}

	connectedCallback() {
		this.moduleContainer = this.querySelector(DynamicRecommendation.selectors.container);

		this.addEvents();
		this.renderRecommendations();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.eventBus.on(appEvents.CONFIG_UPDATE, () => {
			this.renderRecommendations();
		});
	}

	removeEvents() {
		this.eventBus.off(appEvents.CONFIG_UPDATE, () => {
			this.renderRecommendations();
		});
	}

	renderRecommendations() {
		const recommendations = this.getRecommendations();

		if (recommendations.length > 0) {
			this.updateContent(this.renderTemplate(recommendations), true);
		}
		else {
			this.updateContent();
		}
	}

	getRecommendations(maxItems = 5) {
		const recommendations = dpuApi.getDynamicRecommendations();
		return recommendations.slice(0, maxItems).map(recommendation => Object.assign({}, recommendation, dpuApi.getItem(recommendation.id)));
	}

	renderTemplate(recommendations) {
		return template.render(dynamicRecommendationTemplate, recommendations);
	}

	updateContent(html = '', isVisible = false) {
		this.moduleContainer.innerHTML = html;
		if (isVisible) {
			this.classList.remove(DynamicRecommendation.selectors.hidden);
		}
		else {
			this.classList.add(DynamicRecommendation.selectors.hidden);
		}

	}
}

if (!window.customElements.get('audi-configurator-dynamic-recommendation')) {
	window.customElements.define('audi-configurator-dynamic-recommendation', DynamicRecommendation);
}
