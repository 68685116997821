/**
* This module intercepts clicks on <a> elements
* decorated with <TODO> attribute and sends
* a request to the DP
*/

import {dom as DOM_UTILS} from 'core-utils';

import {dpuApi as API} from './dpu/api';

const __ = {};
const exports = {
	__: __
};

__.handleClickDownloadLink = function(event) {
	event.preventDefault();

	const url = SETUPS.get('nemo.url.dpu') + 'media-zip';
	const filename = API.getCarline() + '.zip';
	const options = __.getFetchOptions(filename);

	fetch(url, options)
		.then(response => {
			if (response.ok) {
				return response.blob();
			}
		})
		.then(blob => {
			const fakeLink = __.createFakeLink(filename);
			fakeLink.href = window.URL.createObjectURL(blob);
			fakeLink.click();
		})
		.catch(err => {
			console.error(err);
		});

	return true;
};

__.getFetchOptions = (filename) => {
	// the DPU only understands Form Data as request data format
	// so we use URLSearchParams which transforms the data into '?key=value&key2=value2'
	// this is needed for 'Content-Type': 'application/x-www-form-urlencoded'
	const data = new URLSearchParams();
	data.append('carline', API.getCarlineName());
	data.append('images', __.getImageListFromConfigurationObject().join('\n'));

	return {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Content-Disposition': `attachment; filename=${filename}`
		},
		body: data
	};
};

__.createFakeLink = (filename) => {
	const fakeLink = document.createElement('a');
	fakeLink.download = filename;
	fakeLink.style.display = 'none';
	fakeLink.setAttribute('data-tracking-exclude', true);

	document.body.appendChild(fakeLink);
	return fakeLink;
};

__.addEvents = function() {
	__.domEventDelegate.on('click', '.nm-j-zip-download-delegate-click', __.handleClickDownloadLink);
};

__.getImageListFromConfigurationObject = function() {
	const assets = API.getConfigurationAssets() || [];
	const baseUrl = API.getRenderUrl();
	const list = [];

	for (const key of Object.keys(assets)) {
		const imageUrl = 'https:' + baseUrl + (assets[key].fullscreen || assets[key]['fullscreen.new'] || assets[key]['stage.new']);
		list.push(imageUrl);
	}

	return list;
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
};

export {exports as zipDownload};
