import {dom as DOM_UTILS} from "core-utils";
import {dpuApi as CONFIGURATOR_API} from "./dpu/api";
import {dpuController as DPU_CONTROLLER} from "./dpu/controller";
import {appEvents as EVENTS} from "core-application";
import {router as ROUTER} from "core-application";
// private vars
const __ = {},
	exports = {
		'__': __
	};
__.oDefaults = {
	sPreconfTeaserSelector: '.nm-module-preconfiguration-teaser',
	spreconfigSlide: '.nm-md-preconfig-teaser-slide',
	sButtonSelector: '.j-preconfig-submit',
	sButtonConfigurationClass: 'nm-md-preconfig-teaser-custom-configuration',
	sSelectorTabsNavi: 'ul.nm-preconfiguration-teaser-thumbs',
	sSelectorTeaserTemplates: 'j-preconfig-teaser-tpl-',
	sTabsSelectorDelegateClick: 'li.nm-preconfiguration-teaser-thumbnail',
	sMenuSelectedClass: 'nm-selected',
	sSelectorTeaserContainer: '.nm-preconfig-teaser-peephole',
	selectorArrow: '.nm-preconfiguration-teaser-slide-arrows a',
	selectorArrowPrev: '.nm-preconfiguration-teaser-slide-prev',
	selectorArrowNext: '.nm-preconfiguration-teaser-slide-next',
	classHidden: 'nm-hidden'
};

__.selectedIndex = 0;
__.maxIndex = 0;
__.startPos = {
	x: 0,
	y: 0
};
__.PREV = "prev";
__.sensitivity = 40;
__.direction = 'left';
__.isAnimating = false;
__.xDirection = "none";
__.yDirection = "none";
__.deltaX = 0;
__.deltaY = 0;
__.eventLookup = {
	touchdevice: {
		down: 'touchstart',
		move: 'touchmove',
		up: 'touchend'
	},
	desktopdevice: {
		down: 'mousedown',
		move: 'mousemove',
		up: 'mouseup'
	}
};
__.isDown = false;
__.deviceType = 'desktopdevice'; // 'touchdevice'
__.isTouchDevice = function() {
	return !!('ontouchstart' in window) || !!(navigator.msMaxTouchPoints);
};

__.handlePageLoaded = function() {
	// add module events
	if (__.$preconfigTeaserContainer && jQuery.contains(document, __.$preconfigTeaserContainer[0])) {
		return false;
	}
	__.$preconfigTeaserContainer = jQuery(__.oDefaults.sPreconfTeaserSelector);
	// teaser exists?
	if (__.$preconfigTeaserContainer.length) {
		var templates = jQuery('script[id^=\'' + __.oDefaults.sSelectorTeaserTemplates + '\']');
		__.maxIndex = templates.length - 1;
		if (__.isTouchDevice()) {
			__.deviceType = 'touchdevice';
		}
		if (__.maxIndex > 0) {
			__.addSwipeEvents();
			__.$preconfigTeaserContainer.on('click.tabs touchend.tabs', __.oDefaults.sTabsSelectorDelegateClick, __.handleTabClick);
		}
		/* jslint maxlen: 175 */
		__.$preconfigTeaserContainer.on('click.configuration.start touchend.configuration.start', __.oDefaults.sButtonSelector, __.handleConfigurationStartClick);
		__.eventBus.on(EVENTS.CONFIG_UPDATE, __.updateButtonLabel);
		__.updateButtonLabel();
		__.updateArrows(0);
	}
};

__.addSwipeEvents = function() {
	__.removeSwipeEvents();
	/* jslint maxlen: 175 */
	__.$preconfigTeaserContainer.off(__.eventLookup[__.deviceType].down, __.handleMouseDown).on(__.eventLookup[__.deviceType].down, __.handleMouseDown);
};

__.removeSwipeEvents = function() {
	__.$preconfigTeaserContainer.off(__.eventLookup[__.deviceType].up, __.handleMouseUp);
	__.$preconfigTeaserContainer.off(__.eventLookup[__.deviceType].move, __.handleMouseMove);
};

__.handleMouseDown = function(event) {
	__.isDown = false;
	// swipe not allowed for dropdown,input and option elements
	/* jslint maxlen: 175 */
	if (event.target.nodeName.toLowerCase() !== "select" && event.target.nodeName.toLowerCase() !== "input" && event.target.nodeName.toLowerCase() !== "option") {
		__.isDown = true;
		__.deltaX = 0;
		__.deltaY = 0;
		__.startPos.x = event.pageX || event.originalEvent.changedTouches[0].pageX;
		__.startPos.y = event.pageY || event.originalEvent.changedTouches[0].pageY;
		__.$preconfigTeaserContainer.off(__.eventLookup[__.deviceType].up, __.handleMouseUp).on(__.eventLookup[__.deviceType].up, __.handleMouseUp);
		if (!__.isTouchDevice()) {
			__.$preconfigTeaserContainer.on(__.eventLookup[__.deviceType].move, __.handleMouseMove);
		}
	}
};

__.getXDirection = function(deltaX) {
	if (deltaX > 0) {
		return "left";
	}
	return "right";
};
__.getYDirection = function(deltaY) {
	if (deltaY > 0) {
		return "up";
	}
	return "down";
};

__.handleMouseUp = function _handleMouseUp(event) {
	__.down = false;
	__.removeSwipeEvents();
	__.deltaX = __.startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
	__.deltaY = __.startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);

	__.xDirection = __.getXDirection(__.deltaX);
	__.yDirection = __.getYDirection(__.deltyY);

	// handle horizontal movement only
	if (Math.abs(__.deltaX) > __.sensitivity && Math.abs(__.deltaY) < __.sensitivity) {
		__.triggerViewChange(__.xDirection);
	}
};

__.handleMouseMove = function _handleMouseMove(event) {
	if (__.isDown) {
		__.deltaX = __.startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
		__.deltaY = __.startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);

		__.xDirection = __.getXDirection(__.deltaX);
		__.yDirection = __.getYDirection(__.deltyY);

		// horizontal move
		if (Math.abs(__.deltaX) > __.sensitivity && Math.abs(__.deltaY) < __.sensitivity) {
			__.removeSwipeEvents();
			__.isDown = false;
			__.triggerViewChange(__.xDirection);
		}
	}
};

__.removeEvents = function() {
	__.$preconfigTeaserContainer = jQuery(__.oDefaults.sPreconfTeaserSelector);
	if (__.$preconfigTeaserContainer.length) {
		__.removeSwipeEvents();
		__.$preconfigTeaserContainer.off('click.tabs touchend.tabs', __.handleTabClick);
		__.$preconfigTeaserContainer.off('click.configuration.start touchend.configuration.start', __.handleConfigurationStartClick);
		jQuery('body').off(EVENTS.CONFIG_UPDATE, __.updateButtonLabel);
	}
};

__.handleConfigurationStartClick = function(event) {
	__.cancelEvent(event);
	var $btn = jQuery(this);
	var prString = $btn.data('prstring');
	var audiCode = $btn.data('audicode');

	jQuery(event.target).closest('a').trigger('click.link-tracking');

	if (audiCode) {
		// load configuration by AudiCode
		DPU_CONTROLLER.loadConfigurationFromTeaser({
			audicode: audiCode,
			prstring: null
		}, 'audicode.preconfig-teaser').then(function() {
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
		});
		return false;
	}
	if (prString) {
		// load configuration by prString
		DPU_CONTROLLER.loadConfigurationFromTeaser({
			prstring: prString,
			audicode: null
		}, 'prstring.preconfig-teaser').then(function() {
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
		});
		return false;
	}

	// always go to the defined url to start a configuration
	ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());

	// else ==> load Default Configuration
	jQuery("a.nm-configuration-new").trigger('click.configuration.new');

	// console.error("no AudiCode or prString defined!!!");
};

__.handleTabClick = function(event) {
	if (event && event.preventDefault) {
		event.preventDefault();
	}

	var index,
		previous = __.selectedIndex;

	if (this.hasAttribute('data-direction')) {
		var	direction = this.getAttribute('data-direction');
		if (direction === __.PREV) {
			index = previous - 1;
		}
		else {
			index = previous + 1;
		}
	}
	else {
		index = $(this).index();
	}

	__.cancelEvent(event);
	if (__.isAnimating) {
		return null;
	}
	else if (index === previous) {
		// no index change!
		return false;
	}
	else if (index > previous) {
		__.direction = 'left';
	}
	else {
		__.direction = 'right';
	}
	__.selectedIndex = index;
	__.updateContent(__.selectedIndex, __.direction);
};

__.updateArrows = function(index_) {
	if (__.maxIndex == 0) {
		return;
	}

	var arrowPrev = DOM_UTILS.getElement(__.oDefaults.selectorArrowPrev),
		arrowNext = DOM_UTILS.getElement(__.oDefaults.selectorArrowNext);

	if (index_ == 0) {
		arrowPrev.classList.add(__.oDefaults.classHidden);
	}
	else {
		arrowPrev.classList.remove(__.oDefaults.classHidden);
	}
	if (index_ == __.maxIndex) {
		arrowNext.classList.add(__.oDefaults.classHidden);
	}
	else {
		arrowNext.classList.remove(__.oDefaults.classHidden);
	}
};

__.getNextIndex = function(currIndex) {
	return (currIndex + 1) % (__.maxIndex + 1);
};

__.getPreviousIndex = function(currIndex) {
	var prevIdx = currIndex - 1;
	if (prevIdx < 0) {
		prevIdx = __.maxIndex;
	}
	return prevIdx;
};

__.updateContent = function(index_, direction_) {
	// insert new content and animate in
	__.insertTeaser(index_, direction_);
	__.updateSelection(index_);
};

__.triggerViewChange = function(swipeDirection_) {
	var index = __.selectedIndex;

	if (__.isAnimating) {
		return null;
	}
	else {
		__.legacyEventBus.trigger('swipe.preconfiguration', {
			prop16: "<prefix>:" + CONFIGURATOR_API.getCarlineName(),
			eVar39: "Pre-Config Switch",
			prop39: "Pre-Config Switch",
			eVar44: "Pre-Config Switch",
			prop44: "Pre-Config Switch",
			eVar46: "<pagename>:Pre-Config Switch:Swipe",
			prop46: "<pagename>:Pre-Config Switch:Swipe",
			prop67: "<pagename>"
		});
	}

	// direction
	__.direction = swipeDirection_;
	if (swipeDirection_ === 'right') {
		index = __.getPreviousIndex(index);
	}
	else {
		index = __.getNextIndex(index);
	}
	__.selectedIndex = index;
	// update content and start animation
	__.updateContent(__.selectedIndex, __.direction);
};

__.handlePageLeave = function() {
	// remove module events
	__.removeEvents();
};

__.animateStage = function(direction_, $teaserContainer, currentHeight, newHeight) {
	// animate stage
	var contentWidth = $teaserContainer.width();

	var xPos = '-' + (contentWidth * 0.5) + 'px';
	__.isAnimating = true;

	if (direction_ === 'left') {
		$teaserContainer.css('height', currentHeight).animate({
			'margin-left': xPos,
			'height': newHeight
		}, 500, 'easeOutSine', __.animationEnded);
	}
	else {
		$teaserContainer.css('margin-left', xPos).animate({
			'margin-left': 0,
			'height': newHeight
		}, 500, 'easeOutSine', __.animationEnded);
	}
};

__.insertTeaser = function(index_, direction_) {
	var $teaserContainer = jQuery(__.oDefaults.sSelectorTeaserContainer);
	var templateHTML, $template, $currentTemplate, currentHeight, newHeight;
	$teaserContainer.addClass('nm-slide-animation-start-' + direction_);
	// append new teaser
	templateHTML = __.getTemplateByIndex(index_);
	$template = jQuery(templateHTML);
	$currentTemplate = jQuery(__.oDefaults.spreconfigSlide);
	currentHeight = $currentTemplate.height() + 'px';

	$template.appendTo($teaserContainer);
	newHeight = $template.height() + 'px';

	__.animateStage(direction_, $teaserContainer, currentHeight, newHeight);

	// trigger content:rendered to inform the fefa about new dom content
	const teaserContainer = document.querySelector(__.oDefaults.sSelectorTeaserContainer);
	const customEvent = new CustomEvent('content:rendered', {detail: {element: teaserContainer}});
	document.dispatchEvent(customEvent);
};

__.animationEnded = function() {
	var $animationContainer = $(this).removeClass(['nm-slide-animation-start-', __.direction].join('')).removeAttr('style');
	var $oldSlide = $animationContainer.find(__.oDefaults.spreconfigSlide).first();
	$oldSlide.remove();
	__.isAnimating = false;
	__.updateArrows(__.selectedIndex);
};

__.updateButtonLabel = function() {
	// check for custom configuration
	if (CONFIGURATOR_API.hasCustomConfiguration()) {
		$(__.oDefaults.sSelectorTeaserContainer).addClass(__.oDefaults.sButtonConfigurationClass);
	}
	else {
		$(__.oDefaults.sSelectorTeaserContainer).removeClass(__.oDefaults.sButtonConfigurationClass);
	}
};

/**
	 * update selected Tab
	 * @param {index_}
	 * @return {void}
	 */
__.updateSelection = function(index_) {
	var $tabContainer = __.$preconfigTeaserContainer.find(__.oDefaults.sSelectorTabsNavi);
	var $tabs = $tabContainer.find(__.oDefaults.sTabsSelectorDelegateClick);
	var $currTab;
	$tabs.removeClass(__.oDefaults.sMenuSelectedClass);
	$currTab = jQuery($tabs[index_]);
	$currTab.addClass(__.oDefaults.sMenuSelectedClass);
};

__.getTemplateByIndex = function(index_) {
	// jQuery 1.9 BUG with client-side templates and whitespaces!!!=>trim()
	var template = jQuery(['#', __.oDefaults.sSelectorTeaserTemplates, index_].join('')).html() || '';
	template = template.trim();
	return template;
};

/**
	 * prevent default event behavior
	 * @param {Object} event_ - e.g. click event
	 * @return {void}
	 */
__.cancelEvent = function(event_) {
	if ( !!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};


__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handlePageLoaded);
	// TODO: fix tracking dependency!!!
	// __.domEventDelegate.on('swipe.preconfiguration', TRACKING_UTILS.proxyMethod(SWIPE_TRACKER.swipeTrackingHandler, __));
	__.domEventDelegate.on('click', __.oDefaults.selectorArrow, __.handleTabClick);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
	__.handlePageLoaded();
};

export {exports as preconfigurationTeaser};

