// listen to document.ready, pageloaded, formloaded and add onchange handler to select
// add .nm-at-select-selected if checked & not first-item / default value

// FORM_LOADED
// PAGE_LOADED
// APP_READY


import {appEvents as EVENTS} from "core-application";
const __ = {},
	exports = {
		__: __
	};
__.instance = false;
__.oDefaults = {
	sDropdownSelectionClass:"nm-at-select-default-val"
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.onPageLoaded);
	__.eventBus.on(EVENTS.FORM_LOADED, __.onFormLoaded);


	__.handleSelectChanges();
};

__.onPageLoaded=function(){
	__.handleSelectChanges();
};

__.onFormLoaded=function(){
	__.handleSelectChanges();
};

__.handleSelectChanges = function(event) {
	var $selects = jQuery("select");
	jQuery.each($selects, function() {

		var $select = jQuery(this);

		__.legacyEventBus.on('change.select', __.updateSelect);

		__.setSelectState($select);
	});
};

__.updateSelect = function(event_) {
	var $select = jQuery(event_.target);

	__.setSelectState($select);
};

__.setSelectState = function(select_) {

	var $selected = select_.find("option:selected");
	var $filterValue, $el, $isDefault;
	jQuery.each($selected, function() {
		$el = jQuery(this);
		$filterValue = $el.val();
		$isDefault = ($el.attr("data-is-default") !== undefined);
		$el.parent().toggleClass(__.oDefaults.sDropdownSelectionClass, ( ($filterValue && $filterValue === "*") || $isDefault ));
	});
};

__.initialize = function() {
	__.addEvents();
	__.handleSelectChanges();
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.initialize();
};

export {exports as formSelect};
