const template = `<div class="nm-layer-wrapper nm-layer-wrapper-dialogue nm-layer-wrapper-transfer">
	<div class="nm-layer nm-layer-transfer nm-layer-transfer-default">
		<div class="nm-layer-inner">
			<div class="nm-layer-conflict-content clearfix">
				<div class="nm-3col cearfix">
					<div class="">
						<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">
							{{=window.i18n['transfer-layer.configuration-transfer']}}
						</h2>
						{{? 0 !== it.deleted.length}}
						<strong class="nm-el-pg nm-el-pg-01">
							{{=window.i18n['transfer-layer.configuration-transfer-warning']}}:
						</strong>
						<ul class="nm-options-list nm-transfer-trigger nm-j-configurator-no-update">
							{{~it.deleted :value:index}}
							<li class="nm-check-list-item nm-j-configurator-item">
								<div class="nm-options-cell-additional">
									/*
									<div class="nm-list-item-additional clearfix">
										<div class="nm-price nm-el-pg nm-el-pg-04">
											{{=value.price}}
										</div>
									</div>
									*/
									<span class="nm-list-item-text nm-el-pg nm-el-pg-04">
										{{=value.name}}
									</span>
								</div>
							</li>
							{{~}}
						</ul>
						{{?}}
					</div>
					<ul class="nm-button-list clearfix">
						/*<li>
							<a class="nm-b7 nm-j-configurator-delegate-transfer-click" data-transfer="cancel"
							href="{{=it.urls.cancel}}">{{=window.i18n['transfer-layer.label.cancel']}}</a>
						</li>*/
						<li>
							<div class="nm-btn-continue">
								<a class="nm-j-configurator-delegate-transfer-click nm-el-btn nm-el-btn-01 nm-el-btn-ast" data-transfer="submit">{{=window.i18n['proceed-label']}}</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>`;
export {template as transferLayerTemplate};
