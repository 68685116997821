import {dom} from 'core-utils';

export default class Basket extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this.basketContainer = this.querySelector('.nm-basket-container');
		this.footerCheck = this.parentNode.querySelector('.nm-basket-scroll-check-bottom');
		this.topCheck = [...document.querySelectorAll('.nm-navigation-scroll-check')].pop();
		this.contentContainer = this.parentNode.querySelector('.nm-content');

		this.isStickyBottom = false;

		this.addEvents();
		this.setupPageForBasket();
		this.observerTopScrollPosition = this.observeTopScrollPosition();
		this.observerBottomScrollPosition = this.observeBottomScrollPosition();
		this.observerMatchingWidget = this.observeMatchingWidget();
	}

	disconnectedCallback() {
		this.removeEvents();
		this.observerTopScrollPosition.disconnect();
		this.observerBottomScrollPosition.disconnect();
		this.observerMatchingWidget.disconnect();
	}

	addEvents() {
		this.basketContainer.addEventListener('transitionend', dom.throttle(() => this.setupPageForBasket(), 500));
		window.addEventListener('resize', () => this.setupPageForBasket());
	}

	removeEvents() {
		this.basketContainer.removeEventListener('transitionend', dom.throttle(() => this.setupPageForBasket(), 500));
		window.removeEventListener('resize', () => this.setupPageForBasket());
	}

	setupPageForBasket() {
		const basketHeight = this.getBasketHeight();

		this.contentContainer.style.minHeight = basketHeight + 'px';
		this.footerCheck.style.bottom = basketHeight + 'px';
		this.isStickable = window.innerHeight > basketHeight;
		this.checkBottomPosition();
	}

	observeBottomScrollPosition() {
		const observer = new IntersectionObserver(
			entries => entries.forEach(entry => this.checkBottomPosition(entry)), {
				threshold: [0, 1]
			}
		);
		observer.observe(this.footerCheck);

		return observer;
	}

	observeTopScrollPosition() {
		const observer = new IntersectionObserver(
			entries => entries.forEach(entry => this.checkTopPosition(entry)), {
				threshold: [0, 1]
			}
		);
		observer.observe(this.topCheck);
		return observer;
	}

	observeMatchingWidget() {
		const observer = new MutationObserver(() => {
			this.setupPageForBasket();
		});

		observer.observe(this, {childList: true, subtree: true});

		return observer;
	}

	checkTopPosition(entry) {
		const currentEntry = entry ? entry : {
			boundingClientRect: this.topCheck.getBoundingClientRect(),
			isIntersecting: false
		};
		this.isStickyTop = (this.isStickable || !this.isFullBasketShown) &&
			!currentEntry.isIntersecting &&
			currentEntry.boundingClientRect.top.toFixed(0) <= 0;
	}

	checkBottomPosition(entry) {
		const currentEntry = entry ? entry : {
			boundingClientRect: this.footerCheck.getBoundingClientRect(),
			isIntersecting: false
		};
		this.isStickyBottom = this.isStickable && this.isFullBasketShown &&
			!currentEntry.isIntersecting &&
			currentEntry.boundingClientRect.top.toFixed(0) <= 0;
	}

	getBasketHeight() {
		this.classList.add('nm-basket-superwrap-hidden-measure');
		const height = this.basketContainer.clientHeight;
		this.classList.remove('nm-basket-superwrap-hidden-measure');
		return height + this.getBasketOffset();
	}

	getBasketOffset() {
		const ADDITIONAL_OFFSET = 54;
		const initialOffset = (this.isFullBasketShown ? ADDITIONAL_OFFSET : 0);

		const inPageNavigation = document.querySelector('.nm-nav-wrap');
		const navigationHeight = inPageNavigation ? inPageNavigation.clientHeight : 0;

		const currentConsumption = document.querySelector('.nm-module-current-consumption');
		const currentConsumptionHeight = currentConsumption ? currentConsumption.clientHeight : 0;

		return initialOffset + navigationHeight + currentConsumptionHeight;
	}

	set isStickyBottom(isSticky) {
		if (isSticky) {
			this.classList.add('nm-basket-sticky-bottom');
		}
		else {
			this.classList.remove('nm-basket-sticky-bottom');
		}
	}

	set isStickyTop(isSticky) {
		if (isSticky) {
			this.classList.add('nm-basket-sticky-top');
		}
		else {
			this.classList.remove('nm-basket-sticky-top');
		}

		if (isSticky && !this.isFullBasketShown) {
			this.style.top = this.getBasketOffset() + 'px';
		}
		else {
			this.style.top = '';
		}
	}

	get isStickyTop() {
		return this.classList.contains('nm-basket-sticky-top');
	}

	set isStickable(isStickable) {
		this.setAttribute('data-is-stickable', isStickable);
	}

	get isStickable() {
		return this.getAttribute('data-is-stickable') === 'true';
	}

	get isFullBasketShown() {
		const BREAKPOINT = 1100;
		const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		return (viewportWidth >= BREAKPOINT);
	}
}

if (!window.customElements.get('audi-configurator-basket')) {
	window.customElements.define('audi-configurator-basket', Basket);
}
