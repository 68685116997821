const template = `
<div class="nm-module nm-module-carstore nm-carstore" data-module="carstore">
	<div class="nm-3col">
		<h2 class="nm-headline-medium nm-carstore-headline">{{=window.i18n['carstore-layer.saved-slots']}}</h2>
		<p class="nm-text-large">{{=window.i18n['carstore-layer.manage-slots']}}</p>
		<ul class="nm-carstore-saved-items">
			{{~ it.list :value }}
				<li class="nm-carstore-item nm-j-carstore-item" data-item-position="{{=value.position}}">
					<span class="nm-carstore-item-position nm-headline-teaser">{{=value.position}}</span>
					<img class="nm-carstore-item-image" src="{{? !!value['exterior-image']}}{{=value['exterior-image']}}?alt={{?}}{{=it.altImage}}" alt="{{=value.name}}" />
					<div class="nm-carstore-item-content">
						<div class="nm-carstore-item-details nm-j-carstore-item-details">
							{{? !!value.name}}
								<strong class="nm-carstore-detail nm-headline-teaser">{{=value.name}}</strong>
							{{?}}
							{{? !!value.shoppingCartName}}
								<p class="nm-carstore-detail nm-text-block">{{=value.shoppingCartName}}<br />
									{{? !!value.powersAndUnits}}
										{{=value.powersAndUnits}}
									{{?}}
								</p>
							{{?}}
							{{? !!value.price && value.valid !== false}}
								<strong class="nm-carstore-detail nm-price nm-headline-teaser">{{=value.price}}</strong>
							{{?}}
							{{? !!value.audicode}}
								<p class="nm-carstore-detail nm-audicode nm-text-block">{{=window.i18n['audicode-label']}}: {{=value.audicode}}</p>								
							{{?}}
						</div>
						<form class="nm-carstore-modify-section nm-j-carstore-modify" data-form-id="{{=value.position}}" action="#">
							<fieldset>
								<select id="carstore-select-{{=value.position}}" class="nm-carstore-modify-select nm-j-carstore-modify-select">
									{{? value.valid !== false}}
										<option value="configuration-load">{{=window.i18n['carstore-layer.load-slot']}}</option>
									{{??}}
										<option>{{=window.i18n['carstore-layer.invalid-slot']}}</option>
									{{?}}
									<option value="configuration-delete">{{=window.i18n['delete-configuration-label']}}</option>
								</select>
								<input type="hidden" name="carstore-position" value="{{=value.position}}">
								<button class="nm-el-btn nm-el-btn-04 nm-el-btn-ast nm-j-carstore-submit" type="submit">submit</button>
							</fieldset>
						</form>
						<div class="nm-carstore-delete-section nm-j-carstore-delete-section nm-hidden">
							<strong class="nm-el-hdl nm-el-hdl-04 nm-at-hdl-b">{{=window.i18n['carstore-layer.delete-slot']}}</strong>
							<p class="nm-el-pg nm-el-pg-03">{{=window.i18n['carstore-layer.delete-dialog']}}</p>
							<div class="nm-modify-buttons">
								<a class="nm-carstore-button nm-el-btn nm-el-btn-01 nm-j-abort-button" href="#">{{=window.i18n['abort-label']}}</a> 
								<a class="nm-carstore-button nm-el-btn nm-el-btn-01 nm-el-btn-ast nm-j-delete-button" href="#">{{=window.i18n['delete-label']}}</a>
							</div>
						</div>
					</div>
				</li>
			{{~}}
		</ul>
	</div>
</div>`;

export {template as carstoreListTemplate};
