import {stateRegistry} from 'microkernel';

export const CONFIGURATION_STORE_ID = 'dbadConfigurationStore';

class ConfigurationStore {
	constructor() {
		if (!ConfigurationStore.instance) {
			this._initStore();
			ConfigurationStore.instance = this;
		}
		return ConfigurationStore.instance;
	}

	_initStore() {
		const initialData = {
			configuration: null,
			conflicts: null,
			transfers: null,
			items: null,
			defaultPrString: null,
			header: null,
			families: null,
			searchstopwords: null
		};
		const actions = {
			update: this._update
		};
		stateRegistry.addStore(CONFIGURATION_STORE_ID, initialData, actions);
	}

	_update(state, params) {
		return {...state, ...params, ...ConfigurationStore._processUpdate(params)};
	}

	static _processUpdate(params) {
		return {
			selectedTrimline: ConfigurationStore._getSelectedTrimline(params)
		};
	}

	static _getSelectedTrimline(params) {
		if (!!params.items) {
			return Object.values(params.items).reduce(
				(trimlineId, item) => (ConfigurationStore._isSelectedTrimline(item) ? item.id : trimlineId),
				null
			);
		}
		return null;
	}

	static _isSelectedTrimline(item) {
		return ConfigurationStore._isTrimlineItem(item) && ConfigurationStore._isSelectedItem(item);
	}

	static _isTrimlineItem(item) {
		return item.id && item.id.indexOf('trimline_') > -1;
	}

	static _isSelectedItem(item) {
		return item.status && item.status.charAt(3) === '1';
	}
}

const configurationStoreInstance = new ConfigurationStore();
Object.freeze(configurationStoreInstance);
