import {dpuApi as CONFIGURATOR_API} from "./dpu/api";
import {dpuController as DPU_CONTROLLER} from "./dpu/controller";
import {appEvents as EVENTS} from "core-application";
import {router as ROUTER} from "core-application";
const __ = {},
	exports = {
		__: __
	};
__.oDefaults = {
	sPreconfTeaserSelector: '.nm-md-preconfs-teaser',
	sPreconfsSlide: '.nm-md-preconfs-teaser-slide',
	sButtonSelector: '.j-preconfs-submit',
	sButtonConfigurationClass: 'nm-md-preconfs-teaser-custom-configuration',
	sSelectorDotsNavi: 'ul.nm-dots-navi',
	sSelectorTeaserTemplates: 'j-preconfs-teaser-tpl-',
	sDotsSelectorDelegateClick: 'li.nm-dot',
	sMenuSelectedClass: 'nm-selected',
	sSelectorTeaserContainer: '.nm-peephole'
};

__.selectedIndex = 0;
__.maxIndex = 0;
__.startPos = {
	x: 0,
	y: 0
};
__.sensitivity = 40;
__.direction = 'left';
__.isAnimating = false;
__.xDirection = "none";
__.yDirection = "none";
__.deltaX = 0;
__.deltaY = 0;
__.eventLookup = {
	touchdevice: {
		down: 'touchstart',
		move: 'touchmove',
		up: 'touchend'
	},
	desktopdevice: {
		down: 'mousedown',
		move: 'mousemove',
		up: 'mouseup'
	}
};
__.isDown = false;
__.deviceType = 'desktopdevice'; // 'touchdevice'
__.isTouchDevice = function() {
	return !!('ontouchstart' in window) || !!(navigator.msMaxTouchPoints);
};

__.handlePageLoaded = function() {
	// add module events
	__.$preconfsTeaserContainer = jQuery(__.oDefaults.sPreconfTeaserSelector);
	// teaser exists?
	if (__.$preconfsTeaserContainer.length) {
		var templates = jQuery('script[id^=\'' + __.oDefaults.sSelectorTeaserTemplates + '\']');
		__.maxIndex = templates.length - 1;
		if (__.isTouchDevice()) {
			__.deviceType = 'touchdevice';
		}
		if (__.maxIndex > 0) {
			__.addSwipeEvents();
			__.$preconfsTeaserContainer.on('click.dots touchend.dots', __.oDefaults.sDotsSelectorDelegateClick, __.handleDotClick);
		}
		/* jslint maxlen: 175 */
		__.$preconfsTeaserContainer.on('click.configuration.start touchend.configuration.start', __.oDefaults.sButtonSelector, __.handleConfigurationStartClick);
		__.eventBus.on(EVENTS.CONFIG_UPDATE, __.updateButtonLabel);
		__.updateButtonLabel();
		// }
	}
};

__.addSwipeEvents = function() {
	__.removeSwipeEvents();
	/* jslint maxlen: 175 */
	__.$preconfsTeaserContainer.off(__.eventLookup[__.deviceType].down, __.handleMouseDown).on(__.eventLookup[__.deviceType].down, __.handleMouseDown);
};

__.removeSwipeEvents = function() {
	__.$preconfsTeaserContainer.off(__.eventLookup[__.deviceType].up, __.handleMouseUp);
	__.$preconfsTeaserContainer.off(__.eventLookup[__.deviceType].move, __.handleMouseMove);
};

__.handleMouseDown = function(event) {
	__.isDown = false;
	// swipe not allowed for dropdown,input and option elements
	/* jslint maxlen: 175 */
	if (event.target.nodeName.toLowerCase() !== "select" && event.target.nodeName.toLowerCase() !== "input" && event.target.nodeName.toLowerCase() !== "option") {
		__.isDown = true;
		__.deltaX = 0;
		__.deltaY = 0;
		__.startPos.x = event.pageX || event.originalEvent.changedTouches[0].pageX;
		__.startPos.y = event.pageY || event.originalEvent.changedTouches[0].pageY;
		__.$preconfsTeaserContainer.off(__.eventLookup[__.deviceType].up, __.handleMouseUp).on(__.eventLookup[__.deviceType].up, __.handleMouseUp);
		if (!__.isTouchDevice()) {
			__.$preconfsTeaserContainer.on(__.eventLookup[__.deviceType].move, __.handleMouseMove);
		}
	}
};

__.handleMouseUp = function _handleMouseUp(event) {
	__.down = false;
	__.removeSwipeEvents();
	__.deltaX = __.startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
	__.deltaY = __.startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);

	if (__.deltaX > 0) {
		__.xDirection = "left";
	}
	else {
		__.xDirection = "right";
	}

	if (__.deltaY > 0) {
		__.yDirection = "up";
	}
	else {
		__.yDirection = "down";
	}
	// handle horizontal movement only
	if (Math.abs(__.deltaX) > __.sensitivity && Math.abs(__.deltaY) < __.sensitivity) {
		__.triggerViewChange(__.xDirection);
	}
};

__.handleMouseMove = function _handleMouseMove(event) {
	if (__.isDown) {
		__.deltaX = __.startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
		__.deltaY = __.startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);
		if (__.deltaX > 0) {
			__.xDirection = "left";
		}
		else {
			__.xDirection = "right";
		}

		if (__.deltaY > 0) {
			__.yDirection = "up";
		}
		else {
			__.yDirection = "down";
		}
		// horizontal move
		if (Math.abs(__.deltaX) > __.sensitivity && Math.abs(__.deltaY) < __.sensitivity) {
			__.removeSwipeEvents();
			__.isDown = false;
			__.triggerViewChange(__.xDirection);
		}
	}
};

__.removeEvents = function() {
	__.$preconfsTeaserContainer = jQuery(__.oDefaults.sPreconfTeaserSelector);
	if (__.$preconfsTeaserContainer.length) {
		__.removeSwipeEvents();
		__.$preconfsTeaserContainer.off('click.dots touchend.dots', __.handleDotClick);
		__.$preconfsTeaserContainer.off('click.configuration.start touchend.configuration.start', __.handleConfigurationStartClick);
		jQuery('body').off(EVENTS.CONFIG_UPDATE, __.updateButtonLabel);
	}
};

__.handleConfigurationStartClick = function(event) {
	__.cancelEvent(event);
	var $btn = jQuery(this);
	var prString = $btn.data('prstring');
	var audiCode = $btn.data('audicode');

	if (audiCode) {
		// load configuration by AudiCode
		DPU_CONTROLLER.loadConfigurationFromTeaser({
			audicode: audiCode,
			prstring: null
		}, 'audicode.preconfs-teaser').then(function() {
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
		});
		return false;
	}
	if (prString) {
		// load configuration by prString
		DPU_CONTROLLER.loadConfigurationFromTeaser({
			prstring: prString,
			audicode: null
		}, 'prstring.preconfs-teaser').then(function() {
			ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());
		});
		return false;
	}

	// always go to the defined url to start a configuration
	ROUTER.open('page', CONFIGURATOR_API.getCurrentCarlineUrl());

	// else ==> load Default Configuration
	jQuery("a.nm-configuration-new").trigger('click.configuration.new');

	// console.error("no AudiCode or prString defined!!!");
};

__.handleDotClick = function(event) {
	__.cancelEvent(event);
	if (__.isAnimating) {
		return null;
	}
	var index = $(this).index();
	var previous = __.selectedIndex;
	if (index === previous) {
		// no index change!
		return false;
	}
	// animate left or right?
	if (index > previous) {
		__.direction = 'left';
	}
	else {
		__.direction = 'right';
	}
	__.selectedIndex = index;
	__.updateContent(__.selectedIndex, __.direction);
};

__.getNextIndex = function(currIndex) {
	return (currIndex + 1) % (__.maxIndex + 1);
};

__.getPreviousIndex = function(currIndex) {
	currIndex = currIndex - 1;
	if (currIndex < 0) {
		currIndex = __.maxIndex;
	}
	return currIndex;

};

__.updateContent = function(index_, direction_) {
	// insert new conatent and animate in
	__.insertTeaser(index_, direction_);
	__.updateSelection(index_);
};

__.triggerViewChange = function(swipeDirection_) {
	if (__.isAnimating) {
		return null;
	}
	var index = __.selectedIndex;
	// direction
	if (swipeDirection_ === 'right') {
		__.direction = 'right';
		index = __.getPreviousIndex(index);
	}
	else {
		__.direction = 'left';
		index = __.getNextIndex(index);

	}
	__.selectedIndex = index;
	// update conetnt and start animation
	__.updateContent(__.selectedIndex, __.direction);
};


__.handlePageLeave = function() {
	// remove module events
	__.removeEvents();
};

__.insertTeaser = function(index_, direction_) {
	var $teaserContainer = jQuery(__.oDefaults.sSelectorTeaserContainer);
	$teaserContainer.addClass('nm-slide-animation-start-' + direction_);
	// append new teaser
	var templateHTML = __.getTemplateByIndex(index_);
	var $template = jQuery(templateHTML); // .clone();
	// _$currentView=$template.appendTo(jQuery(_oDefaults.sPreconfTeaserSelector).first().empty());
	var $currentTemplate = jQuery(__.oDefaults.sPreconfsSlide);
	var currentHeight = $currentTemplate.height() + 'px';

	$template.appendTo($teaserContainer);

	var newHeight = $template.height() + 'px';

	// animate stage
	var contentWidth = $teaserContainer.width();

	var xPos = '-' + (contentWidth * 0.5) + 'px';
	__.isAnimating = true;
	if (direction_ === 'left') {
		$teaserContainer.css('height', currentHeight).animate({
			'margin-left': xPos,
			'height': newHeight
		}, 500, 'easeOutSine', __.animationEnded);
	}
	else {
		$teaserContainer.css('margin-left', xPos).animate({
			'margin-left': 0,
			'height': newHeight
		}, 500, 'easeOutSine', __.animationEnded);
	}
};

__.animationEnded = function() {
	// ???
	var $animationContainer = $(this).removeClass(['nm-slide-animation-start-', __.direction].join('')).removeAttr('style');
	var $oldSlide = $animationContainer.find(__.oDefaults.sPreconfsSlide).first();
	$oldSlide.remove();
	__.isAnimating = false;
};

__.updateButtonLabel = function() {
	// check for custom configuration
	if (CONFIGURATOR_API.hasCustomConfiguration()) {
		$(__.oDefaults.sSelectorTeaserContainer).addClass(__.oDefaults.sButtonConfigurationClass);
	}
	else {
		$(__.oDefaults.sSelectorTeaserContainer).removeClass(__.oDefaults.sButtonConfigurationClass);
	}
};

// update selected Dot
__.updateSelection = function(index_) {
	var $dotContainer = __.$preconfsTeaserContainer.find(__.oDefaults.sSelectorDotsNavi);
	var $dots = $dotContainer.find(__.oDefaults.sDotsSelectorDelegateClick);
	$dots.removeClass(__.oDefaults.sMenuSelectedClass);
	var $currDot = jQuery($dots[index_]);
	$currDot.addClass(__.oDefaults.sMenuSelectedClass);
};

__.getTemplateByIndex = function(index_) {
	// jQuery 1.9 BUG with client-side templates and whitespaces!!!=>trim()
	var template = jQuery(['#', __.oDefaults.sSelectorTeaserTemplates, index_].join('')).html() || '';
	template = template.trim();
	return template;
};

/**
	 * prevent default event behavior
	 * @param {Object} event_ - e.g. click event
	 * @returns {Void}
	 */
__.cancelEvent = function(event_) {
	if ( !!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};


__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(EVENTS.PAGE_LEAVE, __.handlePageLeave);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handlePageLoaded);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
	__.handlePageLoaded();
};

export {exports as preconfsTeaser};
