import {appEvents as EVENTS} from 'core-application';
import {layerApi as LAYER_API} from 'core-application';
import {template as TPL_RENDERER} from 'core-utils';
import {hideHeaderFooter as HIDEHEADERFOOTER} from 'core-global';
import {configStartImplicitTemplate as START_IMPLICIT_TPL} from './configurationstart-implicit-layer-template';
import {configStartLoadedTemplate as CONFIG_ADOPT_TPL} from './configurationstart-loaded-layer-template';
const __ = {},
	exports = {
		__: __
	};
__.instance = false;
__.enabled = true;
__.source = false;
__.oDefaults = {
	sCloseLayerButtonSelector: '.nm-configurationstart-layer-close'
};

__.addEvents = function() {
	__.legacyEventBus.on('click.layer.configstart.close', __.oDefaults.sCloseLayerButtonSelector, __.closeStartLayer);
};
__.removeEvents = function() {
	__.legacyEventBus.off('click.layer.configstart.close', __.oDefaults.sCloseLayerButtonSelector, __.closeStartLayer);
};

__.trackLoadConfigEvent = function() {
	if (!!__.enabled) {
		return new Promise(function(resolve) {
			var interval = setInterval(function() {
				if (!!__.layerProcessed) {
					clearInterval(interval);
					if (!!__.layerActive) {
						console.log('resolve: ', __.source);
						if (__.source === 'carstore') {
							resolve({'source': 'myAudi'});
						}
						else if (__.source=== 'audicode-input') {
							resolve({'source': 'audicode'});
						}
						else {
							resolve({'source': __.source});
						}
					}
					else {
						console.log('reject');
						return Promise.reject();
					}
				}
			}, 50);
		});
	}
	else {
		return Promise.reject();
	}
};

/**
 * startlayer is not to be shown
 * @return {boolean} - true, if start layer shall not be shown
 */
__.isStartlayerNotToShow = function() {
	return (!__.enabled || HIDEHEADERFOOTER.isHiddenHeaderFooter() || SETUPS.get('scope.nemo.hide_startlayer'));
};

/*
 * opens a special 'configuration start' layer
 */
__.openStartLayer = function(data) {
	var data_ = data;

	if (__.isStartlayerNotToShow()) {
		__.layerProcessed = true;
		__.layerActive = false;
		return Promise.reject(new Error('Start layers not supported.'));
	}

	return new Promise(function(resolve, reject) {
		data_ = data;
		let layerType = 'configurationstart-implicit';
		let templateHtml;
		let tpl;

		// user template for external configurations?
		if (!!data_) {
			layerType='configurationstart-loaded';
			tpl=CONFIG_ADOPT_TPL;
		}
		else {
			layerType = 'configurationstart-implicit';
			tpl=START_IMPLICIT_TPL;
		}

		templateHtml = TPL_RENDERER.render(tpl, data_||{});
		setTimeout(function() {
			document.body.scrollTop = 0;
			let promise = LAYER_API.open(layerType, templateHtml, __.closeStartLayer);
			promise.then(function(layerInstance) {
				__.layerProcessed = true;
				__.layerActive = false;
				if (!!data_) {
					__.source = data_.start_type;
					__.layerActive = true;
				}
				__.instance = layerInstance;
				__.addEvents();
				jQuery('body').addClass('nm-config-layer-open');
				resolve(__.instance);
			}, function(err) {
				__.layerProcessed = true;
				__.layerActive = false;
				reject(err);
			});
		}, 600);
	});
};


__.closeStartLayer = function(event_) {
	__.cancelEvent(event_);
	if (__.instance && typeof __.instance.close === 'function') {
		__.instance.close();

	}
	__.eventBus.emit(EVENTS.STARTLAYER_CLOSE);
	__.instance = null;
	__.removeEvents();
	jQuery('body').removeClass('nm-config-layer-open');
};

__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

__.initialize = function() {
	// force singleton
	if (!__.instance) {
		__.addEvents();
		__.instance = true;
		__.layerProcessed = false;
		__.layerActive = true;
	}
};
/**
 * open a startlayer
 * @param {Object} data_ - startlayer data (e.g for adopting configuration warnings)
 * @returns {Promise} - promise opening the layer
 * (returning a layerInstace on resolve)
 */
exports.handleStartLayer = function(data_) {

	return __.openStartLayer(data_);
};

exports.unHandleStartLayer = function() {
	__.layerProcessed = true;
	__.layerActive = false;
};

/**
 * enable/disable start layers. Useful for applications without start layers, e.g. MSSC
 * @param {boolean} enabled start layer enabled
 * @returns {void} nothing
 */
exports.setEnabled = function(enabled) {
	__.enabled = enabled;
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.initialize();
};
export {exports as startlayer};

