import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {iph as IPH} from 'core-global';

import {dpuApi as CONFIGURATOR_API} from './dpu/api';

const __ = {},
	exports = {
		__: __
	};

let _plainLink, _entryLink, _$VTPlinks=[], $item;

__.oDefaults = {
	sSelectorVTPlink: '.nm-j-vtp-link'
};
__.handleJSONresponse = function(data_) {
	// TODO if items>0 update linkTarget plainLink=>entryLink
	var _link = _plainLink, suffix, oldText, newText;
	suffix = '';
	if (!!data_ && !!data_.num && data_.num > 0) {
		_link = _entryLink;
		suffix = ' (' + data_.num + ')';
	}
	else {
		_link = false;
	}
	_$VTPlinks=_$VTPlinks||[];
	_$VTPlinks.forEach(function(item) {
		$item = item;

		// use original CQ-link if nor results have been returned
		if (!_link) {
			_link = $item.getAttribute('data-vtp-original');
		}

		$item.setAttribute('href', _link);
		oldText = $item.textContent.split(' (')[0];
		newText = oldText + suffix;

		__.replaceVtpLinkText($item, newText);
	});
};

/**
 * replace the text in a VTP link
 * @param{HTMLElement} item_ - the item in which the text shall be replaced
 * @param{String} newText_ - the new text to set
 * @returns{void}
 */
__.replaceVtpLinkText = function(item_, newText_) {
	let item = item_;
	let isChildReplace = false;

	// note: a new arrow link has one inner element with the class 'audi-link-s__text' or 'audi-link-m__text' containing the text
	[...item.children]
		.filter(child => (child.classList.contains('audi-link-s__text') || child.classList.contains('audi-link-m__text')))
		.every(matchedChild => {
			matchedChild.textContent = newText_;
			isChildReplace = true;
			return false;
		});

	// default behavior as fallback - will work with normal links
	if (!isChildReplace) {
		item.textContent = newText_;
	}
};

__.sendJSONPrequest = function(serviceUrl_) {
	// remove jQuery JSONP callback if it exists
	var url=serviceUrl_.replace(/&callback=\?$/, '');
	__.getJSONP(url).then(function(data) {
		__.handleJSONresponse(data);
	}, function(err) {
		console.log('VTP JSON request failed! ', err.message);
		__.handleJSONresponse(null);
	});
};

__.getJSONP = function (url_) {
	return new Promise(function (resolve, reject) {
		var timeout = __.oDefaults.xhrTimeout, url,
			callback_name = 'jsonp_callback',
			prefix, script, timeout_trigger = window.setTimeout(function () {
				window[callback_name] = function () {}; // eslint-disable-line no-empty-function
				reject('JSONP Error');
			}, timeout);
		window[callback_name] = function (data) {
			window.clearTimeout(timeout_trigger);
			resolve(data);
		};
		// add 'callback' request param
		if (url_.indexOf(callback_name) < 0) {
			prefix = url_.indexOf('?') < 0 ? '?' : '&';
			url = url_ + prefix + 'callback=' + callback_name;
		}
		script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url || url_;
		document.getElementsByTagName('head')[0].appendChild(script);
	});
};

__.checkForIPHDealerLink = function(origHref) {
	var href = origHref;

	if (IPH.inIPHMode()) {
		href = IPH.addPartnerId(href);
		href = IPH.updateNWB(href);
	}
	return href;
};
__.handleUpdates = function() {
	var _nwb = CONFIGURATOR_API.getNwbLink();
	var restoredLink = '';
	_$VTPlinks = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorVTPlink);
	// Links available in the current page and configuration?
	// only update when last request returned any results
	if (_$VTPlinks.length) {
		// restore original CQ-link from data-attribute
		_$VTPlinks.forEach(function(item) {
			$item = item;
			if (!$item.getAttribute('data-vtp-original')) {
				restoredLink = $item.getAttribute('href');
				$item.setAttribute('data-vtp-original', restoredLink);
			}
		});
		if (!!_nwb) {
			_plainLink = __.checkForIPHDealerLink(_nwb.plainLink);
			_entryLink = __.checkForIPHDealerLink(_nwb.entryLink);
			__.sendJSONPrequest(__.checkForIPHDealerLink(_nwb.queryLink));
		}
	}
};
__.addEvents = function() {
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.handleUpdates);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handleUpdates);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handleUpdates);
};
__.initialize = function() {
	// force singleton
	if (!__.instance) {
		__.addEvents();
		__.instance = true;
	}
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.initialize();
};
export {exports as vtp};
