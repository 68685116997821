import {stateRegistry} from 'microkernel';
import {CONFIGURATION_STORE_ID} from './stores/configuration-store';
import {dpuController} from './dpu/controller';
import {dpuModel} from './dpu/model';
import {polling} from 'core-utils';

class ScsAttributesInitial {
	constructor() {
		if (SETUPS.get('scope.nemo.configuration.force.initial.request') === 1) {
			this._alreadyCalled = false;
			this._makeInitialCallForScsAttributesIfNecessary = this._makeInitialCallForScsAttributesIfNecessary.bind(this);
			stateRegistry.subscribeToStore(CONFIGURATION_STORE_ID, this._makeInitialCallForScsAttributesIfNecessary);
		}
	}

	static get pollTime() {
		return 30000;
	}

	async _makeInitialCallForScsAttributesIfNecessary(state_) {
		if (state_ && state_.configuration && !state_.configuration['stock-car-attrs'] && this._alreadyCalled === false) {
			stateRegistry.unsubscribeFromStore(CONFIGURATION_STORE_ID, this._makeInitialCallForScsAttributesIfNecessary);
			this._alreadyCalled = true;

			try {
				await polling.wait(
					dpuController.isDataInitialized,
					ScsAttributesInitial.pollTime
				);

				const configuration = dpuModel.getConfiguration();

				if (!configuration['stock-car-attrs']) {
					dpuController.loadConfigurationByPrString(dpuModel.getPrString(), dpuModel.getSubsessionID())
						.then(function(response) {
							dpuModel.adoptConfiguration(response);
						});
				}
			}
			catch (e) {
				console.log('scs initial attribute loading timeout');
				return {};
			}

		}
	}
}

const scsAttributesInitial = new ScsAttributesInitial();
export {scsAttributesInitial};
