const template = `
<audi-configurator-carstore-list-item class="carstore-list-item" data-module="carstore-list-item" data-audicode="{{=it.audicode.id}}">
	<div class="carstore-list-item__image-container">
		{{? !!it.images && !!it.images.exterior_front && !!it.images.exterior_front['flyout']}}
			<img class="carstore-list-item__image" src="{{=it.mediaservice}}{{=it.images['exterior_front']['flyout']}}" alt="{{=it.configuration.carlineName}}" />
		{{??}}
			<img class="carstore-list-item__image carstore-list-item__image--fail" src={{=SETUPS.get('nemo.url.assets')+"/img/fallback/fb-carstore.png"}} alt="" />
		{{?}}
	</div>
	<div class="carstore-list-item__description">
		{{? !!it.configuration.carlineName}}
			<div class="audi-headline-order-3 carstore-list-item__carline">{{=it.configuration.carlineName}}</div>
		{{?}}
		{{? !!it.configuration.description}}
			<div class="audi-copy-s carstore-list-item__engine">{{=it.configuration.description}}</div>
		{{?}}
		{{? !!it.configuration.prices.total}}
			<div class="audi-copy-m carstore-list-item__price">{{=it.configuration.prices.total}}</div>
		{{?}}
	</div>
	<form action="#" class="carstore-list-item__form">
		<legend class="carstore-list-item__legend">{{=window.i18n['carstore-layer.manage-slots']}}</legend>
		<fieldset class="carstore-list-item__form-administration">
			<div class="audi-dropdown carstore-list-item__form-select-wrapper">
				<select name="carstore-list-item__form-select" class="audi-dropdown-select carstore-list-item__form-select">
					<option value="configuration-load">{{=window.i18n['carstore-layer.load-slot']}}</option>
					<option value="configuration-delete">{{=window.i18n['delete-configuration-label']}}</option>
				</select>
				<svg class="nm-icon-arrow">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
				</svg>
			</div>
			{{? !!it.audicode.id}}
				<div class="audi-copy-s carstore-list-item__audicode">{{=window.i18n['audicode-label']}}: {{=it.audicode.id}}</div>
			{{?}}
			<button class="audi-button carstore-list-item__button carstore-list-item__button--submit j-carstore-submit">
				<span class="audi-button__text">{{=window.i18n['carstore-layer.continue-label']}}</span>
			</button>
		</fieldset>
		<fieldset class="carstore-list-item__form-dialogue">
			<audi-carstore-delete-audicode data-audicode="{{=it.audicode.id}}">
				<button class="audi-button carstore-list-item__button carstore-list-item__button--delete j-carstore-delete" type="submit">
					<span class="audi-button__text">{{=window.i18n['delete-label']}}</span>
				</button>
				<button class="audi-button audi-button--ghost carstore-list-item__button carstore-list-item__button--cancel j-carstore-cancel" type="reset">
				<span class="audi-button__text">{{=window.i18n['abort-label']}}</span>
			</audi-carstore-delete-audicode>
			</button>
		</fieldset>
	</form>
</audi-configurator-carstore-list-item>
`;

export {template as carstoreListItemTemplate};
