import {animate} from 'core-utils';

export default class OfferRate extends HTMLElement {
	static get observedAttributes() {
		return ['data-rate'];
	}

	static get selectors() {
		return {
			circle: '.nm-offer-rate-path',
			text: '.nm-offer-rate-value'
		};
	}

	static get options() {
		return {
			circle: {
				duration: 2000,
				property: 'strokeDashoffset',
				easing: 'easeInOutSine'
			},
			text: {
				duration: 2000,
				property: 'innerHTML',
				easing: 'noEasingInt'
			},
			observer: {
				threshold: 1.0
			}
		};
	}

	constructor() {
		super();
	}

	connectedCallback() {
		this.initElements();
		this.setInitialValues();

		if (this.isIntersectionOberserverAvailable()) {
			this.setupIntersectionObserver();
		}
		else {
			this.startAnimations();
		}
	}

	attributeChangedCallback(name, oldValue, newValue) {
		switch (name) {
			case 'data-rate':
				this.updateRate(oldValue, newValue);
				break;
			default:
		}
	}

	updateRate(oldValue, newValue) {
		if (oldValue !== null && oldValue !== newValue) {
			this.initRate();
			this.startAnimations();
		}
	}

	isIntersectionOberserverAvailable() {
		return 'IntersectionObserver' in window
			&& 'IntersectionObserverEntry' in window
			&& 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
	}

	setupIntersectionObserver() {
		const observer =
			new IntersectionObserver(entries => {
				// we use intersectionRatio instead of isIntersecting for Edge 15
				entries
					.filter(entry => entry.intersectionRatio >= OfferRate.options.observer.threshold)
					.forEach(() => this.startAnimations());
			}, OfferRate.options.observer);

		observer.observe(this);
	}

	initElements() {
		this.circle = this.querySelector(OfferRate.selectors.circle);
		this.text = this.querySelector(OfferRate.selectors.text);
		this.pathLength = typeof this.circle.getTotalLength === 'function' ?
			this.circle.getTotalLength() : 0;
		this.initRate();
	}

	initRate() {
		this.rate = parseInt(this.getAttribute('data-rate'), 10);
		this.rate = this.rate > 100 ? 100 : this.rate;
	}

	setInitialValues() {
		this.circle.style.strokeDasharray = this.pathLength;
		this.circle.style.strokeDashoffset = this.pathLength;
	}

	startAnimations() {
		const circleTargetValue = this.pathLength * (1 - this.rate / 100);

		animate.animateElementStyleProperty(circleTargetValue, this.circle, OfferRate.options.circle);

		if (this.text) {
			animate.animateElementProperty(this.rate, this.text, OfferRate.options.text);
		}
	}
}

if (!window.customElements.get('audi-configurator-offerrate')) {
	window.customElements.define('audi-configurator-offerrate', OfferRate);
}
