const template = `<div class="nm-layer-wrapper nm-layer-wrapper-dialogue">
	<div class="nm-layer nm-layer-dialogue">
		<div class="nm-layer-inner">
			<div class="nm-layer-dialogue-content clearfix">
				<div class="nm-3col clearfix">
					<div class="nm-module nm-start-layer nm-md-configurationstart-implicit-layer">
						<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">
							{{=window.i18n['configurationstart-layer-info']}}
						</h2>
						<p class="nm-el-pg nm-el-pg-01">{{= window.i18n['configurationstart-layer-edit']}}</p>
						<div class="nm-btn-continue">
							<a href="#" class="nm-configurationstart-layer-close nm-el-btn nm-el-btn-01 nm-el-btn-ast" style="float:right;">{{=window.i18n['proceed-label']}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>`;
export {template as configStartImplicitTemplate};
