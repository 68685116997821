import {dom} from 'core-utils';

class Tooltip extends HTMLElement {
	static get selectors() {
		return {
			hideButton: '.nm-tooltip-hide',
			popup: '.nm-tooltip-popup'
		};
	}

	static get classes() {
		return {
			visible: 'nm-j-tooltip-visible',
			tipLeft: 'nm-tip-left',
			tipRight: 'nm-tip-right'
		};
	}

	constructor() {
		super();
	}

	connectedCallback() {
		this.popup = this.querySelector(Tooltip.selectors.popup);
		this.hideButton = this.querySelector(Tooltip.selectors.hideButton);
		this.addEvents();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.addEventListener('click', this.handleTooltipClick.bind(this));
		this.addEventListener('mouseover', this.setPosition.bind(this));
		if (this.hideButton) {
			this.hideButton.addEventListener('click', this.closeTooltip.bind(this));
		}
	}

	removeEvents() {
		this.removeEventListener('click', this.handleTooltipClick.bind(this));
		this.removeEventListener('mouseover', this.setPosition.bind(this));
		if (this.hideButton) {
			this.hideButton.removeEventListener('click', this.closeTooltip.bind(this));
		}
	}

	handleTooltipClick(event) {
		if (this.isCloseButton(event.target)) {
			return;
		}
		this.openTooltip();
	}

	isCloseButton(button) {
		return button === this.hideButton;
	}

	openTooltip() {
		this.popup.classList.add(Tooltip.classes.visible);
	}

	closeTooltip() {
		this.popup.classList.remove(Tooltip.classes.visible);
	}

	setPosition() {
		this.removePositionClass();
		const rect = this.popup.getBoundingClientRect();
		if (rect.left < 0) {
			this.classList.add(Tooltip.classes.tipLeft);
		}
		else if (rect.left + rect.width > window.innerWidth) {
			this.classList.add(Tooltip.classes.tipRight);
		}
	}

	removePositionClass() {
		this.classList.remove(Tooltip.classes.tipRight, Tooltip.classes.tipLeft);
	}
}

if (!window.customElements.get('audi-configurator-tooltip')) {
	window.customElements.define('audi-configurator-tooltip', Tooltip);
}

const tooltipCloseHelper = {
	closeAllTooltips: function() {
		const visibleTooltips = document.querySelectorAll('.' + Tooltip.classes.visible);

		[...visibleTooltips].forEach(popup => {
			popup.classList.remove(Tooltip.classes.visible);
		});
	},
	handleTooltipOutsideClick: function(event) {
		if (dom.isElementOutsideOfElementWithSelector(event.target, 'audi-configurator-tooltip')) {
			this.closeAllTooltips();
		}
	},
	initialize: function() {
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', this.handleTooltipOutsideClick.bind(this));
	}
};

export {tooltipCloseHelper as tooltip};
