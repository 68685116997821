import {dpuApi as API} from './dpu/api';
import {dpuController as DPU_CONTROLLER} from './dpu/controller';
import {dom as DOM_UTILS} from 'core-utils';
import {state as STATE} from 'core-global';
import {appEvents as EVENTS} from 'core-application';
import {router as ROUTER} from 'core-application';
import {template as TPL_RENDERER} from 'core-utils';
import {searchLayerTemplate as SEARCH_TPL} from './search-layer-template';
import {searchAudicodeErrorTemplate as SEARCH_ERROR_TPL} from './search-audicode-error-template';
import {dpuModel as DPU_MODEL} from './dpu/model';

const SEARCH_OVERLAY = '.nm-header-overlay-container .nm-navigation-header-search-overlay.nm-header-overlay-is-open',
	ADDITIONAL_LOGO = '.nm-has-additional-logo',
	ADDITIONAL_LOGO_HEIGHT_ADJUSTMENT = 30,
	__ = {},
	exports = {
		__: __
	};

// private vars
__.mobileBreakpoint = 750;
__.searchLayerOpen = false;
__.searcListProposalsArr = null;
__.audicodeActivated = false;
__.oDefaults = {
	sAcceptAudicodeButton: '.nm-j-audicode-accept'
};

/**
 * load search list proposals from SETUPS url
 * @returns {void}
 */
__.loadSearchListProposals = async function() {
	const url = SETUPS.get('nemo.url.searchproposals');
	return fetch(url, {credentials: 'include'})
		.then(response => {
			if (!response.ok) {
				throw new Error(response.statusText);
			}
			else {
				return response.json();
			}
		})
		.then(response => {
			__.searcListProposalsArr = !!response && !!response.Proposals ? response.Proposals : [];
		})
		.catch(error => console.log('Error fetching search proposals - server responded with: ', error));
};

/**
 * search string in proposals
 * @param {string} searchString_ - the string to search
 * @returns {Array} - the search results
 */
__.searchResultListProposals = function(searchString_) {
	var searchString;
	var searchResults = [];

	if (!!searchString_) {
		searchString = searchString_.toLowerCase();

		if (!!__.searcListProposalsArr && __.searcListProposalsArr.length) {
			jQuery.each(__.searcListProposalsArr, function(index, value) {
				if (!value.Search) {
					return true;
				}
				if (value.Search.indexOf(searchString) > -1) {
					searchResults.push(value);
				}
			});
		}
	}
	return searchResults;
};

/**
 * add events
 * @returns {void}
 */
__.addEvents = function() {
	var eventType = 'click';

	__.legacyEventBus.on('keyup', '.nm-su .nm-su-input', __.onOpenSearchLayer);
	__.legacyEventBus.on('paste', '.nm-su .nm-su-input', __.onOpenSearchLayerDelayed);
	__.legacyEventBus.on('click', '.nm-su .nm-su-input', __.onOpenSearchLayer);
	__.legacyEventBus.on('click', '.nm-su .nm-su-submit', __.onOpenSearchLayer);
	__.legacyEventBus.on('click', '.nm-su .nm-su-reset', __.resetSearch);
	__.legacyEventBus.on('submit', '.nm-su form', __.formSubmit);
	__.eventBus.on(EVENTS.MENUFLYOUT_OPEN, __.clearSearch);
	__.eventBus.on(EVENTS.MENUFLYOUT_CLOSE, __.emptySearchResults);

	// this is needed to make closing work on the iPad
	if (typeof document.documentElement.ontouchstart !== 'undefined') {
		eventType = 'touchstart';
	}
	__.legacyEventBus.on(eventType, __.onClickOutsideOfSearchLayer);
};

/**
 * remove events
 * @returns {void}
 */
__.removeEvents = function() {
	var eventType = 'click';

	__.legacyEventBus.off('keyup', '.nm-su .nm-su-input', __.onOpenSearchLayer);
	__.legacyEventBus.off('paste', '.nm-su .nm-su-input', __.onOpenSearchLayerDelayed);
	__.legacyEventBus.off('click', '.nm-su .nm-su-input', __.onOpenSearchLayer);
	__.legacyEventBus.off('click', '.nm-su .nm-su-submit', __.onOpenSearchLayer);
	__.legacyEventBus.off('click', '.nm-su .nm-su-reset', __.resetSearch);
	__.legacyEventBus.off('submit', '.nm-su form', __.formSubmit);
	__.eventBus.off(EVENTS.MENUFLYOUT_OPEN, __.clearSearch);
	__.eventBus.off(EVENTS.MENUFLYOUT_CLOSE, __.emptySearchResults);

	if (typeof document.documentElement.ontouchstart !== 'undefined') {
		eventType = 'touchstart';
	}
	__.legacyEventBus.off(eventType, __.onClickOutsideOfSearchLayer);
};

/**
 * remove HTML from search results container
 * @returns {void}
 */
__.emptySearchResults = function() {
	jQuery('.nm-su-result-container').empty();
};

/**
 * reset the search
 * @returns {void}
 */
__.resetSearch = function() {
	jQuery(SEARCH_OVERLAY)
		.find('input.nm-su-input')
		.focus();
	__.emptySearchResults();
	jQuery(SEARCH_OVERLAY)
		.find('input.nm-su-input')
		.keyup();
};

/**
 * @param {Object} data_ - data to clear
 * @returns {void}
 */
__.clearSearch = function(data_) {
	if (typeof data_.item !== 'undefined') {
		jQuery(SEARCH_OVERLAY)
			.find('input.nm-su-input')
			.val('');
		jQuery(SEARCH_OVERLAY)
			.find('input.nm-su-input')
			.focus();
	}
};

/**
 * conditional action for submit on return (prevent url form submit in some cases)
 * @param {Event} event_ - the submit event
 * @returns {void}
 */
__.formSubmit = function(event_) {
	var searchString, searchResultsPageUrl;

	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}

	// audicode configuration was loaded
	if (__.loadedConfiguration) {
		__.acceptLoadedConfiguration();
		return false;
	}

	// audicode loading
	if (jQuery('.nm-j-audicode-loading').length) {
		return false;
	}
	searchString = __.readSearchStringFromInput();

	if (searchString.length <= 0) {
		return false;
	}
	searchResultsPageUrl = __.buildSearchResultsPageUrl(searchString);

	if (!!searchResultsPageUrl) {
		__.performSearch(searchResultsPageUrl, searchString);
	}
	return false;
};

/**
 * trigger tracking actions and resolve updated search url
 * @param {string} searchResultsPageUrl_ - the new document.location to target
 * @param {string} searchString_ - the targetUrl
 * @returns {void}
 */
__.performSearch = function(searchResultsPageUrl_, searchString_) {
	const payload = __.generateTrackingPayload(searchResultsPageUrl_, searchString_);
	const searchSubmitEvent = new CustomEvent('CUSTOM_TRACKING_EVENT', payload);

	document.dispatchEvent(searchSubmitEvent);
	document.location = searchResultsPageUrl_;
};

/**
 * generates the payload object to enrich the customEvent
 * @param {string} searchResultsPageUrl_ - the new document.location to target
 * @param {string} searchString_ - the targetUrl
 * @returns {void}
 */
__.generateTrackingPayload = function(searchResultsPageUrl_, searchString_) {
	let elementName = 'button';

	if (document.activeElement.classList.contains('nm-su-input')) {
		elementName = 'other';
	}

	const payload = {
		detail: {
			eventInfo: {
				eventAction: 'internal_link',
				eventName: 'main search - submit'
			},
			attributes: {
				componentName: 'main search',
				label: '',
				currentURL: window.location.href,
				targetURL: searchResultsPageUrl_,
				clickID: '',
				elementName: elementName,
				value: searchString_,
				pos: ''
			}
		}
	};

	return payload;
};

/**
 * read search string from input
 * @returns {string} - the extracted search string
 */
__.readSearchStringFromInput = function() {
	// get the user input
	var searchString = jQuery('.nm-su .nm-su-input').val();
	// remove leading and trailing whitespaces
	searchString = searchString
		.replace(/^\s+/g, '')
		.replace(/</g, '')
		.replace(/>/g, '');
	return searchString;
};

/**
 * delay search layer for paste operation
 * @returns {void}
 */
__.onOpenSearchLayerDelayed = function() {
	var element = this;

	setTimeout(function() {
		jQuery(element).keyup();
	}, 50);
};

/**
 * fake event to catch default event
 * @returns {boolean} false
 */
__.preventEvent = function() {
	return false;
};

/**
 * remove stop words from search string
 * @param {string} searchString_ the raw search string
 * @returns {string} search string without stop words
 */
__.removeStopWordsFromSearchString = function(searchString_) {
	var searchString = searchString_;
	var replaceSearchStopWordsRegExp;

	if (!!API.getSearchStopWords()) {
		replaceSearchStopWordsRegExp = new RegExp('\\b(?:i|' + API.getSearchStopWords() + ')\\b\\s*', 'gi');
		searchString = searchString.replace(replaceSearchStopWordsRegExp, '');
	}
	return searchString;
};

/**
 * detect audicode from input and store
 * @param {Object} resultList_ - the result list object
 * @param {string} searchString_ - the search string
 * @returns {Object} - audicode-enriched result list
 */
__.detectAndStoreAudiCode = function(resultList_, searchString_) {
	if (searchString_.length === 8 && searchString_.search(/[Aa]{1}[a-zA-Z0-9]{7}/) !== -1) {
		resultList_.audicode = searchString_.toUpperCase();
		// Deactivate submit.
		__.legacyEventBus.off('submit', '.nm-su form', __.formSubmit);
		__.legacyEventBus.off('submit', '.nm-su form', __.preventEvent);
		__.legacyEventBus.on('submit', '.nm-su form', __.preventEvent);
	}
	else {
		// Re-activate submit.
		__.legacyEventBus.off('submit', '.nm-su form', __.preventEvent);
		__.legacyEventBus.off('submit', '.nm-su form', __.formSubmit);
		__.legacyEventBus.on('submit', '.nm-su form', __.formSubmit);
	}
	return resultList_;
};

/**
 * handle the audicode input
 * @param {string} searchString_ - the search string
 * @param {*} resultList_ - the result list
 * @returns {Object} - the enriched result list
 */
__.handleAudiCodeInput = function(searchString_, resultList_) {
	var audicodeStopWords = SETUPS.get('nemo.search.audicodestopwords');

	// only check for Audicode if search did not return a match
	if (
		resultList_.audicode &&
		!resultList_.search.length &&
		(!!audicodeStopWords && audicodeStopWords.indexOf(searchString_) === -1)
	) {
		__.triggerAudicodeLoad(resultList_.audicode);
	}
	else {
		resultList_.audicode = null;
	}
	resultList_.searchString = !!searchString_ ? searchString_ : __.readSearchStringFromInput();
	resultList_.siteMapSearch = API.searchNavigation(searchString_, 'siteMap');
	resultList_.modelSearch = API.searchNavigation(searchString_, 'model');
	resultList_.footerSearch = API.searchNavigation(searchString_, 'footer');
	resultList_.gsaSearchUrl = __.buildSearchResultsPageUrl(searchString_);
	resultList_.searchListProposals = __.searchResultListProposals(searchString_);
	return resultList_;
};

/**
 * create and fill the result list
 * @param {string} searchString_ - the search string
 * @returns {Object} - the result list
 */
__.generateResultList = function(searchString_) {
	var resultList = {},
		searchString = searchString_,
		resultArray;

	__.searchLayerOpen = true;

	resultList = __.detectAndStoreAudiCode(resultList, searchString);

	if (searchString.indexOf('|') !== -1) {
		resultList.prstring = searchString;
	}

	if (searchString.search(/^([a-z0-9]+)=((true)|(false))$/) !== -1) {
		resultArray = (/^([a-z0-9]+)=((true)|(false))$/).exec(searchString);
		STATE.triggerModeChange(resultArray[1], resultArray[2]);
	}

	if (searchString.search(/dpu_extra=(.*)/) !== -1) {
		resultArray = (/^dpu_extra=(.*)/).exec(searchString);
		DPU_CONTROLLER.setDpuExtra(resultArray[1]);
	}

	searchString = __.removeStopWordsFromSearchString(searchString);
	// show search result only for inputs with at least 2 Chars
	resultList.search =
		SETUPS.get('showEquipmentsWithinSearchLayerContext') && !!searchString && searchString.length > 1
			? API.search(searchString, true)
			: [];
	resultList = __.handleAudiCodeInput(searchString, resultList);

	return resultList;
};

/**
 * handle the search
 * @param {Event} event_ the original event
 * @param {string} currentInput_ - the current input
 * @param {string} searchString_ - the search string
 * @param {jQuery} $currentLayerContainer_ - the layer container
 * @returns {void}
 */
__.handleSearch = function(event_, currentInput_, searchString_, $currentLayerContainer_) {
	var resultList = __.generateResultList(searchString_);
	var renderedTemplate;
	var changed = currentInput_ !== searchString_ || event_.type === 'click';

	// open search layer on click or change of text input
	if (searchString_.length > 1 && changed) {
		renderedTemplate = TPL_RENDERER.render(SEARCH_TPL, resultList);
		$currentLayerContainer_.empty().html(renderedTemplate);
		__.eventBus.emit(EVENTS.SEARCH_OPEN, resultList);
	}
	else if (searchString_.length <= 1) {
		// nur schließen, wenn wir ein zu kurzes suchwort haben, bei keiner änderung offen bleiben.
		__.closeSearchLayer();
	}
};

/**
 * handle submit in small viewport
 * @param {Event} event_ - the original click event
 * @param {string} searchString_ - the search string
 * @returns {void}
 */
__.handleSmallViewportSubmit = function(event_, searchString_) {
	var searchString = searchString_;
	var searchResultsPageUrl;

	if (jQuery(window).width() < __.mobileBreakpoint && jQuery(event_.target).hasClass('nm-su-submit')) {
		// audicode loading
		if (jQuery('.nm-j-audicode-loading').length) {
			if (__.audicodeActivated) {
				__.acceptLoadedConfiguration();
			}
		}
		else {
			searchString = __.readSearchStringFromInput();
			searchResultsPageUrl = __.buildSearchResultsPageUrl(searchString);

			if (searchResultsPageUrl !== null) {
				document.location = searchResultsPageUrl;
			}
		}
	}
};

/**
 * check whether exit condition for opening search layer applies
 * @param {Event} event_ - the original click event
 * @returns {boolean} true in case of exit condition, false else (and default)
 */
__.isExitConditionFulfilled = function(event_) {
	if (!__.audicodeActivated) {
		__.loadedConfiguration = null;
	}
	else {
		// search result is visible=> accept audicode on return
		if (event_.keyCode === 13 && jQuery('.nm-j-audicode-accept').is(':visible')) {
			__.acceptLoadedConfiguration();
			return true;
		}
	}
	// ignore shift and return (!)
	if (event_.keyCode === 16 || event_.keyCode === 13) {
		return true;
	}
	return false;
};

/**
 * handle for open search layer interaction
 * @param {Event} event_ - the interaction event
 * @returns {void}
 */
__.onOpenSearchLayer = function(event_) {
	let $container, $currentLayerContainer, searchString, currentInput;

	if (__.isExitConditionFulfilled(event_)) {
		return;
	}
	__.searchForm = jQuery(event_.target).parents('form');

	$container = jQuery(this).closest('.nm-su');
	$currentLayerContainer = $container.find('.nm-su-result-container');
	searchString = __.readSearchStringFromInput();
	currentInput = $container.data('current') || '';

	// check if input changed
	if (currentInput !== searchString) {
		__.audicodeActivated = false;
		__.loadedConfiguration = null;
	}
	$container.data('current', searchString);

	__.handleSearch(event_, currentInput, searchString, $currentLayerContainer);
	__.setFlyoutHeight($currentLayerContainer);
	__.handleSmallViewportSubmit(event_, searchString);
};

/**
 * build the gsa search url
 * @param {string} searchString_ - the search string
 * @returns {void}
 */
__.buildSearchResultsPageUrl = function(searchString_) {
	let url = API.getSearchResultsPageUrl();
	const searchGetParameter = (SETUPS.get('nemo.newSiteSearch.enabled')) ? "?q=" : "#search="; /* set query parameter for either sulzer search or (obsolete) gsaSearch */

	if (!url) {
		return null;
	}
	url += searchGetParameter + encodeURIComponent(searchString_.toLowerCase());
	return url;
};

/**
 * handler for click outside of search layer
 * @param {Event} event_ - the click event
 * @returns {void}
 */
__.onClickOutsideOfSearchLayer = function(event_) {
	// only close the layer if there are no other layers opened on top of it or
	// it is clicked inside of the search layer
	let clickedTarget = event_.target;

	if (
		__.searchLayerOpen &&
		__.isClickedTargetOutsideSearchLayer(clickedTarget) &&
		__.isClickedTargetOutsideSearchSearchInputField(clickedTarget) &&
		__.isClickedTargetOutsideAnyOtherLayer(clickedTarget)
	) {
		__.closeSearchLayer();
	}
};

/**
 * has the click been outside of the search layer?
 * @param {Element} clickedTarget_ the click target
 * @returns {boolean} - true if click target is outside of the search layer, else false
 */
__.isClickedTargetOutsideSearchLayer = function(clickedTarget_) {
	// test if element is child of a search layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(clickedTarget_, '.nm-su-result-container');
};

/**
 * has the click been outside of the search input field?
 * @param {Element} clickedTarget_ - the click target
 * @returns {boolean} - true if click target outside of search input field, else false
 */
__.isClickedTargetOutsideSearchSearchInputField = function(clickedTarget_) {
	// test if element is child of a search layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(clickedTarget_, 'div.nm-su');
};

/**
 * has the click been outside of any other layer?
 * @param {Element} clickedTarget_ - the click target
 * @returns {boolean} - true if click target is outside of any other layer, else false
 */
__.isClickedTargetOutsideAnyOtherLayer = function(clickedTarget_) {
	// test if element is child of a any layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(clickedTarget_, '.nm-layer-wrapper');
};

/**
 * close the search layer
 * @returns {void}
 */
__.closeSearchLayer = function() {
	/*
	 * Delay emptying of container, so other listeners on elements
	 * in the container can be executed (e.g. audicode button tracker).
	 */
	setTimeout(function() {
		__.searchLayerOpen = false;
		jQuery('.nm-su-result-container').empty();
		__.eventBus.emit(EVENTS.SEARCH_CLOSE);
	}, 100);
};

/**
 * trigger audicode loading
 * @param {string} audiCode_ - the audicode to load
 * @returns {void}
 */
__.triggerAudicodeLoad = async function(audiCode_) {
	DPU_CONTROLLER.loadConfigurationByAudiCode(audiCode_).then(
		function(data) {
			DPU_MODEL.adoptConfiguration(data).then(() => {
				const carImages = API.getCarImages();
				__.onAudiCodeLoaded({...data, images: carImages});
			});
		},
		function(error) {
			error.message = error.message || window.i18n['standard-audicode-error'];
			error.audicode = audiCode_;
			__.showAudiCodeLoadError(error);
		}
	);
};

/**
 * show audicode loading error
 * @param {Object} error_ - the error object
 * @returns {void}
 */
__.showAudiCodeLoadError = function(error_) {
	var $resultContainer = jQuery('.nm-su-result-container');
	var renderedTemplate;

	error_.message = API.getErrorMessage(error_);
	if (error_.id === 'AUDICODE_CONF_NOT_VALID' && SETUPS.get('scope.nemo.audicode.not_valid_pdflink')) {
		error_.pdflink = SETUPS.get('nemo.pdf.url') + '/audicode/' + error_.audicode;
		const i18nKey = 'search-layer.audicode-pdf';
		error_.pdflabel = (window.i18n && window.i18n[i18nKey]) ? window.i18n[i18nKey] : 'PDF anzeigen';
	}

	renderedTemplate = TPL_RENDERER.render(SEARCH_ERROR_TPL, error_);
	console.error(error_);
	// Re-activate form submission.
	__.legacyEventBus.off('submit', '.nm-su form', __.preventEvent);
	__.legacyEventBus.on('submit', '.nm-su form', __.formSubmit);

	$resultContainer.empty().html(renderedTemplate);
	__.setFlyoutHeight($resultContainer);
};

/**
 * handle an audicode error
 * @param {string} message_ - the error message
 * @returns {void}
 */
__.handleAudiCodeError = function(message_) {
	let error = {};

	if (!!message_) {
		error.message = message_;
	}
	__.audicodeActivated = false;
	__.loadedConfiguration = undefined; // forget loaded Configuration Data
	__.showAudiCodeLoadError(error);
};

/**
 * handler for audicode loaded return from DPU
 * @param {Object} data_ - the audicode data
 * @returns {void}
 */
__.onAudiCodeLoaded = function(data_) {
	let audicode, $el;

	__.loadedConfiguration = data_;

	// look for an input field with the same Audicode
	if (__.loadedConfiguration && data_.audicode && __.loadedConfiguration.audicode.id) {
		audicode = __.loadedConfiguration.audicode.id.toUpperCase();
		// search for an input field with the returne Audicode
		$el = jQuery('.nm-su-input').filter(function() {
			return (
				jQuery(this)
					.val()
					.toUpperCase() === audicode
			);
		});
		if (!!$el && $el.length === 1) {
			__.audicodeActivated = true;
			__.showLoadedConfiguration($el);
		}
		else {
			__.handleAudiCodeError();
		}
	}
	else {
		__.handleAudiCodeError(window.i18n['standard-audicode-error'], 'ERROR loading Audicode');
	}
};

/**
 * show the loaded Configuration
 * @param {Element} $input_ - the input element
 * @returns {void}
 */
__.showLoadedConfiguration = function($input_) {
	const $container = $input_.closest('.nm-su'),
		$currentLayerContainer = $container.find('.nm-su-result-container'),
		configData = {
			search: {},
			audicode: __.loadedConfiguration.audicode.id,
			configuration: __.loadedConfiguration,
			mediaservice: API.getRenderUrl(),
			success: !!__.loadedConfiguration.audicode.success,
			'vtp-audicode': !!__.loadedConfiguration.audicode['other-url'],
			'other-url': !!__.loadedConfiguration.audicode['other-url']
				? __.loadedConfiguration.audicode['other-url']
				: null,
			message: __.loadedConfiguration.audicode.message
		},

		renderedTemplate = TPL_RENDERER.render(SEARCH_TPL, configData);

	$currentLayerContainer.one(
		'click.accept.loaded.audicode',
		__.oDefaults.sAcceptAudicodeButton,
		__.acceptLoadedConfiguration
	);
	$currentLayerContainer.empty().html(renderedTemplate);
	__.setFlyoutHeight($currentLayerContainer);
};

/**
 * accept the loaded configuration
 * @param {Event|null} event_ - the click event
 * @returns {void}
 */
__.acceptLoadedConfiguration = function(event_) {
	let audicode = !!(__.loadedConfiguration && __.loadedConfiguration.audicode)
		? __.loadedConfiguration.audicode
		: null;

	if (!!event_ && !SETUPS.get('scope.nemo.audicode.externallink') && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}

	if (!!event_ && SETUPS.get('scope.nemo.audicode.externallink')) {
		return true;
	}
	__.eventBus.emit(EVENTS.SEARCH_LOAD_AUDICODE, {
		audicode: audicode
	});

	if (!__.loadedConfiguration.audicode['other-url']) {
		DPU_CONTROLLER.handleExternalConfiguration(__.loadedConfiguration, {
			startType: 'audicode-search'
		});
		// always go to the defined url to start a configuration
		ROUTER.open('page', API.getCurrentCarlineUrl());
	}
	else if (__.loadedConfiguration.audicode['other-url'] !== '') {
		if (SETUPS.get('scope.nemo.audicode.externallink')) {
			event_.target.href = __.loadedConfiguration.audicode['other-url'];
			return true;
		}
		else {
			window.location = __.loadedConfiguration.audicode['other-url'];
		}
	}
	__.closeSearchLayer();
};

/**
 * set the flyout height
 * @param {Element} $overlay_ - the search overlay
 * @returns {void}
 */
__.setFlyoutHeight = function($overlay_) {
	jQuery('.nm-su').removeClass('noSrcollbars');

	if ($overlay_.height() < 410) {
		jQuery('.nm-su').addClass('noSrcollbars');
	}
	let flyoutHeight = $overlay_.height() + 150;
	let doubleRow = jQuery(ADDITIONAL_LOGO);

	if (doubleRow.length > 0) {
		flyoutHeight += ADDITIONAL_LOGO_HEIGHT_ADJUSTMENT;
	}
	jQuery(SEARCH_OVERLAY).height(flyoutHeight);
};

/**
 * fulfills the initialization promise and triggers initialization
 * @param {EventEmitter} eventBus_ - the global event bus
 * @returns {void} - nothing
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.legacyEventBus = jQuery('body');
	__.addEvents();
	__.loadSearchListProposals();
};
export {exports as dpuSearch};
