import {doT} from 'core-vendor';
import {router} from 'core-application';

export default class EngineCompareForm extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this.form = this.querySelector('.nm-engine-compare__form');
		this.addEvents();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.form.addEventListener('submit', event => {
			this.submitForm(event);
		});
	}

	removeEvents() {
		this.form.removeEventListener('submit', event => {
			this.submitForm(event);
		});
	}

	submitForm(event) {
		event.preventDefault();

		const linkPattern = this.form.getAttribute('data-link-pattern');
		if (!!linkPattern) {
			const dotTemplate = doT.template(linkPattern);
			const url = dotTemplate({motorcodes: this.getSelectedEngines()});
			router.open('layer', url);
		}
	}

	getSelectedEngines() {
		const engineSelects = this.querySelectorAll('.nm-engine-compare__select[data-compare-type="engine"]');
		return [...engineSelects].map(select => select.value);
	}
}

if (!window.customElements.get('audi-configurator-enginecompare-form')) {
	window.customElements.define('audi-configurator-enginecompare-form', EngineCompareForm);
}
