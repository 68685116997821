import {patternMatching as PATTERN} from 'core-global';

import {dpuApi as CONFIGURATOR_API} from '../dpu/api';

// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
var __ = {},
	/**
	 * Public API
	 *
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};
/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * // To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};

__.register = function () {
	PATTERN.register('carlineID', __.getCarlineId);
};

__.getCarlineId = function() {
	var carlineId;
	return new Promise(function(resolve, reject) {
		carlineId = CONFIGURATOR_API.getCarline();

		if (carlineId) {
			resolve(carlineId);
		}
		else {
			reject(new TypeError('load pattern -carlineID- failed'));
		}
	});
};

exports.initialize = function() {
	__.register();
};

export {exports as patternCarlineId};
