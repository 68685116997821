import {audicode as AUDICODE} from './audicode/audicode';

export default class CarCompare extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		if (this.isAudicodeParamInUrl()) {
			this.injectCompareElement();
		}
		else {
			AUDICODE.loadAudiCode()
				.then(audicode => {
					this.updateURL(audicode);
				})
				.then(() => {
					this.injectCompareElement();
				});
		}
	}

	isAudicodeParamInUrl() {
		const url = new URL(window.location.href);
		return url.searchParams.has('ac');
	}

	updateURL(audicode) {
		const newURL = new URL(window.location.href);
		newURL.searchParams.set('ac', audicode);
		window.history.replaceState({path: newURL.href}, '', newURL.href);
	}

	injectCompareElement() {
		const customElement = document.createElement('car-compare');
		this.appendChild(customElement);
	}
}

if (window.customElements.get('audi-configurator-car-compare') === undefined) {
	window.customElements.define('audi-configurator-car-compare', CarCompare);
}
