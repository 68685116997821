import {dpuController} from './dpu/controller';
import {polling} from 'core-utils';
import {dpuModel} from './dpu/model';

export const sendDataToConfiguratorDataService = (configurationData) => {
	let configurationUpdate = new CustomEvent('configurationUpdate', {
		detail: configurationData
	});

	document.dispatchEvent(configurationUpdate);
};

const configuratorDataRequestHandler = async () => {
	await polling.wait(
		dpuController.isDataInitialized,
		30000
	);

	const configurationData = dpuModel.getRawConfigurationData();
	sendDataToConfiguratorDataService(configurationData);
};


const registerConfiguratorDataRequestHandler = () => {
	document.addEventListener('configurationDataRequest', () => configuratorDataRequestHandler());
};

registerConfiguratorDataRequestHandler();
