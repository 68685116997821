import {appEvents as EVENTS} from "core-application";
import {aveApi as AVE_API} from "./ave/api";
const __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	'sIdCanvas': 'nm-ave-canvas',

	'sClassActive': 'nm-active',
	'sClassLoading': 'nm-3d-loading',
	'sClassUnLoading': 'nm-3d-unloading',
	'sClassAVECandidate': 'nm-ave-candidate',
	'sClassModeAuto': 'nm-md-in-mode-automatic',
	'sClassLandscapeWrapper': 'nm-md-in-landscape-wrapper',
	'sClassPortraitWrapper': 'nm-md-in-portrait-wrapper',

	'sSelectorInlineRenderingModule': '.nm-md-inline-rendering',
	'sSelectorAVEContainer': '.nm-md-ave-container',
	'sSelectorAVEContainerActive': '.nm-md-ave-container.nm-active',

	'sSelectorInlineRendering': '.nm-j-configurator-renderimage',
	'sSelectorInlineRenderingButton360': '.nm-md-inline-rendering .nm-icon-360-view',
	'sSelectorInlineRenderingButton2d': '.nm-md-inline-rendering .nm-icon-2d-view',
	'sSelectorButton360': '.nm-icon-360-view',
	'sSelectorButton2d': '.nm-icon-2d-view',
	'sSelectorAVECandidate': '.nm-ave-candidate',
	'sSelectorLandscapeWrapper': '.nm-md-in-landscape-wrapper',
	'sSelectorPortraitWrapper': '.nm-md-in-portrait-wrapper',
	'sSelectorLoading': '.nm-3d-loading'
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.AVE_AVAILABLE, __.handlerEnable360);
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlerPageLoaded);
	__.eventBus.on(EVENTS.AVE_STREAM_READY, __.aveStreamReadyHandler);
	__.eventBus.on(EVENTS.PAGE_OPEN, __.handlerPageOpen);
};

/**
 * enable 360 facilities on page loaded event
 * @param {Object} payload - the page content domElement (jQuery)
 * @return {void} nothing
 */
__.handlerPageLoaded = function(payload) {
	var $el;
	if (AVE_API.isAveAvailable() === true) {
		$el=jQuery(payload.domElement);
		if (!$el.find(__.oDefaults.sSelectorInlineRenderingButton360).is(":visible")) {
			$el.find(__.oDefaults.sSelectorInlineRenderingButton360).show();
			$el.find(__.oDefaults.sSelectorInlineRenderingButton360).off('click', __.handlerActivate3d);
			$el.find(__.oDefaults.sSelectorInlineRenderingButton2d).off('click', __.handlerActivate2d);
			$el.find(__.oDefaults.sSelectorInlineRenderingButton360).on('click', __.handlerActivate3d);
			$el.find(__.oDefaults.sSelectorInlineRenderingButton2d).on('click', __.handlerActivate2d);
		}
	}
};

/**
 * enable 360 facilities, i.e. show button and activate listener
 * @returns {void} nothing
 */
__.handlerEnable360 = function() {
	if (AVE_API.isAveAvailable() === true) {
		if (!jQuery(__.oDefaults.sSelectorInlineRenderingButton360).is(":visible")) {
			jQuery(__.oDefaults.sSelectorInlineRenderingButton360).show();
			jQuery(__.oDefaults.sSelectorInlineRenderingButton360).off('click', __.handlerActivate3d);
			jQuery(__.oDefaults.sSelectorInlineRenderingButton2d).off('click', __.handlerActivate2d);
			jQuery(__.oDefaults.sSelectorInlineRenderingButton360).on('click', __.handlerActivate3d);
			jQuery(__.oDefaults.sSelectorInlineRenderingButton2d).on('click', __.handlerActivate2d);
		}
	}
};

/**
 * handler to disable possible 3d stream on page leave (e.g. in-page navi)
 * @returns {void}
 */
__.handlerPageOpen = function() {
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);

	if (activeAveContainer.length > 0) {
		// deactivate active 3d frames
		__.setContainerTo2d(activeAveContainer);
		AVE_API.deactivate(activeAveContainer.attr('id'));
	}
};

/**
 * handler for click on 360° button
 * @param {Object} e - the click event
 * @returns {void}
 */
__.handlerActivate3d = function(e) {
	var eventTarget = jQuery(e.currentTarget);
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);
	var newContainerWrapper = eventTarget.parent().parent();

	e.preventDefault();

	// activate 3d for new container
	__.activate3dForContainer(newContainerWrapper, activeAveContainer);

	// listen to resize to move AVE if necessary
	__.enableResizeHandler();
};

/**
 * activate 3d for container
 * @param {JQuery} newContainerWrapper - the new container to activate
 * @param {JQuery} activeAveContainer - possibly already active container, which will be deactived (may be empty)
 * @returns {void}
 */
__.activate3dForContainer = function(newContainerWrapper, activeAveContainer) {

	var renderImg = newContainerWrapper.find(__.oDefaults.sSelectorInlineRendering);
	var newAveContainer = newContainerWrapper.children(__.oDefaults.sSelectorAVEContainer);
	var newAveContainerId = newAveContainer.attr('id');
	var viewPerspective = renderImg.attr('data-renderimage-view');

	// deactivate other possible 3d frames
	__.setContainerTo2d(activeAveContainer);

	// activate new container
	__.setContainerTo3d(newContainerWrapper, activeAveContainer);


	// move possible other active ave to this place or activate new one
	if (activeAveContainer.length > 0) {
		AVE_API.moveAVE(activeAveContainer.attr('id'), newAveContainerId, viewPerspective);
		__.removeLoadingImg(jQuery(__.oDefaults.sSelectorAVECandidate));
	}
	else {
		AVE_API.activateAVE(newAveContainerId, viewPerspective);
	}
};

/**
 * do animation and style classes on new 3d container
 * @param {jQuery} newContainerWrapper - the new container wrapper
 * @returns {void}
 */
__.setContainerTo3d = function(newContainerWrapper) {
	var renderImg = newContainerWrapper.find(__.oDefaults.sSelectorInlineRendering);
	var newAveContainer = newContainerWrapper.children(__.oDefaults.sSelectorAVEContainer);
	newContainerWrapper.find(__.oDefaults.sSelectorButton360).hide();
	newContainerWrapper.find(__.oDefaults.sSelectorButton2d).show();
	renderImg.addClass(__.oDefaults.sClassLoading);
	renderImg.addClass(__.oDefaults.sClassAVECandidate);
	newAveContainer.addClass(__.oDefaults.sClassActive);
};

/**
 * handler for click on 2D button
 * @param {Object} e - the click event
 * @returns {void}
 */
__.handlerActivate2d = function(e) {
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);

	e.preventDefault();

	__.setContainerTo2d(activeAveContainer);
	AVE_API.deactivate(activeAveContainer.attr('id'));
};

/**
 * reset active ave container to 2D mode
 * @param {JQuery} activeAveContainer - the active AVE container to reset
 * @returns {void}
 */
__.setContainerTo2d = function(activeAveContainer) {

	if (activeAveContainer.length > 0) {
		activeAveContainer.parent().find(__.oDefaults.sSelectorButton2d).hide();
		activeAveContainer.parent().find(__.oDefaults.sSelectorButton360).show();
		setTimeout(function() {
			activeAveContainer.next().removeClass(__.oDefaults.sClassUnLoading);
		}, 1000);

		activeAveContainer.parent().find(__.oDefaults.sSelectorInlineRendering).removeClass(__.oDefaults.sClassLoading);
		activeAveContainer.parent().find(__.oDefaults.sSelectorInlineRendering).removeClass(__.oDefaults.sClassAVECandidate);

		activeAveContainer.removeClass(__.oDefaults.sClassActive);
	}
};

/**
 * remove loading image from target object
 * @param {jQuery} targetObj - the obj from which to remove the loading image
 * @returns {void}
 */
__.removeLoadingImg = function(targetObj) {
	console.log("removeLoadingImg");
	targetObj.addClass(__.oDefaults.sClassUnLoading);
	targetObj.removeClass(__.oDefaults.sClassLoading);
};


__.triggerSwipeTracker = function(event) {
	var isAnimationClick = event.target.closest(".nm-ave-animation-selector-wrapper");
	// TODO: remove tracking dependency!
	// if (!isAnimationClick) {
	//	RENDERINGLAYER_TRACKER.swipeInlineHandler("Rotate");
	// }
};

/**
 * remove loading image when ZL stream ready event fires
 * @returns {void}
 */
__.aveStreamReadyHandler = function() {
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);
	__.removeLoadingImg(jQuery(__.oDefaults.sSelectorLoading));

	if (activeAveContainer.length > 0) {
		activeAveContainer.off('mousedown', __.triggerSwipeTracker);
		activeAveContainer.off('touchstart', __.triggerSwipeTracker);
		activeAveContainer.on('mousedown', __.triggerSwipeTracker);
		activeAveContainer.on('touchstart', __.triggerSwipeTracker);
	}
};

/**
 * enable resize handler
 * @returns {void}
 */
__.enableResizeHandler = function() {
	jQuery(window).off('resize', __.handlerResize);
	jQuery(window).on('resize', __.handlerResize);
};

/**
 * resize handler to move AVE elem when changing from portrait to landscape
 * @returns {void}
 */
__.handlerResize = function() {
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);

	var activeLandscape = activeAveContainer.parent().hasClass(__.oDefaults.sClassLandscapeWrapper);
	var activePortrait = activeAveContainer.parent().hasClass(__.oDefaults.sClassPortraitWrapper);
	var correspondingElem;

	if (jQuery(window).width() < jQuery(window).height() && activeLandscape) {
		// portrait switch
		correspondingElem = activeAveContainer.closest(__.oDefaults.sSelectorInlineRenderingModule).find(__.oDefaults.sSelectorPortraitWrapper);
	}
	else if (jQuery(window).width() >= jQuery(window).height() && activePortrait) {
		// landscape mode
		correspondingElem = activeAveContainer.closest(__.oDefaults.sSelectorInlineRenderingModule).find(__.oDefaults.sSelectorLandscapeWrapper);
	}
	if (correspondingElem) {
		__.activate3dForContainer(correspondingElem, activeAveContainer);
	}
};

/**
 * initialize
 * @param {EventEmitter} eventBus_ - the event bus
 * @returns {void} nothing
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};
export {exports as inlinerendering};
