const template = `
	<div class="carstore-list-item carstore-list-item--fail">
		<div class="carstore-list-item__image-container">
			<img class="carstore-list-item__image carstore-list-item__image--fail" src={{=SETUPS.get('nemo.url.assets')+"/img/fallback/fb-carstore.png"}} alt="" />
		</div>
		<p class="audi-copy-m carstore-list__error-message">
			<span class="carstore-list__error-message-text">{{=it.error.message}}</span>
			<span class="carstore-list__error-message-text">{{=window.i18n['audicode-label']}}: {{=it.audicode}}</span>
		</p>
		<form class="carstore-list-item__form">
			<legend class="carstore-list-item__legend">{{=window.i18n['delete-configuration-label']}}</legend>
			<fieldset>
				<audi-carstore-delete-audicode data-audicode="{{=it.audicode}}">
					<button class="audi-button carstore-list-item__button carstore-list-item__button--delete j-carstore-delete" type="submit">
						<span class="audi-button__text">{{=window.i18n['delete-label']}}</span>
					</button>
				</audi-carstore-delete-audicode>
			</fieldset>
		</form>
	</div>
`;
export {template as carstoreListFailTemplate};
