export default class EngineCompareFilter extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this.fuelDropdown = this.querySelector('.nm-engine-compare__dropdown[data-compare-type="fuel"]');
		this.engineDropdown = this.querySelector('.nm-engine-compare__dropdown[data-compare-type="engine"]');

		this.trimlineSelect = this.querySelector('.nm-engine-compare__select[data-compare-type="trimline"]');
		this.fuelSelect = this.querySelector('.nm-engine-compare__select[data-compare-type="fuel"]');
		this.engineSelect = this.querySelector('.nm-engine-compare__select[data-compare-type=engine]');

		this.fuelOptions = this.querySelectorAll('.nm-engine-compare__select[data-compare-type="fuel"] option');
		this.engineOptions = this.querySelectorAll('.nm-engine-compare__select[data-compare-type="engine"] option');

		this.hasTrimline = this.trimlineSelect !== null;
		this.selectedFuel = this.fuelSelect.value;
		this.selectedEngine = this.engineSelect.value;

		this.updateFuelAndEngineOptions();
		this.addEvents();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		if (this.hasTrimline) {
			this.trimlineSelect.addEventListener('change', () => {
				this.updateFuelAndEngineOptions();
			});
		}
		this.fuelSelect.addEventListener('change', () => {
			this.updateEngineOptions();
		});
	}

	removeEvents() {
		if (this.hasTrimline) {
			this.trimlineSelect.removeEventListener('change', () => {
				this.updateFuelAndEngineOptions();
			});
		}
		this.fuelSelect.removeEventListener('change', () => {
			this.updateEngineOptions();
		});
	}

	updateFuelAndEngineOptions() {
		this.updateFuelOptions();
		this.updateEngineOptions();
	}

	updateFuelOptions() {
		if (!this.hasTrimline) {
			return; // no further filtering needed
		}

		this.selectedTrimline = this.trimlineSelect.value;
		this.fuelSelect.innerHTML = '';

		[...this.fuelOptions].forEach(option => {
			if (this.isValidOptionForSelectedTrimline(option)) {
				option.selected = option.value === this.selectedFuel;
				this.fuelSelect.add(option);
			}
		});
		this.setDropdownReadonly(this.fuelDropdown, this.fuelSelect.length === 1);
	}

	updateEngineOptions() {
		if (this.hasTrimline) {
			this.selectedTrimline = this.trimlineSelect.value;
		}

		this.selectedFuel = this.fuelSelect.value;
		this.engineSelect.innerHTML = '';
		[...this.engineOptions].forEach(option => {
			if (this.isValidOptionForSelectedTrimlineAndFuel(option)) {
				option.selected = option.value === this.selectedEngine;
				this.engineSelect.add(option);
			}
		});
		this.setDropdownReadonly(this.engineDropdown, this.engineSelect.length === 1);
	}

	isValidOptionForSelectedTrimlineAndFuel(option) {
		return this.isValidOptionForSelectedTrimline(option) && this.isValidOptionForSelectedFuel(option);
	}

	isValidOptionForSelectedTrimline(option) {
		return !this.hasTrimline || option.getAttribute('data-trimline') === this.selectedTrimline;
	}

	isValidOptionForSelectedFuel(option) {
		return option.getAttribute('data-fuel') === this.selectedFuel;
	}

	setDropdownReadonly(dropdown, isReadonly) {
		if (isReadonly) {
			dropdown.classList.add('nm-form-readonly');
		}
		else {
			dropdown.classList.remove('nm-form-readonly');
		}
	}
}

if (!window.customElements.get('audi-configurator-enginecompare-filter')) {
	window.customElements.define('audi-configurator-enginecompare-filter', EngineCompareFilter);
}
