import {appEvents as EVENTS} from "core-application";
import {dom as DOM_UTILS} from "core-utils";
import {dpuApi as DPU_API} from "./dpu/api";
const __ = {},
	exports = {
		'__': __
	};
__.oDefaults = {
	sAttributeInitialCaption: 'data-initialcaption',
	sAttributePrNumber: 'data-partprnumber',
	sClassAozActive: 'nm-aoz-active',
	sClassAozButton: 'nm-aoz-btn',
	sClassAozDescription: 'nm-aoz-description',
	sClassAozTyreLabel: 'nm-aoz-tyre-label',
	sClassAozHintText: 'nm-aoz-hinttext',
	sClassAozInvisible: 'nm-aoz-invisible',
	sClassAozItem: 'nm-aoz-item',
	sClassAozModule: 'nm-md-aoz',
	sClassAozPrice: 'nm-aoz-price',
	sSelectorAozButton: '.nm-aoz-btn a',
	sSelectorAozItem: '.nm-aoz-item',
	selectorModul: '.nm-md-aoz',
	selectorStripe: '.nm-aoz-stripe',
	classShowPrevArrow: 'nm-j-aoz-show-prev-arrow',
	classShowNextArrow: 'nm-j-aoz-show-next-arrow',
	classPrevButton: 'nm-aoz-stripe-prev',
	selectorArrowButton: '.nm-aoz-stripe-navigation-item',
	selectorPrevButton: '.nm-aoz-stripe-prev',
	selectorNextButton: '.nm-aoz-stripe-next',
	selectorStripeContainer: '.nm-aoz-stripe-container',
	selectorStripeWrap: '.nm-aoz-stripe-wrap',
	selectorStripeList: '.nm-aoz-stripe > ul',
	selectorStripeItem: '.nm-aoz-item'
};
__.scrollDuration = 500;
/**
 * add events
 * @returns {void}
 */
__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.onConfigurationUpdate);
	__.eventBus.on(EVENTS.CONFIG_UPDATE, __.onConfigurationUpdate);
	__.domEventDelegate.on('scroll', __.oDefaults.selectorStripe, __.stripeScrollHandler);
	__.domEventDelegate.on('click', __.oDefaults.sSelectorAozItem, __.toggleItem);
	__.domEventDelegate.on('click', __.oDefaults.sSelectorAozButton, __.toggleConfigItem);

	__.domEventDelegate.on('click', __.oDefaults.selectorPrevButton, __.handlePrevNextClick);
	__.domEventDelegate.on('click', __.oDefaults.selectorNextButton, __.handlePrevNextClick);
	window.addEventListener('resize', __.resizeModule);
};

__.onConfigurationUpdate =  function() {
	__.handleSelectionUpdated();
};
/**
 * private function for resizing elements in module
 * @returns {undefined} nothing
 */
__.resizeModule = function() {
	var stripe, stripeItems, stripeWrap, stripeContainer,
		aozModules = DOM_UTILS.getElementsArray(__.oDefaults.selectorModul);

	aozModules.forEach(function(module) {
		stripeContainer = DOM_UTILS.getElement(__.oDefaults.selectorStripeContainer, module);
		stripe = DOM_UTILS.getElement(__.oDefaults.selectorStripe, module);

		if (!!stripe) {
			stripeWrap = DOM_UTILS.closest(stripe, __.oDefaults.selectorStripeWrap);
			__.hideNavigationArrows(stripeWrap);
		}
	});
};
/**
 * private function to handle prev & next click on stripe
 * @param {Event} event_ - click event
 * @returns {undefined}
 */
__.handlePrevNextClick = function(event_) {
	var direction = "next",
		target = DOM_UTILS.closest(event_.target, __.oDefaults.selectorArrowButton);
	if (!target) {
		return;
	}
	if (target.classList.contains(__.oDefaults.classPrevButton)) {
		direction = "prev";
	}
	__.translateStripe(target, direction);
};
/**
 * private function for translating the stripe
 * @param {HTMLElement} context_ - context module
 * @param {string} direction_ - 'next' or 'previous'
 * @returns {void} nothing
 */
__.translateStripe = function(context_, direction_) {
	var newTranslate, scrollDuration,
		stripeContainer = DOM_UTILS.getElement(__.oDefaults.selectorStripeContainer, __.getCurrentModule(context_)),
		stripeWrap = DOM_UTILS.closest(context_, __.oDefaults.selectorStripeWrap),
		stripe = DOM_UTILS.getElement(__.oDefaults.selectorStripe, stripeWrap),
		stripeList = DOM_UTILS.getElement(__.oDefaults.selectorStripeList, stripeWrap);

	if (stripeList.clientWidth > stripeContainer.clientWidth) {
		stripeWrap.classList.remove(__.oDefaults.classShowPrevArrow);
		stripeWrap.classList.remove(__.oDefaults.classShowNextArrow);

		newTranslate = __.calculateNewScrollPosition(stripeContainer, stripe.scrollLeft, direction_, stripeList.clientWidth);
		scrollDuration = __.calculateScrollDuration(stripeContainer, stripe.scrollLeft, newTranslate);
		DOM_UTILS.animateElementX(newTranslate, stripe, scrollDuration).then(function() {
			__.hideNavigationArrows(stripeWrap);
		});
	}
};
/**
 * private function for getting the current module
 * @param {HTMLElement} target_ - event target
 * @returns {HTMLElement} module container/context
 */
__.getCurrentModule = function(target_) {
	if (!target_) {
		return null;
	}
	return DOM_UTILS.closest(target_, __.oDefaults.selectorModul);
};
/**
 * @description private function calculating the duration for the scrolling
 * @param {DOMElement} stripeContainer_ - the container of the stripes
 * @param {DOMElement} currentPos_ - the left scroll position of the stripe
 * @param {NUMBER} newTranslate_ - the new scroll position
 * @returns {NUMBER} the duration of the scrollings
 */
__.calculateScrollDuration = function(stripeContainer_, currentPos_, newTranslate_) {
	return __.scrollDuration * (Math.abs(currentPos_ - newTranslate_)/stripeContainer_.clientWidth);
};

/**
 * @description private function calculating the new scroll position
 * @param {DOMElement} stripeContainer_ - the container of the stripes
 * @param {DOMElement} currentPos_ - the left scroll position of the stripe
 * @param {string} direction_ - "next" or "prev"
 * @param {NUMBER} stripeWidth_ - the width of the stripe
 * @returns {NUMBER} the new scroll position
 */
__.calculateNewScrollPosition = function(stripeContainer_, currentPos_, direction_, stripeWidth_) {
	var newTranslate,
		containerWidth = stripeContainer_.clientWidth;

	if (direction_ === "next") {
		newTranslate = Math.min(currentPos_ + containerWidth, stripeWidth_ - containerWidth);
	}
	else {
		newTranslate = Math.max(0, currentPos_ - containerWidth);
	}
	return newTranslate;
};
/**
 * private function for hiding navigation arrows when neccessary
 * @param {HtmlElement} stripeWrap_ - stripe
 * @returns {void} nothing
 */
__.hideNavigationArrows = function(stripeWrap_) {
	var stripeWrap = stripeWrap_,
		stripe = DOM_UTILS.getElement(__.oDefaults.selectorStripe, stripeWrap),
		stripeItems = DOM_UTILS.getElementsArray(__.oDefaults.selectorStripeItem, stripeWrap),
		stripeItemList = DOM_UTILS.getElement(__.oDefaults.selectorStripeList, stripeWrap),
		scrollHideOffset = 10;

	if (stripeItems.length === 0) {
		return;
	}
	if (stripe.scrollLeft > scrollHideOffset) {
		stripeWrap.classList.add(__.oDefaults.classShowPrevArrow);
	}
	else {
		stripeWrap.classList.remove(__.oDefaults.classShowPrevArrow);
	}
	if ((stripeWrap.clientWidth + stripe.scrollLeft) < (stripeItemList.clientWidth - scrollHideOffset)) {
		stripeWrap.classList.add(__.oDefaults.classShowNextArrow);
	}
	else {
		stripeWrap.classList.remove(__.oDefaults.classShowNextArrow);
	}
};
/**
 * private function for scrolling event handling
 * @param {Event} event_ - scroll event
 * @returns {void} nothing
 */
__.stripeScrollHandler = function(event_) {
	var stripe = event_.target,
		stripeWrap = DOM_UTILS.closest(stripe, __.oDefaults.selectorStripeWrap);
	if (!!stripeWrap) {
		__.hideNavigationArrows(stripeWrap);
	}
};
/**
 * toggle item
 * @param {Event} e - the click event on an item
 * @returns {void}
 */
__.toggleItem = function(e) {
	var closestModule = __.getClosestElementWithClass(e.target, __.oDefaults.sClassAozModule);
	var closestItem = __.getClosestElementWithClass(e.target, __.oDefaults.sClassAozItem);
	e.preventDefault();
	e.stopPropagation();

	if (closestModule && closestItem) {
		if (closestItem.classList.contains(__.oDefaults.sClassAozActive)) {
			__.unsetSelection(closestModule);
		}
		else {
			__.setNewSelection(closestModule, closestItem);
		}
	}
	__.handleSelectionUpdated();
};
/**
 * toggle config item
 * @param {Event} e - click event on config button
 * @returns {void}
 */
__.toggleConfigItem = function(e) {
	var closestModule = __.getClosestElementWithClass(e.target, __.oDefaults.sClassAozModule);
	var activeItem = closestModule.getElementsByClassName(__.oDefaults.sClassAozActive)[0];
	var prNumber = activeItem.getAttribute(__.oDefaults.sAttributePrNumber);
	var configuratorId = prNumber.split(" ").join("_");

	e.preventDefault();
	__.updateButtonWrapperClass(closestModule, activeItem);
};
/**
 * retrieve pr numbers from active module
 * these pr numbers have to be filtered from the dpu request, because they
 * might overrule the call to the stateless render service
 * @param {Element} closestModule - the active module
 * @returns {Array} - prNumbers
 */
__.retrievePrNumbersToFilter = function(closestModule) {
	var i, tilesLength;
	var tiles = closestModule.getElementsByClassName(__.oDefaults.sClassAozItem);
	var prNumbersToFilter = [];

	for (i=0, tilesLength = tiles.length; i<tilesLength; i++) {
		prNumbersToFilter.push(tiles[i].getAttribute('data-configurator-id'));
	}
	return prNumbersToFilter;
};
/**
 * update render image (calls stateless render service)
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {string} prNumber - the pr number to retrieve image from
 * @returns {void}
 */
__.updateRenderImage = function(closestModule, prNumber) {
	var i, renderImagesCount, currentRenderView, currentRenderType, imageUrl;
	var renderViews = [], renderSizes = [];
	var renderImages = closestModule.getElementsByClassName('nm-j-configurator-renderimage');
	var prNumbersToFilter = __.retrievePrNumbersToFilter(closestModule);

	for (i=0, renderImagesCount = renderImages.length; i<renderImagesCount; i++) {
		renderSizes.push(renderImages[i].getAttribute('data-renderimage-type'));
		currentRenderView = renderImages[i].getAttribute('data-renderimage-view');
		if (renderViews.indexOf(currentRenderView) <= -1) {
			renderViews.push(currentRenderView);
		}
	}
	DPU_API.sendRenderServiceRequest(prNumbersToFilter, prNumber, renderViews.join(','), renderSizes.join(',')).then(function(result) {
		for (i=0, renderImagesCount = renderImages.length; i<renderImagesCount; i++) {
			currentRenderType = renderImages[i].getAttribute('data-renderimage-type');
			currentRenderView = renderImages[i].getAttribute('data-renderimage-view');
			imageUrl = result['render-results'][prNumber][currentRenderView][currentRenderType];
			renderImages[i].setAttribute('style', 'background-image: url('+imageUrl+')');
		}
	});
};
/**
 * reset render image to default render image
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @returns {void}
 */
__.resetRenderImage = function(closestModule) {
	var i, renderImagesCount, origImageUrl;
	var renderImages = closestModule.getElementsByClassName('nm-j-configurator-renderimage');

	for (i=0, renderImagesCount = renderImages.length; i<renderImagesCount; i++) {
		origImageUrl = renderImages[i].getAttribute('data-url');
		renderImages[i].setAttribute('style', 'background-image: url('+origImageUrl+')');
	}
};
/**
 * get a classname with only known substring of this class
 * @param {Element} element - the html element to check classlist
 * @param {string} substring - the known substring of the classname to match
 * @returns {string} the complete classname compliant to the pattern
 */
__.getClassBySubstring = function(element, substring) {
	var i, classListLength;
	var retrievedClass = false;
	for (i=0, classListLength=element.classList.length; i<classListLength; i++) {
		if (element.classList[i].indexOf(substring) > -1) {
			retrievedClass = element.classList[i];
			break;
		}
	}
	return retrievedClass;
};
/**
 * the button wrapper needs to get the correct nm-j-configurator-status_xxxx class
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {Element} closestItem - the active aoz item ("closest to click")
 * @returns {void}
 */
__.updateButtonWrapperClass = function(closestModule, closestItem) {
	var activeButton = closestModule.getElementsByClassName(__.oDefaults.sClassAozButton)[0];
	var newStatusClass = __.getClassBySubstring(closestItem, 'nm-j-configurator-status_');
	var oldStatusClass = __.getClassBySubstring(activeButton, 'nm-j-configurator-status_');

	if (oldStatusClass) {
		activeButton.classList.remove(oldStatusClass);
	}

	if (newStatusClass) {
		activeButton.classList.add(newStatusClass);
	}
	__.updateButtonTrackingAttributes(closestModule, closestItem);
};
/**
 * update tracking attributes
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {Element} closestItem - the active aoz item ("closest to click")
 * @returns {void}
 */
__.updateButtonTrackingAttributes = function(closestModule, closestItem) {
	var buttonToClick = closestModule.getElementsByClassName('nm-el-btn')[0];
	var configItemName = closestItem.getAttribute('data-name');
	var configItemFamilyGroup = closestItem.getAttribute('data-familygroup');
	var configItemFamily = closestItem.getAttribute('data-family');
	configItemName = configItemFamily !== "" ? configItemFamily + ':' + configItemName : configItemName;
	configItemName = configItemFamilyGroup !== "" ? configItemFamilyGroup + ':' + configItemName : configItemName;
	configItemName = JSON.stringify(configItemName);

	buttonToClick.parentNode.setAttribute('data-configurator-id', closestItem.getAttribute('data-configurator-id'));
};
/**
 * update description
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {string} text - the new text to set
 * @returns {void}
 */
__.updateDescription = function(closestModule, text) {
	var descriptionTag = closestModule.getElementsByClassName(__.oDefaults.sClassAozDescription)[0];

	if (text === false) {
		descriptionTag.innerHTML = descriptionTag.getAttribute(__.oDefaults.sAttributeInitialCaption);
	}
	else {
		descriptionTag.innerHTML = text;
	}
};
/**
 * update tyre label link
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {string} optionId - the configurator id of the item
 * @returns {void}
 */
 __.updateTyreLabel = function(closestModule, optionId) {
	const tyreLabelElement = closestModule.getElementsByClassName(__.oDefaults.sClassAozTyreLabel)[0];

	if (!optionId) {
		tyreLabelElement.innerHTML = '';
	}
	else {
		tyreLabelElement.innerHTML = `<feature-app
			id="tyre-label-link:aoz-inlinerendering:${optionId}"
			base-url="https://fa-tyre-label-link.cdn.prod.arcade.apps.one.audi/v2.2.0/fh/"
			src="app.js"
			server-src="app.node.js"
			config='{"optionId": "${optionId}"}'>
		</feature-app>`;

		// trigger content:rendered to inform the integrator to initialize the feature apps
		const customEvent = new CustomEvent('content:rendered', {detail: {element: tyreLabelElement}});
		document.dispatchEvent(customEvent);
	}
};
/**
 * update price
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {string} text - the new text to set
 * @returns {void}
 */
__.updatePrice = function(closestModule, text) {
	var priceTag = closestModule.getElementsByClassName(__.oDefaults.sClassAozPrice)[0];
	priceTag.innerHTML = text;
};
/**
 * update hint text
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {string} text - the new text to set
 * @returns {void}
 */
__.updateHintText = function(closestModule, text) {
	var hintTextTag = closestModule.getElementsByClassName(__.oDefaults.sClassAozHintText)[0];
	hintTextTag.innerHTML = text;
};
/**
 * unset selection (i.e. no aoz item is selected anymore)
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @returns {void}
 */
__.unsetSelection = function(closestModule) {
	__.removeClassFromElementsWithClassWithinDomFragment(closestModule, __.oDefaults.sClassAozItem, __.oDefaults.sClassAozActive);
	__.updateDescription(closestModule, false);
	__.updateTyreLabel(closestModule, false);
	__.updatePrice(closestModule, '&nbsp;');
	__.updateHintText(closestModule, '&nbsp;');
	__.addClassToElementsWithClassWithinDomFragment(closestModule, __.oDefaults.sClassAozButton, __.oDefaults.sClassAozInvisible);
	__.resetRenderImage(closestModule);
};
/**
 * set new selection
 * @param {Element} closestModule - the active aoz module ("closest to click")
 * @param {Element} closestItem - the actuve aoz item
 * @returns {void}
 */
__.setNewSelection = function(closestModule, closestItem) {
	__.updateDescription(closestModule, closestItem.getAttribute('data-name'));
	__.updateTyreLabel(closestModule, closestItem.getAttribute('data-configurator-id'));
	__.updatePrice(closestModule, closestItem.getAttribute('data-price'));
	__.updateHintText(closestModule, closestItem.getAttribute('data-hinttext'));
	__.removeClassFromElementsWithClassWithinDomFragment(closestModule, __.oDefaults.sClassAozItem, __.oDefaults.sClassAozActive);
	closestItem.classList.add(__.oDefaults.sClassAozActive);
	__.removeClassFromElementsWithClassWithinDomFragment(closestModule, __.oDefaults.sClassAozButton, __.oDefaults.sClassAozInvisible);
	__.updateButtonWrapperClass(closestModule, closestItem);
};
/**
 * get closest element (same or up the parent tree) with given class
 * @param {Element} inputElem - the element to search from
 * @param {string} selectorClass - the selector class
 * @returns {Element} matched element or false if no match
 */
__.getClosestElementWithClass = function(inputElem, selectorClass) {
	var elemToCheck = inputElem;

	while (elemToCheck.classList && !elemToCheck.classList.contains(selectorClass)) {
		if (elemToCheck.parentNode) {
			elemToCheck = elemToCheck.parentNode;
		}
		else {
			return false;
		}
	}
	return elemToCheck.parentNode ? elemToCheck : false;
};
/**
 * remove class from all elements within given dom fragment
 * @param {Element} domFragment - the dom fragment to operate on
 * @param {string} selectorClass - the selector class for elements to match
 * @param {string} classToRemove - the class to remove
 * @returns {void}
 */
__.removeClassFromElementsWithClassWithinDomFragment = function(domFragment, selectorClass, classToRemove) {
	var i, itemCount;
	var items = domFragment.getElementsByClassName(selectorClass);

	for (i=0, itemCount=items.length; i<itemCount; i++) {
		items[i].classList.remove(classToRemove);
	}
};
/**
 * add class to all elements within given dom fragment
 * @param {Element} domFragment - the dom fragment to operate on
 * @param {string} selectorClass - the selector class for elements to match
 * @param {string} classToAdd - the class to add
 * @returns {void}
 */
__.addClassToElementsWithClassWithinDomFragment = function(domFragment, selectorClass, classToAdd) {
	var i, itemCount;
	var items = domFragment.getElementsByClassName(selectorClass);

	for (i=0, itemCount=items.length; i<itemCount; i++) {
		items[i].classList.add(classToAdd);
	}
};
/**
 * update status class for aoz item
 * @param {Element} aozItem - the aoz item to operate on
 * @param {string} newStatus - the new status to set
 * @return {void}
 */
__.updateStatusClass = function(aozItem, newStatus) {
	var oldStatusClass = __.getClassBySubstring(aozItem, 'nm-j-configurator-status_');
	aozItem.classList.remove(oldStatusClass);
	aozItem.classList.add('nm-j-configurator-status_'+newStatus);
};
/**
 * handle updated selection (on click selection change and on config update)
 * @returns {void}
 */
__.handleSelectionUpdated = function() {
	var i, aozItemsCount, aozData, partPrNumber, closestModule, statusString;
	var aozItems = document.body.getElementsByClassName(__.oDefaults.sClassAozItem);

	const touchedModules = [];
	for (i=0, aozItemsCount=aozItems.length; i<aozItemsCount; i++) {
		partPrNumber = aozItems[i].getAttribute(__.oDefaults.sAttributePrNumber).split(" ").join("_");
		aozData = DPU_API.getItem(partPrNumber);

		if (aozData.status) {
			__.updateStatusClass(aozItems[i], aozData.status);
		}

		if (aozItems[i].classList.contains(__.oDefaults.sClassAozActive)) {
			closestModule = __.getClosestElementWithClass(aozItems[i], __.oDefaults.sClassAozModule);
			__.updateRenderImage(closestModule, partPrNumber);
			touchedModules.push(closestModule);
		}
	}

	__.fallbackToDpuFlyoutImageIfApplicable(touchedModules);
};

__.regexEscape = function(input){
	if(input) {
		return input.replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&");
	}

	return input;
}

__.isWheel = function(prNumber) {
	if(prNumber && prNumber.toUpperCase().startsWith("MRAD")) {
		return true;
	}

	return false;
}

__.fallbackToDpuFlyoutImageIfApplicable = function(touchedModules) {
	const aozModules = DOM_UTILS.getElementsArray(__.oDefaults.selectorModul);
	// we do not need to apply a fix if aoz modules are not used or all aoz modules are already handled with the specific image render method
	if(!aozModules || aozModules.length == 0 || !touchedModules || aozModules.length == touchedModules.length) {
		return;
	}

	const configuration = DPU_API.getConfiguration();
	const mediaServiceUrl = SETUPS.get('nemo.url.mediaservice');
	if(configuration && configuration["mbv-ids"] && configuration["mbv-ids"].prstring && mediaServiceUrl) {
		const splittedPrElements = configuration["mbv-ids"].prstring.split('|');
		if(splittedPrElements.length > 1) {
			aozModules.forEach(function(module) {
				const touchedModule = touchedModules.find(x=> x.getAttribute('id') == module.getAttribute('id'));
				const defaultColorCode = module.dataset.defaultextcolor;
				if(!touchedModule && defaultColorCode && splittedPrElements[1]) {
					// color of the car was changed in configuration and no accessory item was selected. We try now to display the default car rendering from the DPU
					const isRimConfigured = splittedPrElements.reduce((acc, current)=> !acc ? __.isWheel(current) : acc, false);
					const regex = new RegExp(`\\s${__.regexEscape(splittedPrElements[1])}$`);
					if(!regex.test(defaultColorCode) || isRimConfigured){
						const renderImages = module.getElementsByClassName('nm-j-configurator-renderimage');
						let renderImagesCount;
						for (let i=0, renderImagesCount = renderImages.length; i<renderImagesCount; i++) {
							const currentRenderType = renderImages[i].getAttribute('data-renderimage-type');
							const currentRenderView = renderImages[i].getAttribute('data-renderimage-view');
							if(currentRenderView == 'exterior' && currentRenderType) {
								const flyoutDpuRenderImageUrl = configuration?.assets?.exterior[currentRenderType];
								if(flyoutDpuRenderImageUrl) {
									renderImages[i].setAttribute('style', `background-image: url('${mediaServiceUrl.replace("\/$", "")}/${flyoutDpuRenderImageUrl.replace("^\/", "")}')`);
								}
							}
						}
					}
				}
			});
		}
	}
}
/**
 * initialize
 * @param {EventEmitter} eventBus_ - the event bus
 * @returns {void}
 */
exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
	__.handleSelectionUpdated();
};

export {exports as inlinerenderingAoz};

