import {appEvents as EVENTS} from "core-application";
import {renderinglayer as RENDERINGLAYER} from "./renderinglayer";
import {aveApi as AVE_API} from './ave/api';
import {layerApi as LAYERAPI} from "core-application";
import {ave2cloud} from './ave2cloud';

const __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	'sIdCanvas': 'avecanvas',

	'sClassActive': 'nm-active',
	'sClassAVE': 'ave-layer',

	'sSelectorOptionsWrapper': '.nm-ave-animation-selector-wrapper',
	'sSelectorStageWrapper': '.nm-renderinglayer-stagewrapper',

	'sSelectorBack': '.nm-renderinglayer .nm-backarrow',
	'sSelectorNext': '.nm-renderinglayer .nm-nextarrow',
	'sSelectorOuterRenderingLayer': '.nm-layer-renderinglayer',
	'sSelectorRenderingLayer': '.nm-layer-renderinglayer',
	'sSelectorRenderingStageWrapper': '.nm-renderinglayer-stagewrapper',
	'sSelectorThumbnail': '.nm-renderinglayer-item .nm-rl-placeholder',
	'sSelectorThumbnailActive': '.nm-renderinglayer-item .nm-rl-placeholder.nm-active',

	'sSelectorAVEContainerActive': '.nm-md-ave-container.nm-active',

	'sSelectorActiveStageImageWrapper': '.nm-renderinglayer-imagewrapper.nm-active'
};

__.disableStreamReadyEvent = function() {
	__.eventBus.off(EVENTS.AVE_STREAM_READY, RENDERINGLAYER.disableFor3D);
};

/**
	 * layer close event handler
	 * takes care of placing 3d canvas back into page if necessary
	 * @returns {void}
	 */
__.handlerLayerClosed = function() {
	var originalContainer, originalId;

	if (jQuery(__.oDefaults.sSelectorRenderingLayer).length > 0 && jQuery(__.oDefaults.sSelectorRenderingLayer).first().hasClass(__.oDefaults.sClassAVE)) {

		if (jQuery('.nm-3d-unloading').length > 0) {
			originalContainer = jQuery('.nm-3d-unloading').prev();
			originalId = originalContainer.attr('id');
			__.moveOptionsIntoCanvas();
			AVE_API.moveAVE(__.oDefaults.sIdCanvas, originalId);
			originalContainer.addClass(__.oDefaults.sClassActive);
		}
		else {
			AVE_API.deactivate(__.oDefaults.sIdCanvas);
			jQuery(__.oDefaults.sSelectorOptionsWrapper).remove();
		}
	}
};

/**
	 * custom handler for thumbnail clicks
	 * @returns {void}
	 */
__.handlerThumbnailClick = function() {
	if (!jQuery(this).hasClass(__.oDefaults.sClassActive)) {
		__.activateView(this);
	}
};

/**
	 * left navi handler
	 * @param {Event} e - the click event
	 * @returns {void}
	 */
__.handlerBackClick = function(e) {
	var active = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var prev;

	e.preventDefault();

	if (active.length > 0) {
		prev = RENDERINGLAYER.determinePrevFromActive(active);
	}

	if (typeof prev === 'undefined') {
		prev = RENDERINGLAYER.determineDefaultPrev();
	}
	__.activateView(prev);
};

/**
	 * right navi handler
	 * @param {Event} e - the click event
	 * @returns {void}
	 */
__.handlerNextClick = function(e) {
	var active = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var next;

	e.preventDefault();

	if (active.length > 0) {
		next = RENDERINGLAYER.determineNextFromActive(active);
	}

	if (typeof next === 'undefined') {
		next = RENDERINGLAYER.determineDefaultNext();
	}
	__.activateView(next);
};

/**
	 * activate view for given thumbnail object
	 * @param {jQuery} thumbObj - the thumbnail object
	 * @returns {void}
	 */
__.activateView = function(thumbObj) {

	var lastActive = jQuery(__.oDefaults.sSelectorThumbnailActive);
	var oldKey = lastActive.attr('data-key');
	var newKey = jQuery(thumbObj).attr('data-key');

	jQuery(__.oDefaults.sSelectorThumbnail).removeClass(__.oDefaults.sClassActive);
	jQuery(thumbObj).addClass(__.oDefaults.sClassActive);

	if (newKey !== oldKey) {
		AVE_API.showPerspective(newKey);
	}
};

/**
	 * avoid layer closing when moving out of the canvas during a drag
	 * @param {Event} event - the triggering event
	 * @returns {void}
	 */
__.captureAVEDrag = function(event) {
	// var isAnimationClick = event.target.closest(".nm-ave-animation-selector-wrapper");

	jQuery('body').off('click', '.nm-layer-wrapper', LAYERAPI.wrapperClickHandler);
	jQuery('.nm-layer-wrapper').off('mousedown', __.handlerAVEWrapperClick);
	jQuery('.nm-layer-wrapper').on('mousedown', __.handlerAVEWrapperClick);
	jQuery('.nm-layer-wrapper').off('touchstart', __.handlerAVEWrapperClick);
	jQuery('.nm-layer-wrapper').on('touchstart', __.handlerAVEWrapperClick);
	// TODO fix tracking dependency!
	// if (!isAnimationClick) {
	//	RENDERINGLAYER_TRACKER.swipeHandler("Rotate");
	// }
};

/**
	 * activate listeners on thumbnails
	 * @returns {void}
	 */
__.activateThumbListeners = function() {
	jQuery(__.oDefaults.sSelectorThumbnail).on('click', __.handlerThumbnailClick);
	jQuery(__.oDefaults.sSelectorBack).on('click', __.handlerBackClick);
	jQuery(__.oDefaults.sSelectorNext).on('click', __.handlerNextClick);
	jQuery('#' + __.oDefaults.sIdCanvas).off('mousedown', __.captureAVEDrag);
	jQuery('#' + __.oDefaults.sIdCanvas).off('touchstart', __.captureAVEDrag);
	jQuery('#' + __.oDefaults.sIdCanvas).on('mousedown', __.captureAVEDrag);
	jQuery('#' + __.oDefaults.sIdCanvas).on('touchstart', __.captureAVEDrag);
};

/**
	 * activate close handler on layer background
	 * @returns {void}
	 */
__.handlerAVEWrapperClick = function() {
	jQuery('body').off('mousedown', '.nm-layer-wrapper', LAYERAPI.wrapperClickHandler);
	jQuery('body').on('mousedown', '.nm-layer-wrapper', LAYERAPI.wrapperClickHandler);
	jQuery('body').off('touchstart', '.nm-layer-wrapper', LAYERAPI.wrapperClickHandler);
	jQuery('body').on('touchstart', '.nm-layer-wrapper', LAYERAPI.wrapperClickHandler);
};

/**
	 * legacy function to allow correct styling of options within layer
	 * @returns {void}
	 */
__.moveOptionsOutOfCanvas = function() {
	jQuery(__.oDefaults.sSelectorOptionsWrapper).prependTo(jQuery(__.oDefaults.sSelectorStageWrapper));
};

/**
	 * legacy function to allow correct styling of options within layer
	 * @returns {void}
	 */
__.moveOptionsIntoCanvas = function() {
	jQuery(__.oDefaults.sSelectorOptionsWrapper).prependTo(jQuery('#' + __.oDefaults.sIdCanvas));
};

__.retrieveActiveViewPerspective = function() {
	var activeImage = jQuery(__.oDefaults.sSelectorActiveStageImageWrapper);

	if (activeImage && activeImage.attr('data-view')) {
		return activeImage.attr('data-view');
	}
	else {
		return 'exterior_front';
	}
};

/**
	 * prepare dom for ave injection
	 * @returns {void}
	 */
__.prepareDomForAVE = function() {
	if (document.getElementById(__.oDefaults.sIdCanvas) === null) {
		jQuery(__.oDefaults.sSelectorRenderingStageWrapper).prepend('<div id="' + __.oDefaults.sIdCanvas + '" class="nm-md-ave-container"></div>');
	}
	__.eventBus.on(EVENTS.AVE_STREAM_READY, RENDERINGLAYER.disableFor3D);
	jQuery(__.oDefaults.sSelectorOuterRenderingLayer).addClass(__.oDefaults.sClassAVE);
};

/**
	 * handler for ave injection request (either directly or via event)
	 * @returns {void}
	 */
__.handlerInjectAVE = function() {
	var activeAveContainer = jQuery(__.oDefaults.sSelectorAVEContainerActive);

	if (AVE_API.isAveAvailable()) {
		console.log('AVE - prepare dom');

		__.prepareDomForAVE();

		if (activeAveContainer.length > 0) {
			AVE_API.moveAVE(activeAveContainer.attr('id'), __.oDefaults.sIdCanvas, false);
			RENDERINGLAYER.disableFor3D();
		}
		else {
			AVE_API.activateAVE(__.oDefaults.sIdCanvas, __.retrieveActiveViewPerspective());
		}
		__.handlerFollowupInjection();
	}
	else {
		console.log("renderinginjector - no matching carline");
	}
};

/**
	 * necessary steps after AVE injection
	 * @returns {void}
	 */
__.handlerFollowupInjection = function() {
	__.activateThumbListeners();
	__.moveOptionsOutOfCanvas();
};

/**
	 * handler for layer opening (triggered on RENDERINGLAYER_LOADED)
	 * @returns {void}
	 */
__.handlerLayerOpened = function() {

	if (jQuery(__.oDefaults.sSelectorRenderingLayer).length > 0) {

		if (ave2cloud.isAve2CloudAvailable()) {
			__.prepareDomForAVE();
			__.eventBus.emit('AVE2CLOUD.CONTAINER.READY');
		}
		else if (AVE_API.isActiveCarline()) {
			if (AVE_API.isAveAvailable() === true) {
				__.handlerInjectAVE();
			}
			else {
				__.eventBus.on(EVENTS.AVE_AVAILABLE, __.handlerInjectAVE);
			}
		}
		else {
			jQuery(__.oDefaults.sSelectorOuterRenderingLayer).removeClass(__.oDefaults.sClassAVE);
			jQuery('#' + __.oDefaults.sIdCanvas).remove();
		}
	}
	else {
		console.log("renderinginjector - no matching dom element");
	}
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.RENDERINGLAYER_LOADED, __.handlerLayerOpened);
	__.eventBus.on('jslayer.close', __.handlerLayerClosed);
	__.eventBus.on('jslayer.close', __.disableStreamReadyEvent);
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.addEvents();
};

export {exports as renderinginjector};

