import {patternMatching} from 'core-global';
import {dpuApi} from '../dpu/api';

class PatternPrString {

	initialize() {
		patternMatching.register('prstring', this._getPrString);
	}

	/**
	 * Returns PR String from the current configuration
	 *
	 * @returns {Promise} Promise that immediately resolves with the Pr String
	 */
	_getPrString() {
		return new Promise(
			(resolve) => resolve(dpuApi.getPrString())
		);
	}

}

const patternPrString = new PatternPrString();
export {patternPrString};
