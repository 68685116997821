import {signal} from 'core-application';
import {appEvents} from 'core-application';
import {dpuApi} from './dpu/api';
import {entryScroll} from 'core-global';
import {template, polling} from 'core-utils';
import {dynamicRecommendationTemplate} from './dynamic-recommendation-template-bff';

export default class DynamicRecommendation extends HTMLElement {
	static get selectors() {
		return {
			container: '.nm-module-summary-dynamic-recommendation',
			hidden: 'nm-hidden'
		};
	}

	constructor() {
		super();
		this.eventBus = signal.getEmitter();
		entryScroll.register('recommendations', 'summary-dynamic-recommendation');
	}

	connectedCallback() {
		this.moduleContainer = this.querySelector(DynamicRecommendation.selectors.container);

		this.addEvents();
		this.renderRecommendations();
	}

	disconnectedCallback() {
		this.removeEvents();
	}

	addEvents() {
		this.eventBus.on(appEvents.CONFIG_UPDATE, () => {
			this.renderRecommendations();
		});
	}

	removeEvents() {
		this.eventBus.off(appEvents.CONFIG_UPDATE, () => {
			this.renderRecommendations();
		});
	}

	async renderRecommendations() {
		const recommendations = await this.getRecommendations();

		if (recommendations.length > 0) {
			this.updateContent(this.renderTemplate(recommendations), true);
		}
		else {
			this.updateContent();
		}
	}

	getQueryParams() {
		const context = dpuApi.getContext();
		let prString = dpuApi.getPrString();
		let prString2 = dpuApi.getConfiguration().prstring2;
		const ignoreItemIDs = this.getItemsToIgnore().join('');

		if (!context || !prString) {
			return null;
		}
		if (prString.includes("default")){
			prString = dpuApi.replacePackagesInPrString(prString2);
			prString = dpuApi.resolvePackagesInPrString(prString2);
		}
		else {
			prString = dpuApi.replacePackagesInPrString(prString);
			prString = dpuApi.resolvePackagesInPrString(prString);
		}
		// replace packages from prstring with single items


		return new URLSearchParams({
			context,
			ids: prString,
			ignore: ignoreItemIDs
		});
	}

	getItemsFromData(data, maxItems = 5) {
		return data
			.map(item => {
				const dpuItem = dpuApi.getItem(item.id);

				if (!dpuItem || this.shouldBeIgnored(dpuItem.status)) {
					return null;
				}

				return {...item, ...dpuItem};
			})
			.filter(item => item !== null)
			.slice(0, maxItems);
	}

	shouldBeIgnored(status) {
		const ignoreStatus = ['00000', '00001', '00010', '00011', '00100', '00101', '00110', '00111', '01000', '01001', '01010', '01011', '01100', '01101', '01110', '01111', '10000'];

		return ignoreStatus.includes(status);
	}

	getItemsToIgnore() {
		const allItems = dpuApi.getItems();

		if (!allItems) {
			return [];
		}

		return Object.values(allItems)
			.filter(item => (this.shouldBeIgnored(item.status) && item.id.length === 3))
			.map((item) => item.id);
	}

	getRecommendationsBackendUrl() {
		const BFFUrl = SETUPS.get('nemo.recommendationsBackendUrl');
		const queryParams = this.getQueryParams();

		if (!queryParams || !BFFUrl) {
			return null;
		}

		return BFFUrl + '?' + queryParams.toString();
	}

	async getRecommendations() {
		await polling.wait(dpuApi.isInitialized, 5000);
		const recommendationData = await this.fetchRecommendations();

		if (recommendationData && recommendationData.configuration && recommendationData.configuration.recommendations) {
			return this.getItemsFromData(recommendationData.configuration.recommendations);
		}

		return [];
	}

	async fetchRecommendations() {
		const url = this.getRecommendationsBackendUrl();

		if (!url) {
			return null;
		}

		const options = {method: 'GET'};

		return fetch(url, options)
			.then((response) => {
				return response.json();
			}).catch((error) => {
				console.error('Error:', error);
				return null;
			});
	}

	renderTemplate(recommendations) {
		return template.render(dynamicRecommendationTemplate, recommendations);
	}

	updateContent(html = '', isVisible = false) {
		this.moduleContainer.innerHTML = html;
		if (isVisible) {
			this.classList.remove(DynamicRecommendation.selectors.hidden);
		}
		else {
			this.classList.add(DynamicRecommendation.selectors.hidden);
		}
	}
}

if (!window.customElements.get('audi-configurator-dynamic-recommendation-bff')) {
	window.customElements.define('audi-configurator-dynamic-recommendation-bff', DynamicRecommendation);
}
